<section class="ibe-guest-management ibe-guest-management-section">
  <ibe-guest-management-header type="option"></ibe-guest-management-header>
  <ibe-guest-management-sidebar currentRoute="ModifyReservations"></ibe-guest-management-sidebar>
  <section class="ibe-guest-management-bookings">
    <div class="ibe-guest-management-bookings-header">
      <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="service-bell"><line x1="4.551" y1="36.966" x2="43.449" y2="36.966" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="19.678" y1="11.034" x2="28.322" y2="11.034" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><path d="M41.288,32.644a17.288,17.288,0,0,0-34.576,0Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="24" y1="15.356" x2="24" y2="11.034" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>
      <div>
        <h1>{{ 'guest_management.modify_upcoming_reservations' | translate }}</h1>
        <p>{{ 'guest_management.modify_upcoming_reservations_text' | translate }}</p>
      </div>
    </div>

    <p class="ibe-guest-management-modify-reservation-head">
      <svg id="Icons" class="ibe-reservation-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="info"><circle id="circle" cx="24" cy="24" r="19" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><g id="letter-i"><line id="stem" x1="24" y1="19.713" x2="24" y2="34.171" stroke-width="2" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" fill="none"/><line id="tittle" x1="24" y1="13.829" x2="24" y2="14.829" stroke-width="2" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" fill="none"/></g></g></svg>
      {{ 'guest_management.modify_reservations_header' | translate }}
    </p>

    <ibe-reservations-list
      *ngIf="reservations.length && !isLoading"
      [reservations]="reservations"
      (fetchReservations)="getReservations()"></ibe-reservations-list>

    <div *ngIf="isLoading">
      <ibe-loading-circle></ibe-loading-circle>
    </div>
  </section>
</section>
