<div class="ibe-guest-management ibe-guest-management-header ibe-guest-create-account-header" *ngIf="config.settings.guestManagementEnabled && isLoggedIn">
  <nav class="ibe-guest-management-heading ibe-guest-management-heading-nav">
    <a routerLink="/guest/manage">{{ 'guest_management.manage_reservations' | translate }}</a>
  </nav>
</div>

<div class="ibe-confirmation-header">
  <div class="ibe-confirmation-heading"><i class="ibe-icon ibe-icon-tick-circle"></i> <span class="ibe-confirmation-heading-text">{{ 'booking_confirmation.booking_confirmation' | translate }}</span></div>
  <button onclick="window.print();" class="ibe-btn ibe-primary-btn ibe-print-btn"><i class="ibe-icon ibe-icon-printer"></i> {{ 'booking_confirmation.print' | translate }}</button>
</div>

<h2 class="ibe-thank-you-message"><span class="ibe-thank-you-message-text">{{ 'booking_confirmation.thank_you_for_booking' | translate }}</span></h2>
<p class="ibe-booking-sent-email" *ngIf="config.settings.sendGuestEmailsEnabled">{{ 'booking_confirmation.guest_email_sent' | translate }}</p>

<ibe-loading-bar *ngIf="isLoading" class="ibe-loading-bar" [isLoading]="isLoading"></ibe-loading-bar>

<div *ngFor="let booking of bookings">
  <div class="ibe-booking-id-box"><span class="ibe-booking-id-box-text">{{ 'booking_confirmation.booking_id' | translate }}</span> <span class="ibe-booking-id">#{{ booking.id }}</span></div>
  <div class="ibe-reservations">
    <div class="ibe-reservation-container" *ngFor="let reservation of booking.reservations; let i = index;">
      <ibe-reservation-card
        [reservation]="reservation"
        [reservationKey]="i"
        [showButtons]="false"
        [showAddExtrasButton]="false"
        [showIsCancelled]="false"
        [showCancellationPolicy]="false"
      ></ibe-reservation-card>
    </div>
  </div>

  <ibe-total-summary
    *ngIf="booking"
    [currency]="bookingCurrency"
    [bookingTotals]="calculateBookingTotals(booking.reservations)"
    [isSuppressed]="areRatesSuppressed"
    ></ibe-total-summary>
</div>
