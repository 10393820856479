// TODO: discuss style or refactor to make class smaller
// tslint:disable:max-file-line-count
import { Component, OnInit, AfterViewInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { fadeInOnEnterAnimation, scaleUpAnimation } from '../../animations';
import { BookingService } from '../../services/booking.service';
import { BookingError, BookingErrorDetails, ErrorDialogComponent } from '../../error-dialog/error-dialog.component';
import { AnalyticsService } from '../../services/analytics.service';
import { IbeConfigService } from '../../services/ibe-config.service';
import { CreateBookingRequestResponseModel, CreateBookingResponseModel } from 'up-ibe-types';
import { GuaranteeTypeEnum, HttpStatus } from '../../../enums';
import { getPaymentProviderScriptUrl } from '../../helpers/payment.helper';
import { ScriptLoaderService } from '../../services/script-loader.service';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate, LogGuestMovementGuard } from '../../guards/log-guest-movement-guard';
import { JourneyService } from '../../services/journey.service';
import { captureException, Scope, Severity } from '@sentry/browser';
import { ErrorHandlerService, tryInitializeSentry } from '../../services/error-handler.service';

interface ErrorDialogData {
  price?: string;
  ratePlan?: string;
  description?: string;
}

@Component({
  selector: 'ibe-checkout-payment',
  templateUrl: './checkout-payment.component.html',
  styleUrls: ['./checkout-payment.component.scss'],
  animations: [
    fadeInOnEnterAnimation,
    scaleUpAnimation
  ]
})
export class CheckoutPaymentComponent implements OnInit, AfterViewInit, ComponentCanDeactivate {
  public animationState: string;
  public bookingRequestId: string;

  public paymentRequired: boolean;
  // tslint:disable-next-line:no-any
  public paymentSetupData: any;
  public paymentRedirected = false;
  public hasPaymentScriptLoaded = false;

  public prePaymentAmount = 0;
  public bookingCurrency: string;
  public isLoading = true;
  public inPaymentFlow = false;
  private bypassCreation = false;
  private hasPayload = false;
  private hasSentry = false;

  constructor(
    public readonly httpClient: HttpClient,
    private readonly translate: TranslateService,
    public readonly router: Router,
    private readonly route: ActivatedRoute,
    public readonly bookingService: BookingService,
    public readonly dialog: MatDialog,
    private readonly analyticsService: AnalyticsService,
    public readonly config: IbeConfigService,
    private readonly scriptLoader: ScriptLoaderService,
    private changeDetectorRef: ChangeDetectorRef,
    private logGuestMovementGuard: LogGuestMovementGuard,
    private journeyService: JourneyService
  ) {
    this.hasSentry = tryInitializeSentry();
  }

  @HostListener('window:beforeunload', ['$event'])
  public unloadHandler(event: Event) {
    event.preventDefault();
    return this.logGuestMovementGuard.canDeactivate(this);
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return !this.inPaymentFlow;
  }

  public ngOnInit() {
    if (this.bookingService.booking.reservations.length) {
      this.pageSetupOrRedirect();
    }
  }

  private pageSetupOrRedirect() {
    this.route.queryParamMap.subscribe((queryParams: ParamMap) => {
      if (this._pageHasPayload(queryParams)) {
        this.hasPayload = true;
        const bookingRequestId = this.bookingService.getBookingRequestId();
        if (bookingRequestId) {
          this.bookingRequestId = bookingRequestId;
        } else {
          this.router.navigate(['checkout/payment'], { queryParams: {} });
        }
        this.paymentRedirected = true;
      } else {
        // bypassCreation is used for when the query parameters change and you
        // don't want to create a new booking request.
        if (!this.bypassCreation) {
          this.createBookingRequest();
          this._createCheckoutPaymentEvent();
        }
      }
    });
  }

  private _pageHasPayload(queryParams: ParamMap) {
    return queryParams.has('payload');
  }

  public ngAfterViewInit() {
    this.initExternalScripts();
  }

  public createBookingRequest() {
    this.bookingService.createBookingRequest()
      .subscribe(
        (response: CreateBookingRequestResponseModel) => {
          this.paymentRequired = response.paymentRequired;
          if (!this.paymentRequired) {
            this.isLoading = false;
          }
          this.paymentSetupData = undefined;
          if (response.paymentSetupData && Object.keys(response.paymentSetupData).length) {
            this.paymentSetupData = response.paymentSetupData;
          }
          this.bookingRequestId = response.bookingRequestId;
          this.prePaymentAmount = response.prePaymentAmount;
          this.bookingCurrency = response.currency;
          this.bookingService.saveBookingRequestIdToLocalStorage(response);
        },
        (error) => {
          const data = {
            title: this.translate.instant(`dialog_error_codes.${BookingError.BookingProblem}`),
            details: {},
            allowRetry: false
          };
          if (error.status === HttpStatus.Unprocessable_Entity) {
            data.details = this._getBookingErrors([BookingError.NoAvailability]);
          } else if (error.status === HttpStatus.Bad_Request) {
            data.details = this._getBookingErrors(
              [BookingError.InvalidPmsData],
              this.bookingService.populateBookingErrorMessage(error.error.context)
            );
            data.allowRetry = true;
          } else {
            data.details = this._getBookingErrors([BookingError.BookingFailed]);
          }

          this.dialog.open(ErrorDialogComponent, { data }).afterClosed().subscribe(
            (response) => {
              if (response) {
                this.bookingService.mergeReservationsWithPmsData(error.error.context);
                this._reloadPageOnError();
                return;
              }
              this.bookingService.removeAllReservations();
              const params = this.bookingService.getLastSearchedStayCriteria();

              this.router.navigate(['/booking/results'], {
                queryParams: params
              });
            }
          );
        }
      );
  }

  /**
   * Be careful: Only leave paymentData undefined if you want
   * to make a booking with no payment. e.g. When the IBE
   * has payments turned off
   */
  // tslint:disable-next-line:no-any
  public completeBooking(paymentData?: any) {
    this.isLoading = true;
    if (this.config.getPaymentProvider() === 'saferpay') {
      return this._showConfirmationOnCompletionSaferpay(paymentData);
    }
    this.bookingService.completeBooking(paymentData, this.bookingRequestId)
      .subscribe(
        (bookingResponse: CreateBookingResponseModel) => {
          this.inPaymentFlow = false;
          this._onBookingSuccess(bookingResponse);
          this.journeyService.finaliseJourney();
        },
        (error) => {
          this._onBookingFailure(error);
        }
      );
  }

  private _showConfirmationOnCompletionSaferpay(paymentComplete: CreateBookingResponseModel & BookingErrorDetails) {
    return paymentComplete.success ? this._onBookingSuccess(paymentComplete) : this._onBookingFailure(paymentComplete);
  }

  private _onBookingSuccess(bookingResponse: CreateBookingResponseModel) {
    this.inPaymentFlow = false;
    if (bookingResponse.success) {
      this.analyticsService.createAnalyticsTransactionEvent(
        this.bookingRequestId,
        this.bookingService.booking,
        bookingResponse.pmsBookingIds
      );
      this.isLoading = false;
      const confirmationPath = `${window.location.origin}${window.location.pathname}#/booking/confirmation`;
      const params = `?bookingRequestId=${bookingResponse.bookingRequestId}`;
      window.location.href = confirmationPath + params;
    }
  }

  private _onBookingFailure(error: BookingErrorDetails) {
    let data;
    this.inPaymentFlow = false;
    this.bookingService.clearBookingRequestInProgress();
    if (error.error && !error.error.success && error.error.errorCodes) {
      data = {
        title: this.translate.instant(`dialog_error_codes.${BookingError.BookingProblem}`),
        details: this._getBookingErrors(error.error.errorCodes)
      };
    } else {
      data = {
        title: this.translate.instant(`dialog_error_codes.${BookingError.BookingFailed}.title`),
        message: this.translate.instant(`dialog_error_codes.${BookingError.BookingFailed}.message`)
      };
    }
    this.dialog.open(ErrorDialogComponent, { data }).afterClosed().subscribe(() => {
      this._reloadPageOnError();
    });
  }

  public onRedirectComplete(bookingRequestId: string) {
    this.isLoading = false;
    this.bookingService.clearBookingRequestInProgress();
    // We force loation to booking confirmation to remove intrusive saferpay query params
    const confirmationPath = `${window.location.origin}${window.location.pathname}#/booking/confirmation`;
    const params = `?bookingRequestId=${bookingRequestId}`;
    window.location.href = confirmationPath + params;
  }

  private _reloadPageOnError() {
    /* We include a conditional because clients often use weird path names
      that affects the angular route hashing and may redirect them to a non ibe page.
      It is safer to use window.location.href = paymentPath, to redirect clients in that case
    */
    const paymentPath = `${window.location.origin}${window.location.pathname}#/checkout/payment`;
    // If the page has a payload but needs to refresh then bypass the creation of a new booking
    // Remove all query parameters and reload.
    if (this.hasPayload) {
      this.bypassCreation = true;
    }
    if (window.location.href === paymentPath) {
      window.location.reload();
    } else {
      window.location.href = paymentPath;
    }
  }

  private _getBookingErrors(errorCodes: BookingError[], errorParams?: ErrorDialogData) {
    return errorCodes.map((code: BookingError) => ({
      title: this.translate.instant(`dialog_error_codes.${code}.title`),
      message: this.translate.instant(`dialog_error_codes.${code}.message`, errorParams),
      description: errorParams ? errorParams.description : undefined
    }));
  }

  private _createCheckoutPaymentEvent() {
    const reservations = this.bookingService.booking.reservations;
    const step = 3;
    this.analyticsService.createCheckoutEvent(reservations, step);
  }

  public paymentButtonLabel() {
    if (this.paymentRequired && this.prePaymentAmount > 0) {
      return this.translate.instant('checkout_payment.pay');
    } else {
      return this.translate.instant('checkout_payment.confirm');
    }
  }

  public showPaymentComponent(paymentProvider: string) {
    const show = this.bookingRequestId && this.config.getPaymentProvider() === paymentProvider;
    return this.paymentRedirected ? show : this.paymentSetupData && show;
  }

  public showFlexibleGuaranteeMessage() {
    const guaranteeTypes = this.bookingService.booking.reservations.map((x) => x.guaranteeType);
    return !(guaranteeTypes.includes(undefined) || guaranteeTypes.includes(GuaranteeTypeEnum.Prepayment)) &&
      !this.config.settings.highDemandTime;
  }

  public toggleIsLoading(value: boolean) {
    this.isLoading = value;
    this.changeDetectorRef.detectChanges();
  }

  public togglePaymentRedirected(value: boolean) {
    this.paymentRedirected = value;
    this.logGuestMovementGuard.logGuestReturn();
    this.changeDetectorRef.detectChanges();
  }

  public toggleInPaymentFlow(value: boolean) {
    this.inPaymentFlow = value;
    if (!value) {
      this.logGuestMovementGuard.logGuestRedirect(this.config.getPaymentProvider());
    }
  }

  private initExternalScripts(): void {
    const scriptUrl = getPaymentProviderScriptUrl(
      this.config.getPaymentProvider(),
      this.config.getPaymentProviderSettings().testModeEnabled
    );

    if (scriptUrl) {
      this.scriptLoader.loadScript(scriptUrl)
        .then(() => {
          this.hasPaymentScriptLoaded = true;
        })
        .catch((error: Error) => {
          this.handleError(error, 'http', 'ibe-checkout-payment.loadScript', {
            'scriptUrl': scriptUrl
          });
        });
    }
  }

  // tslint:disable-next-line:no-any
  private handleError(error: any, type: string, category: string, details: { [key: string]: any; }): void {
    if (this.hasSentry) {
      const err = ErrorHandlerService.toError(error);
      details.error = error;
      captureException(err, (scope: Scope) =>
        scope.addBreadcrumb({
          type,
          level: Severity.Critical,
          message: `${err.message}`,
          category,
          data: details
        })
      );
    } else {
      console.error('handleError', error);
    }

    const data = {
      title: 'Error',
      message: `${error}`
    };
    this.dialog.open(ErrorDialogComponent, { data }).afterClosed().subscribe(() => {
      this._reloadPageOnError();
    });
  }
}
