import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import {
  CalendarDay
} from '../../booking/availability-calendar/calendar/calendar';

@Component({
  selector: 'ibe-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input('arrivalDate') public arrivalDate: Date | undefined;
  @Input('departureDate') public departureDate: Date | undefined;
  @Input('hoveredDepartureDate') public hoveredDepartureDate: Date | undefined;
  @Input('currentMonth') public currentMonth: Moment;
  @Input('currentMonthDisplay') public currentMonthDisplay: string;
  @Input('calendarDates') public calendarDates = [[moment]];
  @Input('ibeStartDate') public startDate: Date;
  @Output('onArrivalDateSelection') public onArrivalDateSelection: EventEmitter<Date> = new EventEmitter();
  @Output('onDepartureDateSelection') public onDepartureDateSelection: EventEmitter<Date> = new EventEmitter();
  @Output('onDepartureDateHover') public onDepartureDateHover: EventEmitter<Date> = new EventEmitter();
  @Output('onPreviousMonthClick') public onPreviousMonthClick: EventEmitter<undefined> = new EventEmitter();
  @Output('onNextMonthClick') public onNextMonthClick: EventEmitter<undefined> = new EventEmitter();

  constructor() { }

  public ngOnInit() {
    if (this.currentMonth) {
      this.currentMonth = moment(this.currentMonth, 'YYYY-MM-DD');
    } else {
      this.currentMonth = moment();
    }
  }

  public selectPreviousMonth(event: Event) {
    event.preventDefault();
    this.onPreviousMonthClick.emit();
  }

  public selectNextMonth(event: Event) {
    event.preventDefault();
    this.onNextMonthClick.emit();
  }

  public onDaySelect(event: Event, day: CalendarDay) {
    if (!moment(day.date).isSameOrAfter(moment(this.startDate), 'day')) {
      return false;
    }

    return this._selectDay(day);
  }

  private _selectDay(day: CalendarDay) {
    if (this.arrivalDate && this.departureDate) {
      this.arrivalDate = undefined;
      this.departureDate = undefined;
      this.arrivalDate = day.date.toDate();
    } else {
      this.departureDate = day.date.toDate();
      if (this._isDepartureSameOrBeforeArrival(day)) {
        this.departureDate = moment(this.arrivalDate).add(1, 'days').toDate();
      };
      this.onDepartureDateSelection.emit(this.departureDate);
    }
    this.onArrivalDateSelection.emit(this.arrivalDate);
    return true;
  }

  public onDayHover(event: Event, day: CalendarDay) {
    if (this.arrivalDate && !this.departureDate) {
      this.hoveredDepartureDate = day.date.toDate();
      this.onDepartureDateHover.emit(this.hoveredDepartureDate);
    }
  }

  public dayActive(day: CalendarDay) {
    return this.startDate && day.date.isSameOrAfter(this.startDate, 'day');
  }

  public dayArrival(day: CalendarDay) {
    return this.arrivalDate && day.date.isSame(this.arrivalDate, 'day');
  }

  public dayDeparture(day: CalendarDay) {
    return this.departureDate && day.date.isSame(this.departureDate, 'day');
  }

  public dayStayDate(day: CalendarDay) {
    const isBetweenArrivalAndDepature =
      (this.arrivalDate && this.departureDate && day.date.isBetween(this.arrivalDate, this.departureDate, 'day'));

    const isBetweenArrivalAndSelectedDate = this.arrivalDate && day.date.isBetween(this.arrivalDate, this.hoveredDepartureDate);

    return isBetweenArrivalAndDepature || isBetweenArrivalAndSelectedDate;
  }

  private _isDepartureSameOrBeforeArrival(day: CalendarDay) {
    if (moment(day.date.toDate()).isSameOrBefore(this.arrivalDate, 'day')) {
      return true;
    }

    return false;
  }

  public handleEnter(event: KeyboardEvent, day: CalendarDay) {
    this.onDaySelect(event, day)
  }

}
