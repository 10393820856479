import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibe-guest-management-sidebar',
  templateUrl: './guest-management-sidebar.component.html',
  styleUrls: ['./guest-management-sidebar.component.scss']
})
export class GuestManagementSidebarComponent {
  @Input('currentRoute') public currentRoute: string;

  constructor() { }
}
