import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'ibe-persons-qty-selector',
  templateUrl: './persons-qty-selector.component.html',
  styleUrls: ['./persons-qty-selector.component.scss']
})
export class PersonsQtySelectorComponent implements OnInit {
  public value = 0;
  @Input('minimumValue') public minimumValue = 0;
  @Input('maximumValue') public maximumValue: number;
  @Input('initialValue') public initialValue = 0;
  @Output('onValueChange') public onValueChange: EventEmitter<Number> = new EventEmitter();

  public ngOnInit() {
    this.value = this.initialValue;
  }

  public increment() {
    if (this.value === this.maximumValue) {
      return;
    };

    this.value = this.value + 1;
    this.onValueChange.emit(this.value);
  }

  public decrement() {
    if (this.value <= (this.minimumValue)) {
      return;
    }

    this.value = this.value - 1;
    this.onValueChange.emit(this.value);
  }
}
