import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AvailabilityResultModel, AvailabilityResultRateModel } from 'up-ibe-types';
import { OnAddToCartEvent, FeaturedExtraModel } from '../rate-selector/rate-selector.component';
import { groupBy } from 'lodash';
import { IbeConfigService } from 'app/services/ibe-config.service';
import { MatDialog } from '@angular/material/dialog';
import { RateInfoModalComponent } from '../rate-info-modal/rate-info-modal.component';
import { ExtrasStoreService, ExtraQueryParams } from '../../../../services/extras-store.service';
import { ActivatedRoute } from '@angular/router';

interface GroupedRates {
  [id: string]: AvailabilityResultRateModel[];
}

interface SelectedUnitQuantity {
  [id: string]: number;
}

interface RatesObject {
  key: string;
  value: AvailabilityResultRateModel[];
}

interface AddExtraToCart {
  [rateId: string]: boolean;
}

@Component({
  selector: 'ibe-custom-rate-selector',
  templateUrl: './custom-rate-selector.component.html',
  styleUrls: ['./custom-rate-selector.component.scss']
})
export class CustomRateSelectorComponent implements OnInit {
  @Input('availabilityResult') public availabilityResult: AvailabilityResultModel;
  @Input('pmsPropertyId') public pmsPropertyId: string;
  @Input('propertyName') public propertyName: string;
  @Input('useBasePrices') public useBasePrices: boolean;
  @Output('onAddToCart') public onAddToCart: EventEmitter<OnAddToCartEvent> = new EventEmitter();
  public groupedRates: GroupedRates;
  public selectedUnitQty: SelectedUnitQuantity;
  public addExtraToCart: AddExtraToCart;
  public featuredExtras: FeaturedExtraModel[] = [];

  constructor(
    public config: IbeConfigService,
    private readonly dialog: MatDialog,
    public extrasStore: ExtrasStoreService,
    public route: ActivatedRoute
  ) { }

  public ngOnInit(): void {
    this.groupedRates = groupBy(this.availabilityResult.rates, (rate) => {
      return rate.parentRateId ? rate.parentRateId : rate.id
    });

    Object.keys(this.groupedRates).forEach(key => {
      const groupedRates = this.groupedRates[key];

      const maxRates = 2;

      if (groupedRates.length > maxRates) {
        const ratesToDelete = groupedRates.filter(rate => !rate.isMemberRate && rate.id !== key)

        ratesToDelete.forEach(rateToDelete => {
          groupedRates.splice(groupedRates.indexOf(rateToDelete));
        });

        this.groupedRates[key] = groupedRates;
      }
    })

    this.selectedUnitQty = Object.keys(this.groupedRates).reduce((unitQty: SelectedUnitQuantity, rateId: string) => {
      return {
        ...unitQty,
        [rateId]: 1
      }
    }, {});

    if (this.config.accountFeatureWhitelist.serviceToggle) {
      this.availabilityResult.rates.forEach(rate => {
        const params: ExtraQueryParams = {
          params: {
            adults: this.route.snapshot.queryParams.adults,
            arrival: this.route.snapshot.queryParams.arrival,
            departure: this.route.snapshot.queryParams.departure,
            propertyId: this.route.snapshot.queryParams.propertyId,
            ratePlanId: rate.id
          }
        }

        if (this.route.snapshot.queryParams.childrenAges) {
          params.params.childrenAges = this.route.snapshot.queryParams.childrenAges
        }

        this.extrasStore.load(params).subscribe(response => {
          const potentialFeaturedExtra = response.find(extra => !extra.isInclusiveInRate && extra.featuredExtra);
          if (potentialFeaturedExtra) {
            this.featuredExtras.push(...[{
              ...potentialFeaturedExtra,
              rateId: rate.id
            }]);
            this.addExtraToCart = {
              ...this.addExtraToCart,
              [rate.parentRateId || rate.id]: false
            };
          }
        });
      });
    }
  }

  public onAddRoom(selectedRoom: AvailabilityResultModel, selectedRate: AvailabilityResultRateModel, unitQty: number) {
    const featuredExtra = this.addExtraToCart?.[
      selectedRate.parentRateId || selectedRate.id
    ] ? this.featuredExtras.find(extra => selectedRate.id === extra.rateId) : undefined

    if (featuredExtra) {
      selectedRate.totalGrossAmount.amount = selectedRate.totalGrossAmount.amount - (featuredExtra.totalGrossAmount?.amount || 0);

      if (selectedRate.totalBaseAmount) {
        selectedRate.totalBaseAmount.amount = selectedRate.totalBaseAmount.amount - (featuredExtra.totalGrossAmount?.amount || 0);
      }
    }

    this.onAddToCart.emit({
      availabilityResult: selectedRoom,
      availabilityResultRate: selectedRate,
      selectedUnitQty: unitQty,
      featuredExtra
    });
  }

  public rateHasAdditionalIncludedExtras(rate: AvailabilityResultRateModel): boolean {

    if (rate.includedExtras === undefined || !rate.includedExtras.length) {
      return false;
    }

    const additionalIncludedExtras = rate.includedExtras.filter((extra) => {
      return extra.pricingMode === 'Additional';
    });

    return additionalIncludedExtras.length > 0;
  }

  public getParentRateName(id: string) {
    return this.availabilityResult.rates.find(rate => rate.id === id)?.name;
  }

  public generateUnitQtyArray(parentRateId: string) {
    const rates = this.groupedRates[parentRateId];
    const maxRoomsNumber = this.config.settings.roomQtySelectorLimit;
    return Array.from(Array(1 + rates[0].availableUnits).keys()).slice(1, maxRoomsNumber + 1);
  }

  public decreaseQty(rateId: string) {
    if (this.selectedUnitQty[rateId] > 1) {
      this.selectedUnitQty[rateId] = this.selectedUnitQty[rateId] - 1;
    }
  }

  public increaseQty(rateId: string) {
    if (
      this.selectedUnitQty[rateId] < this.config.settings.roomQtySelectorLimit &&
      this.selectedUnitQty[rateId] < this.groupedRates[rateId][0].availableUnits
    ) {
      this.selectedUnitQty[rateId] = this.selectedUnitQty[rateId] + 1;
    }
  }

  public openRateInfoModal(availabilityResultRate: AvailabilityResultRateModel) {
    this.dialog.open(RateInfoModalComponent, {
      data: {
        availabilityResultRate
      },
      panelClass: 'ibe-rate-info-dialog'
    });
  }

  public updateRatePrice(rates: RatesObject, extra: FeaturedExtraModel) {
    if (this.addExtraToCart[rates.key]) {
      rates.value = rates.value.map(rate => {
        rate.totalGrossAmount.amount = rate.totalGrossAmount.amount + (extra.totalGrossAmount?.amount || 0);
        if (rate.totalBaseAmount) {
          rate.totalBaseAmount.amount = rate.totalBaseAmount.amount + (extra.totalGrossAmount?.amount || 0);
        }
        return rate;
      });
    } else {
      rates.value = rates.value.map(rate => {
        rate.totalGrossAmount.amount = rate.totalGrossAmount.amount - (extra.totalGrossAmount?.amount || 0);
        if (rate.totalBaseAmount) {
          rate.totalBaseAmount.amount = rate.totalBaseAmount.amount - (extra.totalGrossAmount?.amount || 0);
        }
        return rate;
      });
    }
  }

  public getFeaturedExtra(rates: RatesObject) {
    return this.featuredExtras.find(extra => rates.value.find(rate => rate.id === extra.rateId));
  }
}
