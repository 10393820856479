import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
  selector: 'ibe-guest-account-auth',
  templateUrl: './guest-account-auth.component.html',
  styleUrls: ['./guest-account-auth.component.scss']
})
export class GuestAccountAuthComponent implements OnInit {

  public hasAccountAuthenticated = false;
  public noError = true;

  constructor(
    private readonly router: Router,
    private readonly http: HttpClient,
    private route: ActivatedRoute
  ) { }

  public ngOnInit(): void {
    this.http.post(`${environment.serverUrl}/api/ibe/guest/authenticate`, {
      guestAuthId: this.route.snapshot.queryParamMap.get('guestAuthId')
    }).subscribe((response) => {
      this.hasAccountAuthenticated = true;
    }, (error => {
      this.noError = false;
    }));
  }

  public backToSearch() {
    this.router.navigate(['booking/search']);
  }

}
