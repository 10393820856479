export interface Step {
  handle: string
  title: string
  number: number
  route: string
}

const STEP_SEARCH_OFFER = 'offer_search';
const STEP_RESULTS_OFFER = 'offer_results';
const STEP_CHECKOUT_DETAILS = 'checkout_details';

export const IBE_STEPS = {
  STEP_SEARCH_OFFER,
  STEP_RESULTS_OFFER,
  STEP_CHECKOUT_DETAILS
}
