import { ReservationModel, ReservationCancellationFeeModel } from 'up-ibe-types';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Language } from '../ibe-config.service';

const NOT_FOUND_TEXT = 'Not Found'

export class CancellationFeeViewModel {
  constructor(readonly model: ReservationCancellationFeeModel | undefined) {}

  public description() {
    return (this.model) ? this.model.description : NOT_FOUND_TEXT
  }

  public amount() {
    return _.get(this.model, 'fee.amount')
  }

  public currency() {
    return _.get(this.model, 'fee.currency')
  }

  public dueDateTime() {
    if (this.model && this.model.dueDateTime) {
      return moment(this.model.dueDateTime).toDate()
    } else {
      return undefined;
    }
  }

  public isPayable() {
    return Boolean(
      this.model  &&
      this.model.dueDateTime &&
      this.model.fee &&
      moment().isAfter(this.model.dueDateTime) &&
      this.model.fee.amount > 0
    )
  }

  public amountDue() {
    return _.get(this.model, 'cancellationFee.fee.amount') || 0;
  }

  public formattedDueDate(language: Language) {
    if (this.model) {
      const momentDate = moment(this.model.dueDateTime);
      momentDate.locale(language);
      return `${momentDate.format('DD MMM. YYYY')} at ${momentDate.format('H:mm a')}`;
    } else {
      return undefined;
    }
  }

  public isEmpty() {
    return Boolean(this.model);
  }

  public isNotEmpty() {
    return !!this.isEmpty();
  }

}

/**
* more convenient access to deeply nested properties
**/
export class ReservationViewModel {
  public readonly cancellationFee: CancellationFeeViewModel;

  constructor(readonly reservation: ReservationModel) {
    this.cancellationFee = new CancellationFeeViewModel(reservation.cancellationFee)
  }
  public bookingReference() {
    return (this.reservation) ? this.reservation.bookingReference : NOT_FOUND_TEXT
  }

  public primaryGuest() {
    return (this.reservation) ? this.reservation.primaryGuest : NOT_FOUND_TEXT
  }

  public isEmpty() {
    return Boolean(this.reservation);
  }

  public isNotEmpty() {
    return !!this.isEmpty();
  }
}
