import { Component } from '@angular/core';

@Component({
  selector: 'ibe-checkout-payment-error',
  templateUrl: './checkout-payment-error.component.html',
  styleUrls: ['./checkout-payment-error.component.scss']
})
export class CheckoutPaymentErrorComponent {
  public errorType: string;

  constructor() { }
}
