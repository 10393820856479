import { Component, Input, OnInit } from '@angular/core';
import { fadeInOnEnterAnimation } from '../animations';
import { IbeConfigService } from 'app/services/ibe-config.service';
import { BookingTotals } from 'app/helpers/booking.helper';

@Component({
  selector: 'ibe-total-summary',
  templateUrl: './total-summary.component.html',
  styleUrls: ['./total-summary.component.scss'],
  animations: [
    fadeInOnEnterAnimation
  ]
})
export class TotalSummaryComponent implements OnInit {
  @Input() public currency: string;
  @Input() public bookingTotals: BookingTotals
  @Input() public isOpen = false;
  @Input() public isSuppressed = false;
  @Input() public displayInclusiveExtrasAsTaxes = false;
  public showCityTax = true;

  constructor(
    public readonly config: IbeConfigService
  ) { }

  public ngOnInit() {

  }

  public toggleSlide() {
    this.isOpen = !this.isOpen;
  }
}
