import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WindowLocationService {
  set href(url: string) {
    window.location.href = url;
  }

  get href() {
    return window.location.href;
  }

  public reload() {
    return window.location.reload();
  }
}
