<div class="ibe-guest-management ibe-guest-management-header">
  <nav class="ibe-guest-management-heading ibe-guest-management-heading-nav ibe-desktop">
    <a [routerLink]="['/guest/manage']" class="ibe-manage-link" routerLinkActive="ibe-active-link">{{ 'guest_management.manage_account' | translate }}</a>
    <a [routerLink]="['/guest/account/edit']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="ibe-active-link">{{ 'guest_management.edit_account_information' | translate }}</a>
    <a [routerLink]="['/guest/reservations/history']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="ibe-active-link">{{ 'guest_management.view_reservations_history' | translate }}</a>
    <a [routerLink]="['/guest/reservations/modify']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="ibe-active-link">{{ 'guest_management.modify_upcoming_reservations' | translate }}</a>
    <a [routerLink]="['/guest/reservations/details']" [routerLinkActiveOptions]="{exact: true}" routerLinkActive="ibe-active-link">
      <svg class="ibe-reservation-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><line x1="4.018" y1="12" x2="20.018" y2="12" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><polyline points="9.667 17.685 3.982 12 9.667 6.315" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></g></svg>
      {{ 'guest_management.back_to_booking_history' | translate }}
    </a>
  </nav>
  <nav class="ibe-guest-management-heading ibe-guest-management-mobile-nav ibe-mobile">
    <a [routerLink]="['/guest/manage']" [routerLinkActiveOptions]="{exact: true}" class="ibe-manage-link" routerLinkActive="ibe-active-link">{{ 'guest_management.manage_account' | translate }}</a>
    <a *ngIf="type === 'detail'" (click)="onReservationDetailsBackClick()" class="ibe-active-link">
      <svg class="ibe-reservation-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><line x1="4.018" y1="12" x2="20.018" y2="12" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><polyline points="9.667 17.685 3.982 12 9.667 6.315" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></g></svg>
      {{ 'guest_management.back_to_booking_history' | translate }}
    </a>
    <a *ngIf="type === 'option'" [routerLink]="['/guest/manage']" class="ibe-active-link">
      <svg class="ibe-reservation-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g><line x1="4.018" y1="12" x2="20.018" y2="12" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/><polyline points="9.667 17.685 3.982 12 9.667 6.315" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/></g></svg>
      {{ 'guest_management.back_to_dashboard' | translate }}
    </a>
  </nav>
  <div class="ibe-guest-management-heading ibe-user-heading">
    <span>{{ 'guest_management.hi' | translate }}, {{ username }}</span>
    <a (click)="logout()">
      <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="log-out"><g id="arrow-right"><line id="arrow-stem" x1="42.294" y1="24" x2="19.276" y2="24" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/><polyline id="arrowhead" points="33.688 15.342 42.345 24 33.688 32.658" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g><polyline id="tray" points="18.834 39.008 5.655 39.008 5.655 8.992 18.834 8.992" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>
      {{ 'guest_management.log_out' | translate }}
    </a>
  </div>
</div>
