<div class="ibe-cart" [ngClass]="{'open': isOpen}" *ngIf="hasReservations" [@fadeInOnEnterAnimation]>
  <div class="ibe-cart-top" (click)="toggleCart()">
    <div class="ibe-room-count">
      <i class="ibe-icon ibe-icon-bed"></i>
      <span class="ibe-room-count-label">{{ 'cart.in_your_cart' | translate }}:</span> {{ reservationCount }} {{ 'cart.rooms' | translate }}
    </div>
    <div class="ibe-close-cart-btn" *ngIf="isOpen">{{ 'cart.close_cart' | translate }}</div>
  </div>
  <div class="ibe-cart-reservations">
    <ibe-reservation-card *ngFor="let reservation of reservations; let i = index;" 
      [reservationKey]="i"
      [reservation]="reservation"
      [@scaleDownOnLeaveAnimation]
      [showAddExtrasButton]="reservation.extrasAvailable"
      [isInCart]="true"
    ></ibe-reservation-card>
  </div>
  <a routerLink="/checkout" class="ibe-btn ibe-checkout-btn">{{ 'cart.go_to_checkout' | translate }}</a>

  <!-- only way to silence a18y errors. about this 
    fragment not pointing to an id.
    no other choice as we have to use fragment routing. -->
  <div id="/checkout"></div>
</div>
