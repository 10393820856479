<div class="ibe-availability-calendar">
  <div class="ibe-calendar-left">
    <ibe-date-picker
      [currentMonth]="leftCalendarCurrentMonth"
      [currentMonthDisplay]="translateMonth(leftCalendarCurrentMonth)"
      [calendarDates]="leftCalendarDates"
      [arrivalDate]="arrivalDate"
      [departureDate]="departureDate"
      [ibeStartDate]="startDate"
      [hoveredDepartureDate]="hoveredDepartureDate"
      (onArrivalDateSelection)="onArrivalDateSelection($event)"
      (onDepartureDateSelection)="onDepartureDateSelection($event)"
      (onDepartureDateHover)="onDepartureDateHover($event)"
      (onPreviousMonthClick)="onPreviousMonthClick($event)"
    ></ibe-date-picker>
  </div>
  <div class="ibe-calendar-right">
    <ibe-date-picker
      [currentMonth]="rightCalendarCurrentMonth"
      [currentMonthDisplay]="translateMonth(rightCalendarCurrentMonth)"
      [calendarDates]="rightCalendarDates"
      [arrivalDate]="arrivalDate"
      [departureDate]="departureDate"
      [ibeStartDate]="startDate"
      [hoveredDepartureDate]="hoveredDepartureDate"
      (onArrivalDateSelection)="onArrivalDateSelection($event)"
      (onDepartureDateSelection)="onDepartureDateSelection($event)"
      (onDepartureDateHover)="onDepartureDateHover($event)"
      (onNextMonthClick)="onNextMonthClick($event)"
    ></ibe-date-picker>
  </div>
</div>
