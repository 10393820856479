<h2 mat-dialog-title class="mat-dialog-title">{{ 'manage_booking.payment_method' | translate }}</h2>
<mat-dialog-content>
  <ibe-loading-bar *ngIf="isLoading" [isLoading]="isLoading"></ibe-loading-bar>
  <ibe-adyen-payment
    *ngIf="paymentSetupData && hasPaymentScriptLoaded && showPaymentComponent('adyen')"
    [paymentSetupData]="paymentSetupData"
    [isLoading]="isLoading"
    (onComplete)="completeBooking($event)">
  </ibe-adyen-payment>

  <ibe-adyen-dropin-payment
    *ngIf="paymentSetupData && showPaymentComponent('adyen-dropin')"
    [paymentSetupData]="paymentSetupData"
    [isLoading]="isLoading"
    (onComplete)="completeBooking($event)"
    [paymentRedirected]="paymentRedirected"
    [bookingRequestId]="bookingRequestId">
  </ibe-adyen-dropin-payment>

  <ibe-saferpay-payment
    *ngIf="paymentSetupData && showPaymentComponent('saferpay')"
    [paymentSetupData]="paymentSetupData"
    (onComplete)="completeBooking($event)">
  </ibe-saferpay-payment>

  <ibe-mycheck-payment
    *ngIf="paymentSetupData && hasPaymentScriptLoaded && showPaymentComponent('mycheck')"
    [paymentSetupData]="paymentSetupData"
    (onComplete)="completeBooking($event)">
  </ibe-mycheck-payment>
</mat-dialog-content>
