import { Router, Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { never } from 'rxjs';

@Injectable({providedIn: 'root'})
export class SearchParamsUpgradeService {
  constructor(private router: Router) {};

  // Updates query params in real-time if they're
  // using old, deprecated queryparams
  public maybeUpgradeAndStop(queryParams: Params) {
      if (queryParams.persons) {
        this._doUpgrade(queryParams);
        return never();
      } else {
        return queryParams;
      }
  }

  private _doUpgrade(queryParams: Params) {
        const urlTree = this.router.createUrlTree([], {
          queryParams: { adults: queryParams.persons, persons: undefined },
          queryParamsHandling: 'merge',
          preserveFragment: true
        });
        this.router.navigateByUrl(urlTree);
  }
}
