import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormGroupDirective } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { BookerModel } from 'up-ibe-types';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { IbeConfigService } from '../services/ibe-config.service';
import { ReplaySubject } from 'rxjs';

interface DialogData {
  bookingOrReservation: string;
  bookingOrReservationId: string;
  guestDetails: BookerModel;
  guestTitleFieldEnabled: boolean;
  propertyId?: string;
}

export interface GuestDetailsDialogResponse {
  updateSuccess: boolean;
  guestDetails: BookerModel;
}

@Component({
  selector: 'ibe-guest-details-dialog',
  templateUrl: './guest-details-dialog.component.html',
  styleUrls: ['./guest-details-dialog.component.scss']
})
export class GuestDetailsDialogComponent implements OnInit {
  @ViewChild('guestDetailsFormDirective', { static: true }) public guestDetailsFormDirective: FormGroupDirective;
  public guestDetailsForm: FormGroup;
  public submit: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public readonly http: HttpClient,
    private readonly formBuilder: FormBuilder,
    private readonly translate:  TranslateService,
    private readonly dialogRef: MatDialogRef<GuestDetailsDialogComponent>,
    private readonly dialog: MatDialog,
    private readonly config: IbeConfigService
    ) {
      this.guestDetailsForm = this.formBuilder.group({});

    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close({
        updateSuccess: false,
        guestDetails: this.data.guestDetails
      });
    })
  }

  public ngOnInit() {}

  public addFormControl(name: string, formGroup: FormGroup) {
    this.guestDetailsForm.addControl(name, formGroup);
    this.guestDetailsForm.patchValue({
      details: {
        ...this.data.guestDetails
      },
      address: {
        ...this.data.guestDetails.address
      }
    });
  }

  public onFormSubmit(event: Event) {
    this.submit.next(true);
    event.preventDefault();
    if (this.guestDetailsForm.valid) {
      this.http.post(`${environment.serverUrl}/api/ibe/update-guest`, {
          bookingOrReservation: this.data.bookingOrReservation,
          bookingOrReservationId: this.data.bookingOrReservationId,
          guestDetails: this.guestDetailsForm.value,
          language: this.config.language,
          propertyId: this.data.propertyId
        })
        // tslint:disable-next-line:no-any
        .subscribe((response: any) => {
          if (response.success) {
            this.dialogRef.close({
              updateSuccess: true,
              guestDetails: this.guestDetailsForm.value
            });
          } else {
            this._showGuestUpdateError();
          }
        }, (error) => {
          this._showGuestUpdateError()
        });
    }
  }

  public onCancel(event: Event) {
    event.preventDefault();
    this.dialogRef.close({
      updateSuccess: false,
      guestDetails: this.data.guestDetails
    });
  }

  private _showGuestUpdateError() {
    this.dialog.open(ErrorDialogComponent, {
      data: {
        title: this.translate.instant('dialog_error_codes.guest_update_failed.title'),
        message: this.translate.instant('dialog_error_codes.guest_update_failed.message')
      }
    }).afterClosed().subscribe(() => {
      window.location.reload();
    });
  }
}
