import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { GuestAuthService } from '../../services/guest-auth.service';

@Component({
  selector: 'ibe-guest-management-header',
  templateUrl: './guest-management-header.component.html',
  styleUrls: ['./guest-management-header.component.scss']
})
export class GuestManagementHeaderComponent implements OnInit {
  @Input('type') public type: string;
  @Input('isReservationUnModifiable') public isReservationUnModifiable: boolean;
  public username: string;

  constructor(
    public guestAuthService: GuestAuthService,
    public router: Router,
    private readonly translate: TranslateService,
    private readonly toasterService: ToasterService
  ) { }

  public ngOnInit() {
    this.username = this.guestAuthService.getUsername();
  }

  public logout() {
    this.guestAuthService.logout();
    this.router.navigate(['/']);
    this.toasterService.pop('success',
      this.translate.instant('guest_management_auth.logout_successful'));
  }

  public onReservationDetailsBackClick() {
    if (this.isReservationUnModifiable) {
      return this.router.navigateByUrl('/guest/reservations/history');
    }
    return this.router.navigateByUrl('/guest/reservations/modify');
  }

}
