import { AfterViewChecked, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImagesService } from 'app/services/images.service';
import { NguCarouselConfig, NguCarouselStore } from '@ngu/carousel';
import * as  objectFitImagesPollyfill from 'object-fit-images';
import { UnitTypeModel } from 'up-ibe-types';

@Component({
  selector: 'ibe-gallery-dialog',
  templateUrl: './gallery-dialog.component.html',
  styleUrls: ['./gallery-dialog.component.scss']
})
export class GalleryDialogComponent implements OnInit, AfterViewChecked {
  public imageCarousel: NguCarouselConfig;
  public unitTypeImagesUrls: string[] = [];
  private firstImageLoaded = false;

  constructor(
    private readonly imagesService: ImagesService,
    @Inject(MAT_DIALOG_DATA) private readonly _data: {
      propertyId: string,
      unitType: UnitTypeModel
    }
  ) { }

  public ngOnInit() {
    this.unitTypeImagesUrls = this.getUnitTypeImagesUrls();
    this.imageCarousel = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      speed: 400,
      interval: {
        timing: 4000
      },
      point: {
        visible: true
      },
      touch: true,
      loop: false
    }
    objectFitImagesPollyfill();
  }

  public ngAfterViewChecked() {
    if (!this.firstImageLoaded) {
      const carousel = document.getElementById('carousel');
      if (carousel) {
        const image = carousel.getElementsByTagName('img')[0];
        if (image && image.height > 0) {
          carousel.style.height = image.height.toString() + 'px';
          this.firstImageLoaded = true;
        }
      }
    }
  }

  public get unitType() {
    return this._data.unitType;
  }

  public getUnitTypeImagesUrls() {
    return this.imagesService.getUnitTypeImagesUrls(this._data.propertyId, this._data.unitType.id);
  }

  public onImageChange(event: NguCarouselStore) {
    const carousel = document.getElementById('carousel');
    if (carousel) {
      const image = carousel.getElementsByTagName('img')[event.currentSlide];
      carousel.style.height = image.height.toString() + 'px';
    }
  }
}
