export enum GuestTitleEnum {
  Mr = 'Mr',
  Ms = 'Ms',
  Mrs = 'Mrs',
  Dr = 'Dr',
  Prof = 'Prof',
  Other = 'Other'
}

export enum TravelPurposeEnum {
  Business = 'Business',
  Leisure = 'Leisure'
}

export enum BookingOrReservationEnum {
  Booking = 'Booking',
  Reservation = 'Reservation'
}

export enum ReservationStatusEnum {
  Confirmed = 'Confirmed',
  InHouse = 'InHouse',
  CheckedOut = 'CheckedOut',
  Canceled = 'Canceled',
  NoShow = 'NoShow',
  OnWaitingList = 'OnWaitingList',
  DueOut = 'DueOut',
  DueIn = 'DueIn'
}

export enum PricingUnitEnum {
  Room = 'Room',
  Person = 'Person'
}

export enum GuaranteeTypeEnum {
  PM6Hold = 'PM6Hold',
  CreditCard = 'CreditCard',
  Prepayment = 'Prepayment',
  Company = 'Company'
}

export enum TaxTypeEnum {
  VAT = 'VAT',
  CityTax = 'CityTax',
  TouristTax = 'TouristTax',
  SalesTax = 'SalesTax',
  ServiceCharge = 'ServiceCharge'
}

export enum HttpStatus {
  Continue = 100,
  Switching_Protocols = 101,
  Processing_WebDAV = 102,
  OK = 200,
  Created = 201,
  Accepted = 202,
  Non_Authoritative_Information = 203,
  No_Content = 204,
  Reset_Content = 205,
  Partial_Content = 206,
  Multi_Status_WebDAV = 207,
  Already_Reported_WebDAV = 208,
  IM_Used = 226,
  Multiple_Choices = 300,
  Moved_Permanently = 301,
  Found = 302,
  See_Other = 303,
  Not_Modified = 304,
  Use_Proxy = 305,
  Unused = 306,
  Temporary_Redirect = 307,
  Permanent_Redirect_experimental = 308,
  Bad_Request = 400,
  Unauthorized = 401,
  Payment_Required = 402,
  Forbidden = 403,
  Not_Found = 404,
  Method_Not_Allowed = 405,
  Not_Acceptable = 406,
  Proxy_Authentication_Required = 407,
  Request_Timeout = 408,
  Conflict = 409,
  Gone = 410,
  Length_Required = 411,
  Precondition_Failed = 412,
  Request_Entity_Too_Large = 413,
  Request_URI_Too_Long = 414,
  Unsupported_Media_Type = 415,
  Requested_Range_Not_Satisfiable = 416,
  Expectation_Failed = 417,
  Im_a_teapot_RFC2324 = 418,
  Enhance_Your_Calm_Twitter = 420,
  Unprocessable_Entity = 422,
  Locked_WebDAV = 423,
  Failed_Dependency_WebDAV = 424,
  Reserved_for_WebDAV = 425,
  Upgrade_Required = 426,
  Precondition_Required = 428,
  Too_Many_Requests = 429,
  Request_Header_Fields_Too_Large = 431,
  No_Response_Nginx = 444,
  Retry_With_Microsoft = 449,
  Blocked_by_Windows_Parental_Controls_Microsoft = 450,
  Unavailable_For_Legal_Reasons = 451,
  Client_Closed_Request_Nginx = 499,
  Internal_Server_Error = 500,
  Not_Implemented = 501,
  Bad_Gateway = 502,
  Service_Unavailable = 503,
  Gateway_Timeout = 504,
  HTTP_Version_Not_Supported = 505,
  Variant_Also_Negotiates_Experimental = 506,
  Insufficient_Storage_WebDAV = 507,
  Loop_Detected_WebDAV = 508,
  Bandwidth_Limit_Exceeded_Apache = 509,
  Not_Extended = 510,
  Network_Authentication_Required = 511,
  Network_read_timeout_error = 598,
  Network_connect_timeout_error = 599
}
