import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { ReservationModel, ExtraModel, IbeServerApiResponseModel } from 'up-ibe-types';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { MatDialog } from '@angular/material/dialog';
import { IbeConfigService } from '../../../services/ibe-config.service';
import { BookingService } from '../../../services/booking.service';
import { ImagesService } from '../../../services/images.service';
import { ErrorDialogComponent } from '../../../error-dialog/error-dialog.component';
import {
  GuestDetailsDialogComponent,
  GuestDetailsDialogResponse
} from '../../../guest-details-dialog/guest-details-dialog.component';
import {
  EditReservationDatesDialogComponent
} from '../../modify-reservation/edit-reservation-dates-dialog/edit-reservation-dates-dialog.component';
import {
  AddExtrasDialogComponent
} from '../../modify-reservation/add-extras-dialog/add-extras-dialog.component';
import {
  BookingCancellationDialogComponent
} from '../../../booking-management/booking-cancellation-dialog/booking-cancellation-dialog.component';
import { environment } from '../../../../environments/environment';
import { GuaranteeTypeEnum } from '../../../../enums';

@Component({
  selector: 'ibe-reservations-list',
  templateUrl: './reservations-list.component.html',
  styleUrls: ['./reservations-list.component.scss']
})
export class ReservationListComponent implements OnInit {
  @Input('reservations') public reservations: ReservationModel[] = [];
  @Input() public isOpen = false;
  @Output('fetchReservations') public fetchReservations: EventEmitter<undefined> = new EventEmitter();
  public allReservationsCount: number;
  public confirmedReservationsCount: number;
  public cancelledReservationsCount: number;
  public checkedOutReservationsCount: number;
  public inHouseReservationsCount: number;
  public noShowReservationsCount: number;
  public waitingListReservationsCount: number;
  public filteredReservations: ReservationModel[];
  public reservationStatus = new FormControl('All');
  public toggledReservationIndex: number;
  public isToggledReservationFlexible: boolean;

  constructor(
    public readonly bookingService: BookingService,
    public readonly config: IbeConfigService,
    private readonly imagesService: ImagesService,
    public readonly dialog: MatDialog,
    public readonly http: HttpClient,
    private readonly translate: TranslateService,
    private readonly toasterService: ToasterService,
    public router: Router,
    public route: ActivatedRoute
  ) { }

  public ngOnInit() {
    this._initReservationFilter();
  }

  public viewReservationDetails(reservation: ReservationModel) {
    this.router.navigate(['/guest/reservations/details'], {queryParams: {
      id: reservation.bookingReference,
      propertyId: reservation.property.id
    }});
  }

  public countReservationsByStatus(filterValue: string) {
    return this.reservations.filter(reservation => reservation.status === filterValue).length;
  }

  public filterReservations(value: string) {
    this.reservationStatus.setValue(value);
    if (this.filteredReservations) {
      this.filteredReservations = this.reservations.filter(reservation => {
        return (
          (this.reservationStatus.value === reservation.status) ||
          (this.reservationStatus.value === 'All')
        );
      });
    }
    return [];
  }

  public formatDate(date: string) {
    const momentDate = moment(date);
    momentDate.locale(this.config.language);
    return `${momentDate.format('MMM DD, YYYY')}`;
  }

  public getUnitTypeImageUrl(propertyId: string, unitTypeId: string) {
    return this.imagesService.getUnitTypeImageUrl(propertyId, unitTypeId);
  }

  public getNumberOfNights(reservation: ReservationModel) {
    return this.bookingService.calculateNumberOfNights(reservation.arrival, reservation.departure);
  }

  public calculateExtrasTotals(extras: ExtraModel[]) {
    return this.bookingService.calculateExtrasTotals(extras).grossTotal;
  }

  public openComingSoonDialog() {
    this.dialog.open(ErrorDialogComponent, {
      data: {
        title: this.translate.instant('dialog_error_codes.coming_soon.title'),
        message: this.translate.instant('dialog_error_codes.coming_soon.message')
      }
    });
  }

  public openCancellationDialog(reservation: ReservationModel) {
    let reservationId = reservation.id;
    if (this.config.isPmsStayntouch()) {
      reservationId = reservation.bookingReference;
    }
    this.dialog.open(BookingCancellationDialogComponent, {
      data: reservation
    }).afterClosed()
      .subscribe((dialogResponse: boolean) => {
        if (dialogResponse) {
          return this.http.post(`${environment.serverUrl}/api/ibe/cancel-reservation`,  {
            propertyId: reservation.property.id,
            bookingReference: reservation.bookingReference,
            reservationId
          })
          .subscribe((apiResponse: IbeServerApiResponseModel) => {
            if (apiResponse.success) {
              this.toasterService.pop('success',
                this.translate.instant('manage_booking.reservation_cancelled'),
                this.translate.instant('manage_booking.reservation_successfully_cancelled'));
              this.fetchReservations.emit();
              this._initReservationFilter();
            }
          }, (error) => {
            this.dialog.open(ErrorDialogComponent, {
              data: {
                title: this.translate.instant('dialog_error_codes.booking_cancel_error.title'),
                message: this.translate.instant('dialog_error_codes.booking_cancel_error.message')
              }
            });
          });
        }
        return;
    });
  }

  public openGuestDetailsDialog(reservation: ReservationModel) {
    this.dialog.open(GuestDetailsDialogComponent, {
      data: {
        bookingOrReservation: 'reservation',
        bookingOrReservationId: reservation.bookingReference,
        guestDetails: reservation.primaryGuest,
        propertyId: reservation.property.id,
        guestTitleFieldEnabled: this.config.settings.checkoutFields.details.title
      },
      panelClass: 'up-ibe-guest-details'
    })
    .afterClosed().subscribe((response: GuestDetailsDialogResponse) => {
      if (response.updateSuccess) {
        this.toasterService.pop('success',
          this.translate.instant('global.success'),
          this.translate.instant('manage_booking.guest_updated_successfully'));
        this.fetchReservations.emit();
      } else {
        this.toasterService.pop('error',
          this.translate.instant('manage_booking.guest_update'),
          this.translate.instant('manage_booking.guest_update_failed'));
      }
    });
  }

  public openEditReservationDatesDialog(reservation: ReservationModel) {
    this.dialog.open(EditReservationDatesDialogComponent, {
      data: reservation
    }).afterClosed().subscribe(response => {
      if (response) {
        this.fetchReservations.emit();
      }
    });
  }

  public openAddExtrasDialog(reservation: ReservationModel) {
    this.dialog.open(AddExtrasDialogComponent, {
      data: reservation
    }).afterClosed().subscribe(success => {
      if (success) {
        this.fetchReservations.emit();
      }
    });
  }

  public reservationTotalAmount(reservation: ReservationModel) {
    return this.bookingService.calculateBookingTotals([reservation]).grossTotal;
  }

  private _initReservationFilter() {
    if (this.reservations.length) {
      this.filteredReservations = [...this.reservations];
      this.allReservationsCount = this.reservations.length;
      this.confirmedReservationsCount = this.countReservationsByStatus('Confirmed');
      this.cancelledReservationsCount = this.countReservationsByStatus('Canceled');
      this.checkedOutReservationsCount = this.countReservationsByStatus('CheckedOut');
      this.inHouseReservationsCount = this.countReservationsByStatus('InHouse');
      this.noShowReservationsCount = this.countReservationsByStatus('NoShow');
      this.waitingListReservationsCount = this.countReservationsByStatus('WaitingList');
      this.waitingListReservationsCount = this.countReservationsByStatus('DueIn');
      this.waitingListReservationsCount = this.countReservationsByStatus('DueOut');
    }
  }

  public toggleSlide(index: number, reservation: ReservationModel) {
    if (!this.toggledReservationIndex) {
      this.toggledReservationIndex = index;
      this.isToggledReservationFlexible = this.isReservationFlexible(reservation);
    }
    if (this.toggledReservationIndex === index) {
      this.isOpen = !this.isOpen;
    } else {
      this.isToggledReservationFlexible = this.isReservationFlexible(reservation);
      this.toggledReservationIndex = index;
      this.isOpen = true;
    }
  }

  public isReservationUnModifiable(reservation: ReservationModel) {
    const unmodifiableStatuses = [undefined, 'Canceled', 'CheckedOut', 'NoShow', 'InHouse', 'DueIn', 'DueOut'];
    return unmodifiableStatuses.includes(reservation.status) || !this.hasValidRate(reservation);
  }

  public isReservationFlexible(reservation: ReservationModel) {
    return !!(reservation.guaranteeType &&
      reservation.guaranteeType !== GuaranteeTypeEnum.Prepayment &&
      this.hasValidRate(reservation));
  }

  public isReservationBelowFlexibleToggledReservation(index: number) {
    return this.isOpen && index > this.toggledReservationIndex && this.isToggledReservationFlexible;
  }

  public isReservationBelowToggledReservation(index: number) {
    return this.isOpen && index > this.toggledReservationIndex;
  }

  public isToggledReservationOpen(index: number) {
    return this.isOpen && index === this.toggledReservationIndex;
  }

  public hasValidRate(reservation: ReservationModel) {
    return reservation.ratePlan && reservation.ratePlan.id !== 'INVALID';
  }
}
