import {
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LanguageInterceptorService implements HttpInterceptor {
  private _translate: TranslateService;

  private get translate() {
    if (!this._translate) {
      this._translate = this.injector.get(TranslateService);
    }
    return this._translate;
  }

  constructor(
    private readonly injector: Injector
  ) { }

  public intercept(
    request: HttpRequest<{}>, next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<{}> | HttpUserEvent<{}>> {
    if (!request.headers.has('Accept-Language') && this.translate.currentLang) {
      request = request.clone({ headers: request.headers.set('Accept-Language', this.translate.currentLang) });
    }
    return next.handle(request);
  }
}
