import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormValidationService } from '../services/form-validation.service';

@Component({
  selector: 'ibe-form-validation-message',
  template: `<div class="ibe-validation-message error" *ngIf="errorMessage">{{ errorMessage }}</div>`,
  styleUrls: ['./form-validation-message.component.scss']
})
export class FormValidationMessageComponent implements OnInit {
  @Input() public form: FormGroupDirective;
  @Input() public control: AbstractControl;
  @Input() public submitObservable: Observable<boolean> | undefined;
  public errorMessage: string | undefined;

  constructor(
    private readonly validation: FormValidationService
  ) {
  }

  public ngOnInit() {
    if (this.form) {
      this.control.valueChanges.subscribe(() => {
        this._setErrorMessage();
      });
      /*
      * I'm going to leave this in on the off chance something somewhere is still able
      * to make use of this, but it doesn't work for the checkout forms as they've been moved
      * into their own component. Not been able to figure out why that's caused this to stop
      * emitting, but I have a feeling it's because the forms technically belong to different
      * directives now
      */
      this.form.ngSubmit.subscribe(() => {
        this._setErrorMessage();
      })
      this.submitObservable?.subscribe((response) => {
        this._setErrorMessage();
      })
    }
  }

  private _setErrorMessage() {
    if (this.control) {
      if (this.control.errors) {
        for (const propertyName in this.control.errors) {
          if (this.control.errors.hasOwnProperty(propertyName)) {
            this.errorMessage = this.validation.getValidatorErrorMessage(this.control.errors[propertyName], propertyName);
          }
        }
      } else {
        this.errorMessage = undefined;
      }
    }
  }
}
