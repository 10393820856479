<div class="ibe-native-date-picker">
  <div class="ibe-form-group ibe-arrival-date">
    <label for="arrival-date-input">{{ 'booking_search.arrival_date' | translate }}</label>
    <input
      id="arrival-date-input"
      class="ibe-form-control ibe-arrival-date-input"
      type="date"
      placeholder="{{ 'booking_search.select_arrival_date' | translate }}"
      [min]="formatDateForHtmlInput(arrivalMinDate)"
      [value]="formatDateForHtmlInput(arrivalDate)"
      [max]="maximumDate"
      (change)="onArrivalDateChange($event.target.value)">
  </div>

  <div class="ibe-form-group ibe-departure-date">
    <label for="departure-date-input">{{ 'booking_search.departure_date' | translate }}</label>
    <input
      id="departure-date-input"
      class="ibe-form-control ibe-departure-date-input"
      type="date"
      placeholder="{{ 'booking_search.select_departure_date' | translate }}"
      [min]="formatDateForHtmlInput(departureMinDate)"
      [max]="maximumDate"
      [value]="formatDateForHtmlInput(departureDate)"
      (change)="onDepartureDateChange($event.target.value)">
  </div>
</div>
