<div class="ibe-rate-options-container">
  <div class="ibe-rate-options-list" *ngFor="let rates of groupedRates | keyvalue">
    <div class="ibe-rate-options-left">
    </div>
    <div class="ibe-rate-options-right">
      <div class="ibe-selector-wrapper">
        <div class="ibe-desktop-selector">
          <button type="button" class="ibe-unit-qty-button ibe-icon ibe-icon-chevron-left" (click)="decreaseQty(rates.key)" [disabled]="selectedUnitQty[rates.key] === 1"><span class="inner-text sr-only">Less</span></button>
          <span class="ibe-unit-qty-display">{{ selectedUnitQty[rates.key] }}</span>
          <button
            type="button"
            class="ibe-unit-qty-button ibe-icon ibe-icon-chevron-right"
            (click)="increaseQty(rates.key)"
            [disabled]="selectedUnitQty[rates.key] === rates.value[0].availableUnits || selectedUnitQty[rates.key] === config.settings.roomQtySelectorLimit"
          >
            <span class="inner-text sr-only">More</span>
          </button>
        </div>
        <div class="ibe-mobile-selector">
          <select aria-label="Choose number of rooms" [(ngModel)]="selectedUnitQty[rates.key]" *ngIf="config.isMultiReservationsSupported" class="ibe-custom-select ibe-unit-qty-select" name="unitQty">
            <option value="{{ unitQty }}" *ngFor="let unitQty of generateUnitQtyArray(rates.key)">{{ unitQty }}</option>
          </select>
        </div>
        <span class="ibe-rate-number-rooms">{{ 'room_results.number_rooms' | translate }}</span>
      </div>
      <div class="ibe-rate-option-container" *ngFor="let rate of rates.value" style="display: flex">
        <div [ngClass]="{'ibe-member-rate-option': rate.isMemberRate}" class="ibe-rate-option">
          <div style="display: flex; align-items: center">
            <div class="ibe-rate-title">
              <span>{{ rate.name }}</span>
            </div>
            <div class="ibe-rate-info-btn" (click)="openRateInfoModal(rates.value[0])">i</div>
          </div>
          <div class="ibe-rate-button-container">
            <button [ngClass]="{'ibe-primary-btn': rate.isMemberRate}" class="ibe-btn ibe-rate-button" (click)="onAddRoom(availabilityResult, rate, selectedUnitQty[rates.key])">{{ 'room_results.add' | translate }}</button>
          </div>
          <div class="ibe-rate-price">
            <span *ngIf="!rate.suppressed && !useBasePrices">{{ rate.totalGrossAmount?.amount | currency: rate.totalGrossAmount.currency:'symbol':'1.2-2' }}</span>
            <span *ngIf="!rate.suppressed && useBasePrices && !rateHasAdditionalIncludedExtras(rate)">{{ rate.totalGrossAmount?.amount | currency: rate.totalGrossAmount.currency:'symbol':'1.2-2' }}</span>
            <span *ngIf="!rate.suppressed && useBasePrices && rateHasAdditionalIncludedExtras(rate)">{{ rate.totalBaseAmount?.amount | currency: rate.totalBaseAmount.currency:'symbol':'1.2-2' }}</span>
          </div>
        </div>
      </div>
      <div class="ibe-featured-extra" *ngIf="config.accountFeatureWhitelist.serviceToggle && getFeaturedExtra(rates)">
        <span>{{ 'room_results.included' | translate }}:</span>
        <mat-checkbox class="ibe-featured-extra-toggle" [(ngModel)]="addExtraToCart[rates.key]" (change)="updateRatePrice(rates, $any(getFeaturedExtra(rates)))">{{ getFeaturedExtra(rates)?.name }}</mat-checkbox>
      </div>
    </div>
  </div>
</div>
