<div class="ibe-calendar">
  <div class="ibe-calendar-navigation">
    <button (click)="selectPreviousMonth($event)" class="previous-button">
      <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><defs><style>.cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:3px;}</style></defs><title>arrow-left</title><polyline class="cls-1" points="28.69 33.9 18.78 24 28.69 14.1"/></svg>
    </button>
    <span>{{ currentMonthDisplay }}</span>
    <button (click)="selectNextMonth($event)" class="next-button">
      <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><defs><style>.cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:3px;}</style></defs><title>arrow-right</title><polyline class="cls-1" points="19.31 14.1 29.22 24 19.31 33.9"/></svg>
    </button>
  </div>
  <div class="ibe-calendar-header">
    <span>{{ 'availability_calendar.m' | translate }}</span>
    <span>{{ 'availability_calendar.tu' | translate }}</span>
    <span>{{ 'availability_calendar.w' | translate }}</span>
    <span>{{ 'availability_calendar.th' | translate }}</span>
    <span>{{ 'availability_calendar.f' | translate }}</span>
    <span>{{ 'availability_calendar.sa' | translate }}</span>
    <span>{{ 'availability_calendar.su' | translate }}</span>
  </div>
  <div class="ibe-calendar-rows">
    <div class="ibe-calendar-row" *ngFor="let row of calendarDates">
      <div class="ibe-calendar-day-wrapper" *ngFor="let day of row">
        <div
          class="ibe-calendar-day"
          *ngIf="day"
          [ngClass]="{
                      'ibe-calendar-day-active': dayActive(day),
                      'ibe-calendar-day-arrival-date': dayArrival(day),
                      'ibe-calendar-day-departure-date': dayDeparture(day),
                      'ibe-calendar-day-stay-date': dayStayDate(day)
          }"
          (click)="onDaySelect($event, day)"
          (keypress)="handleEnter($event, day)"
          (mouseenter)="onDayHover($event, day)"
          (focus)="onDayHover($event, day)"
          tabindex="0">
          <span class="ibe-calendar-day-number-label">{{ day.date | date:'d' }}</span>
        </div>
    </div>
  </div>
</div>
