import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalWindowService {

  public getWindowLocationAssign(url: string) {
    window.location.assign(url);
  }
}
