import { ReservationModel, AvailabilityResultRateModel, AvailabilityResultModel, ExtraModel, ChannelCode } from 'up-ibe-types';
import { Params } from '@angular/router';

export class Cart {
  private reservation: ReservationModel;
  private rate: AvailabilityResultRateModel

  public static FromParams(
    queryParams: Params,
    availabilityResult: AvailabilityResultModel,
    rate: AvailabilityResultRateModel
  ) {
    let promoCode;
    let corporateCode;
    if (rate.isCorporateRate) {
      corporateCode = queryParams.promoCode;
    }
    if (rate.isPromoRate) {
      promoCode = queryParams.promoCode;
    }

    const reservation = {
      property: availabilityResult.property,
      unitType: availabilityResult.unitType,
      ratePlan: {
        id: rate.id,
        name: rate.name,
        pmsDescription: rate.description,
        isMemberRate: !!(rate.isMemberRate)
      },
      totalBaseAmount: rate.totalBaseAmount,
      totalGrossAmount: rate.totalGrossAmount,
      includedExtras: rate.includedExtras,
      prePaymentAmount: rate.prePaymentAmount,
      arrival: availabilityResult.arrival,
      departure: availabilityResult.departure,
      adults: queryParams.adults,
      childrenAges: (queryParams.childrenAges || []),
      extras: [], // extras,
      taxes: rate.taxes,
      guaranteeType: rate.guaranteeType,
      promoCode,
      corporateCode,
      extrasAvailable: false,
      estimatedCityTax: rate.estimatedCityTax,
      estimatedServiceCharge: rate.estimatedServiceCharge,
      suppressedRate: !!(rate.suppressed),
      depositPolicy: rate.depositPolicy,
      channel: 'Ibe' as ChannelCode
    }

    return new Cart(reservation, rate);
  }

  public constructor(
    reservation: ReservationModel,
    rate: AvailabilityResultRateModel
  ) {
    this.reservation = reservation;
    this.rate = rate;
  }

  public withSelectedExtras(extras: ExtraModel[]) {
    const newParams = { ...this.reservation, ...{ extras } }
    return new Cart(newParams, this.rate);
  }

  public withExtrasAvailable(extrasAvailable: boolean) {
    const newParams = { ...this.reservation, ...{ extrasAvailable } }
    return new Cart(newParams, this.rate);
  }

  public getReservation(): ReservationModel {
    return this.reservation;
  }

  public availableUnits() {
    return this.rate.availableUnits;
  }

  public getRate(): AvailabilityResultRateModel {
    return this.rate;
  }
}
