import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { GuestAuthService } from '../services/guest-auth.service';
import { MatDialog } from '@angular/material/dialog';
import { GuestLoginComponent } from '../guest-management/guest-login/guest-login.component';
import { BookingService } from './booking.service';
import { IbeConfigService } from './ibe-config.service';

@Injectable()
export class GuestManagementGuardService implements CanActivate {

  constructor(
    private readonly guestAuthService: GuestAuthService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly bookingService: BookingService,
    private readonly config: IbeConfigService
  ) { }

  public canActivate(route: ActivatedRouteSnapshot) {
    if (this.guestAuthService.isLoggedIn()) {
      return true;
    } else {
      this.dialog.closeAll();
      this.dialog.open(GuestLoginComponent, {
        height: '550px',
        width: '500px'
      })
        .afterClosed()
        .subscribe((dialogResponse: boolean) => {
          if (dialogResponse) {
            const shouldSkipToCheckout =
            this.config.accountFeatureWhitelist.skipStraightToCheckoutOnLogin && this.bookingService.booking.reservations.length;
            if (shouldSkipToCheckout) {
              this.router.navigateByUrl('guest/manage').then(() => this.router.navigateByUrl('checkout/details'));
            } else {
              this.router.navigate(['guest/manage']);
            }
          }
        });

      return false;
    }
  }
}
