/* tslint:disable:max-file-line-count */
import { Component, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective, AbstractControl } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { environment } from '../../environments/environment';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import {
  BookingCancellationDialogComponent
} from './booking-cancellation-dialog/booking-cancellation-dialog.component';
import {
  BookingPaymentDialogComponent
} from './booking-payment-dialog/booking-payment-dialog.component';
import {
  GuestDetailsDialogComponent,
  GuestDetailsDialogResponse
} from '../guest-details-dialog/guest-details-dialog.component';
import {
  AddExtrasDialogComponent
} from '../guest-management/modify-reservation/add-extras-dialog/add-extras-dialog.component';
import {
  BookingModel,
  ReservationModel,
  IbeServerApiResponseModel,
  BookerModel } from 'up-ibe-types';
import { GuaranteeTypeEnum } from '../../enums';
import { serializeQueryParams } from '../helpers/booking.helper';
import { isGuestDataValid } from '../helpers/payment.helper';
import { GlobalWindowService } from '../services/global-window.service';
import { IbeConfigService } from '../services/ibe-config.service';
import { LocalStorageService } from '../services/local-storage.service';
import { isFormControlInvalid } from '../helpers/form.helper';
import { ReservationStatusEnum, BookingOrReservationEnum } from '../../enums';
import { EventsService } from '../services/events.service';

interface BookingManagementParams {
  id: string | null;
  lastName: string | null;
  propertyId: string | null;
  showExtras: string | null;
}

// todo: shouldn't need this
const NOT_FOUND_TEXT = 'Not Found'

@Component({
  selector: 'ibe-booking-management',
  templateUrl: './booking-management.component.html',
  styleUrls: ['./booking-management.component.scss']
})
export class BookingManagementComponent implements OnInit {
  @ViewChild('bookingLoginFormDirective', { static: true }) public bookingLoginFormDirective: FormGroupDirective;
  public isBooking: boolean;
  public isReservation: boolean;
  public bookingOrReservation: string | null;
  public bookingLoginForm: FormGroup;
  public booking: BookingModel | undefined;
  public reservation: ReservationModel | undefined;
  public reservations: ReservationModel[];
  public isLoading = false;
  public reservationStatus = ReservationStatusEnum.Confirmed;
  public guestDetails: BookerModel;
  public bookingManagementParams: BookingManagementParams;
  public reservationConfirmed = false;
  public filteredReservations: ReservationModel[] = [];
  private paymentRedirected = false;
  public showEditButtons = false;

  constructor(
    public readonly config: IbeConfigService,
    public readonly http: HttpClient,
    private readonly formBuilder: FormBuilder,
    public readonly dialog: MatDialog,
    private readonly translate: TranslateService,
    private readonly toasterService: ToasterService,
    public readonly localStorageService: LocalStorageService,
    public readonly globalWindowService: GlobalWindowService,
    private route: ActivatedRoute,
    private router: Router,
    private readonly eventsService: EventsService
  ) {
    this.bookingLoginForm = this.formBuilder.group({
      'propertyId': ['', [Validators.required]],
      'bookingOrReservationId': ['', [Validators.required]],
      'lastName': ['', [Validators.required]]
    });
    this.eventsService.getStepEvent().next('guest-manage');
  }

  public ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.bookingOrReservation = params.get('bookingOrReservation');
    })

    this.route.queryParamMap.subscribe((queryParams: ParamMap) => {

      this.bookingManagementParams = {
        id: queryParams.get('id'),
        lastName: queryParams.get('lastName'),
        propertyId: queryParams.get('propertyId'),
        showExtras: queryParams.get('showExtras')
      };

      if (this.config.redirectPath) {
        const serializedParams = serializeQueryParams(this.bookingManagementParams);
        const url = `${this.config.redirectPath}#/${this.bookingOrReservation}/manage?${serializedParams}`;
        this.globalWindowService.getWindowLocationAssign(url);
        return;
      }

      if (this.bookingOrReservation === 'reservation') {
        this.router.navigate(['/booking/manage'], { queryParams: this.bookingManagementParams });
      }

      this._setFormsValues(this.bookingManagementParams);
    });

  }

  private _setupBookingOrReservation(
    bookingOrReservation: string | null) {
    switch (bookingOrReservation) {
      case BookingOrReservationEnum.Booking:
        this.isBooking = true;
        this.isReservation = false;
      break;
      case BookingOrReservationEnum.Reservation:
        this.isBooking = false;
        this.isReservation = true;
      break;
      default:
        this.router.navigate(['/404'])
      break;
    }
  }

  private _setFormsValues(params: BookingManagementParams) {
    if (params.id) {
      this.bookingLoginForm.controls['bookingOrReservationId'].setValue(params.id);
    }
    if (params.lastName) {
      this.bookingLoginForm.controls['lastName'].setValue(params.lastName);
    }
    if (params.propertyId) {
      this.bookingLoginForm.controls['propertyId'].setValue(params.propertyId);
    }
    if (this.bookingLoginForm.valid) {
      this._setAsBookingOrReservation(this.bookingLoginForm.controls['bookingOrReservationId'].value)
      this.localStorageService.setBookingLoginCredentials(this.bookingLoginForm.value);
      this._fetchBookingOrReservationData();
    }
  }

  public get firstProperty() {
    return this.config.properties[0];
  }

  public clearBookingOrReservation() {
    this.booking = undefined;
    this.reservation = undefined;
    this.bookingLoginForm.reset();

    return this.router.navigate(['/booking/manage'], { queryParams: {} });
  }

  public get reservationStatusOptions() {
    return [
      {
        value: 'Confirmed',
        label: this.translate.instant(`manage_booking.reservation_statuses.confirmed`)
      },
      {
        value: 'Canceled',
        label: this.translate.instant(`manage_booking.reservation_statuses.canceled`)
      },
      {
        value: 'CheckedOut',
        label: this.translate.instant(`manage_booking.reservation_statuses.checked_out`)
      },
      {
        value: 'NoShow',
        label: this.translate.instant(`manage_booking.reservation_statuses.no_show`)
      },
      {
        value: 'InHouse',
        label: this.translate.instant(`manage_booking.reservation_statuses.in_house`)
      }
    ]
  }

  public onFormSubmit(event: Event) {
    event.preventDefault();
    if (this.config.properties.length === 1) {
      this.bookingLoginForm.controls['propertyId'].setValue(this.config.properties[0].pmsId);
    }
    if (this.bookingLoginForm.valid) {
      this._setAsBookingOrReservation(this.bookingLoginForm.controls['bookingOrReservationId'].value)
      this.localStorageService.setBookingLoginCredentials(this.bookingLoginForm.value);
      this._fetchBookingOrReservationData();
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: {
            propertyId: this.bookingLoginForm.controls['propertyId'].value,
            id: this.bookingLoginForm.controls['bookingOrReservationId'].value,
            lastName: this.bookingLoginForm.controls['lastName'].value
          },
          queryParamsHandling: 'merge'
        }
      )
    }
  }

  public _setAsBookingOrReservation(id: string) {
    this.bookingOrReservation = this._isIdReservationOrBooking(id);
    this._setupBookingOrReservation(this.bookingOrReservation);
  }

  private _isIdReservationOrBooking(id: string) {
    if (this.config.isPmsApaleo() && !id.includes('-')) {
      return BookingOrReservationEnum.Booking;
    }
    return BookingOrReservationEnum.Reservation;
  }

  public cancelReservation(reservation: ReservationModel) {
    let reservationId = reservation.id;
    if (this.config.isPmsStayntouch()) {
      reservationId = reservation.bookingReference;
    }
    this.dialog.open(BookingCancellationDialogComponent, {
      data: reservation
    }).afterClosed()
      .subscribe((dialogResponse: boolean) => {
        if (dialogResponse) {
          return this.http.post(`${environment.serverUrl}/api/ibe/cancel-reservation`,  {
            propertyId: reservation.property.id,
            bookingReference: reservation.bookingReference,
            reservationId
          })
          .subscribe((apiResponse: IbeServerApiResponseModel) => {
            if (apiResponse.success) {
              this._fetchBookingOrReservationData();
              this.toasterService.pop('success',
              this.translate.instant('manage_booking.reservation_cancelled'),
              this.translate.instant('manage_booking.reservation_successfully_cancelled'));
            }
          }, (error) => {
            this.dialog.open(ErrorDialogComponent, {
              data: {
                title: this.translate.instant('dialog_error_codes.booking_cancel_error.title'),
                message: this.translate.instant('dialog_error_codes.booking_cancel_error.message')
              }
            });
          });
        }
        return;
    });
  }

  public isFormControlInvalid(formControl: AbstractControl) {
    return isFormControlInvalid(formControl, this.bookingLoginFormDirective);
  }

  public reservationHasConfirmedStatus(reservation: ReservationModel) {
    return (reservation.status === ReservationStatusEnum.Confirmed);
  }

  public openGuestDetailsDialog() {
    const credentials = this.localStorageService.getBookingLoginCredentials();
    let guestDetails = {} as BookerModel;
    if (this.isBooking && this.booking) {
      guestDetails = this.booking.booker;
    }
    if (this.isReservation && this.reservation) {
      guestDetails = this.reservation.primaryGuest as BookerModel;
    }

    this.dialog.open(GuestDetailsDialogComponent, {
      data: {
        bookingOrReservation: this.bookingOrReservation,
        bookingOrReservationId: credentials.bookingOrReservationId,
        guestDetails,
        propertyId: this.bookingLoginForm.controls['propertyId'].value,
        guestTitleFieldEnabled: this.config.settings.checkoutFields.details.title
      }})
      .afterClosed().subscribe((response: GuestDetailsDialogResponse) => {
        if (response.updateSuccess) {
          this.toasterService.pop('success',
            this.translate.instant('global.success'),
            this.translate.instant('manage_booking.guest_updated_successfully'));

          if (this.isBooking && this.booking) {
            this.booking.booker = {
              ...response.guestDetails
            };
          }

          if (this.isReservation && this.reservation) {
            this.reservation.primaryGuest = {
              ...response.guestDetails
            };
          }
        } else {
          this.toasterService.pop('error',
            this.translate.instant('manage_booking.guest_update'),
            this.translate.instant('manage_booking.guest_update_failed'));
        }
      });

  }

  public openPaymentDialog() {
    let guestData = {} as BookerModel;
    let bookingOrReservationId = '';
    let propertyId = '';

    if (this.isBooking && this.booking) {
      guestData = this.booking.booker;
      bookingOrReservationId = this.booking.id as string;
      propertyId = this.booking.reservations[0].property.id;
    }

    if (this.isReservation && this.reservation) {
      guestData = this.reservation.primaryGuest as BookerModel;
      propertyId = this.reservation.property.id;
      bookingOrReservationId = this.reservation.bookingReference as string;
    }

    if (this.config.pmsProvider !== 'APALEO' && !isGuestDataValid(guestData)) {
      this.openGuestDetailsDialog();
    } else {
      this.dialog.open(BookingPaymentDialogComponent,
        { data: {
          bookingOrReservation: this.bookingOrReservation,
          bookingOrReservationId,
          propertyId,
          paymentRedirected: this.paymentRedirected
        }})
        .afterClosed()
        .subscribe((response) => {
          if (response && response.paymentSuccess) {
            this._fetchBookingOrReservationData();
          }
          if (this.paymentRedirected) {
            this.paymentRedirected = false;
            this.router.navigate(
              [],
              {
                relativeTo: this.route,
                queryParams: this.bookingManagementParams
              }
            )
          }
        });
    }
  }

  public login() {
    this.router.navigate(['guest/manage']);
  }

  public openAddExtrasDialog(reservation: ReservationModel) {
    this.dialog.open(AddExtrasDialogComponent, {
      data: reservation
    }).afterClosed().subscribe(success => {
      if (success) {
        this._fetchBookingOrReservationData();
      }
    });
  }

  private _maybeShowExtrasOnOpen(reservations: ReservationModel[] | undefined) {
    const stringTrue = 'true';
    if (this.bookingManagementParams.showExtras &&
      this.bookingManagementParams.showExtras.toLowerCase() === stringTrue
      && reservations && reservations.length) {
      this.openAddExtrasDialog(reservations[0]);
    }
  }

  public isReservationFlexibleAndEditable(reservation: ReservationModel) {
    return this.isReservationModifiable(reservation) && this._isReservationFlexible(reservation);
  }

  public isReservationModifiable(reservation: ReservationModel) {
    const unmodifiableStatuses = [
      ReservationStatusEnum.Canceled,
      ReservationStatusEnum.InHouse,
      ReservationStatusEnum.NoShow,
      ReservationStatusEnum.DueIn,
      ReservationStatusEnum.CheckedOut,
      ReservationStatusEnum.DueOut
    ];
    return !unmodifiableStatuses.includes(reservation.status as ReservationStatusEnum) && reservation.channel === 'Ibe';
  }

  private _isReservationFlexible(reservation: ReservationModel) {
    return reservation.guaranteeType && reservation.guaranteeType !== GuaranteeTypeEnum.Prepayment;
  }

  public bookingId() {
    return (this.booking) ? this.booking.id : NOT_FOUND_TEXT
  }

  public bookingReference() {
    return (this.reservation) ? this.reservation.bookingReference : NOT_FOUND_TEXT
  }

  public booker() {
    return (this.booking) ? this.booking.booker : NOT_FOUND_TEXT
  }

  public primaryGuest() {
    return (this.reservation) ? this.reservation.primaryGuest : NOT_FOUND_TEXT
  }

  public paymentAccount() {
    if (this.booking) {
      return this.booking.paymentAccount || this.booking.reservations.find(reservation => reservation.paymentAccount)?.paymentAccount;
    }
    if (this.reservation) {
      return this.reservation.paymentAccount;
    }
    return;
  }

  private _getfilteredReservations() {
    if (this.booking && this.booking.reservations) {
      return this.booking.reservations.filter((reservation) => {
        this.reservationConfirmed = (reservation.status === 'Confirmed' as ReservationStatusEnum);
        return (reservation.status === this.reservationStatus);
      })
    }

    return [];
  }

  private _fetchBookingOrReservationData() {
    const credentials = this.localStorageService.getBookingLoginCredentials();
    let httpParams = new HttpParams()
      .set('id', credentials.bookingOrReservationId)
      .set('lastName', credentials.lastName);

    if (this.bookingLoginForm.controls['propertyId'].value) {
      httpParams = httpParams.append('propertyId', this.bookingLoginForm.controls['propertyId'].value)
    }

    this.isLoading = true;
    return this.http.get(`${environment.serverUrl}/api/ibe/${this.bookingOrReservation}`, {
      params: httpParams
    })
    // tslint:disable-next-line:no-any
    .subscribe((response: any) => {
      this.isLoading = false;

      if (this.isBooking) {
        this.booking = response;
        if (this.booking && this.booking.reservations) {
          this.reservations = this.booking.reservations;
          this.showEditButtons = (this.config.accountFeatureWhitelist.allowEditingOfDirectBookings
            && !!(this.reservations.find(reservation => reservation.channel === 'Direct')))
            || !this.reservations.find(reservation => reservation.channel !== 'Ibe');
          this._maybeShowExtrasOnOpen(this.reservations);
        }
      }

      if (this.isReservation) {
        this.reservation = response;
        this.showEditButtons = (this.config.accountFeatureWhitelist.allowEditingOfDirectBookings && this.reservation?.channel === 'Direct')
          || this.reservation?.channel === 'Ibe';
        this._maybeShowExtrasOnOpen([this.reservation as ReservationModel]);
      }
      this.filteredReservations = this._getfilteredReservations();

      if (this.route.snapshot.queryParamMap.has('payload')) {
        this.paymentRedirected = true;
        this.openPaymentDialog();
      }
    }, (error) => {
      this.isLoading = false;
      this.dialog.open(ErrorDialogComponent, {
        data: {
          title: this.translate.instant('dialog_error_codes.booking_not_found.title'),
          message: this.translate.instant('dialog_error_codes.booking_not_found.message')
        }
      });
    });
  }

  public changeReservationStatus = (value: ReservationStatusEnum) => {
    this.reservationStatus = value;
    if (value) {
        this.filteredReservations = this.reservations.filter((reservation) => reservation.status === this.reservationStatus);
      } else {
        this.filteredReservations = this.reservations;
      }
  };
}
