import { Injectable } from '@angular/core';
import { IbeConfigService } from '../services/ibe-config.service';
import { StayCriteriaModel, BookingModel, AvailabilityResultModel } from 'up-ibe-types';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private _localStorageKeyPrefix = 'upibe';

  constructor(
    public config: IbeConfigService
  ) { }

  // tslint:disable-next-line:no-any
  public setItem(key: string, data: any) {
    return localStorage.setItem(
      `${this._localStorageKeyPrefix}_${this.config.ibeKey}_${key}`, JSON.stringify(data)
    );
  }

  public getItem(key: string) {
    const item = localStorage.getItem(
      `${this._localStorageKeyPrefix}_${this.config.ibeKey}_${key}`
    );

    if (!item) {
      return false;
    }

    return JSON.parse(item);
  }

  public removeItem(key: string) {
    return localStorage.removeItem(
      `${this._localStorageKeyPrefix}_${this.config.ibeKey}_${key}`
    );
  }

  public getBookingData() {
    return this.getItem('bookingData');
  }

  public getBookingRequestId() {
    return this.getItem('bookingRequestId');
  }

  public getBookingRequestInProgress() {
    return this.getItem('bookingRequestInProgress');
  }

  public getLastSearchedStayCriteria() {
    return this.getItem('lastSearchedStayCriteria');
  }

  public getBookingLoginCredentials() {
    return this.getItem('bookingLoginCredentials');
  }

  public getBookingRequestIdEventHasBeenFiredFor() {
    return this.getItem('setBookingRequestIdEventHasBeenFiredFor');
  }

  public getSaferpayToken() {
    return this.getItem('saferPayToken');
  }

  public getAdyenTransactionId() {
    return this.getItem('adyenTransactionId');
  }

  public setBookingData(data: BookingModel) {
    return this.setItem('bookingData', data);
  }

  public setBookingRequestId(data: string) {
    return this.setItem('bookingRequestId', data);
  }

  public setBookingLoginCredentials(data: {
    propertyId?: string;
    bookingOrReservationId: string;
    lastName: string;
  }) {
    return this.setItem('bookingLoginCredentials', data)
  }

  public setBookingRequestInProgress(data: {success: boolean; id: string}) {
    return this.setItem('bookingRequestInProgress', data);
  }

  public setLastSearchedStayCriteria(data: StayCriteriaModel) {
    return this.setItem('lastSearchedStayCriteria', data);
  }

  public setBookingRequestIdEventHasBeenFiredFor(data: string) {
    return this.setItem('setBookingRequestIdEventHasBeenFiredFor', data);
  }

  public setAdyenTransactionId(data: string) {
    return this.setItem('adyenTransactionId', data);
  }

  public setSaferPayToken(data: string) {
    return this.setItem('saferPayToken', data);
  }

  public setAvailabilityResultData(data: AvailabilityResultModel[]) {
    return this.setItem('availabilityResult', data);
  }

  public removeBookingData() {
    this.removeItem('bookingData');
  }

  public removeBookingRequestId() {
    this.removeItem('bookingRequestId');
  }

  public removeLastSearchedStayCriteria() {
    this.removeItem('lastSearchedStayCriteria');
  }

  public removeBookingRequestInProgress() {
    this.removeItem('bookingRequestInProgress');
  }

  public removeSaferPayToken() {
    this.removeItem('saferPayToken');
  }

  public removeAdyenTransactionId() {
    return this.removeItem('adyenTransactionId');
  }
}
