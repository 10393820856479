import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { ExtraModel, PricingUnit } from 'up-ibe-types';
import { ImagesService } from '../services/images.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ibe-extras-template',
  templateUrl: './extras-template.component.html',
  styleUrls: ['./extras-template.component.scss']
})
export class ExtrasTemplateComponent implements OnChanges {
  @Input('extras') public extras: ExtraModel[] = [];
  @Input('selectedExtras') public selectedExtras: ExtraModel[] = [];
  @Input('isLoading') public isLoading = false;
  @Output('onSelectedExtrasUpdate') public onSelectedExtrasUpdate: EventEmitter<ExtraModel[]> = new EventEmitter();
  public haveImages = false;

  constructor(
    public readonly imagesService: ImagesService,
    private readonly translate: TranslateService
  ) {}

  public ngOnChanges() {
    if (this.extras) {
      this.extras.forEach(extra => {
        if (extra.image) {
          this.haveImages = true;
        }
        if (extra.isInclusiveInRate) {
          const isInSelectedExtras = this.selectedExtras.find(selectedExtra => selectedExtra.id === extra.id);
          if (!isInSelectedExtras) {
            this.selectedExtras.push(extra);
          }
        }
      });
    }
  }

  public toggleExtra(extra: ExtraModel) {
    if (this.isExtraSelected(extra.id)) {
      this._removeExtraFromSelectedExtras(extra);
    } else {
      this._addExtraToSelectedExtras(extra);
    }
    this.onSelectedExtrasUpdate.emit(this.selectedExtras);
  }

  public isExtraSelected(extraId: string) {
    if (this.selectedExtras) {
      return this.selectedExtras.some((extra: ExtraModel) => {
        return extra.id === extraId;
      });
    }

    return false;
  }

  public getExtraImageUrl(extra: ExtraModel) {
    return this.imagesService.getExtraImageUrl(extra);
  }

  public translatePricingUnit(pricingUnit?: PricingUnit) {
    const stringToTranslate = pricingUnit ? `extras.${pricingUnit.toString().toLowerCase()}` : '';
    return this.translate.instant(stringToTranslate);
  }

  private _addExtraToSelectedExtras(extra: ExtraModel) {
    this.selectedExtras = this.selectedExtras.concat(extra);
    return;
  }

  private _removeExtraFromSelectedExtras(extra: ExtraModel) {
    this.selectedExtras = this.selectedExtras.filter((element: ExtraModel) => {
      return extra.id !== element.id;
    });
    return;
  }

}
