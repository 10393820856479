import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroupDirective, FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { FormValidationService } from '../../services/form-validation.service';
import { GuestAuthService } from '../../services/guest-auth.service';
import { isFormControlInvalid } from '../../helpers/form.helper';
import { IbeConfigService } from '../../services/ibe-config.service';
import { NewsletterSubscriptionService } from '../../services/newsletter-subscription.service';
import { forkJoin, Observable, of } from 'rxjs';
import { LocalStorageService } from 'app/services/local-storage.service';
import { mergeMap } from 'rxjs/operators';
import { HttpStatus } from '../../../enums';
import { Property, TermsAndConditions } from 'up-ibe-types';
import { BookingService } from '../../services/booking.service';
import { EventsService } from 'app/services/events.service';

@Component({
  selector: 'ibe-guest-create-account',
  templateUrl: './guest-create-account.component.html',
  styleUrls: ['./guest-create-account.component.scss']
})
export class GuestCreateAccountComponent implements OnInit {
  @ViewChild('guestCreateFormDirective', { static: true }) public guestCreateFormDirective: FormGroupDirective;
  public guestCreateForm: FormGroup;
  public showForm = false;
  public isPmsApaleo = false;
  public saving = false;
  public termsConditionsUrl: string;
  public createAccountText = '';

  constructor(
    public readonly router: Router,
    public readonly guestAuthService: GuestAuthService,
    public readonly http: HttpClient,
    public readonly toasterService: ToasterService,
    public readonly dialog: MatDialog,
    private readonly formBuilder: FormBuilder,
    private readonly translate: TranslateService,
    private readonly formValidationService: FormValidationService,
    private readonly newsletterSubscriptionService: NewsletterSubscriptionService,
    private readonly localStorageService: LocalStorageService,
    public readonly config: IbeConfigService,
    private readonly bookingService: BookingService,
    private readonly eventsService: EventsService
  ) {
    this.termsConditionsUrl = this._getTermsConditionsUrl();
    this.eventsService.getStepEvent().next('guest-manage');

  }

  public ngOnInit() {
    this.isPmsApaleo = this.config.isPmsApaleo();

    if (this.guestAuthService.getToken()) {
      this.router.navigate(['guest/manage']);
    }

    this.guestCreateForm = this.formBuilder.group({
      'firstName': ['', [Validators.required]],
      'lastName': ['', [Validators.required]],
      'username': ['', [Validators.required, Validators.email, FormValidationService.emailValidator]],
      'password': ['', [Validators.required]],
      'confirmPassword': ['', [Validators.required]]
    }, { validator: this.formValidationService.passwordMatchValidator });

    if (
      this.config.accountFeatureWhitelist &&
      this.config.accountFeatureWhitelist.accountCreationToggles &&
      this.config.settings.checkoutFields.address.marketingConsent
    ) {
      this.guestCreateForm.addControl('termsConditions', new FormControl(false, [Validators.requiredTrue]));
      if (this.config.settings.checkoutFields.address.marketingConsent) {
        this.guestCreateForm.addControl('marketingConsent', new FormControl(false, [Validators.required]));
      }
    }

    this.createAccountText = this.config.settings.createAccountText[this.config.language];
  }

  public subscribeToNewsletter(): Observable<object> {
    if (this.localStorageService.getLastSearchedStayCriteria()) {
      return this.newsletterSubscriptionService.subscribeToNewsletter({
        firstName: this.guestCreateForm.value.firstName,
        lastName: this.guestCreateForm.value.lastName,
        email: this.guestCreateForm.value.username
      }, this.localStorageService.getLastSearchedStayCriteria().propertyId)
    }

    return this.subscribeToAllNewsletters();
  }

  public subscribeToAllNewsletters(): Observable<object> {
    if (!this.config.properties?.length) {
      return of({})
    }

    return forkJoin(this.config.properties.map((property: Property) => {
      return this.newsletterSubscriptionService.subscribeToNewsletter({
        firstName: this.guestCreateForm.value.firstName,
        lastName: this.guestCreateForm.value.lastName,
        email: this.guestCreateForm.value.username
      }, property.pmsId)
    })
  )
  }

  public createGuest() {

    if (this.guestCreateForm.valid) {
      this.saving = true;
      this.guestAuthService.createGuest(this._getFormData()).pipe(
        mergeMap(() => this.guestCreateForm.value.marketingConsent ? this.subscribeToNewsletter() : of({}))
      ).subscribe(() => {
        this.saving = false;

        if (this.config.accountFeatureWhitelist.authenticateGuestEmails) {
          this.goToGuestManagement();
          this.toasterService.pop('success',
            this.translate.instant('guest_management_auth.create_successful')
          );
          this.toasterService.pop('success',
            this.translate.instant('guest_management_auth.check_email')
          );
        } else {
          this.guestAuthService.loginGuest(this.guestCreateForm.value).subscribe(response => {
            const shouldSkipToCheckout =
            this.config.accountFeatureWhitelist.skipStraightToCheckoutOnLogin && this.bookingService.booking.reservations.length;
            if (shouldSkipToCheckout) {
              this.router.navigate(['checkout/details'])
            } else {
              this.goToGuestManagement();
            }
          });
        }
      }, (error) => {
        this.saving = false

        this.toasterService.pop('error',
          this.translate.instant('guest_management_auth.create_failed'),
          error.error.message
        );

        if (error.status === HttpStatus.Conflict) {
          this.goToGuestManagement()
        }
      })
    }
  }

  public toggleForm() {
    this.showForm = !this.showForm;
  }

  public goToGuestManagement() {
    this.router.navigate(['guest/manage']);
  }

  public isFormControlInvalid(formControl: AbstractControl | null) {
    return isFormControlInvalid(formControl, this.guestCreateFormDirective);
  }

  private _getTermsConditionsUrl() {
    const url = this.config.settings.termsAndConditionsUrls[this.config.language]
    if (url.length) { return url; }
    const defaultKey: keyof TermsAndConditions = this.config.settings.defaultLanguage as keyof TermsAndConditions
    return this.config.settings.termsAndConditionsUrls[defaultKey];
  }

  private _getFormData() {
    return {
      username: this.guestCreateForm.value['username'],
      password: this.guestCreateForm.value['password'],
      firstName: this.guestCreateForm.value['firstName'],
      lastName: this.guestCreateForm.value['lastName'],
      title: this.guestCreateForm.value['title'],
      phone: this.guestCreateForm.value['phone'],
      marketingConsent: this.guestCreateForm.value['marketingConsent']
    }
  }
}
