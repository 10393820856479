import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class NewsletterSubscriptionService {
    constructor(private http: HttpClient) {}

    public subscribeToNewsletter(subscriber: { firstName: string, lastName: string, email: string }, propertyId: string) {
        return this.http.post(`${environment.serverUrl}/api/ibe/properties/${propertyId}/subscriptions`, {
            subscriber
        })
    }
}
