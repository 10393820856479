import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ExtraModel } from 'up-ibe-types';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map, shareReplay, take } from 'rxjs/operators';
import { arrayIfEmpty } from './store/common';
import { ErrorHandlerService, tryInitializeSentry } from './error-handler.service';
import { captureException, Scope, Severity } from '@sentry/browser';

export interface ExtraQueryParams {
  params: {
    propertyId: string;
    ratePlanId: string;
    arrival: string;
    departure: string;
    adults: string;
    // tslint:disable-next-line:no-any
    childrenAges?: any;
  };
}

@Injectable({
  providedIn: 'root'
})
export class ExtrasStoreService {
  private hasSentry = false;

  constructor(readonly http: HttpClient) {
    this.hasSentry = tryInitializeSentry();
  }

  public load(params: ExtraQueryParams): Observable<ExtraModel[]> {
    const extraUrl = `${environment.serverUrl}/api/ibe/extras`;
    return this.http
      .get<ExtraModel[]>(extraUrl, params)
      .pipe(
        // allow execution to continue if there's an error
        // (guests should still be able to buy a room even if
        // loading extras fails)
        catchError((error: HttpErrorResponse) => {
          this.handleError(error, 'http', 'ExtrasStoreService.load', { 'extraUrl': extraUrl });
          return of([]);
        }),
        arrayIfEmpty(),
        map((value: ExtraModel[]) => value ? value : []),
        take(1), // complete when 1 result is returned
        shareReplay(1) // if multiple callers call me, return the same result
      );
  }

  // tslint:disable-next-line:no-any
  private handleError(error: any, type: string, category: string, details: { [key: string]: any; }): void {
    if (this.hasSentry) {
      const err = ErrorHandlerService.toError(error);
      details.error = error;
      captureException(err, (scope: Scope) =>
        scope.addBreadcrumb({
          type,
          level: Severity.Critical,
          message: `${err.message}`,
          category,
          data: details
        })
      );
    } else {
      console.error('handleError', error);
    }
    // unhandled / ignore error
  }
}
