import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IbeConfigService } from 'app/services/ibe-config.service';
import { RateInfoModalComponent } from '../rate-info-modal/rate-info-modal.component';
import {
  AvailabilityResultModel,
  AvailabilityResultRateModel,
  Property,
  UnitType,
  ExtraModel
} from 'up-ibe-types';
import { orderBy, find } from 'lodash';
import { ExtrasStoreService, ExtraQueryParams } from 'app/services/extras-store.service';
import { ActivatedRoute } from '@angular/router';

export interface OnAddToCartEvent {
  availabilityResult: AvailabilityResultModel,
  availabilityResultRate: AvailabilityResultRateModel,
  selectedUnitQty: number;
  featuredExtra?: FeaturedExtraModel;
};

export interface OnRateSelectEvent {
  rate: AvailabilityResultRateModel;
};

export interface FeaturedExtraModel extends ExtraModel {
  rateId: string;
}

@Component({
  selector: 'ibe-rate-selector',
  templateUrl: './rate-selector.component.html',
  styleUrls: ['./rate-selector.component.scss']
})
export class RateSelectorComponent implements OnInit {
  @Input('availabilityResult') public availabilityResult: AvailabilityResultModel;
  @Input('pmsPropertyId') public pmsPropertyId: string;
  @Input('propertyName') public propertyName: string;
  @Input('useBasePrices') public useBasePrices: boolean;
  @Output('onRateSelect') public onRateSelect: EventEmitter<OnRateSelectEvent> = new EventEmitter();
  @Output('onAddToCart') public onAddToCart: EventEmitter<OnAddToCartEvent> = new EventEmitter();
  public selectedUnitQty = 1;
  public selectedRate: {
    rateId: string
  };
  public hasSpecialRates = false;
  public featuredExtras: FeaturedExtraModel[] = [];
  public addExtraToCart = false;

  constructor(
    public readonly config: IbeConfigService,
    public extrasStore: ExtrasStoreService,
    public route: ActivatedRoute,
    private readonly dialog: MatDialog
  ) { }

  public ngOnInit() {
    this.availabilityResult = {
      ...this.availabilityResult,
      rates: orderBy(this.availabilityResult.rates, rate => this.isSpecialRate(rate), ['desc'])
    }
    this.hasSpecialRates = this._hasSpecialRate(this.availabilityResult.rates);
    this._preselectDefaultOffer();

    if (this.config.accountFeatureWhitelist.serviceToggle) {
      this.availabilityResult.rates.forEach(rate => {
        const params: ExtraQueryParams = {
          params: {
            adults: this.route.snapshot.queryParams.adults,
            arrival: this.route.snapshot.queryParams.arrival,
            departure: this.route.snapshot.queryParams.departure,
            propertyId: this.route.snapshot.queryParams.propertyId,
            ratePlanId: rate.id
          }
        }

        if (this.route.snapshot.queryParams.childrenAges) {
          params.params.childrenAges = this.route.snapshot.queryParams.childrenAges
        }

        this.extrasStore.load(params).subscribe(response => {
          const potentialFeaturedExtra = response.find(extra => !extra.isInclusiveInRate && extra.featuredExtra);

          if (potentialFeaturedExtra) {
            this.featuredExtras.push(...[{
              ...potentialFeaturedExtra,
              rateId: rate.id
            }]);
          }
        });
      });
    }
  }

  public selectRate(rate: AvailabilityResultRateModel) {
    this.selectedRate = {
      rateId: rate.id
    };
    this.onRateSelect.emit({rate});
  }

  public isRateSelected(rateId: string) {
    return this.selectedRate && rateId === this.selectedRate.rateId;
  }

  public isSpecialRate(rate: AvailabilityResultRateModel) {
    return rate.isPromoRate || rate.isCorporateRate
  }

  public rateHasAdditionalIncludedExtras(rate: AvailabilityResultRateModel): boolean {

    if (rate.includedExtras === undefined || !rate.includedExtras.length) {
      return false;
    }

    const additionalIncludedExtras = rate.includedExtras.filter((extra) => {
      return extra.pricingMode === 'Additional';
    });

    return additionalIncludedExtras.length > 0;
  }

  public getFeaturedExtra(rateId: string) {
    return this.featuredExtras.find(extra => extra.rateId === rateId);
  }

  private _hasSpecialRate(rates: AvailabilityResultRateModel[]) {
    const specialRate = find(rates, rate => this.isSpecialRate(rate));
    if (specialRate) {
      return true;
    }
    return false;
  }

  public generateUnitQtyArray(availableUnits: number) {
    const maxRoomsNumber = this.config.settings.roomQtySelectorLimit;
    return Array.from(Array(1 + availableUnits).keys()).slice(1, maxRoomsNumber + 1);
  }

  public openRateInfoModal(availabilityResultRate: AvailabilityResultRateModel) {
    this.dialog.open(RateInfoModalComponent, {
      data: {
        availabilityResultRate
      },
      panelClass: 'ibe-rate-info-dialog'
    });
  }

  public addToCart($event: MouseEvent, availabilityResultRate: AvailabilityResultRateModel) {
    // route transition always happens after cart add
    // so we don't bother enabling the element afterwards
    const buttonEl = $event.target as HTMLButtonElement;
    buttonEl.disabled = true;

    this.onAddToCart.emit({
      availabilityResult: this.availabilityResult,
      availabilityResultRate,
      selectedUnitQty: this.selectedUnitQty,
      featuredExtra: this.addExtraToCart ? this.featuredExtras.find(extra => availabilityResultRate.id === extra.rateId) : undefined
    });
  }

  public decreaseQty() {
    if (this.selectedUnitQty > 1) {
      this.selectedUnitQty = this.selectedUnitQty - 1;
    }
  }

  public increaseQty(availableUnits: number) {
    if (this.selectedUnitQty < this.config.settings.roomQtySelectorLimit && this.selectedUnitQty < availableUnits) {
      this.selectedUnitQty = this.selectedUnitQty + 1;
    }
  }

  public updateRatePrice(rate: AvailabilityResultRateModel, extra: FeaturedExtraModel) {
    if (this.addExtraToCart) {
      rate.totalGrossAmount.amount = rate.totalGrossAmount.amount + (extra.totalGrossAmount?.amount || 0);
      if (rate.totalBaseAmount) {
        rate.totalBaseAmount.amount = rate.totalBaseAmount.amount + (extra.totalGrossAmount?.amount || 0);
      }
    } else {
      rate.totalGrossAmount.amount = rate.totalGrossAmount.amount - (extra.totalGrossAmount?.amount || 0);
    }
    if (rate.totalBaseAmount) {
      rate.totalBaseAmount.amount = rate.totalBaseAmount.amount - (extra.totalGrossAmount?.amount || 0);
    }
  }

  private _preselectDefaultOffer() {
    const defaultRatePlanPmsId = this._getDefaultRatePlanIdFromConfig();
    const specialRate = this.availabilityResult.rates.find(rate => rate.isPromoRate)

    if (this.hasSpecialRates && specialRate) {
      this.selectedRate = {
        rateId: specialRate.id
      }

      return
    }

    if (defaultRatePlanPmsId) {
      this.availabilityResult.rates.forEach((rate) => {
        if (rate.id === defaultRatePlanPmsId) {
          this.selectedRate = {
            rateId: rate.id
          };
        }
      });
    }

    if (!this.selectedRate) {
      this.selectedRate = {
        rateId: this.availabilityResult.rates[0].id
      };
    }
  }

  private _getDefaultRatePlanIdFromConfig() {
    const pmsUnitTypeId = this.availabilityResult.unitType.id;
    const property = this.config.properties.find((_property: Property) => {
      if (_property.pmsId === this.pmsPropertyId) {
        return true;
      }
      return false;
    });

    if (property) {
      const unitType = property.unitTypes.find((_unitType: UnitType) => {
        if (_unitType.pmsId === pmsUnitTypeId) {
          return true;
        }
        return false;
      });

      if (unitType) {
        return unitType.defaultRatePlanPmsId;
      }
    }
    return;
  }
}
