import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormGroupDirective, FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { sortBy } from 'lodash';
import { Settings, CheckoutFields } from 'up-ibe-types';
import { IbeConfigService } from '../../services/ibe-config.service';
import { BookingService } from '../../services/booking.service';
import { fadeInOnEnterAnimation, scaleUpAnimation } from '../../animations';
import { isFormControlInvalid } from '../../helpers/form.helper';
import { forkJoin, Observable } from 'rxjs';

interface AddressField {
  name: string;
  controlArgs: [string, FormControl]
};

@Component({
  selector: 'ibe-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
  animations: [
    fadeInOnEnterAnimation,
    scaleUpAnimation
  ]
})
export class AddressFormComponent implements OnInit {
  @ViewChild('addressFormDirective', { static: true }) public addressFormDirective: FormGroupDirective;
  @Input() public submitObservable: Observable<boolean>;
  @Output() public formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  public addressForm: FormGroup;
  public countriesList: {}[];
  public animationState: string;
  public ibeSettings: Settings;
  public defaultCountries: CountriesResponse[] = [];
  public statesList: {US: {}[], CA: {}[]};
  private maxPostcodeLength = 20;
  private maxAddressLength = 200;
  private statesListLoaded = false;

  constructor(
    private readonly config: IbeConfigService,
    private readonly bookingService: BookingService,
    private readonly http: HttpClient,
    private readonly formBuilder: FormBuilder
  ) {
    this.ibeSettings = this.config.settings;

    this._buildAddressForm();
  }

  public ngOnInit() {
    this.loadCountriesData();
    this.loadStatesData();
    const countryCode = this.bookingService.booking.booker.address?.countryCode;
    this.addressForm.patchValue({
      ...this.bookingService.booking.booker.address,
      countryCode: countryCode ? countryCode : undefined
    });
    if (!this.showStatesField()) {
      this.addressForm.removeControl('state');
    }
    this.formReady.emit(this.addressForm);
  }

  public parseCountriesList(countries: CountriesResponse) {
    return Object.keys(countries).map((key: string) => ({
      value: this.config.isPmsAcProject() ? key.toUpperCase() : key,
      label: countries[key]
    }))
  }

  public parseStatesList(states: StatesResponse) {
    return {
      US: Object.keys(states.US).map((key: string) => ({
        value: key,
        label: states.US[key]
      })),
      CA: Object.keys(states.CA).map((key: string) => ({
        value: key,
        label: states.CA[key]
      }))
    }
  }

  private loadCountriesData() {
    forkJoin({
      countries: this.http.get(environment.countriesListDataUrl),
      defaults: this.http.get(`${environment.serverUrl}/api/ibe/${this.config.ibeKey}/default-address-countries`)
    }).subscribe((response: { countries: CountriesResponse, defaults: { countryCode: string }[] }) => {
      const countries: CountriesResponse[] = this.parseCountriesList(response.countries);
      const flattened: string[] = response.defaults.map(
        (defaultCountry: { countryCode: string }) => defaultCountry.countryCode
      )

      this.defaultCountries = sortBy(countries.filter((country: CountriesResponse) => flattened.includes(country.value)), 'label')
      this.countriesList = sortBy(countries.filter((country: CountriesResponse) => !flattened.includes(country.value)), 'label')
    })
  }

  private loadStatesData() {
    this.http.get(environment.statesListDataUrl).subscribe((response: StatesResponse) => {
      this.statesList = this.parseStatesList(response);
      this.statesListLoaded = true;
    })
  }

  public isFormControlInvalid(formControl: AbstractControl) {
    return isFormControlInvalid(formControl, this.addressFormDirective);
  }

  public onCountrySelect(countryCode: string) {
    if (this.showStatesField(countryCode) && this.config.settings.checkoutFields.address.state) {
      this.addressForm.addControl('state', new FormControl('', [Validators.required]));
    } else {
      this.addressForm.removeControl('state');
    }
  }

  public showStatesField(countryCode?: string) {
    const formCountryCode = this.addressForm.controls['countryCode']?.value
    const isCountryEligble = formCountryCode ?
    formCountryCode === 'US' || formCountryCode === 'CA' :
    countryCode === 'US' || formCountryCode === 'CA';
    return isCountryEligble && this.statesListLoaded;
  }

  private _buildAddressForm() {
    this.addressForm = this.formBuilder.group({});

    const addressFields: AddressField[] = [
      {
        name: 'street',
        controlArgs: ['addressLine1', new FormControl('', [Validators.maxLength(this.maxAddressLength)])]
      },
      {
        name: 'city',
        controlArgs: ['city', new FormControl('', [Validators.maxLength(this.maxAddressLength)])]
      },
      {
        name: 'postalCode',
        controlArgs: [
          'postalCode', new FormControl('', [Validators.minLength(1), Validators.maxLength(this.maxPostcodeLength)])
        ]
      },
      {
        name: 'countryCode',
        controlArgs: ['countryCode', new FormControl('')]
      },
      {
        name: 'state',
        controlArgs: ['state', new FormControl('')]
      },
      {
        name: 'marketingConsent',
        controlArgs: ['marketingConsent', new FormControl(false)]
      }
    ]

    const pickedFields = addressFields.filter((field) => {
      return this.ibeSettings.checkoutFields.address[field.name as keyof CheckoutFields['address']].activated
    });

    pickedFields.forEach(field => {
      this.addressForm.addControl(...field.controlArgs);
      if (this.config.settings.checkoutFields.address[field.name as keyof CheckoutFields['address']].required) {
        this.addressForm.controls[field.controlArgs[0]].setValidators(Validators.required);
      }
    });
  }
}

export interface IbeInterest {
  id: number
  value: string
  ibeId: number
}

export interface IbeInterestsResponse {
  interests: IbeInterest[]
}

export interface CountriesResponse {
  [key: string]: string;
}

export interface StatesResponse {
  US: {
    [key: string]: string;
  }
  CA: {
    [key: string]: string;
  }
}
