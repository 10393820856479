import { Injectable } from '@angular/core';
import { ExtraModel } from 'up-ibe-types';
import { MatDialog } from '@angular/material/dialog';
import { ExtrasComponent } from 'app/extras/extras.component';
import { of, combineLatest, Subject, Observable } from 'rxjs';
import { ExtrasStoreService, ExtraQueryParams } from './extras-store.service';
import { take, switchMap, map } from 'rxjs/operators';
import { Params } from '@angular/router';
import { arrayIfEmpty } from './store/common';

@Injectable({
  providedIn: 'root'
})
export class ExtrasDialogService {
  public extras: Subject<ExtraModel[]> = new Subject<ExtraModel[]>();

  constructor(private readonly dialog: MatDialog, private readonly extrasStore: ExtrasStoreService) {}

  public open = (
    queryParams: Params,
    rateId: string,
    propertyId: string,
    selectedExtras: ExtraModel[] = [],
    skipOnBackdropClick = false
  ): Observable<[boolean, ExtraModel[]]> => {
    const params = this._queryToExtraParams(queryParams, rateId, propertyId)
    const extras$ = this.extrasStore.load(params);
    const dialog = this.dialog.open<ExtrasComponent>(ExtrasComponent, {
      data: {
        selectedExtras,
        propertyId,
        skipOnBackdropClick,
        extras: this.extras
      },
      panelClass: 'up-ibe-extras'
    });
    const hasSomeExtras$ = extras$.pipe(map((extras) => !!extras.length))
    const selectedExtras$ = extras$
      .pipe(
        switchMap((extras: ExtraModel[]) => {
          if (extras.length) {
            this.extras.next(extras);
            return dialog.afterClosed().pipe(arrayIfEmpty())
          } else {
            this.extras.next([])
            return of([])
          }
        }),
        take(1) // finalise as soon as we have 1 result, no need to unsubscribe
      )
      return combineLatest<[boolean, ExtraModel[]]> ([hasSomeExtras$, selectedExtras$]);
  }

  // tslint:disable-next-line:no-any
  private _queryToExtraParams = (
    queryParams: Params,
    rateId: string,
    // tslint:disable-next-line:no-any
    propertyId: any
  ): ExtraQueryParams => {
    return {
      params: {
        propertyId,
        ratePlanId: rateId,
        arrival: queryParams.arrival,
        departure: queryParams.departure,
        adults: queryParams.adults,
        childrenAges: (queryParams.childrenAges || [])
      }
    }
  }
}
