import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ibe-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  public errorType: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router
  ) { }

  public ngOnInit() {
    this._getErrorType();
  }

  private _getErrorType() {
    const internalServerErrorCode = 500;
    this.route.queryParams.subscribe(params => {
      if (parseInt(params.errorCode, 10) === internalServerErrorCode) {
        this.errorType = 'server_error';
      } else {
        this.errorType = 'not_found'
      };
    });
  }

  public backToSearch() {
    this.router.navigate(['booking/search']);
  }
}
