import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroupDirective, AbstractControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormValidationService } from '../../services/form-validation.service';
import { GuestAuthService } from '../../services/guest-auth.service';
import { isFormControlInvalid } from '../../helpers/form.helper';
import { ErrorDialogComponent } from 'app/error-dialog/error-dialog.component';

export const accountNotAuthedMessage = 'Account created before email authentication was required';

@Component({
  selector: 'ibe-guest-login',
  templateUrl: './guest-login.component.html',
  styleUrls: ['./guest-login.component.scss']
})
export class GuestLoginComponent implements OnInit {
  @ViewChild('guestLoginFormDirective', { static: true }) public guestLoginFormDirective: FormGroupDirective;
  public guestLoginForm: FormGroup;
  public loading = false

  constructor(
    public readonly http: HttpClient,
    public readonly guestAuthService: GuestAuthService,
    public readonly dialogRef: MatDialogRef<GuestLoginComponent>,
    private readonly formBuilder: FormBuilder,
    private readonly toasterService: ToasterService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) { }

  public ngOnInit() {
    this.guestLoginForm = this.formBuilder.group({
      'username': ['', [Validators.required, Validators.email, FormValidationService.emailValidator]],
      'password': ['', [Validators.required]]
    });
  }

  public loginGuest() {
    if (this.guestLoginForm.valid) {
      this.loading = true
      this.guestAuthService.loginGuest(this.guestLoginForm.value)
        .subscribe((response) => {
          this.toasterService.pop('success',
            this.translate.instant('guest_management_auth.login_successful'));

          this.dialogRef.close(true);
          this.loading = false;
        }, (error) => {
          if (error.error.message === accountNotAuthedMessage) {
            return this.dialog.open(ErrorDialogComponent, {
              data: {
                title: this.translate.instant('guest_management_auth.authentication_required'),
                message: this.translate.instant('guest_management_auth.check_email'),
                allowRetry: false
              }
            }).afterClosed().subscribe(() => {
              this.router.navigate(['/guest/manage']);
            });
          }

          this.toasterService.pop('error',
            this.translate.instant('guest_management_auth.login_error'),
            error.error.message
          );
          this.loading = false;
          return;
        });
    }
  }

  public isFormControlInvalid(formControl: AbstractControl | null) {
    return isFormControlInvalid(formControl, this.guestLoginFormDirective);
  }

  public createAccount() {
    this.dialogRef.close(false);
    this.router.navigate(['/account/create']);
  }

  public resetPassword() {
    this.dialogRef.close(false);
    this.router.navigate(['/guest/account/reset-password']);
  }

  public manageBooking() {
    this.dialogRef.close(false);
    this.router.navigate(['/booking/manage']);
  }

}
