// use this to protect you from 204 null responses from the api

import { map } from 'rxjs/operators'

function _arrayIfEmpty<T>(val: T[]): T[] {
  return val ? val : [] as T[]
}

export function arrayIfEmpty<T>() {
  return map((value: T[]) => _arrayIfEmpty<T>(value))
}
