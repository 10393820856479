/* tslint:disable:max-file-line-count */
import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective, AbstractControl, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ToasterService } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { BookingService } from '../../services/booking.service';
import { IbeConfigService } from '../../services/ibe-config.service';
import { FormValidationService } from '../../services/form-validation.service';
import { AnalyticsService } from '../../services/analytics.service';
import { fadeInOnEnterAnimation, scaleUpAnimation } from '../../animations';
import { BookerModel, Settings, TravelPurpose, CheckoutFields, TermsAndConditions, PredefinedCommentBubbles } from 'up-ibe-types';
import { DetailsData, AddressData } from '../types';
import { generateGuestTitleOptions, isFormControlInvalid } from '../../helpers/form.helper';
import { GuestAuthService } from '../../services/guest-auth.service';
import { GuestLoginComponent } from '../../guest-management/guest-login/guest-login.component';
import { TravelPurposeEnum, GuaranteeTypeEnum } from 'enums';
import { Router } from '@angular/router';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'environments/environment';
import {
  StatesResponse,
  CountriesResponse,
  IbeInterestsResponse,
  IbeInterest
} from '../address-form/address-form.component';
import { sortBy } from 'lodash';
import { MatCheckboxChange } from '@angular/material/checkbox';

interface OptionType {
  label: string,
  value: string
}

@Component({
  selector: 'ibe-details-form',
  templateUrl: './details-form.component.html',
  styleUrls: ['./details-form.component.scss'],
  animations: [
    fadeInOnEnterAnimation,
    scaleUpAnimation
  ]
})
export class DetailsFormComponent implements OnInit {
  @ViewChild('detailsFormDirective', { static: true }) public detailsFormDirective: FormGroupDirective;
  @Input() public showCreateAccountBox: boolean;
  @Input() public submitObservable: Observable<boolean>;
  @Output() public formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() public toggleShowCreateAccountButton: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public updateLicensePlateNumber: EventEmitter<string> = new EventEmitter<string>();
  public detailsForm: FormGroup;
  public companyForm: FormGroup;
  public readonly guestTitleOptions = generateGuestTitleOptions(this.translate, this.config.pmsProvider);
  public readonly travelPurposeOptions = this._generateTravelPurposeOptions();
  public ibeSettings: Settings;
  public isTravelPurposeBusiness = false;
  public useCommentBubbles = false;
  public showFreeText = false;
  public guest: BookerModel;
  public animationState: string;
  public companyInfoRequired = false;
  public isCreateAccountLoading = false;
  public termsConditionsUrl: string;
  public isChrome: boolean = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
  public isSubmitting = false;
  public isOnCheckout = this.router.url.includes('checkout');
  public predefinedCommentBubbles = this._compilePredefinedCommentOptions(
    this.config.settings.predefinedCommentBubbles, this.config.language
  );
  public licensePlate: string;
  public milesAndMore: string;
  public ibeInterests: IbeInterest[] = [];

  public separatorKeysCodes = [ENTER, COMMA];
  public statesList: {US: {}[], CA: {}[]};
  public defaultCountries: CountriesResponse[] = [];
  public countriesList: {}[];

  constructor(
    public readonly guestAuthService: GuestAuthService,
    public readonly http: HttpClient,
    private readonly dialog: MatDialog,
    private readonly bookingService: BookingService,
    public config: IbeConfigService,
    private readonly toasterService: ToasterService,
    private readonly formBuilder: FormBuilder,
    private readonly translate:  TranslateService,
    private readonly analyticsService:  AnalyticsService,
    private readonly router: Router
  ) {
    this.ibeSettings = this.config.settings;
    this.companyForm = this.formBuilder.group({
      'companyName': [''],
      'companyTaxId': [''],
      'companyStreet': [''],
      'companyCity': [''],
      'companyPostalCode': [''],
      'companyCountryCode': [''],
      'companyState': ['']
    });
    // Save condition into property (used several times in html-part of component)
    this.useCommentBubbles = this.config.accountFeatureWhitelist.predefinedCommentBubbles
      && this.ibeSettings.predefinedCommentBubbles
      && this.ibeSettings.predefinedCommentBubbles[config.language]
      && this.ibeSettings.predefinedCommentBubbles[config.language].length > 0;
    // freetext field initialy hidden when using commentBubbles, always shown if not
    this.showFreeText = !this.useCommentBubbles;
    this.detailsForm = this.formBuilder.group({
      'title': [''],
      'firstName':  [''],
      'lastName':   [''],
      'email':      [{ value: '', disabled: true },
        [FormValidationService.emailValidator]],
      'password':   ['', [FormValidationService.passwordStrengthValidator]],
      'phone':      ['', [FormValidationService.phoneValidator]],
      'travelPurpose': [''],
      'guestComment': [''],
      'guestCommentBubbles': [[]],
      'termsConditions': [false, [Validators.requiredTrue]],
      'company': this.companyForm,
      'interests': []
    });

    if (!this.isOnCheckout) {
      this.ibeSettings.checkoutFields.details.guestComment = {
        activated: false,
        required: false
      };
      this.ibeSettings.checkoutFields.details.travelPurpose = {
        activated: false,
        required: false
      };
      this._toggleCompanyFields(false);
      this.ibeSettings.checkoutFields.address.marketingConsent = {
        activated: false,
        required: false
      };
      this.detailsForm.removeControl('termsConditions');
    }
    this._configureFormFields();
    this.detailsForm.updateValueAndValidity({onlySelf: true});
    if (this.config.accountFeatureWhitelist.licensePlateField) {
      this.detailsForm.addControl('guestComment', new FormControl(''));
    }
    if (this.config.accountFeatureWhitelist.milesAndMore) {
      this.detailsForm.addControl('milesAndMore', new FormControl(''));
    }
    this.showCreateAccountBox = this.isOnCheckout;
    this.termsConditionsUrl = this._getTermsConditionsUrl();
  }

  public ngOnInit() {
    this._activateCompanyField();
    this._updateForm();
    this._createCheckoutDetailsEvent();
    this.loadStatesData();
    this.loadCountriesData();
    this.loadIbeInterests();

    if (!this.isOnCheckout) {
      this.fetchGuest();
    }
    if (this.guestAuthService.isLoggedIn() && this.isOnCheckout) {
      this.fetchGuest();
      this.showCreateAccountBox = false;
    } else {
      this.detailsForm.controls.interests.setValue([]);
      if (this.detailsForm.controls.email) {
        this.detailsForm.controls['email'].enable();
      }
      if (this.detailsForm.controls.password) {
        this.detailsForm.controls.password.valueChanges.subscribe((value) => {
          if (value) {
            this.toggleShowCreateAccountButton.emit(true);
          } else {
            this.toggleShowCreateAccountButton.emit(false);
          }
        })
      }
    }

    if (this.config.settings.forceCommercialPurpose) {
      this.detailsForm.patchValue({ travelPurpose: TravelPurposeEnum.Business })
      this.onTravelPurposeFieldChange(TravelPurposeEnum.Business);
    }

    this.formReady.emit(this.detailsForm);
  }

  private _configureFormFields() {
    if (!this.ibeSettings.checkoutFields.details.guestComment.activated || !this.useCommentBubbles) {
      this.detailsForm.removeControl('guestCommentBubbles');
    }
    for (const field in this.ibeSettings.checkoutFields.details) {
      if (!this.ibeSettings.checkoutFields.details[field as keyof CheckoutFields['details']].activated) {
        if (field.includes('company')) {
          this.companyForm.removeControl(field);
        }
        this.detailsForm.removeControl(field);
      } else {
        if (this.ibeSettings.checkoutFields.details[field as keyof CheckoutFields['details']].required) {
          if (field.includes('company')) {
            this.companyForm.setValidators([Validators.required]);
          } else {
            this.detailsForm.controls[field].setValidators([Validators.required]);
          }
        }
      }
    }
  }

  public onTravelPurposeFieldChange(value: TravelPurpose | undefined) {
    this.isTravelPurposeBusiness = false;
    if (value === 'Business') {
      this._toggleCompanyFields(true);
      this.isTravelPurposeBusiness = true;
    } else {
      this._toggleCompanyFields(false);
    }
  }

  public showStatesField(countryCode?: string) {
    const formCountryCode = this.companyForm.controls['countryCode']?.value
    const isCountryEligble = formCountryCode ?
    formCountryCode === 'US' || formCountryCode === 'CA' :
    countryCode === 'US' || formCountryCode === 'CA';
    return isCountryEligble;
  }

  public onCountrySelect(countryCode: string) {
    if (this.showStatesField(countryCode) && this.config.settings.checkoutFields.address.state) {
      this.companyForm.addControl('state', new FormControl(
        '', this.ibeSettings.checkoutFields.details.companyState.required ? Validators.required : undefined
      ));
    } else {
      this.companyForm.removeControl('state');
    }
  }

  private _toggleCompanyFields(toggleOn: boolean) {
    const activatedCompanyFields = Object.entries(this.ibeSettings.checkoutFields.details).filter(field => {
      if (field[0].includes('company') && field[1].activated) {
        return true;
      } else {
        return false;
      }
    });
    activatedCompanyFields.forEach(field => {
      if (toggleOn) {
        this.companyForm.addControl(field[0], new FormControl(
          '', field[1].required ? Validators.required : undefined
        ))
      } else {
        this.companyForm.removeControl(field[0]);
      }
    });
  }

  private _activateCompanyField() {
    if (this.config.settings.checkoutFields.details.companyTaxId.activated) {
      for (const reservation of this.bookingService.booking.reservations) {
        if (
          !this.config.accountFeatureWhitelist.nonRequiredCompanyDetails &&
          (reservation.guaranteeType === GuaranteeTypeEnum.Company || reservation.corporateCode)
        ) {
          this.companyInfoRequired = true;
          this._toggleCompanyFields(true);
        }
      }
    }
  }

  public isFormControlInvalid(formControl: AbstractControl) {
    return isFormControlInvalid(formControl, this.detailsFormDirective);
  }

  public openLoginDialog() {
    this.dialog.closeAll();
    this.dialog.open(GuestLoginComponent, {
      height: '550px',
      width: '500px'
    }).afterClosed().subscribe((loggedIn: boolean) => {
        if (loggedIn) {
          this.showCreateAccountBox = false;
          this.toggleShowCreateAccountButton.emit(false);
        }
      });
  }

  public parseStatesList(states: StatesResponse) {
    return {
      US: Object.keys(states.US).map((key: string) => ({
        value: key,
        label: states.US[key]
      })),
      CA: Object.keys(states.CA).map((key: string) => ({
        value: key,
        label: states.CA[key]
      }))
    }
  }

  public parseCountriesList(countries: CountriesResponse) {
    return Object.keys(countries).map((key: string) => ({
      value: this.config.isPmsAcProject() ? key.toUpperCase() : key,
      label: countries[key]
    }))
  }

  private loadStatesData() {
    this.http.get(environment.statesListDataUrl).subscribe((response: StatesResponse) => {
      this.statesList = this.parseStatesList(response);
    })
  }

  private loadIbeInterests = (): void =>  {
    this.http.get(`${environment.serverUrl}/api/profile/ibe-interests/${this.config.ibeKey}`)
      .subscribe((response: IbeInterestsResponse) => {
        this.ibeInterests = response.interests;
      });
  }

  private loadCountriesData() {
    forkJoin({
      countries: this.http.get(environment.countriesListDataUrl),
      defaults: this.http.get(`${environment.serverUrl}/api/ibe/${this.config.ibeKey}/default-address-countries`)
    }).subscribe((response: { countries: CountriesResponse, defaults: { countryCode: string }[] }) => {
      const countries: CountriesResponse[] = this.parseCountriesList(response.countries);
      const flattened: string[] = response.defaults.map(
        (defaultCountry: { countryCode: string }) => defaultCountry.countryCode
      )

      this.defaultCountries = sortBy(countries.filter((country: CountriesResponse) => flattened.includes(country.value)), 'label')
      this.countriesList = sortBy(countries.filter((country: CountriesResponse) => !flattened.includes(country.value)), 'label')
    })
  }

  private _updateForm () {
    if (this.bookingService.booking.booker.company) {
      this.companyForm.patchValue({
        companyName: this.bookingService.booking.booker.company.companyName,
        companyTaxId: this.bookingService.booking.booker.company.companyTaxId,
        companyStreet: this.bookingService.booking.booker.company.companyStreet,
        companyCity: this.bookingService.booking.booker.company.companyCity,
        companyPostalCode: this.bookingService.booking.booker.company.companyPostalCode,
        companyCountryCode: this.bookingService.booking.booker.company.companyCountryCode,
        companyState: this.bookingService.booking.booker.company.companyState
      });
    }
    if (this.useCommentBubbles) {
      const guestComments = this.bookingService.booking.bookerComment
          ? this.bookingService.booking.bookerComment.split(', ')
          : [];
      const guestCommentBubbles = guestComments.filter((v) => this.ibeSettings.predefinedCommentBubbles[this.config.language].includes(v));
      const guestComment = guestComments.filter((v) => !this.ibeSettings.predefinedCommentBubbles[this.config.language].includes(v)).join(', ');
      this.detailsForm.patchValue({
        guestComment,
        guestCommentBubbles
      });
      if (guestComment !== '') {
        this.showFreeText = true;
      }
    } else {
      this.detailsForm.patchValue({
        guestComment: this.bookingService.booking.bookerComment
      });
    }
    this.detailsForm.patchValue({
      ...this.bookingService.booking.booker,
      title: this.bookingService.booking.booker.title,
      travelPurpose: this._getTravelPurpose(),
      company: this.companyForm
    });
    const comment: string = this.detailsForm.get('guestComment')?.value
    this.licensePlate = comment?.substring(comment.indexOf('/PI'));
    this.detailsForm.get('guestComment')?.setValue(comment.replace(' ' + this.licensePlate, ''));
    this.onTravelPurposeFieldChange(this._getTravelPurpose());
  }

  private _generateTravelPurposeOptions() {
    const travelPurposeValues = [ TravelPurposeEnum.Business, TravelPurposeEnum.Leisure ];
    return travelPurposeValues.map((value) => {
      return {
        value,
        label: this.translate.instant(`checkout_details.travel_purposes.${value}`)
      }
    });
  }

  private _createCheckoutDetailsEvent() {
    const reservations = this.bookingService.booking.reservations;
    const step = 1;
    this.analyticsService.createCheckoutEvent(reservations, step);
  }

  private _getTravelPurpose() {
    if (this.bookingService.booking.reservations.length) {
      return this.bookingService.booking.reservations[0].travelPurpose
    }
    return undefined;
  }

  public fetchGuest() {
    // Have to encode email: https://github.com/angular/angular/issues/18261
    const guestId = this.guestAuthService.getPmsGuestId();
    return this.guestAuthService.fetchGuestByPmsId({guestId})
      .subscribe((response: { success: boolean, user: BookerModel }) => {
        if (response.success) {
          this.detailsForm.controls.interests.setValue(response.user.interests);
          if (this.guestAuthService.isLoggedIn() && this.detailsForm.controls.milesAndMore) {
            this.detailsForm.controls.milesAndMore.setValue(response.user.milesAndMore);
          }
          this.guest = response.user;
          this.guest.email = this.guestAuthService.getUsername();
          this.bookingService.addPmsGuestIdToReservation(this.guestAuthService.getPmsGuestId());
          this.bookingService.addDetailsToReservation(this.guest as DetailsData);
          if (this.guest.address) {
            this.bookingService.addAddressToReservation(this.guest.address as AddressData);
          }
          this._updateForm();
        }
    }, (response) => {
        this.toasterService.pop('error',
          this.translate.instant('guest_management_auth.fetch_failed'),
          response.error.message
        );
      });
  }

  public onLicensePlateChange(event: string) {
    this.updateLicensePlateNumber.emit(event);
  }

  private _getTermsConditionsUrl() {
    const url = this.config.settings.termsAndConditionsUrls[this.config.language]
    if (url.length) { return url; }
    const defaultKey: keyof TermsAndConditions = this.config.settings.defaultLanguage as keyof TermsAndConditions
    return this.config.settings.termsAndConditionsUrls[defaultKey];
  }

  private _compilePredefinedCommentOptions(
    predefinedCommentBubbles: PredefinedCommentBubbles,
    language: keyof PredefinedCommentBubbles
  ): OptionType[] {
    return predefinedCommentBubbles[language]?.map((comment: string) => {
      return {
        label: comment,
        value: comment
      }
    })
  }

  public hasCommentBubble(option: OptionType): boolean {
    return this.detailsForm.get('guestCommentBubbles')?.value.includes(option.value);
  }

  public addCommentBubble(event: MatCheckboxChange, option: OptionType): void {
    if (event.checked) {
      this.detailsForm.patchValue({
        guestCommentBubbles: [...this.detailsForm.get('guestCommentBubbles')?.value, option.value]
      })
    } else {
      this.detailsForm.patchValue({
        guestCommentBubbles: this.detailsForm.get('guestCommentBubbles')?.value.filter((e: string) => e !== option.value)
      })
    }
  }

  public hasInterest = (ibeInterest: IbeInterest): boolean => {
    let result = false;
    if (this.detailsForm.controls.interests.value) {
      result = this.detailsForm.controls.interests.value.includes(ibeInterest.id.toString());
    }
    return result;
  }

  public handleMilesAndMoreChange = (value: string): void => {
    this.detailsForm.controls.milesAndMore.setValue(value);
  }

  public handleCheckboxChange = (event: MatCheckboxChange, interestId: number): void => {
    if (event.checked) {
      const oldInterests = this.detailsForm.controls.interests.value;
      oldInterests.push(interestId.toString());
      this.detailsForm.controls.interests.setValue(oldInterests);
    } else {
      const newInterests = this.detailsForm.controls.interests.value.filter((interest: string) => interest !== interestId.toString());
      this.detailsForm.controls.interests.setValue(newInterests);
    }
  }
  // tslint:disable-next-line: max-file-line-count
}
