<input type="text"
  matInput
  class="ibe-custom-select"
  placeholder="{{ 'booking_search.select_property' | translate }}"
  (click)="onPropertyIdInputClick()"
  [formControl]="propertyId"
  [matAutocomplete]="auto"
  [ngClass]="invalidClass">
<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayName">
  <mat-optgroup *ngFor="let propertyByCity of filteredMappedProperties" [label]="propertyByCity.city">
    <mat-option (onSelectionChange)="onSearchSelection(property)" *ngFor="let property of propertyByCity.properties" [value]="property">
      {{property.name}}
    </mat-option>
  </mat-optgroup>
</mat-autocomplete>
