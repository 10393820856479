import { Component } from '@angular/core';

@Component({
  selector: 'ibe-loading-circle',
  templateUrl: './loading-circle.component.html',
  styleUrls: ['./loading-circle.component.scss']
})
export class LoadingCircleComponent {

  constructor() { }
}
