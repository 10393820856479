import { Pipe, PipeTransform } from '@angular/core';
import { BookingService } from './services/booking.service';

@Pipe({
  name: 'translateReservationStatus'
})
export class TranslateReservationStatusPipe implements PipeTransform {
  constructor(readonly bookingService: BookingService) {}

  public transform(status: string|undefined): string {
    if (status) {
      return this.bookingService.translateReservationStatus(status);
    } else {
      return 'Unknown';
    }
  }

}
