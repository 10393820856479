<div class="ibe-guest-management ibe-guest-management-header ibe-guest-create-account-header">
  <nav class="ibe-guest-management-heading ibe-guest-management-heading-nav">
    <a [routerLink]="['/booking/search']" class="ibe-manage-link">
      <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="arrow-left"><line id="arrow-stem" x1="8.036" y1="23.964" x2="40.036" y2="23.964" stroke-width="2" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" fill="none"/><polyline id="arrowhead" points="20 36 7.964 23.964 20 11.929" fill="none" stroke="currentcolor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>
      {{ 'guest_management.return_to_booking_search' | translate }}
    </a>
  </nav>
</div>

<section class="ibe-create-guest-account">
  <div class="ibe-row">
    <div class="ibe-col-md-6 ibe-create-guest-account-info">
      <div *ngIf="!createAccountText">
        <h1>{{ 'guest_management_auth.create_account' | translate }}</h1>
        <p>{{ 'guest_management_auth.create_account_with_property_title' | translate }}:</p>
        <ul>
          <li>
            <div class="ibe-icon">
              <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="info"><circle id="circle" cx="24" cy="24" r="19" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><polyline id="tick" points="13.182 25.61 19.321 31.749 34.818 16.251" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>
            </div>
              <span>{{ 'guest_management.manage_reservation_information' | translate }}</span>
          </li>
          <li>
            <div class="ibe-icon">
              <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="info"><circle id="circle" cx="24" cy="24" r="19" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><polyline id="tick" points="13.182 25.61 19.321 31.749 34.818 16.251" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>
            </div>
            <span>{{ 'guest_management.view_reservations_history' | translate }}</span>
          </li>
          <li>
            <div class="ibe-icon">
              <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="info"><circle id="circle" cx="24" cy="24" r="19" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><polyline id="tick" points="13.182 25.61 19.321 31.749 34.818 16.251" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>
            </div>
            <span>{{ 'guest_management.modify_upcoming_reservations' | translate }}</span>
          </li>
        </ul>
      </div>
      <div *ngIf="createAccountText" [innerHTML]="createAccountText"></div>
    </div>

    <div class="ibe-col-md-5">
      <div class="ibe-guest-create-options">
        <button type="button" class="ibe-guest-create-buttons ibe-guest-create-email" (click)="toggleForm()">
          <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="email"><rect id="main-body" x="5" y="11.333" width="38" height="25.333" rx="3.001" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/><polyline id="flap" points="42.51 12.808 24 24.103 5.49 12.808" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>
          <span>{{ 'guest_management_auth.sign_up_with_email' | translate }}</span>
        </button>
        <button type="button" class="ibe-guest-create-buttons ibe-guest-create-facebook">
          <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path id="facebook" d="M34.107,3.567a45.739,45.739,0,0,0-5.334-.281c-5.288,0-8.914,3.228-8.914,9.148v5.1H13.893v6.925h5.966V42.216h7.159V24.459H32.96l.913-6.925H27.018V13.112c0-1.989.538-3.369,3.416-3.369h3.673Z" fill="currentColor"/></svg>
          <span>{{ 'guest_management_auth.sign_up_with_facebook' | translate }}</span>
        </button>
        <button type="button" class="ibe-guest-create-buttons ibe-guest-create-google">
          <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="google-plus"><path d="M39.683,24.371a13.641,13.641,0,0,0-.261-2.663h-15.1v5.5H33.4c-.371,2.335-2.75,6.9-9.08,6.9a10.108,10.108,0,0,1,0-20.212A8.981,8.981,0,0,1,30.69,16.36l4.344-4.169A15.285,15.285,0,0,0,24.317,8a16,16,0,0,0,0,32C33.55,40,39.683,33.517,39.683,24.371Z" fill="currentColor"/></g></svg>
          <span>{{ 'guest_management_auth.sign_up_with_google' | translate }}</span>
        </button>
      </div>

      <div class="ibe-guest-create-email-form">
        <p class="ibe-guest-create-back-link">
          <a (click)="toggleForm()" class="ibe-guest-form-back-btn">< {{ 'guest_management_auth.back' | translate }}</a>
        </p>
        <form class="ibe-guest-signup" #guestCreateFormDirective="ngForm" [formGroup]="guestCreateForm">
          <div class="ibe-form-group">
            <label for="ibeFirstNameInput">{{ 'checkout_details.first_name' | translate }}</label>
            <input
            type="text"
            formControlName="firstName"
            id="ibeFirstNameInput"
            class="ibe-form-control"
            [ngClass]="{'is-invalid': isFormControlInvalid(guestCreateForm.controls.firstName)}"
            placeholder="{{ 'checkout_details.first_name' | translate }}">
            <ibe-form-validation-message [form]="guestCreateFormDirective" [control]="guestCreateForm.controls.firstName"></ibe-form-validation-message>
          </div>
          <div class="ibe-form-group">
            <label for="ibeLastNameInput">{{ 'checkout_details.last_name' | translate }}</label>
            <input
            type="text"
            formControlName="lastName"
            id="ibeLastNameInput"
            class="ibe-form-control"
            [ngClass]="{'is-invalid': isFormControlInvalid(guestCreateForm.controls.lastName)}"
            placeholder="{{ 'checkout_details.last_name' | translate }}">
            <ibe-form-validation-message [form]="guestCreateFormDirective" [control]="guestCreateForm.controls.lastName"></ibe-form-validation-message>
          </div>
          <div class="ibe-form-group">
            <label for="ibeEmailInput">{{ 'checkout_details.email' | translate }}</label>
            <input
            type="text"
            formControlName="username"
            id="ibeEmailInput"
            class="ibe-form-control"
            [ngClass]="{'is-invalid': isFormControlInvalid(guestCreateForm.controls.username)}"
            placeholder="{{ 'checkout_details.email' | translate }}">
            <ibe-form-validation-message [form]="guestCreateFormDirective" [control]="guestCreateForm.controls.username"></ibe-form-validation-message>
          </div>
          <div class="ibe-form-group">
            <label for="ibePasswordInput">{{ 'guest_management_auth.password' | translate }}</label>
            <input
            type="password"
            formControlName="password"
            id="ibePasswordInput"
            class="ibe-form-control"
            [ngClass]="{'is-invalid': isFormControlInvalid(guestCreateForm.controls.password)}"
            placeholder="{{ 'guest_management_auth.password' | translate }}">
            <ibe-form-validation-message [form]="guestCreateFormDirective" [control]="guestCreateForm.controls.password"></ibe-form-validation-message>
          </div>
          <div class="ibe-form-group">
            <label for="ibeConfirmPasswordInput">{{ 'guest_management_auth.confirm_password' | translate }}</label>
            <input
            type="password"
            formControlName="confirmPassword"
            id="ibeConfirmPasswordInput"
            class="ibe-form-control"
            [ngClass]="{'is-invalid': isFormControlInvalid(guestCreateForm.controls.confirmPassword)}"
            placeholder="{{ 'guest_management_auth.confirm_password' | translate }}">
            <ibe-form-validation-message [form]="guestCreateFormDirective" [control]="guestCreateForm.controls.confirmPassword"></ibe-form-validation-message>
          </div>
          <div *ngIf="config.accountFeatureWhitelist && config.accountFeatureWhitelist.accountCreationToggles && config.settings.checkoutFields.address.marketingConsent" class="ibe-form-group ibe-marketing-optin">
            <div class="ibe-checkbox-frame">
              <input type="checkbox" formControlName="marketingConsent" id="ibeMarketingConsentInput">
              <span class="ibe-checkmark"></span>
              <label for="ibeMarketingConsentInput" class="ibe-marketing-consent-text">{{ 'checkout.marketing_consent_text' | translate }}</label>
            </div>
          </div>
          <div class="ibe-form-group ibe-form-group-checkbox" *ngIf="config.accountFeatureWhitelist && config.accountFeatureWhitelist.accountCreationToggles">
            <ibe-form-validation-message [form]="guestCreateFormDirective" [control]="guestCreateForm.controls.termsConditions"></ibe-form-validation-message>
            <div class="ibe-checkbox-frame">
              <input type="checkbox" formControlName="termsConditions" id="ibeTermsConditionsInput">
              <span class="ibe-checkmark"></span>
            </div>
            <label for="ibeTermsConditionsInput" class="ibe-terms-confirm-text">{{ 'checkout.terms_conditions_confirm_text' | translate }} <a href="{{termsConditionsUrl}}" class="ibe-terms-link" target="_blank">{{ 'checkout.terms_conditions_confirm_url' | translate }}</a></label>
          </div>
          <ibe-loading-bar [isLoading]="saving"></ibe-loading-bar>
          <button class="ibe-btn ibe-primary-btn create-guest" (click)="createGuest()" [disabled]="saving">{{ 'guest_management_auth.sign_up' | translate }}</button>
        </form>
      </div>
      <p class="ibe-guest-create-login-link">{{ 'guest_management_auth.has_account_already' | translate }} <a (click)="goToGuestManagement()">{{ 'guest_management_auth.log_in_to_account' | translate }}</a></p>
    </div>
  </div>
</section>
