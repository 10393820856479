import { GuestModel } from 'up-ibe-types';
import * as validate from 'validate.js';
import { environment } from '../../environments/environment';

export function getPaymentProviderScriptUrl(
  paymentProvider: string,
  paymentTestModeEnabled: boolean) {
  let scriptUrl;

  switch (paymentProvider) {
    case 'adyen':
      scriptUrl = environment.paymentProviderScripts.adyen.live;

      if (paymentTestModeEnabled) {
        scriptUrl = environment.paymentProviderScripts.adyen.test;
      }
    break;
    case 'mycheck':
      scriptUrl = environment.paymentProviderScripts.mycheck.live;

      if (paymentTestModeEnabled) {
        scriptUrl = environment.paymentProviderScripts.mycheck.test;
      }
    break;
  }

  return scriptUrl;
}

export function isGuestDataValid(guestDetails: GuestModel) {
  const guestDetailConstraints = {
    'lastName': { presence: { allowEmpty: false }},
    'email': { presence: { allowEmpty: false }},
    'address.countryCode': { presence: { allowEmpty: false }}
  };

  if (validate(guestDetails, guestDetailConstraints)) {
    return false;
  }
  return true;
}
