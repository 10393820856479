import * as moment from 'moment';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IbeConfigService } from 'app/services/ibe-config.service';
import { bookingSearchPopUpAnimation } from '../../../animations';
import { CalendarStayDateSelectionEvent } from 'app/booking/availability-calendar/calendar/calendar';

@Component({
  selector: 'ibe-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss', '../ibe-form-control.scss'],
  animations: [bookingSearchPopUpAnimation]
})
export class DateInputComponent {
  @Input() public arrivalValue: Date
  @Input() public departureValue: Date
  @Input() public startDate: moment.Moment
  @Output() public onStayDateSelection = new EventEmitter<CalendarStayDateSelectionEvent>();

  public showDateRangePicker = false;

  constructor(
    public readonly config: IbeConfigService
  ) { }

  public toggleDateRangePicker() {
    this.showDateRangePicker = !this.showDateRangePicker;
  }

  public formatDateRange(arrival: Date, departure: Date) {
    const start = moment(arrival).locale(this.config.language).format('ddd DD MMM');
    const end = moment(departure).locale(this.config.language).format('ddd DD MMM');
    return `${start} - ${end}`
  }

  public stayDatesChanged($event: CalendarStayDateSelectionEvent) {
    this.onStayDateSelection.emit($event);
  }

  public handleEnterKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.toggleDateRangePicker();
    }
  }
}
