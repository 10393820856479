<div [formGroup]="form" #formDirective="ngForm" class="ibe-region-property-selector">
  <div class="ibe-form-group ibe-region-select" >
    <label>{{ 'booking_search.region' | translate }}</label>
    <select class="ibe-custom-select"
      formControlName="region"
      (change)="onRegionSelection()"
      [ngClass]="{'is-invalid': isFormControlInvalid(form.controls.propertyRegion)}">
      <option
        value="All">{{ 'booking_search.all_regions' | translate }}</option>
      <option value="{{region}}"
        *ngFor="let region of regions; let i=index">{{region}}</option>
    </select>
  </div>

  <div class="ibe-form-group ibe-property-select">
    <label>{{ 'booking_search.property' | translate }}</label>

    <select class="ibe-custom-select"
      formControlName="propertyId"
      (change)="onPropertySelection()">
      <option
        value="All">{{ 'booking_search.all_properties' | translate }}</option>
      <option value="{{property.pmsId}}"
        *ngFor="let property of currentProperties; let i=index">{{property.name}}</option>
    </select>
  </div>
</div>
