import { Component, Inject, OnInit, HostListener, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExtraModel } from 'up-ibe-types';

@Component({
  selector: 'ibe-extras',
  templateUrl: './extras.component.html',
  styleUrls: ['./extras.component.scss'],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        width: 900
      }
    }
  ]
})
export class ExtrasComponent implements OnInit, OnDestroy {
  public extras: ExtraModel[] = [];
  public selectedExtras: ExtraModel[] = [];
  private selectedExtrasCache: ExtraModel[] = [];
  public loading = true;

  private _destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(
    // TODO Export this as a Service
    @Inject(MAT_DIALOG_DATA) public data: {
      selectedExtras: ExtraModel[]
      extras: Subject<ExtraModel[]>,
      propertyId: string,
      skipOnBackdropClick: boolean
    },
    public dialogRef: MatDialogRef<ExtrasComponent, ExtraModel[]>
  ) {
    this.data.extras.pipe(takeUntil(this._destroyed$)).subscribe((extras: ExtraModel[]) => {
      this.loading = false;
      this.extras = extras;
      if (!this.extras.length) {
        this.dialogRef.close([]);
      }
    });
  }

  @HostListener('window:keyup.esc')
  public onKeyUp = (): void => {
    this.skipExtrasStep();
  }

  public ngOnInit() {
    if (this.data.selectedExtras) {
      this.selectedExtras = this.selectedExtrasCache = this.data.selectedExtras;
    }
    this._onBackdropClick();
  }

  public completeExtrasStep = (): void => {
    this.dialogRef.close(this.selectedExtras);
  }

  public skipExtrasStep = (): void => {
    this.filterOutNonInclusiveExtrasOnDialogSkip();
    this.dialogRef.close(this.selectedExtras);
  }

  public onSelectedExtrasUpdate = ($event: ExtraModel[]): void => {
    this.selectedExtras = $event;
  }

  private filterOutNonInclusiveExtrasOnDialogSkip = (): void => {
    this.selectedExtras = this.selectedExtras.filter((extra: ExtraModel) => extra.isInclusiveInRate);
  }

  public onClose = (): void => {
    if (this.data.skipOnBackdropClick) {
      return this.skipExtrasStep();
    }
    this.dialogRef.close(this.selectedExtrasCache);
  }

  private _onBackdropClick = (): void => {
    this.dialogRef.backdropClick().subscribe(() => this.onClose());
  }

  public ngOnDestroy(): void {
    this._destroyed$.next(true);
    this._destroyed$.unsubscribe();
  }
}
