import {
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IbeConfigService } from '../services/ibe-config.service';

@Injectable()
export class IbeKeyInterceptorService implements HttpInterceptor {
  constructor(
    private readonly config: IbeConfigService
  ) {}

  public intercept(
    request: HttpRequest<{}>, next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<{}> | HttpUserEvent<{}>> {
    if (!request.headers.has('ibe-key') && this.config.ibeKey) {
      request = request.clone({ headers: request.headers.set('ibe-key', this.config.ibeKey) });
    }
    return next.handle(request);
  }
}
