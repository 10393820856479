<div class="ibe-region-results-header">
  <h2 class="ibe-region-results-title">
    <span *ngIf="region === 'All'">{{ 'room_results.all_properties' | translate }}</span>
    <span *ngIf="region !== 'All'">{{region}}</span>
    >
    <span>{{ dates }}</span>
    >
    <span>{{ persons }} <span *ngIf="persons === 1">{{ 'room_results.guest' | translate }}</span> <span *ngIf="persons > 1">{{ 'room_results.guests' | translate }}</span></span>
  </h2>
</div>

<div #regionResultsContainer class="ibe-region-results" *ngIf="(availability$ | async)?.length">
  <div class="ibe-region-results-list ibe-row">
    <div class="ibe-region-result ibe-col-4" *ngFor="let result of availability$ | async" (click)="onResultClick(result)">
      <div class="ibe-room-image-outer">
        <div class="ibe-room-image-inner" [ngStyle]="{'background-image': 'url(' + getPropertyImageUrl(result.property.id, result.unitType.id) + ')'}"></div>
      </div>
      <div class="ibe-region-footer">
        <p class="ibe-region-text">{{result.property.location.city}}</p>
        <p class="ibe-region-from-price-text"><span class="ibe-region-from-price">{{ result.fromPrice.amount | currency: result.fromPrice.currency:'symbol':'1.2-2' }}+ </span><br>{{ 'extras.per_night' | translate }}</p>
      </div>
      <p class="ibe-property-text" *ngIf="!result.property.location.addressLine2">{{result.property.name}}</p>
      <p class="ibe-property-text" *ngIf="result.property.location.addressLine2" [innerHTML]="'booking_search.region_results_propertyName_with_address' | translate:{
        addressLine2: result.property.location.addressLine2,
        propertyName: result.property.name
      }"></p>
      <p class="ibe-region-description" *ngIf="result.property.description">{{ result.property.description }}</p>
    </div>
  </div>
</div>

<div class="ibe-no-room-results" *ngIf="noAvailability$ | async">
  <p class="ibe-no-room-results-text">{{ 'room_results.no_availability' | translate }}</p>

  <div class="ibe-region-availability-calendars" *ngIf="noAvailabilityShowCalendar$ | async">
    <div *ngFor="let property of regionProperties">
      <ibe-availability-calendar [property]="property"></ibe-availability-calendar>
    </div>
  </div>
</div>

