<div *ngIf="invoices.length > 0" class="invoice-list-wrapper">
  <div class="invoice-list-container ibe-col-lg-12">
    <div class="ibe-section-heading">
      <h4>Invoices</h4>
    </div>

    <div class="invoice-table-wrapper">
      <table>
        <thead>
          <tr>
            <th>{{ 'guest_management.invoice_number' | translate }}</th>
            <th>{{ 'guest_management.invoice_type' | translate }}</th>
            <th>{{ 'guest_management.invoice_created' | translate }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let invoice of invoices">
            <td>{{ invoice.id }}</td>
            <td>{{ invoice.type }}</td>
            <td>{{ invoice.created | dateFormat: 'Do MMM YYYY hh:mm:ss a':locale }}</td>
            <td width="50px" align="right">
              <a (click)="downloadInvoice(invoice.id)" class="ibe-btn">
                {{ 'guest_management.invoice_download' | translate }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>