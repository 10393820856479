
<form [formGroup]="addressForm" #addressFormDirective="ngForm">
  <div class="ibe-form-group" *ngIf="ibeSettings.checkoutFields.address.street.activated">
    <ibe-form-label labelControlName="streetInput1" [labelControl]="addressForm.controls.addressLine1" labelText="checkout_address.street"></ibe-form-label>
    <input type="text" formControlName="addressLine1" id="ibeStreetInput1" class="ibe-form-control" [ngClass]="{'is-invalid': isFormControlInvalid(addressForm.controls.addressLine1)}" placeholder="{{ 'checkout_address.street' | translate }}">
    <ibe-form-validation-message [form]="addressFormDirective" [control]="addressForm.controls.addressLine1" [submitObservable]="submitObservable"></ibe-form-validation-message>
  </div>
  <div class="ibe-form-group" *ngIf="ibeSettings.checkoutFields.address.city.activated">
    <ibe-form-label labelControlName="cityInput" [labelControl]="addressForm.controls.city" labelText="checkout_address.city"></ibe-form-label>
    <input type="text" formControlName="city" id="ibeCityInput" class="ibe-form-control" [ngClass]="{'is-invalid': isFormControlInvalid(addressForm.controls.city)}" placeholder="{{ 'checkout_address.city' | translate }}">
    <ibe-form-validation-message [form]="addressFormDirective" [control]="addressForm.controls.city" [submitObservable]="submitObservable"></ibe-form-validation-message>
  </div>
  <div class="ibe-form-group" *ngIf="ibeSettings.checkoutFields.address.postalCode.activated">
    <ibe-form-label labelControlName="postalCodeInput" [labelControl]="addressForm.controls.postalCode" labelText="checkout_address.postal_code"></ibe-form-label>
    <input type="text" formControlName="postalCode" id="ibePostalCodeInput" class="ibe-form-control" [ngClass]="{'is-invalid': isFormControlInvalid(addressForm.controls.postalCode)}" placeholder="{{ 'checkout_address.postal_code' | translate }}">
    <ibe-form-validation-message [form]="addressFormDirective" [control]="addressForm.controls.postalCode" [submitObservable]="submitObservable"></ibe-form-validation-message>
  </div>
  <div class="ibe-form-group" *ngIf="ibeSettings.checkoutFields.address.countryCode.activated">
    <ibe-form-label labelControlName="countryCodeSelect" [labelControl]="addressForm.controls.countryCode" labelText="checkout_address.country"></ibe-form-label>
    <select 
      formControlName="countryCode" 
      id="ibeCountryCodeSelect" 
      class="ibe-custom-select" 
      [ngClass]="{'is-invalid': isFormControlInvalid(addressForm.controls.countryCode)}"
      (change)="onCountrySelect($event.target.value)"
    >
      <option value="undefined" disabled selected>{{ 'checkout_details.please_select' | translate }}</option>
      <optgroup *ngIf="defaultCountries.length">
        <option value="{{ defaultCountry.value }}" *ngFor="let defaultCountry of defaultCountries">{{ defaultCountry.label }}</option>
      </optgroup>

      <optgroup>
        <option value="{{ country.value }}" *ngFor="let country of countriesList">{{ country.label }}</option>
      </optgroup>
    </select>
    <ibe-form-validation-message [form]="addressFormDirective" [control]="addressForm.controls.countryCode" [submitObservable]="submitObservable"></ibe-form-validation-message>
  </div>
  <div class="ibe-form-group" *ngIf="ibeSettings.checkoutFields.address.state.activated && showStatesField()">
    <ibe-form-label labelControlName="stateSelect" [labelControl]="addressForm.controls.state" labelText="checkout_address.state"></ibe-form-label>
    <select
      formControlName="state"
      id="ibeStateCodeSelect"
      class="ibe-custom-select"
      [ngClass]="{'is-invalid': isFormControlInvalid(addressForm.controls.state)}"
      *ngIf="addressForm.controls.countryCode.value=='US' || addressForm.controls.countryCode.value=='CA'"
    >
      <option value="undefined" disabled selected>{{ 'checkout_details.please_select' | translate }}</option>
      <optgroup *ngIf="addressForm.controls.countryCode.value=='US'">
        <option value="{{ state.value }}" *ngFor="let state of statesList.US">{{ state.label }}</option>
      </optgroup>
      <optgroup *ngIf="addressForm.controls.countryCode.value=='CA'">
        <option value="{{ state.value }}" *ngFor="let state of statesList.CA">{{ state.label }}</option>
      </optgroup>
      </select>
  </div>
  <div class="ibe-form-group ibe-marketing-optin" *ngIf="ibeSettings.checkoutFields.address.marketingConsent.activated">
    <div class="ibe-checkbox-frame">
      <input type="checkbox" formControlName="marketingConsent" id="ibeMarketingConsentInput">
      <span class="ibe-checkmark"></span>
    </div>
    <span class="ibe-terms-confirm-text">{{ 'checkout.marketing_consent_text' | translate }}</span>
    <ibe-form-validation-message [form]="addressFormDirective" [control]="addressForm.controls.marketingConsent" [submitObservable]="submitObservable"></ibe-form-validation-message>
  </div>
</form>
