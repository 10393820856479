import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import {IbeConfigService} from '../ibe-config.service';

@Pipe({ name: 'currency' })
export class CustomCurrencyPipe implements PipeTransform {
  constructor(private readonly config: IbeConfigService) {}

  public transform(value: number, currencyCode?: string, symbolDisplay?: string, digits?: string): string {
    const currencyPipe = new CurrencyPipe(this.getLocale(this.config.language), 'GBP');
    const price = currencyPipe.transform(value, currencyCode, symbolDisplay, digits);
    if (price) {
      const firstDigit = price.match(/\d/) as RegExpMatchArray;
      let symbol = price.slice(0, firstDigit.index);
      const amount = price.slice(firstDigit.index);
      if (symbol.length > 1) {
        symbol = symbol.concat(' ');
      }
      return symbol + amount;
    }
    return '';
  }

  public getLocale(language: string): string {
    // This is based on the locales that have been imported at the top of app.module.ts.
    // Look in the top of app.module.ts
    const allowedLocales = [
      'en', 'de', 'fi', 'se', 'ru', 'fr'
    ];

    if (!allowedLocales.includes(language)) {
      return 'en'
    }

    return language;
  }
}
