import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroupDirective, FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { range } from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Property, StayCriteriaModel } from 'up-ibe-types';
import { ToasterService } from 'angular2-toaster';
import { bookingSearchPopUpAnimation } from '../../animations';
import { BookingService } from '../../services/booking.service';
import { serializeQueryParams } from '../../helpers/booking.helper';
import { isFormControlInvalid } from '../../helpers/form.helper';
import { GlobalWindowService } from '../../services/global-window.service';
import { IbeConfigService } from '../../services/ibe-config.service';
import { PersonsQtyData } from './persons-qty-popup/persons-qty-popup.component';
import { CalendarStayDateSelectionEvent } from '../availability-calendar/calendar/calendar';
import { QueryParamsToSearchValuesService } from 'app/services/booking/query-params-to-search-values.service';

const customDatePickerFormats = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

const adultsControlOptionCount = 4;

@Component({
  selector: 'ibe-booking-search',
  templateUrl: './booking-search.component.html',
  styleUrls: ['./booking-search.component.scss', './ibe-form-control.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: customDatePickerFormats }
  ],
  animations: [bookingSearchPopUpAnimation]
})
export class BookingSearchComponent implements OnInit {
  @ViewChild('searchFormDirective', { static: true }) public searchFormDirective: FormGroupDirective;
  @ViewChild('ibeDateInputRef', { static: true }) public ibeDateInputRef: ElementRef;
  @Input() public isLoading: boolean;
  @Output('onSearchFormChange') public onSearchFormChange: EventEmitter<Number> = new EventEmitter();
  public searchForm: FormGroup;
  public personsQtyData: PersonsQtyData = {
    adults: 1,
    childrenAges: []
  }
  public properties: Property[];
  public readonly adultSelectControlOptions = range(1, adultsControlOptionCount + 1)
    .map(i => ({
      value: i,
      label: i.toString()
    }));
  public readonly todaysDate: Moment = moment();
  public readonly tomorrowsDate: Moment = moment().add(1, 'day');
  public departureMinDate: Moment = moment().add(1, 'day');
  public arrivalMinDate: Moment = moment();
  public arrivalMaxDate: Moment;
  public isPersonsQtyPopupOpen = false;
  private smallScreenSize = 768;
  public isSmallScreen = window.screen.width <= this.smallScreenSize ? true : false;
  public selectedRegion: string;
  public stayCriteriaParams: StayCriteriaModel;

  constructor(
    private readonly router: Router,
    private readonly bookingService: BookingService,
    private readonly formBuilder: FormBuilder,
    private readonly dateAdapter: DateAdapter<Moment>,
    private readonly translate: TranslateService,
    private readonly toasterService: ToasterService,
    public readonly globalWindowService: GlobalWindowService,
    public readonly config: IbeConfigService,
    private readonly queryParamsToSearchValues: QueryParamsToSearchValuesService,
    private readonly route: ActivatedRoute
  ) {
    this.todaysDate = moment(this.config.getStartDate());
    this.tomorrowsDate = moment(this.config.getStartDate()).add(1, 'day');
    this.arrivalMinDate = this.todaysDate;
    this.departureMinDate = this.tomorrowsDate;
    this.searchForm = this.formBuilder.group({
      'propertyId': ['', [Validators.required]],
      'arrival': [this.todaysDate, [Validators.required]],
      'departure': [this.tomorrowsDate, [Validators.required]],
      'adults': [this.adultSelectControlOptions[0].value, [Validators.required]],
      'promoCode': undefined
    });

    this._onFormChanges();
    this.setInitialStayCriteria();
  }

  public ngOnInit() {
    this.dateAdapter.setLocale(this.translate.currentLang ? this.translate.currentLang : 'en');
    this.properties = this.config.properties;
    this.properties = this._sortByName(this.properties);
    this._setDefaultProperty();
    this._presetSelectedRegionAndPropertyIfRegionEnabled();
  }

  public onPropertyChange() {
    this.config.setCurrentProperty(this.searchForm.controls['propertyId'].value)
  }

  public _setDefaultProperty() {
    this.route.queryParams.subscribe(params => {
      let propertyId: string;

      if (params.propertyId) {
        propertyId = params.propertyId;
      } else if (this.config.defaultPropertyId) {
        propertyId = this.config.defaultPropertyId;
      }

      const propertyWithPmsId = this.properties.find(property => {
        if (propertyId === undefined) {
          return false;
        }

        return String(property.pmsId).toLowerCase() === propertyId.toLowerCase();
      });

      this.onPropertySelection(propertyWithPmsId ? propertyWithPmsId.pmsId : '');
    });
  }

  private maybeSetEmptyPropertyIdToAll() {
    if (this.config.settings.displayPropertiesByRegionEnabled &&
      this.searchForm.controls['propertyId'].value === '') {
      this.searchForm.controls['propertyId'].setValue('All');
    }
  }

  private _presetSelectedRegionAndPropertyIfRegionEnabled() {
    if (this.config.settings.displayPropertiesByRegionEnabled && this.propertiesHaveCities()) {
      if (!this.selectedRegion) {
        this.selectedRegion = 'All';
      }

      this.maybeSetEmptyPropertyIdToAll();
    }
  }

  public get firstProperty() {
    return this.properties[0];
  }

  public onPropertySelection($event: string) {
    this.searchForm.controls['propertyId'].setValue($event);
    this.maybeSetEmptyPropertyIdToAll();
  }

  public onRegionAndPropertySelection($event: { region: string, propertyId: string }) {
    this.onPropertySelection($event.propertyId);
    this.selectedRegion = $event.region;
  }

  public onStayDateSelection($event: CalendarStayDateSelectionEvent) {
    this.searchForm.controls['arrival'].setValue($event.arrivalDate);
    this.searchForm.controls['departure'].setValue($event.departureDate);
  }

  private _isDepartureSameOrBeforeArrival(arrival: Date, departure: Date) {
    return moment(departure).isSameOrBefore(arrival, 'day')
  }

  private _isArrivalOrDepartureBeforeStartDate(arrival: Date, departure: Date) {
    return (moment(departure).isBefore(this.config.getStartDate(), 'day') ||
      moment(arrival).isBefore(this.config.getStartDate(), 'day'))
  }

  private _sortByName(properties: Property[]) {
    return properties.sort((a, b) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      }
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  }

  private setInitialStayCriteria() {
    this.queryParamsToSearchValues.params$(this.todaysDate).subscribe((queryParams) => {
      this.searchForm.patchValue(queryParams);
      this.selectedRegion = queryParams.region;

      if (this._isArrivalOrDepartureBeforeStartDate(queryParams.arrival, queryParams.departure)) {
        this.toasterService.pop('error', this.translate.instant('booking_search.date_error'),
          this.translate.instant('booking_search.date_change'));
      }

      this.personsQtyData.adults = queryParams.adults;
      this.personsQtyData.childrenAges = queryParams.childrenAges
    });
  }

  public submitSearch(event: Event) {
    event.preventDefault();

    if (this.properties.length === 1) {
      this.searchForm.controls['propertyId'].setValue(this.properties[0].pmsId);
    }

    if (this.searchForm.valid) {
      let arrival = this.searchForm.controls['arrival'].value;
      let departure = this.searchForm.controls['departure'].value;

      if (moment(arrival).isBefore(moment())) {
        // If somehow the arrival is in the past, set it to the current day.
        arrival = moment().toDate();
      }

      if (this._isDepartureSameOrBeforeArrival(arrival, departure)) {
        departure = moment(arrival).add(1, 'days').toDate();
      }

      this.stayCriteriaParams = this.searchForm.getRawValue();
      if (this.selectedRegion) {
        this.stayCriteriaParams.region = this.selectedRegion;
      }
      this.stayCriteriaParams.arrival = moment(arrival).format('YYYY-MM-DD');
      this.stayCriteriaParams.departure = moment(departure).format('YYYY-MM-DD');
      this.stayCriteriaParams.adults = this.personsQtyData.adults;
      this.stayCriteriaParams.childrenAges = this.personsQtyData.childrenAges.filter(age => age > 0);

      this.bookingService.saveLastSearchedStayCriteria(this.stayCriteriaParams);

      this.maybeSetEmptyPropertyIdToAll();

      this._navigate();
    }
  }

  private _navigate() {
    if (this.config.redirectPath) {
      const serializedParams = serializeQueryParams(this.stayCriteriaParams);
      const url = `${this.config.redirectPath}#/booking/results?${serializedParams}`
      this.globalWindowService.getWindowLocationAssign(url);
      return;
    }

    this.router.navigate(['booking/results'], {
      queryParams: this.stayCriteriaParams
    });
    return;
  }

  private _onFormChanges() {
    this.searchForm.valueChanges.subscribe((values) => {
      this.onSearchFormChange.emit(values);
    });
  }

  public isFormControlInvalid(formControl: AbstractControl) {
    return isFormControlInvalid(formControl, this.searchFormDirective);
  }

  public togglePersonsQtyPopup() {
    this.isPersonsQtyPopupOpen = !this.isPersonsQtyPopupOpen;
  }

  public handleKeypress($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.togglePersonsQtyPopup()
    }
  }

  public onPersonsQtyChange(data: PersonsQtyData) {
    this.personsQtyData = data;
  }

  public get personsQtyFieldAdultsLabel() {
    const twoAdults = 2;
    const oneAdult = 1;

    if (this.config.isChildrenEnabled && this.personsQtyData.adults < twoAdults) {
      return this.translate.instant('booking_search.adult');
    }

    if (this.config.isChildrenEnabled && this.personsQtyData.adults > oneAdult) {
      return this.translate.instant('booking_search.adults');
    }

    if (!this.config.isChildrenEnabled && this.personsQtyData.adults < twoAdults) {
      return this.translate.instant('booking_search.person');
    }

    if (!this.config.isChildrenEnabled && this.personsQtyData.adults > oneAdult) {
      return this.translate.instant('booking_search.persons');
    }

    return;
  }

  public propertiesHaveCities() {
    for (const property of this.properties) {
      if (!property.location || !property.location.city) {
        return false;
      }
    }
    return true;
  }
// todo: break out parameter filtering into services
// tslint:disable-next-line:max-file-line-count
}
