import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroupDirective, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AnimationEvent } from '@angular/animations';
import { ToasterService } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { ReservationModel } from 'up-ibe-types';
import { IbeConfigService } from '../../services/ibe-config.service';
import { BookingService } from '../../services/booking.service';
import { AnalyticsService } from '../../services/analytics.service';
import { fadeInOnEnterAnimation, scaleUpAnimation } from '../../animations';
import { isFormControlInvalid } from '../../helpers/form.helper';
import { NewsletterSubscriptionService } from '../../services/newsletter-subscription.service';
import { forkJoin, ReplaySubject } from 'rxjs';

@Component({
  selector: 'ibe-checkout-address',
  templateUrl: './checkout-address.component.html',
  styleUrls: ['./checkout-address.component.scss'],
  animations: [
    fadeInOnEnterAnimation,
    scaleUpAnimation
  ]
})
export class CheckoutAddressComponent implements OnInit {
  @ViewChild('addressFormDirective', { static: true }) public addressFormDirective: FormGroupDirective;
  @Output() public formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  public addressForm: FormGroup;
  public animationState: string;
  public submit: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  constructor(
    public readonly config: IbeConfigService,
    private readonly bookingService: BookingService,
    private readonly toasterService: ToasterService,
    private readonly translate:  TranslateService,
    private readonly analyticsService:  AnalyticsService,
    private readonly newsletterSubscriptionService: NewsletterSubscriptionService,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder
  ) {}

  public ngOnInit() {
    this.addressForm = this.formBuilder.group({});
    this._createCheckoutAddressEvent();
  }

  public addFormControl(name: string, formGroup: FormGroup) {
    if (Object.keys(formGroup.controls).length === 0) {
      this.skipAddressStep();
    }
    this.addressForm.addControl(name, formGroup);
  }

  public subscribeToNewsletter() {
    forkJoin(
      this.bookingService.booking.reservations.map((reservation: ReservationModel) => {
       return this.newsletterSubscriptionService.subscribeToNewsletter({
           firstName: this.bookingService.booking.booker.firstName as string,
           lastName: this.bookingService.booking.booker.lastName,
           email: this.bookingService.booking.booker.email as string
       }, reservation.property.id)
     })
    ).subscribe();
  }

  public onFormSubmit(event: Event) {
    this.submit.next(true);
    event.preventDefault();

    if (this.addressForm.valid) {
     if (this.addressForm.controls['address'].value.marketingConsent) {
       this.subscribeToNewsletter()
     }

    if (this.bookingService.addAddressToReservation(this.addressForm.controls['address'].value)) {
      this.toasterService.pop('success',
        this.translate.instant('checkout_address.address_added'),
        this.translate.instant('checkout_address.address_has_been_added')
      );
      this.animationState = 'completed';
    }
    }
  }

  public isFormControlInvalid(formControl: AbstractControl) {
    return isFormControlInvalid(formControl, this.addressFormDirective);
  }

  public scaleUpAnimationComplete($event: AnimationEvent) {
    if ($event.toState === 'completed') {
      this.router.navigate(['checkout/payment']);
    }
  }

  public skipAddressStep() {
    this.router.navigate(['checkout/payment']);
  }

  private _createCheckoutAddressEvent() {
    const reservations = this.bookingService.booking.reservations;
    const step = 2;
    this.analyticsService.createCheckoutEvent(reservations, step);
  }

  public showBillingAddressMessage() {
    return this.config.currentProperty.propertyPaymentProviderSettings.additionalAdyenShopperDetailsEnabled;
  }
}

export interface CountriesResponse {
  [key: string]: string;
}
