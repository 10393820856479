import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export enum BookingError {
  BookingFailed = 'booking_failed',
  NoAvailability = 'no_availability',
  PaymentError = 'payment_error',
  BookingProblem = 'booking_problem',
  InvalidPmsData = 'invalid_pms_data'
}

export interface ErrorDetails {
  title: string;
  message: string;
  description?: string;
}

export interface BookingErrorDetails {
  error: {
    success: boolean;
    errorCodes: BookingError[];
  }
}

@Component({
  selector: 'ibe-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    title: string,
    message?: string,
    details?: ErrorDetails[],
    allowRetry: boolean
  },
  public dialogRef: MatDialogRef<ErrorDialogComponent>) { }

  public onRetry() {
    this.dialogRef.close(true);
  }
}
