<div class="ibe-guest-management-sidebar">
  <div
    *ngIf="currentRoute !== 'ReservationHistory'"
    class="ibe-guest-management-sidebar-option"
    [routerLink]="['/guest/reservations/history']">
    <h2>{{ 'guest_management.view_reservations_history' | translate }}</h2>
    <p>{{ 'guest_management.view_reservations_history_text' | translate }}</p>
  </div>
  <div
    *ngIf="currentRoute !== 'ModifyReservations'"
    class="ibe-guest-management-sidebar-option"
    [routerLink]="['/guest/reservations/modify']">
    <h2>{{ 'guest_management.modify_upcoming_reservations' | translate }}</h2>
    <p>{{ 'guest_management.modify_upcoming_reservations_text' | translate }}</p>
  </div>
  <div class="ibe-guest-management-sidebar-option" [routerLink]="['/booking/search']">
    <h2>{{ 'guest_management.make_new_reservation' | translate }}</h2>
    <p>{{ 'guest_management.make_new_reservation_text' | translate }}</p>
  </div>
  <div
    *ngIf="currentRoute !== 'EditAccount'"
    class="ibe-guest-management-sidebar-option"
    [routerLink]="['/guest/account/edit']">
    <h2>{{ 'guest_management.edit_account_information' | translate }}</h2>
    <p>{{ 'guest_management.edit_account_information_text' | translate }}</p>
  </div>
</div>
