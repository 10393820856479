<h2 class="ibe-checkout-step-title">{{ 'checkout_address.enter_address' | translate }}</h2>
<div class="ibe-checkout-step-box" [@fadeInOnEnterAnimation] [@scaleUpAnimation]="animationState" (@scaleUpAnimation.done)="scaleUpAnimationComplete($event)">

  <div class="ibe-billing-address-message" *ngIf="showBillingAddressMessage()">
    <div><i class="ibe-icon ibe-icon-warning-circle"></i></div>
    <div class="message-text">
      {{ 'checkout_address.billing_address' | translate }}
    </div>
  </div>

  <form [formGroup]="addressForm" #addressFormDirective="ngForm" (submit)="onFormSubmit($event)">
    <ibe-address-form [submitObservable]="submit.asObservable()" (formReady)="addFormControl('address', $event)"></ibe-address-form>
    <button type="submit" class="ibe-btn ibe-primary-btn">{{ 'global.continue' | translate }}</button>
  </form>
</div>
<ul class="ibe-checkout-step-checklist">
  <li routerLink="/checkout/details"><i class="ibe-icon ibe-icon-tick"></i> <span class="ibe-checkout-step-checklist-title">{{ 'checkout.personal_details' | translate }}</span> <i class="ibe-icon ibe-icon-edit"></i></li>
</ul>
