import { TranslateService } from '@ngx-translate/core';
import { GuestTitle } from 'up-ibe-types';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { GuestTitleEnum } from '../../enums';

export function generateGuestTitleOptions(translateService: TranslateService, pmsProvider: string) {
  let guestTitleValues = [] as GuestTitle[];
  if (pmsProvider === 'ACPROJECT') {
    guestTitleValues = [
      GuestTitleEnum.Mr,
      GuestTitleEnum.Ms,
      GuestTitleEnum.Mrs,
      GuestTitleEnum.Other
    ] as GuestTitle[];
  } else {
    guestTitleValues = [
      GuestTitleEnum.Mr,
      GuestTitleEnum.Ms,
      GuestTitleEnum.Dr,
      GuestTitleEnum.Prof,
      GuestTitleEnum.Other
    ] as GuestTitle[];
  }
  /*
    #TODO: Need to make this configurable in UP IBE Admin
    See https://gitlab.com/up-agency/up-ibe-server/issues/149
  */
  return guestTitleValues.map((value) => {
    return {
      value,
      label: translateService.instant(`checkout_details.titles.${value}`)
    }
  });
}

export function isFieldRequired(formControl: AbstractControl) {
  if (formControl.validator) {
      const validator = formControl.validator({} as AbstractControl);
      if (validator && validator.required) {
          return true;
      }
  }
  return false;
}

export function isFormControlInvalid(formControl: AbstractControl | null, formDirective: FormGroupDirective) {
  if (!formControl || !formDirective) {
    return false;
  }

  if (formControl.errors && (formControl.dirty || formControl.touched || formDirective.submitted)) {
    return true;
  };

  return false;
}
