import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';

import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { Router } from '@angular/router';
import { mapErrorCodes, ErrorSource } from '../error-dialog/error-mapping';

@Injectable()
export class ErrorDialogService {

  constructor(
    private readonly translate: TranslateService,
    private readonly dialog: MatDialog,
    private readonly router: Router
  ) {}

  public errorDialog(errorCode: string, errorSource: ErrorSource, reloadPage?: boolean) {
    const error = mapErrorCodes(errorCode, errorSource);
    this.dialog.open(ErrorDialogComponent, {
      data: {
        title: this.translate.instant(`dialog_error_codes.${error}.title`),
        message: this.translate.instant(`dialog_error_codes.${error}.message`)
      }
    }).afterClosed().subscribe(() => {
      if (!this.router.url.includes('manage') || reloadPage) {
        this._reloadPageOnError();
      } else {
        return;
      }
    });
  }

  private _reloadPageOnError() {
    /* We include a conditional because clients often use weird path names
      that affects the angular route hashing and may redirect them to a non ibe page.
      It is safer to use window.location.href = paymentPath, to redirect clients in that case
    */
    const paymentPath = `${window.location.origin}${window.location.pathname}#/checkout/payment`;
    if (window.location.href === paymentPath) {
      window.location.reload();
    } else {
      window.location.href = paymentPath;
    }
  }
}
