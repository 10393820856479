<ibe-availability-calendar *ngIf="noAvailabilityShowCalendar$ | async"></ibe-availability-calendar>

<div #roomResultsContainer class="ibe-room-results" *ngIf="(availability$ | async)?.length">
  <div class="ibe-room-results-header">
      <h2 class="ibe-room-results-title">{{ 'room_results.available_room_options' | translate }}</h2>
      <button class="ibe-availability-calendar-button" *ngIf="config.settings.availabilityCalendarEnabled" (click)="toggleAvailabilityCalendar()"><span><i class="ibe-icon ibe-icon-calendar"></i> {{ 'room_results.flexible_on_dates' | translate }}</span> <span class="ibe-compare-text">{{ 'room_results.compare_rates_with_availability_calendar' | translate }} </span><i class="ibe-icon ibe-icon-chevron-down"></i></button>
  </div>

  <ibe-availability-calendar *ngIf="showAvailabilityCalendar"></ibe-availability-calendar>

  <div class="ibe-room-results-list">
    <div class="ibe-room-result ibe-room-result-{{ result.unitType.id}}" *ngFor="let result of availability$ | async; index as i" >
      <div class="ibe-row">
        <div class="ibe-col-md-4">
          <div class="ibe-room-image" (click)="openGalleryDialog(result.unitType)">
            <div class="ibe-room-image-inner" [ngStyle]="{'background-image': 'url(' + getUnitTypeImageUrl(result.unitType.id) + ')'}"></div>
          </div>
          <div class="ibe-room-content-button-container" *ngIf="result.unitType.customDescription && result.unitType.customDescription.length">
            <span class="ibe-room-content-button" (click)="openDescription(result.unitType.id,$event.target)">
              <div class="ibe-room-info-icon" *ngIf="descriptionId !== result.unitType.id">+</div>
              <div class="ibe-room-info-icon" *ngIf="descriptionId === result.unitType.id">-</div>
              {{ 'room_results.find_out' | translate }}
            </span>
          </div>
        </div>
        <div class="ibe-col-md-8">
          <div class="ibe-row">
            <div [ngClass]="{'ibe-custom-room-info': isCustomSearch}" class="ibe-col-md-9 ibe-room-unitType-info">
              <div class="ibe-room-title">{{ result.unitType.name }}</div>
              <mat-expansion-panel
                (opened)="descriptionPanelIsExpanded[i]=true"
                (closed)="descriptionPanelIsExpanded[i]=false"
                style="box-shadow: none"
                hideToggle
                *ngIf="config?.accountFeatureWhitelist?.expandableDescriptionPanel; else fallbackDescription"
              >
                <mat-expansion-panel-header class="accordion-header">
                  <div
                    [ngClass]="{
                      'ibe-icon-chevron-down': !descriptionPanelIsExpanded[i],
                      'ibe-icon-chevron-up': descriptionPanelIsExpanded[i]
                    }"
                    class="ibe-rate-info-btn"
                  ></div>
                </mat-expansion-panel-header>
                <div class="ibe-room-pms-content-description" [innerHTML]="result.unitType.pmsDescription"></div>
              </mat-expansion-panel>
              <ng-template #fallbackDescription>
                <div class="ibe-room-pms-content-description" [innerHTML]="result.unitType.pmsDescription"></div>
              </ng-template>
            </div>
            <div class="ibe-col-md-3 ibe-room-from-info" *ngIf="!isCustomSearch">
              <div class="ibe-room-from-text" *ngIf="!result.rates[0].suppressed">{{ 'room_results.room_from' | translate }}</div>
              <div class="ibe-from-price" *ngIf="!result.rates[0].suppressed">{{ result.fromPrice.amount | currency: result.fromPrice.currency:'symbol':'1.2-2' }} <span class="ibe-per-night-text">{{ 'room_results.per_night' | translate }}</span></div>
            </div>
          </div>
          <ibe-rate-selector
            [availabilityResult]="result"
            [pmsPropertyId]="propertyId"
            [useBasePrices]="displayInclusiveExtrasAsTaxes"
            (onAddToCart)="onAddToCart($event)"
            *ngIf="!isCustomSearch">
          </ibe-rate-selector>
        </div>
        <div class="ibe-room-custom-content-description ibe-col-md-12" [ngClass]="{'ibe-room-custom-content-closed-description': descriptionId !== result.unitType.id, 'ibe-room-custom-content-open-description': descriptionId === result.unitType.id}" [innerHTML]="result.unitType.customDescription"></div>
      </div>
      <ibe-custom-rate-selector class="ibe-col-md-3 ibe-custom-rate-select"
        [availabilityResult]="result"
        [pmsPropertyId]="propertyId"
        [useBasePrices]="displayInclusiveExtrasAsTaxes"
        (onAddToCart)="onAddToCart($event)"
        *ngIf="isCustomSearch">
      </ibe-custom-rate-selector>
    </div>
  </div>
</div>

<div class="ibe-no-room-results" *ngIf="noAvailability$ | async">
  <p class="ibe-no-room-results-text">{{ 'room_results.no_rooms' | translate }}</p>
  <div class="ibe-availability-calendar-text" *ngIf="config.settings.availabilityCalendarEnabled">
    <span><i class="ibe-icon ibe-icon-calendar"></i> {{ 'room_results.flexible_on_dates' | translate }}</span> {{ 'room_results.compare_rates_with_availability_calendar' | translate }}
  </div>
</div>
