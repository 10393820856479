import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AvailabilityStoreService } from 'app/services/availability-store.service';
import { SearchParamsService, INVALID_PARAMS } from 'app/services/room-results/search-params.service';
import { scrollToElement } from '../../../helpers/scroll.helper';
import { IbeConfigService } from 'app/services/ibe-config.service';
import { ImagesService } from 'app/services/images.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { AlertDialogComponent } from 'app/alert-dialog/alert-dialog.component';
import { BookingService } from 'app/services/booking.service';
import { combineLatest, of, Observable, merge, Subscription } from 'rxjs';
import { map, startWith, catchError, mapTo } from 'rxjs/operators';
import {
  AvailabilityResultByRegionModel, Property
} from 'up-ibe-types';
import * as moment from 'moment';

@Component({
  selector: 'ibe-region-results',
  templateUrl: './region-results.component.html',
  styleUrls: ['./region-results.component.scss']
})
export class RegionResultsComponent implements OnInit, OnDestroy {
  @ViewChild('regionResultsContainer') set regionResultsContainer(elementRef: ElementRef) {
    if (elementRef && this.config.settings.autoscrollEnabled) {
      scrollToElement(elementRef)

    }
  }
  @Output('onLoadingEmit') public onLoadingEmit: EventEmitter<Observable<boolean>> = new EventEmitter();
  public loading$: Observable<boolean>;
  public availability$: Observable<AvailabilityResultByRegionModel[]>;
  public noAvailability$: Observable<boolean>;
  public noAvailabilityShowCalendar$: Observable<boolean>
  public searchParams: Params;
  public dates: string;
  public persons: number;
  public region: string;
  public promoCode: string;
  public subscription: Subscription;
  public noAvailability: boolean;
  public regionProperties: Property[];

  constructor(
    public readonly router: Router,
    private readonly route: ActivatedRoute,
    public readonly availabilityStoreService: AvailabilityStoreService,
    public readonly config: IbeConfigService,
    private readonly searchParamsService: SearchParamsService,
    private readonly imagesService: ImagesService,
    private readonly analyticsService: AnalyticsService,
    public readonly bookingService: BookingService,
    private readonly translate: TranslateService,
    private readonly dialog: MatDialog
  ) { }

  public ngOnInit() {
    const validParams$ = this.searchParamsService.validParams(this.route.queryParams);
    this.availability$ = this.availabilityStoreService.availabilityForRegion$(validParams$);
    this.loading$ = merge<boolean, boolean>(
      validParams$.pipe(mapTo(true)),
      this.availability$.pipe(mapTo(false))
    ).pipe(
      startWith(true),
      catchError(_ => of(false))
    );

    this.onLoadingEmit.emit(this.loading$);

    this.noAvailability$ = this.availability$.pipe(map((availability) => availability.length < 1));

    this.noAvailabilityShowCalendar$ = this.noAvailability$.pipe(
      map((noAvailability) => noAvailability && this.config.settings.availabilityCalendarEnabled),
      startWith(false)
    )

    this.subscription = combineLatest(validParams$, this.availability$)
      .subscribe(([searchParams, availability]) => {
        this.searchParams = searchParams;
        this.promoCode = searchParams.queryParams.promoCode;
        this._getParamsForTitle(searchParams);
        this.regionProperties = this.config.properties.reduce((properties: Property[], property: Property) => {
          if (this.region === 'All' || (property.location?.city?.trim().toLowerCase() === this.region.trim().toLowerCase())) {
            properties.push(property);
          }

          return properties;
        }, []);
        if (availability.length) {
          this.analyticsService.createRegionSearchImpressionEvent(this.region, availability);
        } else {
          this.analyticsService.createNoRegionResultsEvent(searchParams.toHttpParams(), this.region);
        }
      },
        (err) => {
          if (err !== INVALID_PARAMS) {
            throw err;
          }
          this.dialog.open(AlertDialogComponent, {
            data: {
              title: this.translate.instant('room_results.invalid_search_params_error_title'),
              message: this.translate.instant('room_results.invalid_search_params_error_message')
            }
          }).afterClosed().subscribe(() => {
            this.bookingService.clearSearchCriteriaFromLocalStorage();
            return this.router.navigate(['booking/search']);
          });
        });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public getPropertyImageUrl(propertyId: string, unitTypeId: string) {
    return this.imagesService.getPropertyImageUrl(propertyId, unitTypeId);
  }

  private _getParamsForTitle(params: Params) {
    this.region = params.queryParams.region;
    this.dates = this.formatDates(params.queryParams.arrival, params.queryParams.departure);
    this.persons = Number(params.queryParams.adults);
    if (params.queryParams.childrenAges) {
      this.persons = this.persons + params.queryParams.childrenAges.length;
    }
  }

  public onResultClick(result: AvailabilityResultByRegionModel) {
    const stayCriteriaParams = {
      ...this.searchParams.queryParams,
      propertyId: result.property.id
    };
    this.router.navigate(['/booking/results'], {
      queryParams: stayCriteriaParams
    });
    this.bookingService.saveLastSearchedStayCriteria(stayCriteriaParams);
  }

  public formatDates(arrival: string, departure: string) {
    const arrivalDate = moment(arrival);
    const departureDate = moment(departure);
    arrivalDate.locale(this.config.language);
    departureDate.locale(this.config.language);
    const arrivalFormatted = arrivalDate.format('Do MMM');
    const departureFormatted = departureDate.format('Do MMM');
    return `${arrivalFormatted} - ${departureFormatted}`;
  }
}
