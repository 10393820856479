import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';

interface ExcludedRoutes {
  [key: string]: boolean;
}

const excludedRoutes: ExcludedRoutes = {
  booking: true,
  search: true,
  results: true,
  checkout: true,
  details: true,
  address: true,
  payment: true,
  error: true,
  confirmation: true,
  guest: true,
  manage: true,
  edit: true,
  reservations: true,
  history: true,
  modify: true,
  account: true,
  create: true,
  'reset-password': true,
  'complete-reset-password': true,
  reservation: true
}

@Injectable()
export class AnchorGuard implements CanActivateChild {

  constructor() {}

  public canActivateChild(route: ActivatedRouteSnapshot) {
    if (excludedRoutes[route.url[0].path]) {
      if (document.getElementById(route.url[0].path)) {
        console.error(`Conflicting Anchor found: ${route.url[0].path}`)
      }
      return true;
    } else if (document.getElementById(route.url[0].path)) {
      return false;
    } else {
      return true;
    }
  }
}
