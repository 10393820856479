<ibe-steps-bar></ibe-steps-bar>
<div class="ibe-row ibe-checkout-row">
  <div class="ibe-col-md-6">
    <router-outlet></router-outlet>
  </div>
  <div class="ibe-col-md-6">
    <h2 class="ibe-checkout-step-title">{{ 'checkout.your_selection' | translate }}</h2>
    <ibe-reservation-card
      *ngFor="let reservation of reservations; let i = index;"
      [reservationKey]="i"
      [reservation]="reservation"
      [@flyRightOnLeaveAnimation]
      [showButtons]="isReservationsEditable"
      (onRemoveReservation)="onRemoveReservation()"
      (onExtrasChange)="onExtrasChange()"
      [showAddExtrasButton]="reservation.extrasAvailable"
      [displayInclusiveExtrasAsTaxes]="displayInclusiveExtrasAsTaxes"
    ></ibe-reservation-card>
    <button
      *ngIf="config.isMultiReservationsSupported()"
      class="ibe-add-another-room-btn"
      (click)="addAnotherRoom()"
    >
      <i class="ibe-icon ibe-icon-bed-plus"></i>
      <span>{{ 'checkout.add_another_room' | translate }}</span>
    </button>
    <ibe-total-summary
      *ngIf="bookingCurrency"
      [bookingTotals]="bookingTotals"
      [currency]="bookingCurrency"
      [isSuppressed]="isSuppressed"
      [displayInclusiveExtrasAsTaxes]="displayInclusiveExtrasAsTaxes"
    ></ibe-total-summary>
    <div class="ibe-city-tax-estimate" *ngIf="showCityTaxEstimateEnabled">
      <span class="city-tax-message">{{ 'checkout.city_tax_estimate' | translate }}: </span>
      <span class="city-tax">{{ cityTaxEstimate | currency:bookingCurrency:'symbol':'1.2-2' }}</span>
    </div>
  </div>
</div>
