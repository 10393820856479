import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';

import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class JourneyService {
  public journeyToken$: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);

  constructor(
    public readonly storageService: LocalStorageService
  ) {}

  /**
   * Finalise the journey and remove any associated storage items.
   */
  public finaliseJourney(): void {
    this.storageService.removeItem('journey_token');
    this.storageService.removeItem('journey_expiry');
    this.journeyToken$.next(undefined);
  }

  /**
   * Initiate the customer journey by generating a token and setting the expiry.
   */
  public initiateJourney(): void {
    if (!this.hasCurrentJourneyExpired()) {
      // Current journey is still ongoing, leave as is.
      this.journeyToken$.next(
        this.storageService.getItem('journey_token')
      );

      return;
    }

    const token = this.generateJourneyToken();
    const expiryDays = 7;

    this.storageService.setItem('journey_expiry', moment().add(expiryDays, 'days'));
    this.storageService.setItem('journey_token', token);

    this.journeyToken$.next(token);
  }

  /**
   * Check if the current journey is expired or not.
   * This is defualted to 7 days.
   */
  public hasCurrentJourneyExpired(): boolean {
    const expiry = this.storageService.getItem('journey_expiry');

    if (!expiry) {
      return true;
    }

    return moment(expiry).isBefore(moment());
  }

  /**
   * Get the current journey token.
   */
  public getJourneyToken(): string | undefined {
    return this.journeyToken$.getValue();
  }

  /**
   * Generate a random 36 character long token.
   */
  public generateJourneyToken(): string {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const tokenLength = 36;
    let journeyId = '';
    for (let i = 0; i < tokenLength; i++) {
      journeyId += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
    }
    return journeyId;
  }
}
