<h2 class="ibe-checkout-step-title">{{ 'checkout_details.complete_reservation' | translate }}</h2>
<div class="ibe-checkout-step-box" [@fadeInOnEnterAnimation] [@scaleUpAnimation]="animationState" (@scaleUpAnimation.done)="scaleUpAnimationComplete($event)">
  <form [formGroup]="detailsForm" #detailsFormDirective="ngForm" (submit)="onFormSubmit($event)" autocomplete="off">
    <ibe-details-form
      (toggleShowCreateAccountButton)="toggleShowCreateAccountButton($event)"
      (formReady)="addFormControl('details', $event)"
      (updateLicensePlateNumber)="updateLicensePlateNumber($event)"
      [showCreateAccountBox]="showCreateAccountBox"
      [submitObservable]="submit.asObservable()"
    ></ibe-details-form>
    <button type="submit" *ngIf="!showCreateAccountButton" class="ibe-btn ibe-primary-btn">
      {{ 'global.continue' | translate }}
    </button>
    <button
      *ngIf="showCreateAccountButton"
      [disabled]="isSubmitting"
      class="ibe-btn ibe-primary-btn"
      (click)="createGuestAccount($event)"
    >
      {{ 'guest_details.create_account' | translate }}
    </button>
  </form>
</div>
