<div class="ibe-main-heading">
  <h1>
    <span class="ibe-booking-management-span">{{ 'manage_booking.manage_booking' | translate }}</span>
  </h1>
  <p class="ibe-login-link">{{ 'manage_booking.got_account' | translate }} <a (click)="login()">{{ 'manage_booking.login' | translate }}</a></p>
</div>

<section class="ibe-booking-login-form-box" [hidden]="booking || reservation">
  <form [formGroup]="bookingLoginForm" #bookingLoginFormDirective="ngForm" (submit)="onFormSubmit($event)">
    <div class="ibe-form-group" *ngIf="config.isPmsStayntouch() || config.isPmsAcProject">
      <label for="propertyIdSelect">{{ 'manage_booking.location' | translate }}</label>
      <input *ngIf="config.properties.length === 1" class="ibe-form-control" type="text" value="{{ firstProperty.name }}" disabled>
      <select *ngIf="config.properties.length > 1" id="propertyIdSelect" class="ibe-form-control ibe-custom-select" formControlName="propertyId" [ngClass]="{'is-invalid': isFormControlInvalid(bookingLoginForm.controls.propertyId)}">
        <option value="" disabled>{{ 'manage_booking.select_location' | translate }}</option>
        <option value="{{property.pmsId}}" *ngFor="let property of config.properties; let i=index">{{property.name}}</option>
      </select>
      <ibe-form-validation-message [form]="bookingLoginFormDirective" [control]="bookingLoginForm.controls.propertyId"></ibe-form-validation-message>
    </div>
    <div class="ibe-form-group">
      <label for="bookingIdInput">
        <span>{{ 'manage_booking.booking_id' | translate }}</span>
      </label>
      <input type="text" formControlName="bookingOrReservationId" id="bookingIdInput" class="ibe-form-control" [ngClass]="{'is-invalid': isFormControlInvalid(bookingLoginForm.controls.bookingOrReservationId)}" placeholder="{{ 'manage_booking.booking_id' | translate }}">
      <ibe-form-validation-message [form]="bookingLoginFormDirective" [control]="bookingLoginForm.controls.bookingOrReservationId"></ibe-form-validation-message>
    </div>
    <div class="ibe-form-group">
      <label for="lastNameInput">{{ 'manage_booking.last_name' | translate }}</label>
      <input type="text" formControlName="lastName" id="lastNameInput" class="ibe-form-control" [ngClass]="{'is-invalid': isFormControlInvalid(bookingLoginForm.controls.lastName)}" placeholder="{{ 'manage_booking.last_name' | translate }}">
      <ibe-form-validation-message [form]="bookingLoginFormDirective" [control]="bookingLoginForm.controls.lastName"></ibe-form-validation-message>
    </div>
    <button type="submit" class="ibe-btn ibe-primary-btn">{{ 'global.continue' | translate }}</button>
  </form>
  <ibe-loading-bar [isLoading]="isLoading"></ibe-loading-bar>
</section>

<div *ngIf="booking || reservation">
  <div class="ibe-row">
    <div class="ibe-col-md-6">
      <div class="ibe-booking-id">
        <span *ngIf="isBooking">{{ 'manage_booking.booking_id' | translate }}: #{{ bookingId() }}</span>
        <span *ngIf="isReservation">{{ 'manage_booking.reservation_id' | translate }}: #{{ bookingReference() }}</span>
      </div>
    </div>
    <div class="ibe-col-md-6" *ngIf="isBooking">
      <div class="ibe-booking-action-buttons">
        <div class="ibe-form-group">
          <label for="reservationStatusSelect">{{ 'manage_booking.reservation_status' | translate }}</label>
          <select
            id="reservationStatusSelect"
            class="ibe-custom-select ibe-reservation-status-select"
            (change)="changeReservationStatus($event.target.value)"
          >
            <!-- TODO Translate and Re-enable -->
            <!--<option value="">Show All</option>-->
            <option
              value="{{ option.value }}"
              *ngFor="let option of reservationStatusOptions"
              [selected]="( reservationStatus === option.value )"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="ibe-row">
    <div class="ibe-col-md-6">
      <div class="ibe-booking-manage-details" *ngIf="isBooking">
          <h2 class="ibe-section-heading">
            <span>{{ 'manage_booking.booker_details' | translate }}</span>
          </h2>
          <div class="ibe-grey-panel">
            <ibe-booker-details [guest]="booker()"></ibe-booker-details>
          </div>
      </div>
      <div class="ibe-booking-manage-details" *ngIf="isReservation && primaryGuest()">
        <h2 class="ibe-section-heading">
          <span>{{ 'manage_booking.primary_guest_details' | translate }}</span>
        </h2>
        <div class="ibe-grey-panel">
          <ibe-booker-details [guest]="primaryGuest()"></ibe-booker-details>
        </div>
      </div>
      <div class="ibe-booking-manage-details" *ngIf="isReservationModifiable && showEditButtons">
        <button class="ibe-btn ibe-pay-btn ibe-update-details-btn" (click)="openGuestDetailsDialog()">{{ 'manage_booking.update_details' | translate }}</button>
      </div>
      <div class="ibe-booking-payment-details" *ngIf="(paymentAccount() || isReservationModifiable) && showEditButtons">
        <div class="ibe-booking-payment-header">
          <h2 class="ibe-section-heading">{{ 'manage_booking.payment_method' | translate }}</h2>
          <a class="ibe-update-payment-details-button" (click)="openPaymentDialog()" *ngIf="paymentAccount() && isReservationModifiable">
            <svg class="ibe-reservation-detail-icon" id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><title>edit</title><g id="edit"><path id="pencil" d="M42.373,11.047l1.761-1.761a2.278,2.278,0,0,0-.07-3.219L41.933,3.936a2.278,2.278,0,0,0-3.219-.07L36.953,5.627,21.141,21.439a1.183,1.183,0,0,0-.326.616l-1.194,6.324,6.324-1.194a1.183,1.183,0,0,0,.616-.326Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><polyline id="paper" points="40.16 24 40.16 43 5 43 5 7.84 24 7.84" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>
            <span class="ibe-desktop">{{ 'guest_management.update_payment_details' | translate }}</span>
            <span class="ibe-mobile">{{ 'guest_management.update' | translate }}</span>
          </a>
        </div>
        <div class="ibe-grey-panel">
          <div>
            <button class="ibe-btn ibe-pay-btn" *ngIf="!paymentAccount() && isReservationModifiable && showEditButtons" (click)="openPaymentDialog()">{{ 'manage_booking.add_payment_method' | translate }}</button>
            <ibe-credit-card *ngIf="paymentAccount()" [card]="paymentAccount()">
            </ibe-credit-card>
          </div>
        </div>
      </div>
    </div>
    <div class="ibe-col-md-6">
      <div class="ibe-section-heading-container">
        <h2 *ngIf="isBooking" class="ibe-section-heading">{{ 'manage_booking.reservations' | translate }}</h2>
        <h2 *ngIf="isReservation" class="ibe-section-heading">{{ 'manage_booking.your_reservation' | translate }}</h2>
        <div class="ibe-close-booking-reservation">
          <button (click)="clearBookingOrReservation()" class="ibe-close-booking-reservation-button"><i class="ibe-icon ibe-icon-close"></i> {{ 'global.close' | translate }} </button>
        </div>
      </div>
      <div *ngIf="booking">
        <ibe-reservation-card
          *ngFor="let reservation of filteredReservations; let i = index;"
          [reservationKey]="i"
          [reservation]="reservation"
          [showButtons]="false"
          [showCancellationPolicy]="reservationHasConfirmedStatus(reservation)"
          [showAddExtrasButton]="false"
        >
          <div class="ibe-reservation-action-btns" *ngIf="reservationHasConfirmedStatus(reservation) && showEditButtons" [ngClass]="{'ibe-can-add-extras': isReservationFlexibleAndEditable(reservation)}">
            <button class="ibe-btn ibe-add-extras-btn" (click)="openAddExtrasDialog(reservation)">{{ 'offer_card.add_extras' | translate }}</button>
            <button class="ibe-btn ibe-cancel-btn" (click)="cancelReservation(reservation)">{{ 'manage_booking.cancel_reservation' | translate }}</button>
          </div>
        </ibe-reservation-card>
      </div>
      <div class="ibe-no-reservations" *ngIf="booking && !filteredReservations.length">
        {{ 'manage_booking.no_reservations_could_be_found_with_this_status' | translate }}
      </div>

      <div *ngIf="reservation">
        <ibe-reservation-card
          [reservation]="reservation"
          [showButtons]="false"
          [showCancellationPolicy]="reservationHasConfirmedStatus(reservation)"
          [showAddExtrasButton]="false">
          <div class="ibe-reservation-action-btns" *ngIf="reservationHasConfirmedStatus(reservation) && showEditButtons" [ngClass]="{'ibe-can-add-extras': isReservationFlexibleAndEditable(reservation)}">
            <button class="ibe-btn ibe-add-extras-btn" (click)="openAddExtrasDialog(reservation)">{{ 'offer_card.add_extras' | translate }}</button>
            <button class="ibe-btn ibe-cancel-btn" (click)="cancelReservation(reservation)">{{ 'manage_booking.cancel_reservation' | translate }}</button>
          </div>
        </ibe-reservation-card>
      </div>
    </div>
  </div>
</div>
