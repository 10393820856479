import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, FormGroupDirective } from '@angular/forms';
import { ToasterService } from 'angular2-toaster';
import { GuestAuthService } from '../../services/guest-auth.service';
import { Settings } from 'up-ibe-types';
import { TranslateService } from '@ngx-translate/core';
import { IbeConfigService } from '../../services/ibe-config.service';
import { isFormControlInvalid, generateGuestTitleOptions } from '../../helpers/form.helper';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { sortBy } from 'lodash';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'ibe-edit-account',
  templateUrl: './edit-account.component.html',
  styleUrls: ['./edit-account.component.scss']
})
export class EditAccountComponent implements OnInit {
  @ViewChild('guestFormDirective', { static: true }) public guestFormDirective: FormGroupDirective;
  public guestForm: FormGroup;
  public ibeSettings: Settings;
  public countriesList: {}[];
  public animationState: string;
  public submit: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  public readonly guestTitleOptions = generateGuestTitleOptions(this.translate, this.config.pmsProvider);

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly translate:  TranslateService,
    private readonly config: IbeConfigService,
    private readonly http: HttpClient,
    private readonly guestAuthService: GuestAuthService,
    public readonly toasterService: ToasterService
  ) { }

  public ngOnInit() {
    this._loadCountriesData();
    this.ibeSettings = this.config.settings;
    this.guestForm = this.formBuilder.group({});
  }

  public addFormControl(name: string, formGroup: FormGroup) {
    this.guestForm.addControl(name, formGroup);
    const formControlsLength = 2;
    if (Object.keys(this.guestForm.controls).length === formControlsLength) {
      this._loadGuestDetailsData();
    }
  }

  private _loadGuestDetailsData() {
    // Have to encode email: https://github.com/angular/angular/issues/18261
    const pmsGuestId = this.guestAuthService.getPmsGuestId();
    const params = new HttpParams().set('pmsGuestId', pmsGuestId);

    this.http.get(`${environment.serverUrl}/api/ibe/guest`, {
      params
    })
    // tslint:disable-next-line:no-any
    .subscribe((response: any) => {
      if (response.user) {
        this.guestForm.patchValue({
          details: response.user
        });
        this.guestForm.patchValue({
          address: response.user.address
        });
      }
    }, (error) => {
      this.toasterService.pop('error',
        this.translate.instant('guest_management.error_getting_details'));
    });
  }

  private _loadCountriesData() {
    this.http.get(environment.countriesListDataUrl).subscribe((countriesResponse: CountriesResponse) => {
      const countries = Object.keys(countriesResponse).map((key) => {
        return {
          value: key,
          label: countriesResponse[key]
        };
      });
      this.countriesList = sortBy(countries, 'label');
    });
  }

  public isFormControlInvalid(formControl: AbstractControl) {
    return isFormControlInvalid(formControl, this.guestFormDirective);
  }

  public onFormSubmit(event: Event) {
    this.submit.next(true);
    event.preventDefault();
    if (this.guestForm.valid) {
      // Have to encode email: https://github.com/angular/angular/issues/18261
      const pmsGuestId = this.guestAuthService.getPmsGuestId();
      const params = new HttpParams().set('pmsGuestId', pmsGuestId).set('language', this.config.language);
      this.http.put(`${environment.serverUrl}/api/ibe/guest`, {
        ...this.guestForm.value
      }, { params })
      // tslint:disable-next-line:no-any
      .subscribe((response: any) => {
        this.toasterService.pop('success',
          this.translate.instant('guest_management.details_updated_successfully'));
      }, (error) => {
        this.toasterService.pop('error',
          this.translate.instant('guest_management.error_updating_details'));
      });
    }
  }
}

export interface CountriesResponse {
  [key: string]: string;
}
