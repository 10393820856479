import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { IbeConfigService } from '../../services/ibe-config.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'angular2-toaster';
import { ErrorDialogService } from '../../services/error-dialog.service';
import { getPaymentProviderScriptUrl } from '../../helpers/payment.helper';
import { BookingOrReservationEnum } from '../../../enums';
import { IbeServerApiResponseModel } from 'up-ibe-types';
import { ScriptLoaderService } from '../../services/script-loader.service';
import { ErrorSource } from 'app/error-dialog/error-mapping';
import { ErrorHandlerService, tryInitializeSentry } from '../../services/error-handler.service';
import { captureException, Scope, Severity } from '@sentry/browser';

interface PaymentSetupRequestData {
  bookingOrReservation: string;
  bookingOrReservationId: string;
  ibeLanguage: string;
  ibeUrl: string;
  propertyId?: string;
}

interface PaymentSetupData {
  bookingOrReservation: BookingOrReservationEnum,
  bookingOrReservationId: string,
  propertyId: string,
  bookingRequestId: string
}

interface PaymentDialogData extends PaymentSetupData {
  paymentRedirected: boolean;
}

@Component({
  selector: 'ibe-booking-payment-dialog',
  templateUrl: './booking-payment-dialog.component.html',
  styleUrls: ['./booking-payment-dialog.component.scss']
})
export class BookingPaymentDialogComponent implements OnInit {
  public isLoading = true;
  public hasPaymentScriptLoaded = false;
  public paymentSetupData: PaymentSetupData;
  public bookingRequestId: string;
  public paymentRedirected = false;
  private hasSentry = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PaymentDialogData,
    public readonly http: HttpClient,
    private readonly config: IbeConfigService,
    private readonly translate: TranslateService,
    private readonly toasterService: ToasterService,
    private readonly errorDialogService: ErrorDialogService,
    private readonly dialogRef: MatDialogRef<BookingPaymentDialogComponent>,
    private readonly scriptLoader: ScriptLoaderService
  ) {
    this.hasSentry = tryInitializeSentry();
  }

  public ngOnInit() {
    this.paymentRedirected = !!(this.data.paymentRedirected);

    this.config.setCurrentProperty(this.data.propertyId);
    const scriptUrl = getPaymentProviderScriptUrl(
      this.config.getPaymentProvider(),
      this.config.getPaymentProviderSettings().testModeEnabled
    );
    if (scriptUrl) {
      this.scriptLoader.loadScript(scriptUrl).then(() => {
        this.hasPaymentScriptLoaded = true;
        this._getPaymentSetupData();
      }).catch((error: Error) => {
        this.handleError(error, 'http', 'ibe-booking-payment-dialog.ngOnInit', {
          'scriptUrl': scriptUrl
        });
      });
    } else {
      this._getPaymentSetupData();
    }
  }

  // tslint:disable-next-line:no-any
  public completeBooking(paymentData: any) {
    if (this.config.getPaymentProvider() === 'adyen-dropin' && !this.paymentRedirected) {
      this.toasterService.pop('success',
        this.translate.instant('global.success'),
        this.translate.instant('manage_booking.payment_method_added_successfully'));
      return this.dialogRef.close({
        paymentSuccess: true
      });
    } else {
      this.isLoading = true;
      return this.http.post(`${environment.serverUrl}/api/ibe/complete-payment`, {
        ...this.data,
        paymentData,
        bookingRequestId: this.bookingRequestId
      })
        .subscribe((response: IbeServerApiResponseModel) => {
          if (response.success) {
            this.toasterService.pop('success',
              this.translate.instant('global.success'),
              this.translate.instant('manage_booking.payment_method_added_successfully'));
            this.dialogRef.close({
              paymentSuccess: true
            });
          }
          this.isLoading = false;
        }, (error) => {
          this.isLoading = false;
          if (error.error && !error.error.success && error.error.errorCode) {
            this.errorDialogService.errorDialog(error.error, error.error.source);
          } else {
            const errorSource = ErrorSource[this.config.pmsProvider.toUpperCase() as keyof typeof ErrorSource];
            this.errorDialogService.errorDialog('Payment Process Failed', errorSource);
          }
        });
    }
  }

  public showPaymentComponent(paymentProvider: string) {
    return (
      this.paymentSetupData &&
      this.config.getPaymentProvider() === paymentProvider
    );
  }

  private _getPaymentSetupData() {
    this.isLoading = true;
    const ibeUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    const requestData: PaymentSetupRequestData = {
      ...this.data,
      ibeLanguage: this.config.language,
      ibeUrl
    };

    return this.http.post(`${environment.serverUrl}/api/ibe/setup-payment`, { ...requestData })
      .subscribe((response: { result: PaymentSetupData }) => {
        this.paymentSetupData = response.result;
        this.paymentSetupData.bookingOrReservationId = this.data.bookingOrReservationId;
        this.bookingRequestId = response.result.bookingRequestId;
        this.isLoading = false;
      }, error => {
        this.handleError(error, 'http', 'ibe-booking-payment-dialog._getPaymentSetupData', {
          'ibeUrl': ibeUrl
        });
      });
  }

  // tslint:disable-next-line:no-any
  private handleError(error: unknown, type: string, category: string, details: { [key: string]: any; }): void {
    if (this.hasSentry) {
      const err = ErrorHandlerService.toError(error);
      details.error = error;
      captureException(err, (scope: Scope) =>
        scope.addBreadcrumb({
          type,
          level: Severity.Critical,
          message: `${err.message}`,
          category,
          data: details
        })
      );
    } else {
      console.error('handleError', error);
    }

    const errorSource = ErrorSource[this.config.pmsProvider.toUpperCase() as keyof typeof ErrorSource];
    this.errorDialogService.errorDialog('Payment Process Failed', errorSource);
  }
}
