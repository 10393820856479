export const environment = {
  production: false,
  serverUrl: 'https://my-ibe.preview.srv4.detco.de',
  imagesUrl: 'https://s3-eu-west-1.amazonaws.com/images.ibe-staging-assets',
  translationFilesUrl: 'https://ibe.preview.srv4.detco.de/assets/i18n',
  countriesListDataUrl: 'https://ibe.staging.uphotel.agency/assets/countries.json',
  statesListDataUrl: 'https://ibe.staging.uphotel.agency/assets/states.json',
  paymentProviderScripts: {
    adyen: {
      test: 'https://checkoutshopper-test.adyen.com/checkoutshopper/assets/js/sdk/checkoutSDK.1.6.3.min.js',
      live: 'https://checkoutshopper-live.adyen.com/checkoutshopper/assets/js/sdk/checkoutSDK.1.6.3.min.js'
    },
    mycheck: {
      test: 'https://wallet-sdk-sandbox.mycheckapp.com/sdk.js',
      live: 'https://wallet-sdk.mycheckapp.com/sdk.js'
    }
  },
  subDomain: 'ibe',
  version: 'no-commit-sha-found',
  sentry: {
    enabled: true,
    endpointUrl: 'https://8d0246d993bf472c923cc4b4ef0d9489@sentry.io/627677',
    release: 'no-commit-sha-found'
  },
  languages: ['en', 'de', 'fr', 'sv', 'fi', 'ru', 'nl', 'es', 'it'],
  ipDataApiKey: '528ff8ff7781b78846bd8288446f3d1bf5209332d2d5db753c46f2cd'
};
