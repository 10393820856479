<mat-dialog-content>
  <div class="ibe-extra-result" *ngFor="let extra of extras" (click)="toggleExtra(extra)" [ngClass]="{'ibe-extra-selected': isExtraSelected(extra.id)}">
    <div class="ibe-extra-image" *ngIf="haveImages">
      <img class="ibe-extra-image-inner" *ngIf="extra.image" [src]="getExtraImageUrl(extra)">
      <svg class="ibe-extra-image-inner" *ngIf="!extra.image" fill="none" height="150" viewBox="0 0 200 150" width="200" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="m0 0h200v150h-200z" fill="#fff"/><path d="m0 0h200v150h-200z" fill="currentColor" fill-opacity=".125"/><g stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m100 122.5c26.234 0 47.5-21.266 47.5-47.5 0-26.2335-21.266-47.5-47.5-47.5-26.2335 0-47.5 21.2665-47.5 47.5 0 26.234 21.2665 47.5 47.5 47.5z"/><path d="m100 102.5v-55"/><path d="m127.5 75h-55"/><path d="m100 144c38.108 0 69-30.892 69-69 0-38.1076-30.892-69-69-69-38.1076 0-69 30.8924-69 69 0 38.108 30.8924 69 69 69z" opacity=".5"/><path d="m100 163c48.601 0 88-39.399 88-88 0-48.6011-39.399-88-88-88-48.6011 0-88 39.3989-88 88 0 48.601 39.3989 88 88 88z" opacity=".25"/><path d="m100 180c57.99 0 105-47.01 105-105 0-57.9899-47.01-105-105-105-57.9899 0-105 47.0101-105 105 0 57.99 47.0101 105 105 105z" opacity=".125"/></g></g></svg>
    </div>
    <div class="ibe-extra-info">
      <div class="ibe-extra-name">
        {{extra.name}}
        <div class="ibe-included-extra-info" *ngIf="extra.isInclusiveInRate">
          <svg class="ibe-included-extra-icon" width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g>
              <circle class="ibe-" cx="12" cy="12" r="9.5" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
              <g>
                <line x1="12" y1="9.856" x2="12" y2="17.085" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                <circle cx="12" cy="6.915" r="1" fill="currentColor"/>
              </g>
            </g>
          </svg>
          <span class="ibe-included-extra-text">{{ 'extras.optional_included_rate_info' | translate }}</span>
        </div>
      </div>
      <div class="ibe-extra-details">
        <div class="ibe-extra-content">
          <p class="ibe-extra-description" [innerHTML]="extra.description"></p>
        </div>
        <div class="ibe-extra-action" *ngIf="!extra.isInclusiveInRate">
          <div class="ibe-extra-price">
            {{ extra.grossAmount.amount | currency:extra.grossAmount.currency:'symbol':'1.2-2' }} <span class="per-label">{{ 'extras.per' | translate }}</span>
            <span class="ibe-extra-per-unit"> {{ translatePricingUnit(extra.pricingUnit) }} </span>
            <span class="ibe-extra-per-period" *ngIf="((extra.totalGrossAmount.amount / extra.quantity) === extra.grossAmount.amount); else perNight">{{ 'extras.per_stay' | translate }}</span>
          </div>
          <span class="ibe-extra-toggle-status">
            <i class="ibe-icon ibe-icon-tick" *ngIf="isExtraSelected(extra.id)"></i>
            <i class="ibe-icon ibe-icon-plus" *ngIf="!isExtraSelected(extra.id)"></i>
          </span>
        </div>
        <div class="ibe-extra-totalGrossAmount" *ngIf="!extra.isInclusiveInRate && (extra.totalGrossAmount.amount > extra.grossAmount.amount)">
          {{ extra.totalGrossAmount.amount | currency:extra.totalGrossAmount.currency:'symbol':'1.2-2' }} {{ 'extras.total' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div class="" *ngIf="isLoading && (!extras || !extras.length)">
    <ibe-loading-circle></ibe-loading-circle>
  </div>
  <p class="ibe-no-extras-text" *ngIf="(!extras || !extras.length) && !isLoading">{{ 'extras.no_extras_for_property' | translate }}<p>
</mat-dialog-content>

<ng-template #perNight>
  <span class="ibe-extra-per-period">{{ 'extras.per_night' | translate }}</span>
</ng-template>
