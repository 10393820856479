import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { ReservationModel, ExtraModel } from 'up-ibe-types';
import { ToasterService } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { ExtrasStoreService } from '../../../services/extras-store.service';
import { ReservationService } from '../../../services/reservation.service';
import { ErrorDialogComponent } from '../../../error-dialog/error-dialog.component';

@Component({
  selector: 'ibe-add-extras-dialog',
  templateUrl: './add-extras-dialog.component.html',
  styleUrls: ['./add-extras-dialog.component.scss'],
  providers: [
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {width: 900}}
  ]
})
export class AddExtrasDialogComponent implements OnInit {
  public isLoading = true;
  public selectedExtras: ExtraModel[] = [];
  public extras: ExtraModel[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ReservationModel,
    public dialogRef: MatDialogRef<AddExtrasDialogComponent, boolean>,
    private readonly extrasStore: ExtrasStoreService,
    public reservationService: ReservationService,
    public toasterService: ToasterService,
    public translate: TranslateService,
    public dialog: MatDialog
  ) {}

  public ngOnInit() {
    this._fetchExtras();
  }

  private _fetchExtras() {
    const params = {
      propertyId: this.data.property.id,
      ratePlanId: this.data.ratePlan.id,
      arrival: this.data.arrival,
      departure: this.data.departure,
      adults: String(this.data.adults),
      childrenAges: this.data.childrenAges
    };
    this.extrasStore.load({params})
      .subscribe(response => {
        this.extras = this._filterOutReservationExtras(response);
        this.isLoading = false;
      });
  }

  public completeExtrasStep() {
    const updatedExtras = this.data.extras.concat(this.selectedExtras)
    this.isLoading = true;
    const params = {
      propertyId: this.data.property.id,
      reservationId: this.data.id as string,
      reservation: {
        ...this.data,
        extras: updatedExtras
      }
    };
    this.reservationService.addExtrasToReservation(params)
      .subscribe((success: boolean) => {
        this.isLoading = false;
        if (success) {
          this.data.extras = this.data.extras.concat(this.selectedExtras)
          this.toasterService.pop('success',
            this.translate.instant('extras.extras_updated'),
            this.translate.instant('extras.extras_updated_successfully'));
        }
        this.dialogRef.close(success);
      }, error => {
        this.dialog.open(ErrorDialogComponent, {
          data: {
            title: this.translate.instant('dialog_error_codes.extras_update_error.title'),
            message: this.translate.instant('dialog_error_codes.extras_update_error.message')
          }
        });
        this.dialogRef.close();
      });
  }

  public skipExtrasStep() {
    this.dialogRef.close();
  }

  // This is done so guests can't unselect already selected extras
  private _filterOutReservationExtras(extras: ExtraModel[]) {
    const reservationExtrasId = this.data.extras.map(extra => extra.id);
    return extras.filter(extra => !reservationExtrasId.includes(extra.id));
  }

  public onSelectedExtrasUpdate($event: ExtraModel[]) {
    this.selectedExtras = $event;
  }

}
