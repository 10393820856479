import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { IbeConfigService } from '../services/ibe-config.service';
import { GuestAuthService } from '../services/guest-auth.service';
import { IBE_STEPS, Step } from './step.type';
import { EventsService } from 'app/services/events.service';

@Component({
  selector: 'ibe-steps-bar',
  templateUrl: './steps-bar.component.html',
  styleUrls: ['./steps-bar.component.scss']
})
export class StepsBarComponent implements OnInit {
  private STEP_SEARCH_OFFER = {
    handle: IBE_STEPS.STEP_SEARCH_OFFER,
    title: this.translate.instant('steps_bar.search_accommodation'),
    number: 1,
    route: 'booking/search'
  };
  private STEP_RESULTS_OFFER = {
    handle: IBE_STEPS.STEP_RESULTS_OFFER,
    title: this.translate.instant('steps_bar.select_room_rate'),
    number: 2,
    route: 'booking/results'
  };
  private STEP_CHECKOUT_DETAILS = {
    handle: IBE_STEPS.STEP_CHECKOUT_DETAILS,
    title: this.translate.instant('steps_bar.checkout'),
    number: 3,
    route: 'checkout/details'
  };

  public steps: Step[] = [
    this.STEP_SEARCH_OFFER,
    this.STEP_RESULTS_OFFER,
    this.STEP_CHECKOUT_DETAILS
  ];
  public currentStep: Step | undefined;

  constructor(
    public readonly guestAuthService: GuestAuthService,
    public config: IbeConfigService,
    public route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public translate: TranslateService,
    private eventsService: EventsService
  ) {}

  public ngOnInit(): void {
    this.route.data.subscribe((data: Data): void => {
      this.currentStep = this.steps.find((step: Step): boolean => step.handle === data.step);
      this.eventsService.getStepEvent().next(this._mapStep());
    });
  }

  public isStepActive = (stepHandle: string): boolean => {
    if (this.currentStep) {
      return stepHandle === this.currentStep.handle;
    }
    return false;
  }

  public goToStep = (step: Step): void => {
    this.router.navigateByUrl(step.route);
  }

  public goToGuestManagement = (e: Event): void => {
    if (e) {
      e.preventDefault();
    }
    this.router.navigateByUrl('guest/manage');
  }

  private _mapStep = (): string => {
    if (this.currentStep) {
      return this.currentStep.handle;
    }
    return IBE_STEPS.STEP_SEARCH_OFFER;
  }
}
