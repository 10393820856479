<section class="ibe-guest-management ibe-guest-management-section">
  <ibe-guest-management-header type="option"></ibe-guest-management-header>
  <ibe-guest-management-sidebar currentRoute="ReservationHistory"></ibe-guest-management-sidebar>
  <section class="ibe-guest-management-bookings">
    <div class="ibe-guest-management-bookings-header">
      <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="file"><g id="lines"><line id="line-3" x1="15.549" y1="33.676" x2="32.451" y2="33.676" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/><line id="line-2" x1="15.549" y1="26.538" x2="32.451" y2="26.538" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/><line id="line-1" x1="15.549" y1="19.4" x2="32.451" y2="19.4" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/></g><path id="page" d="M39,41.9V11.863L31.451,5H10.211A1.16,1.16,0,0,0,9,6.1V41.9A1.16,1.16,0,0,0,10.211,43H37.789A1.16,1.16,0,0,0,39,41.9Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><polyline id="fold" points="30.287 5 30.287 12.92 39 12.92" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>
      <div>
        <h1>{{ 'guest_management.view_reservations_history' | translate }}</h1>
        <p>{{ 'guest_management.view_reservations_history_text' | translate }}</p>
      </div>
    </div>

    <ibe-reservations-list
      *ngIf="reservations.length"
      [reservations]="reservations"
      (fetchReservations)="getReservationHistory()"></ibe-reservations-list>

    <div *ngIf="isLoading">
      <ibe-loading-circle></ibe-loading-circle>
    </div>
  </section>
</section>
