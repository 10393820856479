import { Component, OnInit, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { ReplaySubject } from 'rxjs';

import { ToasterConfig } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { includes } from 'lodash';

import { fadeInOnEnterAnimation } from './animations';
import { IbeConfigService, SupportedLanguagesEnum } from './services/ibe-config.service';
import { ThemeService } from './services/theme.service';
import { GlobalWindowService } from './services/global-window.service';
import { AnalyticsService } from './services/analytics.service';
import { environment } from 'environments/environment';
import { Config } from 'up-ibe-types';

import { JourneyService } from './services/journey.service';
import { httpLoaderFactory } from './app.module';

@Component({
  selector: 'ibe-up',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeInOnEnterAnimation]
})
export class AppComponent implements OnInit {
  public configHasLoaded = false;
  public translationFileHasLoaded = false;
  public toasterConfig: ToasterConfig = new ToasterConfig({
    showCloseButton: false,
    tapToDismiss: true,
    timeout: 5000,
    animation: 'flyRight'
  });
  public ibeLoadError: string;

  constructor(
    private readonly translate: TranslateService,
    private element: ElementRef,
    private config: IbeConfigService,
    public themeService: ThemeService,
    public globalWindowService: GlobalWindowService,
    public analyticsService: AnalyticsService,
    public readonly httpClient: HttpClient,
    private readonly router: Router,
    private readonly journeyService: JourneyService
  ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');
  }

  public ngOnInit() {
    this.setIbeKey();
    this.journeyService.initiateJourney();
    this.loadConfig();
    this._setupPageViewAnalytics()
    this._setupGlobalRouterDispatch();
  }

  private setIbeKey() {
    const ibeKey = this.element.nativeElement.getAttribute('ibe-key');

    if (!ibeKey) {
      console.error('Missing ibe-key attribute. Did you forget to set it?');
    }

    this.config.ibeKey = ibeKey;
  }

  private loadConfig() {
    this.httpClient.get(environment.serverUrl + '/api/ibe/config')
      .subscribe((config: Config) => {
        this.config.setConfig(config);
        this.configHasLoaded = true;
        this._setupIbeLanguage();
        this._setupRedirect();
        this._setupDefaultPropertyId();
        this.config.initializeCurrentProperty();

        if (config.properties.length === 0) {
          this.ibeLoadError = `No properties have been enabled for this IBE yet.
          Please enable properties in the UP IBE Admin and try again.`;
        }

      }, (response) => {
        if (response.error.message) {
          this.ibeLoadError = response.error.message;
        }
      });
  }

  private _setupIbeLanguage() {
    // get language from language attribute on ibe widget code
    const languageAttributeValue = this.element.nativeElement.getAttribute('language');
    let language: SupportedLanguagesEnum;

    // check if a language has been set and if its supported
    if (languageAttributeValue && includes(environment.languages, languageAttributeValue)) {
      language = languageAttributeValue;
    } else {
      // otherwise use default language setting from ibe config
      language = this.config.settings.defaultLanguage as SupportedLanguagesEnum;
    }

    this.translate.use(language).subscribe((translationFile) => {
      if (this.config.accountFeatureWhitelist.useCustomTranslationFiles) {
        const path = environment.translationFilesUrl + '/customTranslationFiles/';
        httpLoaderFactory(this.httpClient, path).getTranslation(language)
          .subscribe((customTranslationFile) => this.translate.setTranslation(language, customTranslationFile));
      }
      this.translationFileHasLoaded = true
    });
    this.config.language = language;
  }

  private _setupRedirect() {
    const redirectTo = this.element.nativeElement.getAttribute('mask-redirect-path');
    if (redirectTo) {
      const origin = window.location.origin;
      this.config.redirectPath = `${origin}${redirectTo}`;
    }
  }

  private _setupDefaultPropertyId() {
    this.config.defaultPropertyId = this.element.nativeElement.getAttribute('default-property-id');
  }

  private _setupPageViewAnalytics() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const urlWithRemovedQueryParams = event.urlAfterRedirects.split('?')[0];

        this.analyticsService.defineOrGetDatalayer().push({
          'event': 'UP IBE Page View',
          'pageUrl': urlWithRemovedQueryParams
        });
      }
    });
  }

  // Globally dispatch route change events on
  // the window object. This is so webdevs can
  // change the rest of the page outside the IBE
  // depending on which step the visitor is viewing
  //
  // Required because it looks like angular hijacks
  // the html5 hashchange event and makes it unusable
  // outside of angular

  private _setupGlobalRouterDispatch() {
    const windowTime = 3000;
    window.ibeHashChange = new ReplaySubject(1, windowTime);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.ibeHashChange.next(event.url);
      }
    });
  }
}
