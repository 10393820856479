import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BookingService } from '../../../services/booking.service';
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from '../../../services/local-storage.service';
import { SaferpayPaymentDialogComponent } from './saferpay-payment-dialog.component';
import { interval, of } from 'rxjs';
import { catchError, concatMap, filter, take } from 'rxjs/operators';
import { CreateBookingResponseModel } from 'up-ibe-types';
import { BookingErrorDetails } from 'app/error-dialog/error-dialog.component';

@Component({
  selector: 'ibe-saferpay-payment',
  template: `<ibe-loading-bar [isLoading]="true"></ibe-loading-bar>`,
  styleUrls: ['./saferpay-payment.component.scss']
})
export class SaferpayPaymentComponent implements OnInit {
  @Input('paymentSetupData') public paymentSetupData: {
    ResponseHeader: {
      SpecVersion: string,
      RequestId: string
    },
    Token: string,
    Expiration: string,
    RedirectUrl: string
  };
  @Input('bookingRequestId') public bookingRequestId: string;
  @Input('paymentRedirected') public paymentRedirected = false;
  // tslint:disable-next-line:no-any
  @Output('onComplete') public onComplete: EventEmitter<CreateBookingResponseModel | BookingErrorDetails> = new EventEmitter();
  @Output('toggleIsLoading') public toggleIsLoading: EventEmitter<boolean> = new EventEmitter();
  @Output('onRedirectComplete') public onRedirectComplete: EventEmitter<string> = new EventEmitter();
  @Output('toggleInPaymentFlow') public toggleInPaymentFlow: EventEmitter<boolean> = new EventEmitter();
  @Output('togglePaymentRedirected') public togglePaymentRedirected: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public readonly dialog: MatDialog,
    public readonly localStorageService: LocalStorageService,
    private readonly bookingService: BookingService,
    private readonly http: HttpClient
  ) {}

  public ngOnInit() {
    if (!this.paymentSetupData && !this.paymentRedirected) {
      return console.error('No Saferpay redirect URL')
    }
    this._checkForPaymentCompletion();
    if (this.paymentRedirected && this._processPayload()) {
      return;
    }

    this._openPaymentDialog();
  }

  private _processPayload() {
    return this.localStorageService.getSaferpayToken();
  }

  private _openPaymentDialog() {
    this.toggleInPaymentFlow.emit(false);
    this.dialog.open(SaferpayPaymentDialogComponent, {
      data: {
        url: this.paymentSetupData.RedirectUrl
      },
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-dialog',
      disableClose: false
    });
    this.localStorageService.setSaferPayToken(this.paymentSetupData.Token);
    this.toggleIsLoading.emit(false);
  }

  private _checkForPaymentCompletion() {
    const inProg = this.bookingService.getBookingRequestInProgress();
    if (inProg && inProg.success) {
      this.onRedirectComplete.emit(this.bookingRequestId);
      return;
    }

    const waitTime = 2500;
    const httpParams = new HttpParams()
      .set('bookingRequestId', this.bookingRequestId);

    interval(waitTime).pipe(
      concatMap(() => this.http.get(environment.serverUrl + '/api/payment/saferpay/is-booking-complete', {
        params: httpParams
      })),
      filter((response: CreateBookingResponseModel) => !!(response)),
      take(1),
      catchError((error: BookingErrorDetails) => of(error))
    ).subscribe((response) => {
      if (this._isError(response)) {
        this.dialog.closeAll();
        this.onComplete.emit(response);
        return;
      }
      this._handlePaymentCompletionResponse(response);
    });
  }

  private _handlePaymentCompletionResponse(response: CreateBookingResponseModel) {
    let success = false;
    if (response) {
      success = response.success;
      this.dialog.closeAll();
      this.toggleInPaymentFlow.emit(true);
      this.onComplete.emit({
        success,
        bookingRequestId: response.bookingRequestId,
        pmsBookingIds: response.pmsBookingIds,
        message: response.message
      });
    }
    this.bookingService.setBookingRequestInProgress({success, id: this.bookingRequestId});
  }

  private _isError(response: CreateBookingResponseModel | BookingErrorDetails): response is BookingErrorDetails {
    return 'error' in response;
  }
}
