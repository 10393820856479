import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'dateFormat' })
export class DateFormatPipe implements PipeTransform {

  public transform(value: Date | moment.Moment, dateFormat: string, locale: string = 'en'): string {
    return moment(value).locale(locale).format(dateFormat);
  }
}
