import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { flatten, compact } from 'lodash';
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Subject, ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AvailabilityRestrictionsDialogComponent } from '../availability-restrictions-dialog/availability-restrictions-dialog.component';
import {
  CalendarDay,
  CalendarStayDateSelectionEvent,
  CalendarDayData,
  CheapestAvailabilityData,
  CalendarDayRestrictions,
  DayClass
} from './calendar';
import * as validate from 'validate.js';
import { uniqBy } from 'lodash';
import { IbeConfigService } from '../../../services/ibe-config.service';
import { Property } from 'up-ibe-types';

const dateRestrictionConstraints = {
  'minLengthOfStay': { numericality: {lessThanOrEqualTo: 0 }},
  'maxLengthOfStay': { numericality: {greaterThanOrEqualTo: 365 }}
};

@Component({
  selector: 'ibe-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  @Input('arrivalDate') public arrivalDate: Date | undefined;
  @Input('departureDate') public departureDate: Date | undefined;
  @Input('hoveredDepartureDate') public hoveredDepartureDate: Date | undefined;
  @Input('currentMonth') public currentMonth: moment.Moment;
  @Input('property') public property: Property | undefined;
  @Input('potentialAvailabilitySpanSubject') public potentialAvailabilitySpanSubject: ReplaySubject<CalendarDay[]>;
  @Output('onArrivalDateSelection') public onArrivalDateSelection: EventEmitter<Date> = new EventEmitter();
  @Output('onDepartureDateSelection') public onDepartureDateSelection: EventEmitter<Date> = new EventEmitter();
  @Output('onDepartureDateHover') public onDepartureDateHover: EventEmitter<Date> = new EventEmitter();
  @Output('onStayDateSelection') public onStayDateSelection: EventEmitter<CalendarStayDateSelectionEvent> = new EventEmitter();
  public calendarDates: (CalendarDay | undefined)[] = [[moment]] as unknown as CalendarDay[];
  public isLoading = false;
  private _request: Subscription;
  public fetchAvailability = new Subject<Date>();
  public smallestMinLengthOfStay: number;
  public biggestMaxLengthOfStay = 364;
  public restrictionSet: CalendarDayRestrictions[] = [];
  public potentialAvailabilitySpan: CalendarDay[] = [];
  public currentMonthDisplay: string;
  public numOfDaysWithRestrictions = 0;
  public numOfDaysLeftInMonth = 0;

  constructor(
    public readonly http: HttpClient,
    public readonly currentRoute: ActivatedRoute,
    private readonly toasterService: ToasterService,
    public readonly dialog: MatDialog,
    private readonly translate: TranslateService,
    public readonly config: IbeConfigService
  ) { }

  public ngOnInit() {
    if (this.currentMonth) {
      this.currentMonth = moment(this.currentMonth, 'YYYY-MM-DD');
    } else {
      this.currentMonth = moment();
    }

    this.currentMonthDisplay = this.currentMonth.locale(this.config.language).format('MMM YYYY');

    this._request = this._fetchAvailabilityData(this.currentMonth.toDate())
      .subscribe((cheapestAvailability: CheapestAvailabilityData) => {
        this.smallestMinLengthOfStay = cheapestAvailability.smallestMinLos || 0;
        this._generateCalendarDates(cheapestAvailability.dayData);
      });

    const debounceTimeDuration = 1000;
    this.fetchAvailability
      .pipe(debounceTime(debounceTimeDuration))
      .subscribe(() => {
        this._request.unsubscribe();
        this._request = this._fetchAvailabilityData(this.currentMonth.toDate())
          .subscribe((cheapestAvailability: CheapestAvailabilityData) => {
            this._generateCalendarDates(cheapestAvailability.dayData);
          });
      });
  }

  public selectPreviousMonth() {
    if (this.currentMonth.month() !== moment().month()) {
      this.currentMonth = moment(this.currentMonth).subtract(1, 'month');
      this.currentMonthDisplay = this.currentMonth.locale(this.config.language).format('MMM YYYY');
      this.isLoading = true;
      this.fetchAvailability.next();
    }
  }

  public selectNextMonth() {
    this.currentMonth = moment(this.currentMonth).add(1, 'month');
    this.currentMonthDisplay = this.currentMonth.locale(this.config.language).format('MMM YYYY');
    this.isLoading = true;
    this.fetchAvailability.next();
  }

  public onDaySelect(event: Event, day: CalendarDay) {
    if (this._isDaySelectable(day)) {
      if (this.config.settings.availabilityCalendarRestrictionsModalEnabled && day.hasRestrictions && this.departureDate) {
        return this.dialog.open(AvailabilityRestrictionsDialogComponent, {
          data: day.data.restrictions
        }).afterClosed()
          .subscribe((dialogResponse: boolean) => {
            if (dialogResponse) {
              return this._selectDay(day);
            }
            return false;
          })
      } else {
        return this._selectDay(day);
      }
    } else {
      return false;
    }
  }

  private _isDaySelectable(day: CalendarDay) {
    if (!day.data.isAvailable || !moment(day.date).isSameOrAfter(moment(), 'day')) {
      return false;
    }
    if (this.arrivalDate && !this.departureDate && day.data.restrictions?.closedOnDeparture) {
      return false;
    }
    if ((this.departureDate || !this.arrivalDate) && day.data.restrictions?.closedOnArrival) {
      return false;
    }
    return true;
  }

  private _selectDay(day: CalendarDay) {
    let daysArray = [];
    this.potentialAvailabilitySpanSubject.subscribe(response => this.potentialAvailabilitySpan = response);
    if (this.arrivalDate && this.departureDate) {
      this.smallestMinLengthOfStay = day.data.restrictions?.minLengthOfStay || 0;
      this.arrivalDate = day.date.toDate();
      this.departureDate = undefined;
    } else if (!this.arrivalDate || this._isDepartureSameOrBeforeArrival(day)) {
      this.arrivalDate = day.date.toDate();
    } else {
      if (moment(day.date).isBefore(moment(this.arrivalDate).add(this.smallestMinLengthOfStay, 'days'))) {
        return false;
      };
      daysArray = this.potentialAvailabilitySpan.concat(flatten(compact(this.calendarDates)));
      const arrivalIndex = daysArray.findIndex((date) => date?.date.isSame(moment(this.arrivalDate), 'day'));
      const departureIndex = daysArray.findIndex((date) => date?.date.isSame(day.date, 'day'));

      if (daysArray.slice(arrivalIndex, departureIndex).find(searchedDay => !searchedDay?.data.isAvailable)) {
        this.toasterService.pop('error',
        this.translate.instant('availability_calendar.calendar_error'),
        this.translate.instant('availability_calendar.unavailable_booking_period'));
        this.arrivalDate = undefined;
        return false;
      }

      this.departureDate = day.date.toDate();
    }

    daysArray = flatten(this.calendarDates);
    const arrival = daysArray.findIndex((date) => date?.date.isSame(moment(this.arrivalDate), 'day'));
    const lastDayOfMonth = daysArray.findIndex((date) => date?.date.isSame(moment(this.arrivalDate).endOf('month'), 'day'));

    this.onArrivalDateSelection.emit(this.arrivalDate);
    this.potentialAvailabilitySpanSubject.next(daysArray.slice(arrival, lastDayOfMonth + 1) as CalendarDay[]);
    this.onDepartureDateSelection.emit(this.departureDate);

    if (this.arrivalDate && this.departureDate) {
      this.onStayDateSelection.emit({
        arrivalDate: this.arrivalDate,
        departureDate: this.departureDate
      });
    }
    return true;
  }

  public onDayHover(event: Event, day: CalendarDay) {
    if (this.arrivalDate && !this.departureDate) {
      this.hoveredDepartureDate = day.date.toDate();
      this.onDepartureDateHover.emit(this.hoveredDepartureDate);
    }
  }

  public isArrivalDay(day: CalendarDay) {
    return (this.arrivalDate && moment(day.date).isSame(moment(this.arrivalDate), 'day'));
  }

  public isDepartureDay(day: CalendarDay) {
    return (this.departureDate && moment(day.date).isSame(moment(this.departureDate), 'day'));
  }

  public calendarDayClass(day: CalendarDay) {
    const classes: DayClass = {
      'ibe-calendar-day-active': moment(day.date).isSameOrAfter(moment(), 'day'),
      'ibe-no-availability': day.data && !day.data.isAvailable,
      'ibe-calendar-day-arrival-date': !!(this.arrivalDate && moment(day.date).isSame(moment(this.arrivalDate), 'day')),
      'ibe-calendar-day-departure-date': !!(this.departureDate && moment(day.date).isSame(moment(this.departureDate), 'day')),
      'ibe-calendar-day-stay-date': false,
      'ibe-calendar-day-invalid-date': moment(day.date).isBetween(
        moment(this.arrivalDate), moment(this.arrivalDate).add(this.smallestMinLengthOfStay, 'days'), 'days'
      ),
      'ibe-calendar-maintenance-day': !!(day.data.restrictions?.closedOnArrival && day.data.restrictions?.closedOnDeparture)
    };
    classes[moment(day.date).format('ddd') + '-' + moment(day.date).format('YYYY-MM-DD')] = true;

    if ((this.departureDate && moment(day.date).isBetween(moment(this.arrivalDate), moment(this.departureDate), 'day'))
     || (moment(day.date).isBetween(moment(this.arrivalDate), moment(this.hoveredDepartureDate), 'day'))) {
      classes['ibe-calendar-day-stay-date'] = true;
    }

    return classes;
  }

  private _isDepartureSameOrBeforeArrival(day: CalendarDay) {
    if (moment(day.date.toDate()).isSameOrBefore(this.arrivalDate, 'day')) {
      return true;
    }

    return false;
  }

  private _fetchAvailabilityData(date: Date) {
    this.isLoading = true;
    this.numOfDaysWithRestrictions = 0;
    this.numOfDaysLeftInMonth = 0;
    const queryParams = this.currentRoute.snapshot.queryParams;
    const propertyId = this.property ? this.property.pmsId : queryParams.propertyId;
    const children = queryParams.childrenAges ? queryParams.childrenAges.length : 0;

    const fromDate = moment(date).startOf('month').isBefore(moment()) ? moment() : moment(date).startOf('month');

    let params = new HttpParams()
      .set('from', fromDate.format('YYYY-MM-DD'))
      .set('to', moment(date).endOf('month').format('YYYY-MM-DD'))
      .set('propertyId', propertyId)
      .set('adults', queryParams.adults)
      .set('children', children.toString());

    if (queryParams.promoCode) {
      params = params.set('promoCode', queryParams.promoCode);
    }

    return this.http.get(environment.serverUrl + '/api/ibe/cheapest-availability-per-day', {
      params
    });
  }

  private _generateCalendarDates(calendarDayData: CalendarDayData[]) {
    this.numOfDaysLeftInMonth = calendarDayData.length;
    const localCurrentMonth: moment.Moment[] = Array(this.currentMonth.daysInMonth()).fill(undefined).map((day, index) => {
      return moment(this.currentMonth).date(index + 1);
    });

    const paddedMonth = [];
    // add empty entries to fill up everything before the first monday in the month
    let firstOfMonth = moment(localCurrentMonth[0]);
    while (firstOfMonth.day() !== 1) {
      paddedMonth.push(undefined);
      firstOfMonth = firstOfMonth.subtract(1, 'day');
    };
    // add the dates in the month with some supporting data
    paddedMonth.push(...localCurrentMonth.map(date => {
      const dayData = calendarDayData.find((data) => {
        return moment(date).isSame(moment(data.date), 'day');
      });

      const dayDate = moment(date.toISOString());
      let dateHasRestrictions = false;
      if (!dayData) {
        return {
          date: dayDate,
          data: {},
          hasRestrictions: dateHasRestrictions
        };
      } else {
        if (dayData.restrictions && !validate.isEmpty(dayData.restrictions) && validate(dayData.restrictions, dateRestrictionConstraints)) {
          if (this._dayHasLengthOfStayRestriction(dayData.restrictions)) {
            this.numOfDaysWithRestrictions++;
            this.restrictionSet.push(dayData.restrictions);
            dateHasRestrictions = true;
          }
          if (dayData.restrictions.maxLengthOfStay && dayData.restrictions.maxLengthOfStay > this.biggestMaxLengthOfStay) {
            dayData.restrictions.maxLengthOfStay = 0;
          }
        }
        if ((!dayData.isAvailable || !dayDate.isSameOrAfter(moment(), 'day'))) {
          dateHasRestrictions = false;
        }
      }
      return {
        date: dayDate,
        data: dayData,
        hasRestrictions: dateHasRestrictions
      }
    }));
    // split into 7's
    // tslint:disable-next-line:no-any
    const evenlySplitWeeks: any = [];
    const daysInWeekNumber = 7;
    paddedMonth.forEach((date, index) => {
      if (index % daysInWeekNumber === 0) {
        evenlySplitWeeks.push([]);
      };
      evenlySplitWeeks[evenlySplitWeeks.length - 1].push(date);
    });

    // pad the other way now
    const lastWeek = evenlySplitWeeks[evenlySplitWeeks.length - 1];
    while (lastWeek.length < daysInWeekNumber) {
      lastWeek.push(undefined);
    }
    this.restrictionSet = uniqBy(this.restrictionSet, (item) => {
      return item.maxLengthOfStay ? item.maxLengthOfStay : item.minLengthOfStay;
    });
    this.calendarDates = evenlySplitWeeks;
    this.isLoading = false;
  }

  public getRestrictionText(key: keyof CalendarDayRestrictions) {
    let restrictionText = '';
    if (key === 'minLengthOfStay') {
      restrictionText = restrictionText + this.translate.instant(`availability_calendar.restrictions.min_length_of_stay`,
        {minLengthOfStay: this.restrictionSet[0].minLengthOfStay}
      );
    }
    if (key === 'maxLengthOfStay') {
      restrictionText = restrictionText + this.translate.instant(`availability_calendar.restrictions.max_length_of_stay`,
        {maxLengthOfStay: this.restrictionSet[0].maxLengthOfStay}
      );
    }
    return restrictionText;
  }

  public someDayHasRestriction(key: keyof CalendarDayRestrictions) {
    return flatten(this.calendarDates).find(day => day?.data?.restrictions?.[key]);
  }

  private _dayHasLengthOfStayRestriction(restrictions: CalendarDayRestrictions): boolean {
    return !!(restrictions.minLengthOfStay && restrictions.minLengthOfStay > 1)
      || !!(restrictions.maxLengthOfStay && restrictions.maxLengthOfStay > 0);
  }
// tslint:disable-next-line: max-file-line-count
}
