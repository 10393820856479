import { Component, OnInit, Input } from '@angular/core';
import { ReservationModel } from 'up-ibe-types';
import { ReservationService } from '../../services/reservation.service';
import { ReservationStatusEnum } from '../../../enums';

@Component({
  selector: 'ibe-reservations-history',
  templateUrl: './reservations-history.component.html',
  styleUrls: ['./reservations-history.component.scss']
})
export class ReservationHistoryComponent implements OnInit {
  public isLoading = false;
  @Input('reservations') public reservations: ReservationModel[] = [];

  constructor(
    public readonly reservationService: ReservationService
  ) { }

  public ngOnInit() {
    this.getReservationHistory();
  }

  public getReservationHistory() {
    this.isLoading = true;
    this.reservationService.getGuestReservations()
      .subscribe((reservations: ReservationModel[]) => {
        if (reservations.length) {
          this.reservations = this.reservationService.filterReservationsByStatuses(reservations, [
            ReservationStatusEnum.Canceled,
            ReservationStatusEnum.CheckedOut,
            ReservationStatusEnum.NoShow
          ]);
        } else {
          this.reservations = [];
        }
        this.isLoading = false;
      });
  }
}
