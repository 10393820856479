import { Component, OnInit } from '@angular/core';
import { fadeInOnEnterAnimation, scaleUpAnimation } from '../../animations';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AnimationEvent } from '@angular/animations';
import { BookingService } from 'app/services/booking.service';
import { ToasterService } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { GuestAuthService } from 'app/services/guest-auth.service';
import { finalize } from 'rxjs/operators';
import { IbeConfigService } from 'app/services/ibe-config.service';
import { Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ibe-checkout-details',
  templateUrl: './checkout-details.component.html',
  styleUrls: ['./checkout-details.component.scss'],
  animations: [
    fadeInOnEnterAnimation,
    scaleUpAnimation
  ]
})
export class CheckoutDetailsComponent implements OnInit {
  public detailsForm: FormGroup;
  public animationState: string;
  public isCreateAccountLoading = false;
  public isSubmitting = false;
  public showCreateAccountButton = false;
  public showCreateAccountBox = (
    this.config.settings.guestManagementEnabled
    && this.config.settings.checkoutFields.details.password.activated
  );
  public submit: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  private licensePlate: string;

  constructor (
    public translate: TranslateService,
    public config: IbeConfigService,
    public router: Router,
    private formBuilder: FormBuilder,
    private bookingService: BookingService,
    private toasterService: ToasterService,
    private guestAuthService: GuestAuthService,
    private readonly http: HttpClient
  ) {}

  public ngOnInit() {
    this.detailsForm = this.formBuilder.group({});
  }

  public addFormControl(name: string, formGroup: FormGroup) {
    this.detailsForm.addControl(name, formGroup);
  }

  public onFormSubmit(event: Event) {
    this.submit.next(true);
    event.preventDefault();
    if (this.detailsForm.valid) {
      if (this.config.accountFeatureWhitelist.licensePlateField) {
        this.detailsForm.get('details.guestComment')?.setValue(`${this.detailsForm.get('details.guestComment')?.value} /PI ${this.licensePlate}`);
      }
      if (this.bookingService.addDetailsToReservation((this.detailsForm.get('details') as FormGroup).getRawValue())) {
        this.toasterService.pop('success',
          this.translate.instant('checkout_details.details_added'),
          this.translate.instant('checkout_details.details_has_been_added')
        );
        this.animationState = 'completed';
      }
      if (
        this.config.settings.interestsEnabled
        && this.guestAuthService.isLoggedIn()
        && this.detailsForm.get('details.interests')
      ) {
        const pmsGuestId = this.guestAuthService.getPmsGuestId();
        const params = new HttpParams().set('pmsGuestId', pmsGuestId);
        this.http.put(`${environment.serverUrl}/api/ibe/guest/update-interests`, {
          interests: this.detailsForm.get('details.interests')?.value
        }, { params }).subscribe((): void => {}, () => {
          this.toasterService.pop('error', this.translate.instant('error_updating_guest_interests'));
        });
      }
    }
  }

  public toggleSubmit() {
    this.isSubmitting = !this.isSubmitting;
  }

  public createGuestAccount(event: Event) {
    event.preventDefault();
    this.isCreateAccountLoading = true;
    this.toggleSubmit();
    return this.guestAuthService.createGuest({
      username: this.detailsForm.controls['details'].value.email,
      password: this.detailsForm.controls['details'].value.password,
      firstName: this.detailsForm.controls['details'].value.firstName,
      lastName: this.detailsForm.controls['details'].value.lastName,
      title: this.detailsForm.controls['details'].value.title,
      phone: this.detailsForm.controls['details'].value.phone,
      marketingConsent: this.detailsForm.controls['details'].value.marketingConsent
    })
    .pipe(finalize(() => this.toggleSubmit()))
    .subscribe((response) => {
      this.toasterService.pop('success',
        this.translate.instant('global.success'),
        this.translate.instant('guest_management_auth.create_successful')
      );
      this.isCreateAccountLoading = false;
      this.showCreateAccountButton = false;
      this.showCreateAccountBox = false;
    }, (response) => {
      this.isCreateAccountLoading = false;
      this.toasterService.pop('error',
        this.translate.instant('guest_management_auth.create_failed'),
        response.error.message
      );
    });
  }

  public scaleUpAnimationComplete($event: AnimationEvent) {
    if ($event.toState === 'completed') {
      this.router.navigate(['checkout/address']);
    }
  }

  public toggleShowCreateAccountButton(value: boolean) {
    this.showCreateAccountButton = value;
  }

  public updateLicensePlateNumber(value: string) {
    this.licensePlate = value;
  }
}
