import { Component, Input, Output, EventEmitter, HostListener, ElementRef, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { IbeConfigService } from '../../../services/ibe-config.service';
export interface PersonsQtyData {
  adults: number,
  childrenAges: number[]
}

@Component({
  selector: 'ibe-persons-qty-popup',
  templateUrl: './persons-qty-popup.component.html',
  styleUrls: ['./persons-qty-popup.component.scss']
})
export class PersonsQtyPopupComponent implements OnInit {
  public childrenQty: number[] = [];
  @Input('personsQtyData') public personsQtyData: PersonsQtyData;
  @Output('onValueChange') public onValueChange: EventEmitter<PersonsQtyData> = new EventEmitter();
  @Output('closePopup') public closePopup: EventEmitter<undefined> = new EventEmitter();

  constructor(
    private readonly _elementRef: ElementRef,
    public readonly config: IbeConfigService
  ) {}

  public ngOnInit() {
    if (
      this.personsQtyData.childrenAges.length &&
      this.config.settings.childrenOptionEnabled
    ) {
      this.childrenQty = _.times(this.personsQtyData.childrenAges.length, (number) => {
        return number;
      });
    }
  }

  public onAdultQtyChange(qty: number) {
    this.personsQtyData.adults = qty;
    this.onValueChange.emit(this.personsQtyData);
  }

  public onChildrenQtyChange(qty: number) {
    if (qty < this.childrenQty.length) {
      this.childrenQty.pop();
      this.personsQtyData.childrenAges.pop();
    }

    if (qty > this.childrenQty.length) {
      this.childrenQty.push(0);
      this.personsQtyData.childrenAges.push(0);
    }

    this.onValueChange.emit(this.personsQtyData);
  }

  public onChildrenAgeChange(index: number, value: string) {
    this.personsQtyData.childrenAges[index] = Number(value);
    this.onValueChange.emit(this.personsQtyData);
  }

  public generateChildrenAgeOptions() {
    const maxChildrenAge = 17;
    return _.times(maxChildrenAge, (number) => {
      return number + 1;
    })
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    const parentElement = targetElement.parentElement;

    if (
      !targetElement ||
      targetElement.id === 'personsQtyField' ||
      (parentElement && parentElement.id === 'personsQtyField')
    ) {
        return;
    }

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.closePopup.emit();
    }
  }
}
