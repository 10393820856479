import { Injectable } from '@angular/core';
import { Config, Property, Settings, FeatureWhitelist } from 'up-ibe-types';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

interface PropertySearchType { pmsId: string; }

interface PaymentProviderSettings {
  idealEnabled: boolean;
  paypalEnabled: boolean;
  testModeEnabled: boolean;
}

export type Language = string;

type ConfigWithAmex = Config & { accountAmexEnabled?: boolean };

export type SupportedLanguagesEnum = 'en' | 'de' | 'fr' | 'ru' | 'sv' | 'fi' | 'nl' | 'es';

@Injectable()
export class IbeConfigService {
  public ibeKey: string;
  public language: SupportedLanguagesEnum;
  public redirectPath?: string;
  public defaultPropertyId: string;
  public pmsProvider: string;
  public accountPaymentProvider: string;
  public settings: Settings & { forceCommercialPurpose: boolean, highDemandTime: boolean } = {
    forceCommercialPurpose: false,
    defaultAddressCountryCode: '',
    defaultLanguage: 'en',
    paymentStepEnabled: true,
    termsAndConditionsUrls: {
      en: '',
      de: '',
      fr: '',
      ru: '',
      sv: '',
      fi: '',
      nl: '',
      es: ''
    },
    primaryColour: '',
    advancedPropertySelectorEnabled: false,
    displayPropertiesByRegionEnabled: false,
    childrenOptionEnabled: false,
    availabilityCalendarEnabled: false,
    onlyShowPromoCodeRatesEnabled: false,
    maxNumberOfAdults: 10,
    maxNumberOfChildren: 10,
    numberOfAdultsDefault: 1,
    showCityTaxEstimateEnabled: false,
    showServiceChargeEnabled: true,
    startDateEnabled: false,
    startDate: new Date(),
    roomQtySelectorLimit: 10,
    guestManagementEnabled: false,
    interestsEnabled: false,
    sendGuestEmailsEnabled: false,
    checkoutFields: {
      details: {
        title: {
          activated: true,
          required: true
        },
        firstName: {
          activated: true,
          required: true
        },
        lastName: {
          activated: true,
          required: true
        },
        phone: {
          activated: true,
          required: true
        },
        email: {
          activated: true,
          required: true
        },
        password: {
          activated: true,
          required: false
        },
        travelPurpose: {
          activated: true,
          required: true
        },
        companyName: {
          activated: true,
          required: true
        },
        companyTaxId: {
          activated: true,
          required: true
        },
        companyStreet: {
          activated: true,
          required: true
        },
        companyCity: {
          activated: true,
          required: true
        },
        companyPostalCode: {
          activated: true,
          required: true
        },
        companyCountryCode: {
          activated: true,
          required: true
        },
        companyState: {
          activated: true,
          required: true
        },
        guestComment: {
          activated: true,
          required: false
        }
      },
      address: {
        street: {
          activated: true,
          required: true
        },
        city: {
          activated: true,
          required: true
        },
        postalCode: {
          activated: true,
          required: true
        },
        countryCode: {
          activated: true,
          required: true
        },
        state: {
          activated: false,
          required: false
        },
        marketingConsent: {
          activated: true,
          required: false
        }
      }
    },
    autoscrollEnabled: true,
    availabilityCalendarRestrictionsModalEnabled: true,
    highDemandTime: false,
    predefinedCommentBubbles: {
      en: [],
      de: [],
      es: [],
      fi: [],
      fr: [],
      it: [],
      nl: [],
      ru: [],
      sv: []
    },
    createAccountText: {
      en: '',
      de: '',
      fr: '',
      ru: '',
      sv: '',
      fi: '',
      nl: '',
      es: '',
      it: ''
    }
  };
  public accountPaypalEnabled = false;
  public accountIdealEnabled = false;
  public accountAmexEnabled = false;
  public accountTestModeEnabled = false;
  public accountFeatureWhitelist: FeatureWhitelist;
  private _properties: Property[];
  private _currentProperty: Property | undefined;

  /**
   * This was introduced so that different components can keep track of the current property
   * when other components change it. It should always be the same value as the original
   * _currentProperty property as the setCurrentProperty() method handles both. Going
   * forward, we should be using the getCurrentPropertySubscribable() method to access the
   * current property, and we should refactor code that uses the original getter to use the
   * new one where possible. This should result in us being able to remove all usage of
   * _currentProperty eventually.
   */
  private _currentPropertySubscribable: BehaviorSubject<Property | undefined> = new BehaviorSubject <Property | undefined>(undefined);

  get properties(): Property[] {
    return this._properties;
  }

  set properties(properties: Property[]) {
    this._properties = properties;
  }

  public setConfig(config: ConfigWithAmex) {
    this.settings = { ...this.settings, ...config.settings };
    this.properties = config.properties;
    this.pmsProvider = config.pmsProvider;
    this.accountPaymentProvider = config.accountPaymentProvider;
    this.accountIdealEnabled = config.accountIdealEnabled;
    this.accountAmexEnabled = config.accountAmexEnabled || false;
    this.accountPaypalEnabled = config.accountPaypalEnabled;
    this.accountTestModeEnabled = config.accountTestModeEnabled;
    this.accountFeatureWhitelist = config.accountFeatureWhitelist || {};
    if (moment(this.settings.startDate).isBefore(new Date())) { this.settings.startDate = new Date() };
  }

  public get isChildrenEnabled() {
    return this.settings.childrenOptionEnabled;
  }

  public initializeCurrentProperty(): void {

    const property = this.findPropertyById(this.defaultPropertyId);

    if (property !== undefined) {
      this._currentPropertySubscribable = new BehaviorSubject<Property>(property);
    }

  }

  public isPmsApaleo() {
    return (this.pmsProvider === 'APALEO');
  }

  public isPmsStayntouch() {
    return (this.pmsProvider === 'STAYNTOUCH');
  }

  public isPmsAcProject() {
    return (this.pmsProvider === 'ACPROJECT');
  }

  public isMultiReservationsSupported() {
    /*
      Always true for now as apaleo, SnT & AC PMS's now support multi reservations via IBE Server.
      This could change in the future if a new PMS is implemented.
    */
    return true;
  }

  public findPropertyById(id: string): Property | undefined {
    const predicate = (property: PropertySearchType) => property.pmsId === id;
    return this.properties.find(predicate);
  }

  public getStartDate() {
    if (this.settings.startDate && moment(this.settings.startDate).isAfter(moment())) {
      return this.settings.startDate;
    }
    return new Date();
  }

  public setCurrentProperty(id: string) {
    const property = this.findPropertyById(id);
    this._currentProperty = property;

    localStorage.setItem(`upibe_${this.ibeKey}_property`, JSON.stringify(property));

    if (property !== undefined) {
      this._currentPropertySubscribable.next(property);
    }
  }

  /**
   * Where necessary, try to avoid using this and instead opt for using the getCurrentPropertySubscribable()
   * method instead.
   */
  public get currentProperty(): Property {
    if (!this._currentProperty) {
      const existingProperty = localStorage.getItem(`upibe_${this.ibeKey}_property`);

      if (existingProperty) {
        return JSON.parse(existingProperty);
      }

      throw new Error('Current Property was not set, set it using this.setCurrentProperty');
    } else {
      return this._currentProperty;
    }
  }

  public getCurrentPropertySubscribable(): Observable<Property | undefined> {
    return this._currentPropertySubscribable.asObservable();
  }

  public getPaymentProvider() {
    if (this.accountPaymentProvider === 'none' || !this.accountPaymentProvider) {
      return this.currentProperty.paymentProvider;
    } else {
      return this.accountPaymentProvider;
    }
  }

  public getPaymentProviderSettings(): PaymentProviderSettings {
    return {
      idealEnabled: !!(this.accountIdealEnabled || this.currentProperty.paymentProviderSettings.idealEnabled),
      paypalEnabled: !!(this.accountPaypalEnabled || this.currentProperty.paymentProviderSettings.paypalEnabled),
      testModeEnabled: !!(this.accountTestModeEnabled || this.currentProperty.paymentProviderSettings.testModeEnabled)
    }
  }
// tslint:disable-next-line: max-file-line-count
}
