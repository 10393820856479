// tslint:disable:max-file-line-count
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToasterService } from 'angular2-toaster';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { IbeConfigService } from './ibe-config.service';
import { ErrorDialogService } from './error-dialog.service';
import { LocalStorageService } from './local-storage.service';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { DetailsData } from '../guest-form/types';
import { AddressData } from '../guest-form/types';
import {
  BookingModel,
  StayCriteriaModel,
  ReservationModel,
  CreateBookingRequestResponseModel,
  CreateBookingResponseModel,
  ExtraModel,
  TravelPurpose,
  ReservationDiffModel,
  AvailabilityResultModel
} from 'up-ibe-types';
import { calculateBookingTotals, calculateExtrasTotals } from '../helpers/booking.helper';
import { Cart } from './booking-service/cart';
import { JourneyService } from './journey.service';
import { ErrorSource } from 'app/error-dialog/error-mapping';

@Injectable()
export class BookingService {
  public booking: BookingModel;

  constructor(
    private readonly toasterService: ToasterService,
    private readonly translate: TranslateService,
    private readonly http: HttpClient,
    private readonly config: IbeConfigService,
    private readonly dialog: MatDialog,
    private readonly errorDialogService: ErrorDialogService,
    private readonly localStorageService: LocalStorageService,
    private readonly journeyService: JourneyService
  ) {
    this.loadBookingData();
  }

  private loadBookingData() {
    const data = this.localStorageService.getBookingData();

    if (data) {
      this.booking = data;
      this.modifyReservationDataIfInvalid();
    } else {
      this.booking = this._getBlankBooking();
    }
  }

  private saveBookingToLocalStorage() {
    this.localStorageService.setBookingData(this.booking);
  }

  public get bookingCurrency() {
    if (this.booking.reservations.length) {
      return this.booking.reservations[0].totalGrossAmount.currency;
    }

    // not sure why it's "false" but I'm not confident enough to change it
    return false;
  }

  // Not technically a reservation model, but it's the closest thing to what it actually is without
  // making a new type that's essentially identical like in the backend
  public mergeReservationsWithPmsData(pmsData: ReservationDiffModel | undefined) {
    this.booking.reservations = this.booking.reservations
    .map(reservation => {
      if (pmsData?.ratePlanToChange && reservation.ratePlan.id === pmsData?.ratePlanToChange.id) {
        return {
          ...reservation,
          ...pmsData,
          ratePlanToChange: undefined
        };
      }
      return reservation;
    });
    this.booking.reservations = this.booking.reservations;
    return this.localStorageService.setBookingData(this.booking);
  }

  public saveLastSearchedStayCriteria(stayCriteria: StayCriteriaModel) {
    return this.localStorageService.setLastSearchedStayCriteria(stayCriteria);
  }

  public getLastSearchedStayCriteria() {
    return this.localStorageService.getLastSearchedStayCriteria();
  }

  public saveAvailabilityResult(availabilityResult: AvailabilityResultModel[]) {
    return this.localStorageService.setAvailabilityResultData(availabilityResult);
  }

  public addReservationToBooking(
    cart: Cart,
    selectedQty: number
  ) {
    const reservation = cart.getReservation();
    const availableUnits = cart.availableUnits();

    if (!this._canReservationBeAdded(reservation)) {
      return false;
    }

    if (!this.config.isMultiReservationsSupported()) {
      this.booking.reservations = [{ ...reservation }];
      return true;
    }

    for (let i = 0; i < selectedQty; i += 1) {
      const sameUnitTypeInBasketCount = this.booking.reservations
        .filter(item => {
          return item.ratePlan.id === reservation.ratePlan.id && item.unitType.id === reservation.unitType.id
        }).length;

      if (availableUnits > sameUnitTypeInBasketCount) {
        this.booking.reservations.push({ ...reservation });
        this.saveBookingToLocalStorage();
      } else {
        const errorSource = ErrorSource[this.config.pmsProvider.toUpperCase() as keyof typeof ErrorSource];
        this.errorDialogService.errorDialog('Unable to add', errorSource);

        return false;
      }
    };

    return true;
  }

  public removeReservationFromBooking(reservationKey: number) {
    this.booking.reservations.splice(reservationKey, 1);
    this.saveBookingToLocalStorage();
    return true;
  }

  public removeReservationFromBookingById(reservationId: string | undefined) {
    const reservationIndex = this.booking.reservations.findIndex(reservation => reservation.id === reservationId);
    if (reservationIndex === -1) {
      return false;
    }
    this.booking.reservations.splice(reservationIndex, 1);
    this.saveBookingToLocalStorage();
    return true;
  }

  public removeAllReservations() {
    this.booking.reservations = [];
    this.saveBookingToLocalStorage();
  }

  public saveExtrasToReservation(reservationKey: number, extras: ExtraModel[]) {
    this.booking.reservations[reservationKey].extras = extras;
    this.saveBookingToLocalStorage();
    return true;
  }

  public removeExtraFromReservation(reservationKey: number, extraId: string) {
    this.booking.reservations[reservationKey].extras = this.booking.reservations[reservationKey].extras
      .filter((element) => {
        return element.id !== extraId;
      });
    this.saveBookingToLocalStorage();
    return true;
  }

  public addDetailsToReservation(data: DetailsData) {
    this.booking.booker.title = data.title;
    this.booking.booker.firstName = data.firstName;
    this.booking.booker.lastName = data.lastName;
    this.booking.booker.email = data.email;
    this.booking.booker.phone = data.phone;
    // this.booking.booker = data;
    this.booking.booker.preferredLanguage = this.config.language;

    if (data.guestComment) {
      if (!data.guestCommentBubbles) {
        data.guestCommentBubbles = [data.guestComment];
      } else {
        data.guestCommentBubbles.push(data.guestComment);
      }
    }
    if (data.guestCommentBubbles) {
      this.booking.bookerComment = data.guestCommentBubbles.join(', ');
    }

    if (data.travelPurpose) {
      this.booking.reservations = this.booking.reservations.map((reservation) => {
        return {
          ...reservation,
          travelPurpose: data.travelPurpose as TravelPurpose
        }
      })

      if (data.company) {
        let companyTaxId = '';
        if (data.company.companyTaxId) {
          companyTaxId = data.company.companyTaxId;
        }
        this.booking.booker.company = {
          companyTaxId,
          ...data.company
        };
      }
    }

    this.saveBookingToLocalStorage();
    return true;
  }

  public addAddressToReservation(data: AddressData) {
    this.booking.booker.address = data;
    this.saveBookingToLocalStorage();
    return true;
  }

  public addPmsGuestIdToReservation(pmsGuestId: string) {
    this.booking.booker.pmsGuestId = pmsGuestId;
    this.saveBookingToLocalStorage();
    return true;
  }

  public removePmsGuestIdFromReservation() {
    delete this.booking.booker.pmsGuestId;
    this.saveBookingToLocalStorage();
    return true;
  }

  public modifyReservationDataIfInvalid() {
    // remove reservations if arrival date is in the past
    const lengthBeforeFilter = this.booking.reservations.length;
    this.booking.reservations = this.booking.reservations
      .filter(reservation => !moment().isAfter(reservation.arrival, 'day'));
    const lengthAfterFilter = this.booking.reservations.length;
    this.saveBookingToLocalStorage();
    this.translate.get('checkout_payment.offers_expired_title').subscribe((translated: string) => {
      if (lengthBeforeFilter > lengthAfterFilter) {
        this.dialog.open(AlertDialogComponent, {
          data: {
            title: translated,
            message: this.translate.instant('checkout_payment.offers_expired_message')
          }
        });
        if (!lengthAfterFilter) {
          this.clearBookingDataFromLocalStorage();
        }
      };
    });
  }

  public setBookingRequestInProgress(data: {success: boolean; id: string}) {
    return this.localStorageService.setBookingRequestInProgress(data);
  }

  public getBookingRequestInProgress() {
    return this.localStorageService.getBookingRequestInProgress();
  }

  public clearBookingRequestInProgress() {
    this.localStorageService.removeSaferPayToken();
    this.localStorageService.removeBookingRequestInProgress();
  }

  public clearBookingDataFromLocalStorage() {
    this.localStorageService.removeSaferPayToken();
    this.localStorageService.removeBookingRequestId();
    this.localStorageService.removeBookingData();
    this.localStorageService.removeBookingRequestInProgress();
    this.localStorageService.removeAdyenTransactionId();
    this.booking = this._getBlankBooking();
  }

  public clearSearchCriteriaFromLocalStorage() {
    return this.localStorageService.removeLastSearchedStayCriteria();
  };

  /**
   * Throughout the booking process some data is attached to the booking in localstorage for easy
   * access and to avoid a lot of processing in the IBE. This method removes that data. This is
   * necessary when the booking data in localstorage is going to be passed as request data.
   * Although PMS' currently ignore the additional data attached to the booking, they may not in
   * the future so this is beneficial as a precaution.
   */
  private removeUnrequiredRequestDataFromBooking(booking: BookingModel): BookingModel {
    const updatedBooking = booking;

    updatedBooking.reservations = updatedBooking.reservations.map((reservation: ReservationModel) => {
      const { totalBaseAmount, ...reservationWithoutTotalBaseAmount } = reservation;
      const { includedExtras, ...reservationWithoutUnrequiredData } = reservationWithoutTotalBaseAmount;
      return reservationWithoutUnrequiredData;
    });

    return updatedBooking;
  }

  public createBookingRequest() {
    return this.http.post(environment.serverUrl + '/api/ibe/create-booking-request', {
      booking: this.removeUnrequiredRequestDataFromBooking(this.booking),
      journeyToken: this.journeyService.getJourneyToken(),
      ibeLanguage: this.config.language,
      ibeUrl: `${window.location.protocol}//${window.location.host}${window.location.pathname}`
    });
  }

  public populateBookingErrorMessage(pmsData: ReservationDiffModel | undefined) {
    const reservation = (this.booking.reservations.find(
      item => pmsData?.ratePlanToChange && pmsData?.ratePlanToChange.id === item?.ratePlan.id
    ));

    const priceToDisplay = pmsData?.totalGrossAmount ? pmsData?.totalGrossAmount.amount : reservation?.totalGrossAmount.amount;

    const ratePlanToDisplay = pmsData?.ratePlan || reservation?.ratePlan;

    return {
      price: `${priceToDisplay} ${reservation?.totalGrossAmount.currency}`,
      ratePlan: ratePlanToDisplay?.name,
      description: ratePlanToDisplay?.pmsDescription
    }
  }

  public saveBookingRequestIdToLocalStorage(response: CreateBookingRequestResponseModel) {
    return this.localStorageService.setBookingRequestId(response.bookingRequestId);
  }

  public getBookingRequestId() {
    return this.localStorageService.getBookingRequestId();
  }

  /**
   * Be careful: Only leave paymentData undefined if you want
   * to make a booking with no payment.
   */
  // tslint:disable-next-line:no-any
  public completeBooking(paymentData: any|undefined, bookingRequestId: string) {
    return this.http.post(environment.serverUrl + '/api/ibe/complete-booking', {
      bookingRequestId,
      paymentData
    }).pipe(tap((response: CreateBookingResponseModel) => {}));
  }

  public calculateBookingTotals(reservations: ReservationModel[], displayInclusiveExtrasAsTaxes: boolean = false) {
    return calculateBookingTotals(reservations, displayInclusiveExtrasAsTaxes);
  }

  public calculateExtrasTotals(extras: ExtraModel[]) {
    return calculateExtrasTotals(extras);
  }

  public translateReservationStatus(status: string) {
    switch (status) {
      case 'Confirmed':
        return this.translate.instant('manage_booking.reservation_statuses.confirmed');
      case 'Canceled':
        return this.translate.instant('manage_booking.reservation_statuses.canceled');
      case 'CheckedOut':
        return this.translate.instant('manage_booking.reservation_statuses.checked_out');
      case 'InHouse':
        return this.translate.instant('manage_booking.reservation_statuses.in_house');
      case 'NoShow':
        return this.translate.instant('manage_booking.reservation_statuses.no_show');
      default:
        return status;
    }
  }

  public calculateNumberOfNights(arrivalDate: Date | string, departureDate: Date | string) {
    const arrival = moment(arrivalDate);
    const departure = moment(departureDate);
    return departure.diff(arrival, 'days');
  }

  private _canReservationBeAdded(reservation: ReservationModel) {
    const hasDifferentPropertyId = (item: ReservationModel) => {
      return item.property.id !== reservation.property.id;
    };

    if (this.booking.reservations.some(hasDifferentPropertyId)) {
      this.toasterService.pop('error',
        this.translate.instant('room_results.cannot_add_reservation'),
        this.translate.instant('room_results.reservations_in_cart_are_for_different_property')
      );

      return false;
    }

    if (this.booking.reservations.length && this.bookingCurrency !== reservation.totalGrossAmount.currency) {
      const errorMesg = this.translate.instant('room_results.reservations_in_cart_are_for_different_currency');
      this.toasterService.pop('error',
        this.translate.instant('room_results.cannot_add_reservation'),
        errorMesg + ' (' + this.bookingCurrency + ')');
      return false;
    }

    return true;
  }

  public bookerDetailsHaveBeenAdded(): boolean {

    if (
      this.booking.booker.title !== undefined ||
      this.booking.booker.firstName ||
      this.booking.booker.lastName ||
      this.booking.booker.email ||
      this.booking.booker.phone
    ) {
      return true;
    }

    return false;
  }

  public updateBookingOnLogout() {
    const reservationsWithMemberRates = this.booking.reservations.filter(reservation => reservation.ratePlan.isMemberRate);

    if (reservationsWithMemberRates) {
      reservationsWithMemberRates.forEach(reservation => {
        this.removeReservationFromBookingById(reservation.id);
      });
      this.saveBookingToLocalStorage();
      this.toasterService.pop(
        'success',
        this.translate.instant('cart.cart_updated'),
        this.translate.instant('cart.member_rates_cleared')
      );
    }
    return true;
  }

  private _getBlankBooking(): BookingModel {
    return  {
      booker: {
        title: undefined,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        preferredLanguage: 'en',
        address: {
          addressLine1: '',
          city: '',
          postalCode: '',
          countryCode: this.config.settings.defaultAddressCountryCode
        }
      },
      reservations: [],
      bookerComment: ''
    };
  }
}

export { Cart }
