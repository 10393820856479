import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ibe-guest-management',
  templateUrl: './guest-management.component.html',
  styleUrls: ['./guest-management.component.scss']
})
export class GuestManagementComponent implements OnInit {
  public isBrowserIE = false;

  public ngOnInit() {
    if (window.navigator.userAgent.includes('Trident')) {
      this.isBrowserIE = true;
    }
  }
}
