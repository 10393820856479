import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BookingService } from 'app/services/booking.service';
import { environment } from '../../environments/environment';

enum LogType {
  return = 'RETURN',
  redirect = 'REDIRECT',
  exit = 'EXIT'
}

export interface ComponentCanDeactivate {
  canDeactivate(): boolean | Observable<boolean>;
}

@Injectable()
export class LogGuestMovementGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(
    private readonly http: HttpClient,
    private readonly bookingService: BookingService
  ) {}

  public canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate() ?
      true :
      this.logAndConfirmExit()
  }

  public logGuestRedirect(paymentProvider: string) {
    this.http.post(`${environment.serverUrl}/api/ibe/notify`, {
      bookingRequestId: this.bookingService.getBookingRequestId(),
      paymentProvider,
      logType: LogType.redirect
    }).subscribe();
  }

  public logAndConfirmExit() {
    this.http.post(`${environment.serverUrl}/api/ibe/notify`, {
      bookingRequestId: this.bookingService.getBookingRequestId(),
      logType: LogType.exit
    }).subscribe();

    return confirm('Are you sure you want to leave? If you quit now, your booking may still be completed');
  }

  // Use this so we can see if Guests do actually come back from 3DS when they complain of failures
  public logGuestReturn() {
    this.http.post(`${environment.serverUrl}/api/ibe/notify`, {
      bookingRequestId: this.bookingService.getBookingRequestId(),
      logType: LogType.return
    }).subscribe();
  }
}
