import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToasterService } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { BookingService } from '../services/booking.service';
import { RemovalModalComponent } from '../booking/cart/removal-modal/removal-modal.component';
import { ImagesService } from '../services/images.service';
import { AnalyticsService } from '../services/analytics.service';
import { IbeConfigService } from '../services/ibe-config.service';
import { ReservationModel, ExtraModel } from 'up-ibe-types';
import { ReservationStatusEnum } from '../../enums';
import * as _ from 'lodash';
import { ExtrasDialogService } from 'app/services/extras-dialog.service';

interface ReservationClassInterface {
  'has-extras': boolean;
  'show-add-extras-button': boolean;
  'single-reservation': boolean;
  [key: string]: boolean;
}

@Component({
  selector: 'ibe-reservation-card',
  templateUrl: './reservation-card.component.html',
  styleUrls: ['./reservation-card.component.scss']
})
export class ReservationCardComponent implements OnInit {
  @Input() public reservationKey: number;
  @Input() public reservation: ReservationModel;
  @Input() public showButtons = true;
  @Input() public isInCart = false;
  @Input() public showCancellationPolicy = true;
  @Input() public showIsCancelled = true;
  @Input() public showAddExtrasButton = true;
  @Input() public displayInclusiveExtrasAsTaxes = false;
  @Output('onRemoveReservation') public onRemoveReservation: EventEmitter<string> = new EventEmitter();
  @Output('onExtrasChange') public onExtrasChange: EventEmitter<string> = new EventEmitter();
  public numberOfNights: number;
  public arrivalDate: string;
  public departureDate: string;

  constructor(
    private readonly bookingService: BookingService,
    private readonly toasterService: ToasterService,
    private readonly router: Router,
    private readonly translate:  TranslateService,
    private readonly imagesService: ImagesService,
    private readonly analyticsService: AnalyticsService,
    private readonly dialog: MatDialog,
    public readonly http: HttpClient,
    public readonly config: IbeConfigService,
    private readonly extrasDialogService: ExtrasDialogService
  ) {}

  public ngOnInit() {
    this.numberOfNights = this.calculateNumberOfNights(this.reservation.arrival, this.reservation.departure);
    this.arrivalDate = this.formatDate(this.reservation.arrival);
    this.departureDate = this.formatDate(this.reservation.departure);
  }

  public get bookingCurrency() {
    return this.bookingService.bookingCurrency;
  }

  public removeReservation() {
    this.dialog.open(RemovalModalComponent).afterClosed().subscribe((closeAction) => {
      if (closeAction === 'confirm') {
        if (this.bookingService.removeReservationFromBooking(this.reservationKey)) {
          this.onRemoveReservation.emit();
          this.toasterService.pop('success',
            this.translate.instant('offer_card.room_removed'),
            this.translate.instant('offer_card.room_successfully_removed')
          );
          this.analyticsService.createRoomExtrasRemoveFromCartEvent(this.reservation);
          this.analyticsService.createRoomRemoveFromCartEvent(this.reservation);
        }

        if (this.bookingService.booking.reservations.length === 0) {
          this.router.navigate(['booking/results'], { queryParamsHandling: 'preserve' });
        }
      }
    });
  }

  public addExtras() {
    const params = {
      propertyId: this.reservation.property.id,
      ratePlanId: this.reservation.ratePlan.id,
      arrival: moment(this.reservation.arrival).format('YYYY-MM-DD'),
      departure: moment(this.reservation.departure).format('YYYY-MM-DD'),
      adults: this.reservation.adults.toString(),
      childrenAges: this.reservation.childrenAges.toString()
    }

    this.extrasDialogService.open(
      params, this.reservation.ratePlan.id, this.reservation.property.id, this.reservation.extras
    ).subscribe((data) => {
      const selected: ExtraModel[] = data[1];

      if (!_.isEqual(selected, this.reservation.extras)) {
          if (this.bookingService.saveExtrasToReservation(this.reservationKey, selected)) {
            this.toasterService.pop('success',
              this.translate.instant('offer_card.extras_saved'),
              this.translate.instant('offer_card.extras_successfully_saved')
            );
          this.onExtrasChange.emit();
          }
      }
    })
  }

  public removeExtra(extra: ExtraModel) {
    if (this.bookingService.removeExtraFromReservation(this.reservationKey, extra.id)) {
      this.toasterService.pop('success',
        this.translate.instant('offer_card.extra_removed'),
        this.translate.instant('offer_card.extra_successfully_removed')
      );
      this.onExtrasChange.emit();
    }
    this.analyticsService.createExtraRemoveFromCartEvent(this.reservation.property, extra, this.reservation.adults);
  }

  private calculateNumberOfNights(arrivalDate: string, departureDate: string) {
    const arrival = moment(arrivalDate);
    const departure = moment(departureDate);
    return departure.diff(arrival, 'days');
  }

  public getUnitTypeImageUrl(unitTypeId: string) {
    return this.imagesService.getUnitTypeImageUrl(this.reservation.property.id, unitTypeId);
  }

  public reservationClass(reservation: ReservationModel) {
    const classes: ReservationClassInterface = {
      'has-extras': Boolean(reservation.extras && reservation.extras.length),
      'show-add-extras-button': this.showAddExtrasButton,
      'single-reservation': reservation.hasOwnProperty('booker')
    }

    return Object.keys(classes).filter(key => {
      return classes[key];
    }).join(' ');
  }

  public get isCancelled() {
    return this.reservation.status === ReservationStatusEnum.Canceled;
  }

  public hasCancellationFee() {
    return !(_.isEmpty(_.pick(this.reservation, ['cancellationFee.fee.amount'])));
  }

  public cancellationDescription() {
    return _.get(this.reservation, ['cancellationFee', 'description']);
  }

  public cancellationAmount() {
    return _.get(this.reservation, ['cancellationFee', 'fee', 'amount']);
  }

  public cancellationCurrency() {
    return _.get(this.reservation, ['cancellationFee', 'fee', 'currency']);
  }

  private formatDate(date: string) {
    const momentDate = moment(date);
    momentDate.locale(this.config.language);
    return momentDate.format('DD MMM YYYY');
  }
}
