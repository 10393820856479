import { HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JourneyService } from '../services/journey.service';

@Injectable()
export class JourneyTokenInterceptor implements HttpInterceptor {
  public constructor(
    private readonly journeyService: JourneyService
  ) {}

  public intercept(request: HttpRequest<{}>, next: HttpHandler):
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<{}> | HttpUserEvent<{}>> {
      let token = this.journeyService.getJourneyToken();

      if (!token) {
        this.journeyService.initiateJourney();
        token = this.journeyService.getJourneyToken();
      }

      if (token) {
        request = request.clone({ headers: request.headers.set('X-Journey-Token', token) });
      }

      return next.handle(request);
    }
}
