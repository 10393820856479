import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibe-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent {
  @Input() public isLoading = false;

  constructor() {}
}
