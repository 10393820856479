<ibe-steps-bar></ibe-steps-bar>

<div class="ibe-search-box">
  <form class="ibe-search-box-form" [formGroup]="searchForm" #searchFormDirective="ngForm" (submit)="submitSearch($event)">

    <div class="ibe-form-group ibe-property-id" *ngIf="properties.length > 1">
      <div *ngIf="!config.settings.displayPropertiesByRegionEnabled">
        <label for="property-custom-select">{{ 'booking_search.property' | translate }}</label>
        <select 
        (change)="onPropertyChange()"
         id="property-custom-select"
         class="ibe-custom-select"
          formControlName="propertyId"
          [ngClass]="{'is-invalid': isFormControlInvalid(searchForm.controls.propertyId)}"
          *ngIf="!config.settings.advancedPropertySelectorEnabled || !propertiesHaveCities()">
          <option
            value="" disabled>{{ 'booking_search.select_property' | translate }}</option>
          <option value="{{property.pmsId}}"
            *ngFor="let property of properties; let i=index">{{property.name}}</option>
        </select>
        <ibe-property-selector
          *ngIf="config.settings.advancedPropertySelectorEnabled && propertiesHaveCities()"
          (onPropertySelection)="onPropertySelection($event)"
          [selectedPropertyId]="searchForm.controls.propertyId.value"
          [properties]="properties"
          [invalidClass]="{'is-invalid': isFormControlInvalid(searchForm.controls.propertyId)}"
        ></ibe-property-selector>
      </div>

      <ibe-region-selector
        *ngIf="config.settings.displayPropertiesByRegionEnabled && propertiesHaveCities()"
        (onRegionAndPropertySelection)="onRegionAndPropertySelection($event)"
        [properties]="properties">
      </ibe-region-selector>

      <ibe-form-validation-message *ngIf="!config.settings.displayPropertiesByRegionEnabled"
        [form]="searchFormDirective"
        [control]="searchForm.controls.propertyId"
      ></ibe-form-validation-message>
    </div>

    <div class="ibe-form-group ibe-property-id" *ngIf="properties.length === 1">
      <label>{{ 'booking_search.property' | translate }}</label>
      <div class="ibe-property-name">{{ firstProperty.name }}</div>
    </div>

    <ibe-date-input
      class="ibe-form-group ibe-stay-date-selection"
      id="ibeDateInput"
      [arrivalValue]="searchForm.controls.arrival.value"
      [departureValue]="searchForm.controls.departure.value"
      [startDate]="todaysDate"
      (onStayDateSelection)="onStayDateSelection($event)">
    </ibe-date-input>

    <ibe-native-datepicker
      [arrival]="searchForm.controls.arrival.value"
      [departure]="searchForm.controls.departure.value"
      [startDate]="todaysDate"
      (onStayDateSelection)="onStayDateSelection($event)">
      <ibe-form-validation-message [form]="searchFormDirective" [control]="searchForm.controls.arrival"></ibe-form-validation-message>
      <ibe-form-validation-message [form]="searchFormDirective" [control]="searchForm.controls.departure"></ibe-form-validation-message>
    </ibe-native-datepicker>

    <div class="ibe-form-group ibe-persons-qty">
      <label>{{ 'booking_search.persons_qty' | translate }}</label>
      <div
        id="personsQtyField"
        class="ibe-form-control ibe-persons-qty-field"
        (click)="togglePersonsQtyPopup()"
        (keypress)="handleKeypress($event)"
        [ngClass]="{'ibe-persons-qty-popup-open': isPersonsQtyPopupOpen}"
        tabindex="0">
          <i class="ibe-icon ibe-icon-user"></i>
          {{ personsQtyData.adults }} {{ personsQtyFieldAdultsLabel }}
          <span *ngIf="config.isChildrenEnabled"> • {{ personsQtyData.childrenAges.length }} {{ 'booking_search.children' | translate }}</span>
      </div>
      <ibe-persons-qty-popup
        [personsQtyData]="personsQtyData"
        (onValueChange)="onPersonsQtyChange($event)"
        (closePopup)="togglePersonsQtyPopup()"
        *ngIf="isPersonsQtyPopupOpen"
        [@bookingSearchPopUpAnimation]
      ></ibe-persons-qty-popup>
    </div>

    <div class="ibe-form-group ibe-promo-code">
      <label class="ibe-promo-code-label" for="promo-code-input">{{ 'booking_search.promo_code' | translate }}</label>
      <input
        id="promo-code-input"
        class="ibe-form-control ibe-promo-code-input"
        placeholder="{{ 'booking_search.promo_code' | translate }}"
        formControlName="promoCode"
        [ngClass]="{'is-invalid': isFormControlInvalid(searchForm.controls.promoCode)}">
      <ibe-form-validation-message [form]="searchFormDirective" [control]="searchForm.controls.promoCode"></ibe-form-validation-message>
    </div>
    <button type="submit" class="ibe-btn ibe-primary-btn ibe-submit-search-btn">{{ 'booking_search.submit_button' | translate | uppercase }}</button>
  </form>
</div>
<ibe-loading-bar [isLoading]="isLoading"></ibe-loading-bar>
