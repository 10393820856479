import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { Property } from 'up-ibe-types';
import { isFormControlInvalid } from '../../../helpers/form.helper';

interface RegionAndPropertyId {
  region: string;
  propertyId: string
}

@Component({
  selector: 'ibe-region-selector',
  templateUrl: './region-selector.component.html',
  styleUrls: ['./region-selector.component.scss']
})
export class RegionSelectorComponent implements OnInit {
  @Input('properties') public properties: Property[];
  @Output('onRegionAndPropertySelection') public onRegionAndPropertySelection: EventEmitter<RegionAndPropertyId> = new EventEmitter();
  @ViewChild('formDirective', { static: true }) public formDirective: FormGroupDirective;
  public regions: string[] = [];
  public currentProperties: Property[] = [];
  public form: FormGroup;

  constructor(
    public readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      'region': ['All', [Validators.required]],
      'propertyId': ['All', [Validators.required]]
    });
  }

  public ngOnInit() {
    this.currentProperties = this.properties;
    this._getRegionsFromProperties();
    this.setRegionAndProperty();
  }

  public setRegionAndProperty() {
    this.route.queryParams.subscribe((params: Params) => {
      if (params.region) {
        this.form.controls['region'].setValue(params.region);
        this._filterPropertiesByRegion();
      }
      if (params.propertyId) {
        this.form.controls['propertyId'].setValue(params.propertyId);
      }
    });
  }

  public onPropertySelection() {
    this._emitValues();
  }

  public onRegionSelection() {
    if (this._shouldShowAllProperties()) {
      this.currentProperties = this.properties;
    } else {
      this._filterPropertiesByRegion();
    }
    this._emitValues();
  }

  private _emitValues() {
    this.onRegionAndPropertySelection.emit({
      region: this._getRegionFormValue(),
      propertyId: this.form.controls['propertyId'].value
    });
  }

  private _getRegionsFromProperties() {
    const regions = this.properties.map((property) => {
      return property.location?.city?.trim() || '';
    });
    this.regions = [...new Set(regions)].sort();
  }

  private _shouldShowAllProperties() {
    return this.form.controls['region'].value === 'All';
  }

  public _filterPropertiesByRegion() {
    const selectedRegion = this._getRegionFormValue();
    if (selectedRegion !== 'All') {
      this.currentProperties = this.properties.filter(property => {
        return property.location?.city?.trim() === selectedRegion?.trim();
      });
    }

    // if the region is changed we need to make sure the property is in the region
    // else change to all properties
    const selectedProperty = this.currentProperties.find(property => {
      return this.form.controls['propertyId'].value === property.id;
    })
    if (!selectedProperty) {
      this.form.controls['propertyId'].setValue('All');
    }
  }

  public isFormControlInvalid(formControl: AbstractControl) {
    return isFormControlInvalid(formControl, this.formDirective);
  }

  private _getRegionFormValue() {
    return this.form.controls['region'].value;
  }
}
