import { Injectable } from '@angular/core';
import { IbeConfigService } from './ibe-config.service';
import { Property, Image, ExtraModel, UnitType } from 'up-ibe-types';
import { environment } from '../../environments/environment';

@Injectable()
export class ImagesService {
  constructor(
    public readonly config: IbeConfigService
  ) {}

  public getUnitTypeImagesUrls(propertyId: String, unitTypeId: String) {
    const property = this._getConfigProperty(propertyId);

    if (!property) {
      return [];
    }

    const unitTypeImages = property.unitTypes.find((unitType: UnitType) => {
      return unitType.pmsId === unitTypeId;
    });

    if (unitTypeImages && unitTypeImages.images.length) {
      return unitTypeImages.images.map((image: Image) => {
        return environment.imagesUrl + '/' + image.path;
      });
    } else {
      return [];
    }
  }

  public getPropertyImageUrl(propertyId: string, unitTypeId: string) {
    const property = this._getConfigProperty(propertyId);

    if (!property) {
      return '';
    }

    const propertyImageUrls = property.images.map((image: Image) => {
      return environment.imagesUrl + '/' + image.path;
    });

    if (propertyImageUrls && propertyImageUrls.length) {
      return propertyImageUrls[0];
    } else {
      const unitTypeImages = property.unitTypes.find((unitType: UnitType) => {
        return unitType.pmsId === unitTypeId;
      });

      if (unitTypeImages && unitTypeImages.images.length) {
        const unitTypeImageUrls = unitTypeImages.images.map((image: Image) => {
          return environment.imagesUrl + '/' + image.path;
        });
        return unitTypeImageUrls[0];
      } else {
        return '';
      }
    }
  }

  public getUnitTypeImageUrl(propertyId: String, unitTypeId: String) {
    const images = this.getUnitTypeImagesUrls(propertyId, unitTypeId);
    if (images && images.length) {
      return images[0];
    } else {
      return '';
    }
  }

  public getExtraImageUrl(extra: ExtraModel) {
    if (extra.image) {
      return environment.imagesUrl + '/' + extra.image.path;
    }
    return undefined;
  }

  private _getConfigProperty(propertyId: String) {
    if (!this.config.properties) {
      return false;
    }

    const property = this.config.properties.find((configProperty: Property) => {
      return configProperty.pmsId === propertyId;
    });

    if (!property) {
      return false;
    }

    return property;
  }
}
