<form [formGroup]="detailsForm" #detailsFormDirective="ngForm" autocomplete="off">
  <div class="ibe-row" *ngIf="ibeSettings.checkoutFields.details.title.activated">
    <div class="ibe-form-group ibe-col-md-3">
      <ibe-form-label labelControlName="ibeTitleInput" [labelControl]="detailsForm.controls.title" labelText="checkout_details.title"></ibe-form-label>
      <select name="title" formControlName="title" class="ibe-custom-select" id="ibeTitleInput">
        <option value="undefined" disabled selected>{{ 'checkout_details.select' | translate }}</option>
        <option value="{{option.value}}" *ngFor="let option of guestTitleOptions">{{option.label}}</option>
      </select>
      <ibe-form-validation-message [form]="detailsFormDirective" [control]="detailsForm.controls.title" [submitObservable]="submitObservable"></ibe-form-validation-message>
    </div>
    <div class="ibe-form-group ibe-col-md-9" *ngIf="ibeSettings.checkoutFields.details.firstName.activated">
      <ibe-form-label labelControlName="ibeFirstNameInput" [labelControl]="detailsForm.controls.firstName" labelText="checkout_details.first_name"></ibe-form-label>
      <input type="text" formControlName="firstName" id="ibeFirstNameInput" class="ibe-form-control" [ngClass]="{'is-invalid': isFormControlInvalid(detailsForm.controls.firstName)}" placeholder="{{ 'checkout_details.first_name' | translate }}">
      <ibe-form-validation-message [form]="detailsFormDirective" [control]="detailsForm.controls.firstName" [submitObservable]="submitObservable"></ibe-form-validation-message>
    </div>
  </div>
  <div class="ibe-form-group" *ngIf="!ibeSettings.checkoutFields.details.title.activated && ibeSettings.checkoutFields.details.firstName.activated">
    <ibe-form-label labelControlName="ibeFirstNameInput" [labelControl]="detailsForm.controls.firstName" labelText="checkout_details.first_name"></ibe-form-label>
    <input type="text" formControlName="firstName" id="ibeFirstNameInput" class="ibe-form-control" [ngClass]="{'is-invalid': isFormControlInvalid(detailsForm.controls.firstName)}" placeholder="{{ 'checkout_details.first_name' | translate }}">
    <ibe-form-validation-message [form]="detailsFormDirective" [control]="detailsForm.controls.firstName" [submitObservable]="submitObservable"></ibe-form-validation-message>
  </div>
  <div class="ibe-form-group" *ngIf="ibeSettings.checkoutFields.details.lastName.activated">
    <ibe-form-label labelControlName="ibeLastNameInput" [labelControl]="detailsForm.controls.lastName" labelText="checkout_details.last_name"></ibe-form-label>
    <input type="text" formControlName="lastName" id="ibeLastNameInput" class="ibe-form-control" [ngClass]="{'is-invalid': isFormControlInvalid(detailsForm.controls.lastName)}" placeholder="{{ 'checkout_details.last_name' | translate }}">
    <ibe-form-validation-message [form]="detailsFormDirective" [control]="detailsForm.controls.lastName" [submitObservable]="submitObservable"></ibe-form-validation-message>
  </div>
  <div class="ibe-form-group" *ngIf="ibeSettings.checkoutFields.details.phone.activated">
    <ibe-form-label labelControlName="ibePhoneInput" [labelControl]="detailsForm.controls.phone" labelText="checkout_details.phone"></ibe-form-label>
    <input type="text" formControlName="phone" id="ibePhoneInput" class="ibe-form-control" [ngClass]="{'is-invalid': isFormControlInvalid(detailsForm.controls.phone)}" placeholder="{{ 'checkout_details.phone' | translate }}">
    <ibe-form-validation-message [form]="detailsFormDirective" [control]="detailsForm.controls.phone" [submitObservable]="submitObservable"></ibe-form-validation-message>
  </div>
  <div class="ibe-form-group ibe-checkout-email" *ngIf="ibeSettings.checkoutFields.details.email.activated">
    <ibe-form-label labelControlName="ibeEmailInput" [labelControl]="detailsForm.controls.email" labelText="checkout_details.email"></ibe-form-label>
    <input type="email" formControlName="email" id="ibeEmailInput" class="ibe-form-control" [ngClass]="{'is-invalid': isFormControlInvalid(detailsForm.controls.email)}" placeholder="{{ 'checkout_details.email' | translate }}">
    <span class="ibe-checkout-email-icon"
      *ngIf="detailsForm.controls.email.disabled"
      matTooltip="Because you are signed in, you must use your account's email address to complete this booking. Log out to use a different email address."
      matTooltipClass="ibe-checkout-email-tooltip">
      <svg d="Icons" class="ibe-checkout-email-tooltip-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="info"><circle id="circle" cx="24" cy="24" r="19" fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><g id="letter-i"><line id="stem" x1="24" y1="19.713" x2="24" y2="34.171" stroke-width="2" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" fill="none"/><line id="tittle" x1="24" y1="13.829" x2="24" y2="14.829" stroke-width="2" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" fill="none"/></g></g></svg>
    </span>
    <ibe-form-validation-message [form]="detailsFormDirective" [control]="detailsForm.controls.email" [submitObservable]="submitObservable"></ibe-form-validation-message>
  </div>
  <div class="ibe-guest-register" *ngIf="showCreateAccountBox">
    <div class="ibe-guest-register-title">{{ 'checkout_details.guest_register_title' | translate }}</div>
    <div class="ibe-guest-register-subtitle">{{ 'checkout_details.guest_register_subtitle' | translate }}</div>
    <div class="ibe-form-group">
      <ibe-form-label labelControlName="ibePassword" [labelControl]="detailsForm.controls.password" labelText="checkout_details.password"></ibe-form-label>
      <!--
        This is to stop Chrome from autofilling the email and password, if in Chrome we change the type
        to text and set the input to have a password disc input.
      -->
      <input
        id="ibePassword"
        formControlName="password"
        [type]="isChrome ? 'text' : 'password'"
        class="ibe-form-control no-autofill"
        [ngClass]="{'is-invalid': isFormControlInvalid(detailsForm.controls.password), 'password-disc': isChrome}"
        placeholder="{{ 'checkout_details.password' | translate }}"
      >
      <ibe-form-validation-message [form]="detailsFormDirective" [control]="detailsForm.controls.password" [submitObservable]="submitObservable"></ibe-form-validation-message>
    </div>
    <div class="ibe-guest-register-login" (click)="openLoginDialog()">{{ 'guest_management_auth.has_account_already' | translate }} <span>{{ 'guest_management_auth.log_in_to_account' | translate }}</span></div>
    <ibe-loading-bar [isLoading]="isCreateAccountLoading"></ibe-loading-bar>
  </div>
  <div class="ibe-form-group" *ngIf="ibeSettings.checkoutFields.details.travelPurpose.activated">
    <ibe-form-label labelControlName="ibeTravelPurposeInput" [labelControl]="detailsForm.controls.travelPurpose" labelText="checkout_details.travel_purpose"></ibe-form-label>
    <select [attr.disabled]="config.settings.forceCommercialPurpose ? true : null" name="travelPurpose" formControlName="travelPurpose" class="ibe-custom-select" id="ibeTravelPurposeInput" (change)="onTravelPurposeFieldChange($event.target.value)">
      <option value="undefined" disabled selected>{{ 'checkout_details.please_select' | translate }}</option>
      <option value="{{option.value}}" *ngFor="let option of travelPurposeOptions">{{option.label}}</option>
    </select>
    <ibe-form-validation-message [form]="detailsFormDirective" [control]="detailsForm.controls.travelPurpose" [submitObservable]="submitObservable"></ibe-form-validation-message>
  </div>
  <div formGroupName="company" *ngIf="isTravelPurposeBusiness || companyInfoRequired">
    <div class="ibe-form-group" *ngIf="ibeSettings.checkoutFields.details.companyName.activated || companyInfoRequired">
      <ibe-form-label labelControlName="ibeCompanyName" [labelControl]="companyForm.controls.name" labelText="checkout_details.company_name"></ibe-form-label>
      <input type="text" formControlName="companyName" id="ibeCompanyName" class="ibe-form-control" placeholder="{{ 'checkout_details.company_name' | translate }}">
      <ibe-form-validation-message [form]="detailsFormDirective" [control]="companyForm.controls.companyName" [submitObservable]="submitObservable"></ibe-form-validation-message>
    </div>
    <div class="ibe-form-group" *ngIf="companyInfoRequired || ibeSettings.checkoutFields.details.companyTaxId.activated">
      <ibe-form-label labelControlName="IbeCompanyTaxId" [labelControl]="companyForm.controls.taxId" labelText="checkout_details.company_taxId"></ibe-form-label>
      <input type="text" formControlName="companyTaxId" id="ibeCompanyTaxId" class="ibe-form-control" placeholder="{{ 'checkout_details.company_taxId' | translate }}">
      <ibe-form-validation-message [form]="detailsFormDirective" [control]="companyForm.controls.companyTaxId" [submitObservable]="submitObservable"></ibe-form-validation-message>
    </div>
    <div class="ibe-form-group" *ngIf="companyInfoRequired || ibeSettings.checkoutFields.details.companyStreet.activated">
      <ibe-form-label labelControlName="IbeCompanyStreet" [labelControl]="companyForm.controls.street" labelText="checkout_details.company_street"></ibe-form-label>
      <input type="text" formControlName="companyStreet" id="ibeCompanyStreet" class="ibe-form-control" placeholder="{{ 'checkout_details.company_street' | translate }}">
      <ibe-form-validation-message [form]="detailsFormDirective" [control]="companyForm.controls.companyStreet" [submitObservable]="submitObservable"></ibe-form-validation-message>
    </div>
    <div class="ibe-form-group" *ngIf="companyInfoRequired || ibeSettings.checkoutFields.details.companyCity.activated">
      <ibe-form-label labelControlName="IbeCompanCity" [labelControl]="companyForm.controls.city" labelText="checkout_details.company_city"></ibe-form-label>
      <input type="text" formControlName="companyCity" id="ibeCompanyCity" class="ibe-form-control" placeholder="{{ 'checkout_details.company_city' | translate }}">
      <ibe-form-validation-message [form]="detailsFormDirective" [control]="companyForm.controls.companyCity" [submitObservable]="submitObservable"></ibe-form-validation-message>
    </div>
    <div class="ibe-form-group" *ngIf="companyInfoRequired || ibeSettings.checkoutFields.details.companyPostalCode.activated">
      <ibe-form-label labelControlName="IbeCompanyPostalCode" [labelControl]="companyForm.controls.postalCode" labelText="checkout_details.company_postal_code"></ibe-form-label>
      <input type="text" formControlName="companyPostalCode" id="ibeCompanyPostalCode" class="ibe-form-control" placeholder="{{ 'checkout_details.company_postal_code' | translate }}">
      <ibe-form-validation-message [form]="detailsFormDirective" [control]="companyForm.controls.companyPostalCode" [submitObservable]="submitObservable"></ibe-form-validation-message>
    </div>
    <div class="ibe-form-group" *ngIf="companyInfoRequired || ibeSettings.checkoutFields.details.companyCountryCode.activated">
      <ibe-form-label labelControlName="IbeCompanyCountryCode" [labelControl]="companyForm.controls.countryCode" labelText="checkout_details.company_country"></ibe-form-label>
      <select
        formControlName="companyCountryCode"
        id="ibeCountryCodeSelect"
        class="ibe-custom-select"
        [ngClass]="{'is-invalid': isFormControlInvalid(companyForm.controls.countryCode)}"
        (change)="onCountrySelect($event.target.value)"
      >
        <option value="undefined" disabled selected>{{ 'checkout_details.please_select' | translate }}</option>
        <optgroup *ngIf="defaultCountries.length">
          <option value="{{ defaultCountry.value }}" *ngFor="let defaultCountry of defaultCountries">{{ defaultCountry.label }}</option>
        </optgroup>

        <optgroup>
          <option value="{{ country.value }}" *ngFor="let country of countriesList">{{ country.label }}</option>
        </optgroup>
      </select>
      <ibe-form-validation-message [form]="detailsFormDirective" [control]="companyForm.controls.companyCountryCode" [submitObservable]="submitObservable"></ibe-form-validation-message>
    </div>
    <div class="ibe-form-group" *ngIf="(companyInfoRequired || ibeSettings.checkoutFields.details.companyState.activated) && (companyForm.controls.companyCountryCode?.value=='US' || companyForm.controls.companyCountryCode?.value=='CA')">
      <ibe-form-label labelControlName="IbeCompanyState" [labelControl]="companyForm.controls.state" labelText="checkout_details.company_state"></ibe-form-label>
      <select
        formControlName="companyState"
        id="ibeStateCodeSelect"
        class="ibe-custom-select"
        [ngClass]="{'is-invalid': isFormControlInvalid(companyForm.controls.companyState)}"
        *ngIf="companyForm.controls.companyCountryCode.value=='US' || companyForm.controls.companyCountryCode.value=='CA'"
      >
        <option value="undefined" disabled selected>{{ 'checkout_details.please_select' | translate }}</option>
        <optgroup *ngIf="companyForm.controls.companyCountryCode.value=='US'">
          <option value="{{ state.value }}" *ngFor="let state of statesList.US">{{ state.label }}</option>
        </optgroup>
        <optgroup *ngIf="companyForm.controls.companyCountryCode.value=='CA'">
          <option value="{{ state.value }}" *ngFor="let state of statesList.CA">{{ state.label }}</option>
        </optgroup>
      </select>
      <ibe-form-validation-message [form]="detailsFormDirective" [control]="companyForm.controls.companyState" [submitObservable]="submitObservable"></ibe-form-validation-message>
    </div>
  </div>
  <div class="ibe-form-group" *ngIf="ibeSettings.checkoutFields.details.guestComment.activated">
    <label for="ibeBookerCommentInput" class="ibe-comment-box-label">{{ 'checkout_details.booker_comment' | translate }}</label>
    <span *ngIf="useCommentBubbles" id="ibeBookerCommentInputBubbles">
      <div class="commentBubblesWrapper">
        <div *ngFor="let option of predefinedCommentBubbles" class="commentBubble ibe-primary-btn">
          <mat-checkbox
            [checked]="hasCommentBubble(option)"
            (change)="addCommentBubble($event, option)"
          >
            {{ option.label }}
          </mat-checkbox>
        </div>
        {{ '' // show freetext comment field: }}
        <div
          *ngIf="!showFreeText"
          matTooltip="{{ 'checkout_details.comment_leave_message' | translate }}"
          (click)="showFreeText=true"
          class="commentBubble ibe-primary-btn"
          style="display: flex; cursor: pointer; width: 9%; height: 42px; position: relative; justify-content: center; align-items: center; padding: 0;"
        >
          <i
            class="ibe-icon ibe-icon-add_circle"
            style="font-size: 150%; display: block;"
          ></i>
        </div>
      </div>
    </span>
    <div style="position: relative">
      {{ '' // showFreeText is always true when useCommentBubble is not used }}
      <textarea *ngIf="showFreeText" formControlName="guestComment" id="ibeBookerCommentInput" class="ibe-form-control ibe-comment-box" placeholder="{{ 'checkout_details.booker_comment' | translate }}"></textarea>
      {{ '' // without useCommentBubble, button should only clear content, not hide }}
      <button mat-icon-button (click)="showFreeText=!useCommentBubbles; detailsForm.patchValue({guestComment:''})" *ngIf="showFreeText" style="position:absolute; top:0; right: 0;">
        <i class="ibe-icon ibe-icon-delete ibe-comment-clear"></i>
      </button>
    </div>
    <ibe-form-validation-message *ngIf="isOnCheckout" [form]="detailsFormDirective" [control]="detailsForm.controls.guestComment" [submitObservable]="submitObservable"></ibe-form-validation-message>
  </div>
  <div class="ibe-form-group" *ngIf="this.config.settings.interestsEnabled && ibeInterests.length && this.guestAuthService.isLoggedIn()">
    <label>Interests</label>
    <mat-checkbox
      *ngFor="let ibeInterest of ibeInterests"
      [checked]="hasInterest(ibeInterest)"
      (change)="handleCheckboxChange($event, ibeInterest.id)"
    >
      {{ ibeInterest.value[this.ibeSettings.defaultLanguage] || ibeInterest.value['en'] }}
    </mat-checkbox>
  </div>
  <div class="ibe-form-group" *ngIf="config.accountFeatureWhitelist.milesAndMore && this.guestAuthService.isLoggedIn()">
    <label for="milesAndMore" class="ibe-comment-box-label">
      {{ 'checkout_details.milesAndMore' | translate }}
    </label>
    <input
      [value]="this.detailsForm.controls.milesAndMore.value"
      (input)="handleMilesAndMoreChange($event.target.value)"
      type="text"
      id="milesAndMore"
      class="ibe-form-control"
      placeholder="{{ 'checkout_details.milesAndMore' | translate }}"
    >
  </div>
  <div class="ibe-form-group" *ngIf="config.accountFeatureWhitelist.licensePlateField && isOnCheckout">
    <label for="ibeLicensePlate" class="ibe-comment-box-label">{{ 'checkout_details.license_plate' | translate }}</label>
    <input [(ngModel)]="licensePlate" [ngModelOptions]="{standalone: true}" (input)="onLicensePlateChange($event.target.value)" type="text" id="ibeLicensePlate" class="ibe-form-control" placeholder="{{ 'checkout_details.license_plate' | translate }}">
  </div>
  <div class="ibe-form-group ibe-form-group-checkbox">
    <ibe-form-validation-message *ngIf="isOnCheckout" [form]="detailsFormDirective" [control]="detailsForm.controls.termsConditions" [submitObservable]="submitObservable"></ibe-form-validation-message>
    <div style="display: flex;">
      <div class="ibe-checkbox-frame" *ngIf="isOnCheckout">
        <input type="checkbox" formControlName="termsConditions" id="ibeTermsConditionsInput">
        <span class="ibe-checkmark"></span>
      </div>
      <label *ngIf="isOnCheckout" for="ibeTermsConditionsInput" class="ibe-terms-confirm-text">{{ 'checkout.terms_conditions_confirm_text' | translate }} <a href="{{termsConditionsUrl}}" class="ibe-terms-link" target="_blank">{{ 'checkout.terms_conditions_confirm_url' | translate }}</a></label>
    </div>
  </div>
</form>
