<div class="ibe-total-tax-breakdown" [ngClass]="{'open': isOpen}" *ngIf="!isSuppressed">
  <div class="ibe-total-row" *ngIf="bookingTotals?.taxTotal > 0">
    <div class="ibe-total-row-label">{{ 'checkout.exc_vat' | translate }}</div>
    <div class="ibe-total-row-value">{{ bookingTotals.netTotal | currency:currency:'symbol':'1.2-2' }}</div>
  </div>
  <div class="ibe-total-row" *ngFor="let breakdown of bookingTotals?.breakdown">
    <div class="ibe-total-row-label breakdown-label">{{ breakdown.name }}</div>
    <div class="ibe-total-row-value breakdown-value">{{ breakdown.amount | currency:currency:'symbol':'1.2-2' }}</div>
  </div>
  <div class="ibe-total-row vat" *ngIf="bookingTotals?.taxTotal > 0">
    <div class="ibe-total-row-label">{{ 'checkout.vat' | translate }}</div>
    <div class="ibe-total-row-value">{{ bookingTotals.taxTotal | currency:currency:'symbol':'1.2-2' }}</div>
  </div>
  <div class="ibe-total-row" *ngIf="bookingTotals?.taxTotal === 0">
    <div class="ibe-total-row-label">{{ 'checkout.exc_tax' | translate }}</div>
    <div class="ibe-total-row-value">{{ bookingTotals.netTotal | currency:currency:'symbol':'1.2-2' }}</div>
  </div>
  <div *ngIf="displayInclusiveExtrasAsTaxes">
    <div class="ibe-total-row" *ngFor="let additionalIncludedExtrasBreakdown of bookingTotals?.additionalIncludedExtrasBreakdown">
      <div class="ibe-total-row-label breakdown-label">{{ additionalIncludedExtrasBreakdown.name }}</div>
      <div class="ibe-total-row-value breakdown-value">{{ additionalIncludedExtrasBreakdown.totalGrossAmount.amount | currency:currency:'symbol':'1.2-2' }}</div>
    </div>
  </div>
  <div class="ibe-total-row city-tax" *ngIf="(bookingTotals?.cityTaxTotal > 0) && showCityTax">
    <div class="ibe-total-row-label">{{ 'checkout.city_tax' | translate }}</div>
    <div class="ibe-total-row-value">{{ bookingTotals.cityTaxTotal | currency:currency:'symbol':'1.2-2' }}</div>
  </div>
  <div class="ibe-total-row tourist-tax" *ngIf="(bookingTotals?.touristTaxTotal > 0)">
    <div class="ibe-total-row-label">{{ 'checkout.tourist_tax' | translate }}</div>
    <div class="ibe-total-row-value">{{ bookingTotals.touristTaxTotal | currency:currency:'symbol':'1.2-2' }}</div>
  </div>
  <div class="ibe-total-row sales-tax" *ngIf="(bookingTotals?.salesTaxTotal > 0)">
    <div class="ibe-total-row-label">{{ 'checkout.sales_tax' | translate }}</div>
    <div class="ibe-total-row-value">{{ bookingTotals.salesTaxTotal | currency:currency:'symbol':'1.2-2' }}</div>
  </div>
</div>
<div class="ibe-total-cost" [ngClass]="{'open': isOpen}" *ngIf="!isSuppressed">
  <div class="ibe-total-cost-label">{{ 'checkout.total_cost' | translate }}</div>
  <div class="ibe-total-cost-value">{{ bookingTotals?.grossTotal | currency:currency:'symbol':'1.2-2' }}</div>
  <div class="ibe-vat-breakdown-btn" (click)="toggleSlide()">
    <i style="margin-right: 5px" [ngClass]="{'ibe-icon-chevron-down': !isOpen, 'ibe-icon-chevron-up': isOpen}"></i>
    {{ 'checkout.tax_breakdown' | translate }}
  </div>
</div>
