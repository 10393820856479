import { ElementRef } from '@angular/core';

export function scrollToElement(elementRef: ElementRef | Element) {
  const target = elementRef instanceof ElementRef ? elementRef.nativeElement : elementRef;
  return target.scrollIntoView({block: 'start', inline: 'nearest', behavior: 'smooth'});
};

export function scrollToTop() {
  window.scrollTo(0, 0);
}
