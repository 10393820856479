import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

interface InitiatePaymentPayload {
  data: { [key: string]: string },
  bookingRequestId: string,
  merchantAccount: string
}

interface AdyenResponse {
  resultCode: string,
  reason: string,
  action: { [key: string]: string }
}

@Injectable({
  providedIn: 'root'
})
export class AdyenService {
  constructor(private http: HttpClient) {}

  public initiatePayment(payload: InitiatePaymentPayload): Observable<AdyenResponse> {
    return this.http.post<AdyenResponse>(
      `${environment.serverUrl}/api/ibe/process-payment`, {
        ...payload,
        redirect: window.location.href
      }
    )
  }

  // Taken directly from the adyen docs:
  // @see https://docs.adyen.com/online-payments/api-only#step-5-additional-payment-details
  // If the shopper is required to do additional actions to complete the payment,
  // Drop-in handles presenting additional actions in the client app.
  // After the shopper completes the payment, Drop-in provides the data.
  // You use the data from Drop-in and submit it in a POST
  public submitAdditionalDetails(bookingRequestId: string, payload: { [ key: string ]: string | Object }): Observable<AdyenResponse> {
    return this.http.post<AdyenResponse>(
      `${environment.serverUrl}/api/ibe/payments/${bookingRequestId}/details`, payload
    )
  }

  public getIpAddress() {
    return fetch('https://myibe.com/mip/')
      .then(response => response.json());
  }
}
