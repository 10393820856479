import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IbeConfigService } from '../../services/ibe-config.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'ibe-results-container',
  templateUrl: './results-container.component.html',
  styleUrls: ['./results-container.component.scss']
})
export class ResultsContainerComponent implements OnInit {
  public loading$: Observable<boolean>;
  public isRegionSearch = false;

  constructor(
    public readonly config: IbeConfigService,
    private readonly route: ActivatedRoute
  ) { }

  public ngOnInit() {
    this._isRegionSearch();
  }

  public onLoadingEmit($event: Observable<boolean>) {
    // Added setTimeout to fix:
    //   ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
    //   Previous value: 'null'. Current value: 'true'.
    // Maybe something like ObservableInput would be better: https://stackoverflow.com/a/59116890/3486297
    setTimeout(() => this.loading$ = $event, 0);
  }

  private _isRegionSearch() {
    this.route.queryParams.subscribe(params => {
      if (this.config.settings.displayPropertiesByRegionEnabled &&
        params.region && params.propertyId && params.propertyId === 'All') {
        this.isRegionSearch = true;
      } else {
        this.isRegionSearch = false;
      }
    });
  }

}
