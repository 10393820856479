import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {HttpClient, HttpParams} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { BookingService } from './booking.service';

const localStorageTokenKey = 'upibe_guest_jwt';
const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class GuestAuthService {
  constructor(
    public readonly http: HttpClient,
    private readonly bookingService: BookingService
  ) {
  }

  public getToken() {
    return jwtTokenGetter();
  }

  public isTokenValid() {
    const token = this.getToken();

    if (token) {
      return !jwtHelper.isTokenExpired(token);
    }

    return false
  }

  // This method is just some syntactic sugar to make code more readable outside of this service
  public isLoggedIn() {
    return this.isTokenValid();
  }

  public getUsername() {
    const token = this.getToken();

    if (!token) {
      return;
    }

    const decodedToken = jwtHelper.decodeToken(token);

    return decodedToken.username;
  }

  public getPmsGuestId() {
    const token = this.getToken();

    if (!token) {
      return;
    }

    const decodedToken = jwtHelper.decodeToken(token);
    return decodedToken.pmsGuestId;
  }

  public logout() {
    this.bookingService.updateBookingOnLogout();
    return localStorage.removeItem(localStorageTokenKey);
  }

  public createGuest(data: {
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    title: string;
    phone: string;
    marketingConsent: boolean;
  }) {
    const url = window.location.href.split('#')[0];
    return this.http.post(`${environment.serverUrl}/api/ibe/guest`, {
      ...data,
      url
    }).pipe(tap((response: { token: string; username: string; }) => {
      this.loginGuest({ username: data.username, password: data.password }).subscribe(() => {});
    }));
  }

  public loginGuest(data: {
    username: string;
    password: string;
  }) {
    const url = window.location.href.split('#')[0];
    return this.http.post(`${environment.serverUrl}/api/ibe/guest/login`, {
      ...data,
      url
    }).pipe(tap((response: { token: string; username: string; }) => {
        this._setToken(response.token)
      }));
  }

  public fetchGuestByPmsId(data: {
    guestId: string
  }) {
    const httpParams = new HttpParams()
      .set('pmsGuestId', encodeURIComponent(data.guestId));

    return this.http.get(`${environment.serverUrl}/api/ibe/guest`, {
      params: httpParams
    })
  }

  private _setToken(token: string) {
    localStorage.setItem(localStorageTokenKey, token);
  }
}

export function jwtTokenGetter() {
  return localStorage.getItem(localStorageTokenKey);
}
