/* tslint:disable:max-file-line-count */
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import {LocationStrategy, HashLocationStrategy, registerLocaleData} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatChipsModule } from '@angular/material/chips';
import { ToasterModule } from 'angular2-toaster';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NguCarouselModule } from '@ngu/carousel';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BookingComponent } from './booking/booking.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { StepsBarComponent } from './steps-bar/steps-bar.component';
import { BookingSearchComponent } from './booking/booking-search/booking-search.component';
import { RoomResultsComponent } from './booking/results-container/room-results/room-results.component';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import {
  GalleryDialogComponent
} from './booking/results-container/room-results/gallery-dialog/gallery-dialog.component';
import {
  RateInfoModalComponent
} from './booking/results-container/room-results/rate-info-modal/rate-info-modal.component';
import { CheckoutDetailsComponent } from './checkout/checkout-details/checkout-details.component';
import { CheckoutAddressComponent } from './checkout/checkout-address/checkout-address.component';
import { CheckoutPaymentComponent } from './checkout/checkout-payment/checkout-payment.component';
import { CheckoutPaymentErrorComponent } from './checkout/checkout-payment-error/checkout-payment-error.component';
import { AdyenPaymentComponent } from './checkout/checkout-payment/adyen-payment/adyen-payment.component';
import {
  AdyenDropinPaymentComponent
} from './checkout/checkout-payment/adyen-dropin-payment/adyen-dropin-payment.component';
import { MycheckPaymentComponent } from './checkout/checkout-payment/mycheck-payment/mycheck-payment.component';
import { BookingConfirmationComponent } from './booking/booking-confirmation/booking-confirmation.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ReservationCardComponent } from './reservation-card/reservation-card.component';
import { CartComponent } from './booking/cart/cart.component';
import { FormValidationMessageComponent } from './form-validation-message/form-validation-message.component';
import { ExtrasComponent } from './extras/extras.component';
import { RemovalModalComponent } from './booking/cart/removal-modal/removal-modal.component';
import { TotalSummaryComponent } from './total-summary/total-summary.component';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { BookingManagementComponent } from './booking-management/booking-management.component';
import { GuestManagementComponent } from './guest-management/guest-management.component';
import { AvailabilityCalendarComponent } from './booking/availability-calendar/availability-calendar.component';
import { CalendarComponent } from './booking/availability-calendar/calendar/calendar.component';
import { LoadingCircleComponent } from './loading-circle/loading-circle.component';
import { CreditCardComponent } from './booking-management/credit-card/credit-card.component';
import {
  BookingCancellationDialogComponent
} from './booking-management/booking-cancellation-dialog/booking-cancellation-dialog.component';
import {
  BookingPaymentDialogComponent
} from './booking-management/booking-payment-dialog/booking-payment-dialog.component';
import { IbeConfigService } from './services/ibe-config.service';
import { ThemeService } from './services/theme.service';
import { BookingService } from './services/booking.service';
import { AnalyticsService } from './services/analytics.service';
import { FormValidationService } from './services/form-validation.service';
import { RoomResultsGuardService } from './services/room-results-guard.service';
import { GuestManagementGuardService } from './services/guest-management-guard.service';
import { ImagesService } from './services/images.service';
import { GlobalWindowService } from './services/global-window.service';
import { LanguageInterceptorService } from 'app/interceptors/language-interceptor.service';
import { IbeKeyInterceptorService } from 'app/interceptors/ibe-key-interceptor.service';
import { JourneyTokenInterceptor } from 'app/interceptors/journey-token.interceptor';
import { AuthInterceptor } from 'app/interceptors/auth.interceptor';
import { ErrorHandlerService } from './services/error-handler.service';
import { ErrorDialogService } from './services/error-dialog.service';
import { GuestAuthService } from './services/guest-auth.service';
import { ReservationService } from './services/reservation.service';
import { RateSelectorComponent } from './booking/results-container/room-results/rate-selector/rate-selector.component';
import { GuestLoginComponent } from './guest-management/guest-login/guest-login.component';
import { GuestCreateAccountComponent } from './guest-management/guest-create-account/guest-create-account.component';
import { InvoiceListComponent } from './guest-management/reservations-history/invoice-list/invoice-list.component'
import {
  PersonsQtySelectorComponent
} from './booking/booking-search/persons-qty-popup/persons-qty-selector/persons-qty-selector.component';
import { PersonsQtyPopupComponent } from './booking/booking-search/persons-qty-popup/persons-qty-popup.component';
import { PropertySelectorComponent } from './booking/booking-search/property-selector/property-selector.component';
// tslint:disable-next-line:max-line-length
import {
  AvailabilityRestrictionsDialogComponent
} from './booking/availability-calendar/availability-restrictions-dialog/availability-restrictions-dialog.component';
import { GuestDetailsDialogComponent } from './guest-details-dialog/guest-details-dialog.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DatePickerComponent } from './date-range-picker/date-picker/date-picker.component';
import { NativeDatepickerComponent } from './date-range-picker/native-datepicker/native-datepicker.component';
import { FormLabelComponent } from './form-label/form-label.component';
import { ReservationHistoryComponent } from './guest-management/reservations-history/reservations-history.component';
import { ModifyReservationComponent } from './guest-management/modify-reservation/modify-reservation.component';
import { EditAccountComponent } from './guest-management/edit-account/edit-account.component';
import {
  ReservationListComponent
} from './guest-management/reservations-history/reservations-list/reservations-list.component';
import {
  GuestManagementOptionsComponent
} from './guest-management/guest-management-options/guest-management-options.component';
import { GuestPasswordResetComponent } from './guest-management/guest-password-reset/guest-password-reset.component';
import {
  GuestManagementSidebarComponent
} from './guest-management/guest-management-sidebar/guest-management-sidebar.component';
import {
  ReservationDetailComponent
} from './guest-management/reservations-history/reservation-detail/reservation-detail.component';
import { BookerDetailsComponent } from './booking-management/booker-details/booker-details.component';
import {
  EditReservationDatesDialogComponent
} from './guest-management/modify-reservation/edit-reservation-dates-dialog/edit-reservation-dates-dialog.component';
import { ScriptLoaderService } from './services/script-loader.service';

import { jwtTokenGetter } from './services/guest-auth.service';
import { LogGuestMovementGuard } from './guards/log-guest-movement-guard';
import { SearchParamsUpgradeService } from './services/room-results/search-params-upgrade.service';
import { SearchParamsService } from './services/room-results/search-params.service';
import {
  GuestManagementHeaderComponent
} from './guest-management/guest-management-header/guest-management-header.component';
import {
  AddExtrasDialogComponent
} from './guest-management/modify-reservation/add-extras-dialog/add-extras-dialog.component';
import { ExtrasTemplateComponent } from './extras-template/extras-template.component';
import { TranslateReservationStatusPipe } from './translate-reservation-status.pipe';
import { CustomCurrencyPipe } from './services/pipes/custom-currency.pipe';
import { RegionSelectorComponent } from './booking/booking-search/region-selector/region-selector.component';
import { ResultsContainerComponent } from './booking/results-container/results-container.component';
import { RegionResultsComponent } from './booking/results-container/region-results/region-results.component';
import { DateInputComponent } from './booking/booking-search/date-input/date-input.component';
import { SaferpayPaymentComponent } from './checkout/checkout-payment/saferpay-payment/saferpay-payment.component';
import { SaferpayPaymentDialogComponent
  } from './checkout/checkout-payment/saferpay-payment/saferpay-payment-dialog.component';
import {DisableAutofillDirective} from './directives/disable-autofill.directive';
import { AdyenFactory } from './factories/adyen.factory';
import { DateFormatPipe } from './pipes/date-format.pipe';

// LOCALE DATA - USED IN CURRENCIES
import localeDe from '@angular/common/locales/de';
import localeFi from '@angular/common/locales/fi';
import localeSe from '@angular/common/locales/se';
import localeRu from '@angular/common/locales/ru';
import localeFr from '@angular/common/locales/fr';
import { AnchorGuard } from './guards/anchor-guard';
import { DetailsFormComponent } from './guest-form/details-form/details-form.component';
import { AddressFormComponent } from './guest-form/address-form/address-form.component';
import {
  GuestAccountAuthComponent
} from './guest-management/guest-create-account/guest-account-auth/guest-account-auth.component';
import {
  CustomRateSelectorComponent
} from './booking/results-container/room-results/custom-rate-selector/custom-rate-selector.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
[localeDe, localeFi, localeSe, localeRu, localeFr].forEach(locale => registerLocaleData(locale))
export const httpLoaderFactory = (http: HttpClient, path = ''): TranslateHttpLoader => {
  if (path) {
    return new TranslateHttpLoader(http, path, '.json');
  }
  return new TranslateHttpLoader(http, environment.translationFilesUrl + '/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    BookingComponent,
    CheckoutComponent,
    StepsBarComponent,
    BookingSearchComponent,
    RoomResultsComponent,
    LoadingBarComponent,
    GalleryDialogComponent,
    RateInfoModalComponent,
    CheckoutDetailsComponent,
    CheckoutAddressComponent,
    CheckoutPaymentComponent,
    CheckoutPaymentErrorComponent,
    BookingConfirmationComponent,
    ErrorPageComponent,
    ReservationCardComponent,
    ExtrasComponent,
    RemovalModalComponent,
    CartComponent,
    FormValidationMessageComponent,
    TotalSummaryComponent,
    AdyenPaymentComponent,
    AdyenDropinPaymentComponent,
    MycheckPaymentComponent,
    ErrorDialogComponent,
    AlertDialogComponent,
    GuestDetailsDialogComponent,
    BookingManagementComponent,
    AvailabilityCalendarComponent,
    CalendarComponent,
    LoadingCircleComponent,
    CreditCardComponent,
    RateSelectorComponent,
    BookingCancellationDialogComponent,
    BookingPaymentDialogComponent,
    PersonsQtySelectorComponent,
    PersonsQtyPopupComponent,
    PropertySelectorComponent,
    AvailabilityRestrictionsDialogComponent,
    DateRangePickerComponent,
    DatePickerComponent,
    NativeDatepickerComponent,
    FormLabelComponent,
    GuestManagementComponent,
    GuestLoginComponent,
    GuestCreateAccountComponent,
    ReservationHistoryComponent,
    ModifyReservationComponent,
    EditAccountComponent,
    ReservationListComponent,
    GuestManagementOptionsComponent,
    GuestManagementSidebarComponent,
    ReservationDetailComponent,
    BookerDetailsComponent,
    GuestPasswordResetComponent,
    EditReservationDatesDialogComponent,
    GuestManagementHeaderComponent,
    AddExtrasDialogComponent,
    ExtrasTemplateComponent,
    TranslateReservationStatusPipe,
    CustomCurrencyPipe,
    RegionSelectorComponent,
    ResultsContainerComponent,
    RegionResultsComponent,
    DateInputComponent,
    SaferpayPaymentComponent,
    SaferpayPaymentDialogComponent,
    DisableAutofillDirective,
    InvoiceListComponent,
    DateFormatPipe,
    DetailsFormComponent,
    AddressFormComponent,
    GuestAccountAuthComponent,
    CustomRateSelectorComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToasterModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatDialogModule,
    MatMomentDateModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    NguCarouselModule,
    MatChipsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        whitelistedDomains: [
          'ibe-server.uphotel.local:3000',
          'ibe-server.staging.uphotel.agency',
          'ibe-server.uphotel.agency',
          'my-ibe.preview.srv4.detco.de',
          environment.serverUrl.replace('https://', '')
        ]
      }
    }),
    MatExpansionModule,
    MatIconModule
  ],
  providers: [
    IbeConfigService,
    ThemeService,
    BookingService,
    AnalyticsService,
    FormValidationService,
    RoomResultsGuardService,
    GuestManagementGuardService,
    ImagesService,
    GlobalWindowService,
    ErrorDialogService,
    LogGuestMovementGuard,
    AnchorGuard,
    GuestAuthService,
    ReservationService,
    ScriptLoaderService,
    SearchParamsService,
    SearchParamsUpgradeService,
    AdyenFactory,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: IbeKeyInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JourneyTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  entryComponents: [
    GalleryDialogComponent,
    RateInfoModalComponent,
    ExtrasComponent,
    RemovalModalComponent,
    ErrorDialogComponent,
    AlertDialogComponent,
    GuestDetailsDialogComponent,
    BookingCancellationDialogComponent,
    BookingPaymentDialogComponent,
    AvailabilityRestrictionsDialogComponent,
    EditReservationDatesDialogComponent,
    GuestLoginComponent,
    AddExtrasDialogComponent,
    SaferpayPaymentDialogComponent
  ],
  bootstrap: [ AppComponent ]
})
// tslint:disable-next-line: max-file-line-count
export class AppModule {}
