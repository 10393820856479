import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import AdyenCheckout from '@adyen/adyen-web';
import { Injectable } from '@angular/core';
import { AdyenDropinComponent } from 'app/checkout/checkout-payment/adyen-dropin-payment/adyen-dropin-payment.component';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AdyenFactory {
  private showMessageSource = new BehaviorSubject<boolean>(false);
  public showMessage = this.showMessageSource.asObservable();

  public async create(configuration: CoreOptions, component: string, element: HTMLElement) {
    return (await AdyenCheckout(configuration)).create(component, {
      onSelect: (dropin: AdyenDropinComponent) => {
        if (dropin.props.type !== 'card') {
          this.showMessageSource.next(true);
        } else {
          this.showMessageSource.next(false);
        }
      }
    }).mount(element);
  }
}
