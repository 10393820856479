import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { SearchFormValues } from '../../services/booking/types';
import { CalendarStayDateSelectionEvent, CalendarDay } from './calendar/calendar';
import { Property } from 'up-ibe-types';
import { ReplaySubject } from 'rxjs';

export interface ArrivalSelectEvent {
  date: Date,
  potentialAvailabilitySpan: CalendarDay[]
}

@Component({
  selector: 'ibe-availability-calendar',
  templateUrl: './availability-calendar.component.html',
  styleUrls: ['./availability-calendar.component.scss']
})
export class AvailabilityCalendarComponent implements OnInit {
  @Input('property') public property: Property | undefined;
  public leftCalendarCurrentMonth: Moment;
  public rightCalendarCurrentMonth: Moment;

  public arrivalDate: Date;
  public departureDate: Date;
  public hoveredDepartureDate: Date;
  public potentialAvailabilitySpan: CalendarDay[];
  public searchFormValues: SearchFormValues;
  public potentialAvailabilitySpanSubject: ReplaySubject<CalendarDay[]> = new ReplaySubject<CalendarDay[]>();

  constructor(
    public readonly currentRoute: ActivatedRoute,
    public readonly router: Router
  ) { }

  public ngOnInit() {
    this._checkRequiredQueryParams();
    this._initData();
  }

  public onSearchFormChange(searchFormValues: SearchFormValues) {
    this.searchFormValues = searchFormValues;
  }

  public onStayDateSelection($event: CalendarStayDateSelectionEvent) {
    this.arrivalDate = $event.arrivalDate;
    this.departureDate = $event.departureDate;
    this.router.navigate(['booking/results'], {
      queryParams: {
        arrival: moment(this.arrivalDate).format('YYYY-MM-DD'),
        departure: moment(this.departureDate).format('YYYY-MM-DD')
      },
      queryParamsHandling: 'merge' }
    );
  }

  public onArrivalDateSelection($event: Date) {
    this.arrivalDate = $event;
  }

  public onDepartureDateSelection($event: Date) {
    this.departureDate = $event;
  }

  public onDepartureDateHover($event: Date) {
    this.hoveredDepartureDate = $event;
  }

  private _checkRequiredQueryParams() {
    const queryParams = this.currentRoute.snapshot.queryParams;
    if (!queryParams.propertyId || !queryParams.adults || !queryParams.arrival || !queryParams.departure) {
      this.router.navigateByUrl('/booking/search');
    }
  }

  private _initData() {
    const queryParams = this.currentRoute.snapshot.queryParams;
    this.arrivalDate = moment(queryParams.arrival).toDate();
    this.departureDate = moment(queryParams.departure).toDate();
    this.leftCalendarCurrentMonth = moment(queryParams.arrival);
    this.rightCalendarCurrentMonth = moment(queryParams.arrival).add(1, 'month');
  }
}
