import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReservationModel } from 'up-ibe-types';
import { IbeConfigService } from '../../services/ibe-config.service';
import { CancellationFeeViewModel, ReservationViewModel } from 'app/services/booking/reservation.viewmodels';

@Component({
  selector: 'ibe-booking-cancellation-dialog',
  templateUrl: './booking-cancellation-dialog.component.html',
  styleUrls: ['./booking-cancellation-dialog.component.scss']
})
export class BookingCancellationDialogComponent {
  public cancellationFeeDueDate: string;
  public reservation: ReservationViewModel;
  public cancellationFee: CancellationFeeViewModel;
  public language: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: ReservationModel,
    public readonly config: IbeConfigService
  ) {
    this.reservation = new ReservationViewModel(data);
    this.cancellationFee = this.reservation.cancellationFee;
    this.language = this.config.language;
  }

  public get isCancellationFeePayable() {
    return this.cancellationFee.isPayable();
  }
}
