import { Component, OnInit, Input } from '@angular/core';
import { ReservationModel } from 'up-ibe-types';
import { ReservationService } from '../../services/reservation.service';
import { ReservationStatusEnum } from 'enums';

@Component({
  selector: 'ibe-modify-reservation',
  templateUrl: './modify-reservation.component.html',
  styleUrls: ['./modify-reservation.component.scss']
})
export class ModifyReservationComponent implements OnInit {
  public isLoading = false;
  @Input('reservations') public reservations: ReservationModel[] = [];

  constructor(
    public readonly reservationService: ReservationService
  ) { }

  public ngOnInit() {
    this.getReservations();
  }

  public getReservations() {
    this.isLoading = true;
    this.reservationService.getGuestReservations()
      .subscribe((reservations: ReservationModel[]) => {
        if (reservations.length) {
          this.reservations = this.reservationService.filterReservationsByStatuses(reservations, [
            ReservationStatusEnum.Confirmed
          ]);
        } else {
          this.reservations = [];
        }
        this.isLoading = false;
      });
  }
}
