import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';

import { isEmpty } from 'lodash';

import { BookingService } from './booking.service';

@Injectable()
export class RoomResultsGuardService implements CanActivate {

  constructor(
    private readonly router: Router,
    private readonly bookingService: BookingService
  ) { }

  public canActivate(route: ActivatedRouteSnapshot) {
    if (isEmpty(route.queryParams)) {
      const stayCriteriaParams = this.bookingService.getLastSearchedStayCriteria();

      if (stayCriteriaParams) {
        this.router.navigate(['booking/results'], {
          queryParams: stayCriteriaParams
        });
      } else {
        this.router.navigate(['booking/search']);
        return false;
      }
    }

    return true;
  }
}
