import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Property } from 'up-ibe-types';
import { groupBy, map } from 'lodash';

interface PropertiesByCity {
  city: string;
  properties: Property[];
}

@Component({
  selector: 'ibe-property-selector',
  templateUrl: './property-selector.component.html',
  styleUrls: ['./property-selector.component.scss']
})
export class PropertySelectorComponent implements OnInit {
  @Input('invalidClass') public invalidClass: string;
  @Input('properties') public properties: Property[];
  @Input('selectedPropertyId') public selectedPropertyId: string;
  @Output('onPropertySelection') public onPropertySelection: EventEmitter<string> = new EventEmitter();
  @ViewChild(MatAutocompleteTrigger, { static: true }) public matAutoComplete: MatAutocompleteTrigger;
  public filteredMappedProperties: PropertiesByCity[];
  public filterValue = '';
  public propertyId = new FormControl();

  constructor(translate: TranslateService) {}

  public ngOnInit() {
    this._setInitialPropertyId();
    const mappedProperties = this._mapPropertiesByCity();
    this.filteredMappedProperties = [...mappedProperties];
    this._filterPropertiesBySearch(mappedProperties);
  }

  public onSearchSelection(property: Property) {
    this.propertyId.setValue(property.name);
    this.onPropertySelection.emit(property.pmsId);
  }

  public onPropertyIdInputClick() {
    this.propertyId.setValue('');
    this.matAutoComplete.openPanel();
  }

  public displayName(property: Property): string | undefined {
    return property ? (typeof property === 'string' ? property : property.name) : undefined;
  }

  private _setInitialPropertyId() {
    if (this.selectedPropertyId) {
      const selectedProperty = this.properties.find(property => {
        return property.pmsId === this.selectedPropertyId;
      });
      if (selectedProperty) {
        this.propertyId.setValue(selectedProperty.name);
      }
    }
  }

  private _mapPropertiesByCity() {
    const groupedPropertiesByCity = groupBy(this.properties, property => {
      return property.location?.city?.trim()
    });
    return map(groupedPropertiesByCity, (properties, city) => {
      return {
        city,
        properties: this._sortByName(properties)
      };
    });
  }

  private _filterPropertiesBySearch(mappedProperties: PropertiesByCity[]) {
    this.propertyId.valueChanges.subscribe(query => {
      this.filterValue = typeof query === 'string' ?
         query.toLowerCase() : query.name.toLowerCase();
      this.filteredMappedProperties = this._filterMappedProperties(mappedProperties);
    });
  }

  private _filterMappedProperties(mappedProperties: PropertiesByCity[]): PropertiesByCity[] {
    if (this.filterValue) {
      return mappedProperties.map(group => {
        return {
          city: group.city,
          properties: this._filterProperties(group.properties)
        };
      }).filter(group => group.properties.length > 0);
    }
    return mappedProperties;
  }

  private _filterProperties(properties: Property[]) {
    return properties.filter(property => {
      const inName = property.name.toLowerCase().includes(this.filterValue);
      const inCity = property.location?.city?.toLowerCase().includes(this.filterValue);
      return inName || inCity;
    });
  }

  private _sortByName(properties: Property[]) {
    return properties.sort((a, b) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      }
      if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      }
      return 0;
    });
  }

}
