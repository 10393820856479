<section class="ibe-guest-management ibe-guest-management-section">
  <ibe-guest-management-header></ibe-guest-management-header>
  <div class="ibe-guest-management-options">
  <div class="ibe-guest-management-option" (click)="navigateToGuestUrl('/guest/reservations/history')">
    <div class="ibe-guest-management-icon ibe-guest-reservations-history-icon">
      <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="file"><g id="lines"><line id="line-3" x1="15.549" y1="33.676" x2="32.451" y2="33.676" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/><line id="line-2" x1="15.549" y1="26.538" x2="32.451" y2="26.538" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/><line id="line-1" x1="15.549" y1="19.4" x2="32.451" y2="19.4" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/></g><path id="page" d="M39,41.9V11.863L31.451,5H10.211A1.16,1.16,0,0,0,9,6.1V41.9A1.16,1.16,0,0,0,10.211,43H37.789A1.16,1.16,0,0,0,39,41.9Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><polyline id="fold" points="30.287 5 30.287 12.92 39 12.92" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>
    </div>
    <div>
      <h2>{{ 'guest_management.view_reservations_history' | translate }}</h2>
      <p>{{ 'guest_management.view_reservations_history_text' | translate }}</p>
    </div>
  </div>
  <div class="ibe-guest-management-option" (click)="navigateToGuestUrl('/guest/reservations/modify')">
    <div class="ibe-guest-management-icon ibe-guest-modify-reservation-icon">
      <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="service-bell"><line x1="4.551" y1="36.966" x2="43.449" y2="36.966" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="19.678" y1="11.034" x2="28.322" y2="11.034" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><path d="M41.288,32.644a17.288,17.288,0,0,0-34.576,0Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="24" y1="15.356" x2="24" y2="11.034" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>
    </div>
    <div>
      <h2>{{ 'guest_management.modify_upcoming_reservations' | translate }}</h2>
      <p>{{ 'guest_management.modify_upcoming_reservations_text' | translate }}</p>
    </div>
  </div>
  <div class="ibe-guest-management-option" *ngIf="!reservationInProgress" (click)="navigateToGuestUrl('/booking/search')">
    <div class="ibe-guest-management-icon ibe-guest-new-reservation-icon">
      <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="double-bed"><rect x="4.75" y="27.469" width="38.5" height="7.75" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/><rect x="5.922" y="9.635" width="36.167" height="17.833" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/><line x1="7.219" y1="35.219" x2="7.219" y2="38.365" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="40.781" y1="35.219" x2="40.781" y2="38.365" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><path d="M42.089,27.469V25.676a3,3,0,0,0-3-3H8.921a3,3,0,0,0-3,3v1.793" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><rect x="11.438" y="17.677" width="10.125" height="5" rx="2.5" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/><rect x="26.438" y="17.677" width="10.125" height="5" rx="2.5" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/></g></svg>
    </div>
    <div>
      <h2>{{ 'guest_management.make_new_reservation' | translate }}</h2>
      <p>{{ 'guest_management.make_new_reservation_text' | translate }}</p>
    </div>
  </div>
  <div class="ibe-guest-management-option" *ngIf="reservationInProgress" (click)="navigateToGuestUrl('/checkout/details')">
    <div class="ibe-guest-management-icon ibe-guest-new-reservation-icon">
      <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="double-bed"><rect x="4.75" y="27.469" width="38.5" height="7.75" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/><rect x="5.922" y="9.635" width="36.167" height="17.833" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/><line x1="7.219" y1="35.219" x2="7.219" y2="38.365" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="40.781" y1="35.219" x2="40.781" y2="38.365" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><path d="M42.089,27.469V25.676a3,3,0,0,0-3-3H8.921a3,3,0,0,0-3,3v1.793" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><rect x="11.438" y="17.677" width="10.125" height="5" rx="2.5" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/><rect x="26.438" y="17.677" width="10.125" height="5" rx="2.5" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none"/></g></svg>
    </div>
    <div>
      <h2>{{ 'guest_management.continue_reservation' | translate }}</h2>
      <p>{{ 'guest_management.continue_reservation_text' | translate }}</p>
    </div>
  </div>
  <div class="ibe-guest-management-option" (click)="navigateToGuestUrl('/guest/account/edit')">
    <div class="ibe-guest-management-icon ibe-guest-edit-account-icon">
      <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><g id="edit"><path id="pencil" d="M42.373,11.047l1.761-1.761a2.278,2.278,0,0,0-.07-3.219L41.933,3.936a2.278,2.278,0,0,0-3.219-.07L36.953,5.627,21.141,21.439a1.183,1.183,0,0,0-.326.616l-1.194,6.324,6.324-1.194a1.183,1.183,0,0,0,.616-.326Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><polyline id="paper" points="40.16 24 40.16 43 5 43 5 7.84 24 7.84" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg>
    </div>
    <div>
      <h2>{{ 'guest_management.edit_account_information' | translate }}</h2>
      <p>{{ 'guest_management.edit_account_information_text' | translate }}</p>
    </div>
  </div>
</div>
</section>
