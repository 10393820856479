import { Component, OnInit, Input, Output, HostListener, EventEmitter, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { IbeConfigService } from '../services/ibe-config.service';
import { CalendarStayDateSelectionEvent } from '../booking/availability-calendar/calendar/calendar';

@Component({
  selector: 'ibe-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  @Input('arrival') public arrivalDate: Date = moment().toDate();
  @Input('departure') public departureDate: Date | undefined = moment().add(1, 'days').toDate();
  @Input('startDate') public startDate: Date;
  @Output('onStayDateSelection') public onStayDateSelection: EventEmitter<CalendarStayDateSelectionEvent> = new EventEmitter();
  @Output('closePopup') public closePopup: EventEmitter<undefined> = new EventEmitter();

  public leftCalendarCurrentMonth: Moment;
  public rightCalendarCurrentMonth: Moment;
  public leftCalendarDates = [[moment]];
  public rightCalendarDates = [[moment]];
  public hoveredDepartureDate: Date;

  constructor(
    private readonly _elementRef: ElementRef,
    public readonly currentRoute: ActivatedRoute,
    public readonly router: Router,
    public readonly config: IbeConfigService
  ) { }

  public ngOnInit() {
    this._initData();
  }

  public onArrivalDateSelection($event: Date) {
    this.arrivalDate = $event;
    this.departureDate = undefined;
    this._stayDateSelected();
  }

  public onDepartureDateSelection($event: Date) {
    if (!this.departureDate) {
      this.departureDate = $event;
      this._stayDateSelected();
      this.closePopup.emit();
    }
  }

  public onDepartureDateHover($event: Date) {
    this.hoveredDepartureDate = $event;
  }

  private _stayDateSelected() {
    if (this.arrivalDate && this.departureDate) {
      this.onStayDateSelection.emit({
        arrivalDate: this.arrivalDate,
        departureDate: this.departureDate
      });
    }
  }

  private _initData() {
    const queryParams = this.currentRoute.snapshot.queryParams;

    if (queryParams.arrival && queryParams.departure) {
      this.arrivalDate = moment(queryParams.arrival).toDate();
      this.departureDate = moment(queryParams.departure).toDate();
    } else if (!(this.arrivalDate && this.departureDate) && !(queryParams.arrival && queryParams.departure)) {
      this.arrivalDate = this.startDate;
      this.departureDate = moment(this.startDate).add(1, 'days').toDate();
    }

    this.leftCalendarCurrentMonth = moment(this.arrivalDate);
    this.rightCalendarCurrentMonth = moment(this.arrivalDate).add(1, 'months');
    this._initializeCalendars(this.leftCalendarCurrentMonth, this.rightCalendarCurrentMonth);
  }

  public onPreviousMonthClick($event: undefined) {
    this.leftCalendarCurrentMonth = moment(this.leftCalendarCurrentMonth).subtract(1, 'month');
    this.rightCalendarCurrentMonth = moment(this.rightCalendarCurrentMonth).subtract(1, 'month');
    this._initializeCalendars(this.leftCalendarCurrentMonth, this.rightCalendarCurrentMonth);
  }

  public onNextMonthClick($event: undefined) {
    this.leftCalendarCurrentMonth = moment(this.leftCalendarCurrentMonth).add(1, 'month');
    this.rightCalendarCurrentMonth = moment(this.rightCalendarCurrentMonth).add(1, 'month');
    this._initializeCalendars(this.leftCalendarCurrentMonth, this.rightCalendarCurrentMonth);
  }

  public translateMonth(currentMonth: Moment) {
    return moment(currentMonth).locale(this.config.language).format('MMM YYYY');
  }

  private _initializeCalendars(leftCalendarMonth: Moment, rightCalendarMonth: Moment) {
    this.leftCalendarDates = this._generateCalendarDates(leftCalendarMonth);
    this.rightCalendarDates = this._generateCalendarDates(rightCalendarMonth);
  }

  private _generateCalendarDates(currentMonth: Moment) {
    // days in the month
    const localCurrentMonth: Moment[] = Array(currentMonth.daysInMonth()).fill(undefined).map((day, index) => {
      return moment(currentMonth).date(index + 1);
    });

    const paddedMonth = [];
    // add empty entries to fill up everything before the first monday in the month
    let firstOfMonth = moment(localCurrentMonth[0]);
    while (firstOfMonth.weekday() !== 1) {
      paddedMonth.push(undefined);
      firstOfMonth = firstOfMonth.subtract(1, 'day');
    };

    // add the dates in the month
    paddedMonth.push(...localCurrentMonth.map(date => {
      return {
        date: moment(date.toISOString())
      };
    }));

    // split into 7's
    // tslint:disable-next-line:no-any
    const evenlySplitWeeks: any = [];
    const daysInWeekNumber = 7;
    paddedMonth.forEach((date, index) => {
      if (index % daysInWeekNumber === 0) {
        evenlySplitWeeks.push([]);
      };
      evenlySplitWeeks[evenlySplitWeeks.length - 1].push(date);
    });

    // pad the other way now
    const lastWeek = evenlySplitWeeks[evenlySplitWeeks.length - 1];
    while (lastWeek.length < daysInWeekNumber) {
      lastWeek.push(undefined);
    }

    return evenlySplitWeeks;
  }

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    const parentElement = targetElement.parentElement;

    if (
      !targetElement ||
      targetElement.id === 'ibeDateInput' ||
      (parentElement && parentElement.id === 'ibeDateInput')
    ) {
        return;
    }

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.closePopup.emit();
    }
  }
}
