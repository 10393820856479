
import { mergeMap, map, filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { BookingService } from '../services/booking.service';
import { ImagesService } from '../services/images.service';
import { IbeConfigService } from '../services/ibe-config.service';
import { flyRightOnLeaveAnimation } from '../animations';
import { Property, ReservationModel } from 'up-ibe-types';
import { BookingTotals, calculateCityTaxEstimate } from '../helpers/booking.helper';
import { GuestAuthService } from '../services/guest-auth.service';

@Component({
  selector: 'ibe-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  animations: [
    flyRightOnLeaveAnimation
  ]
})
export class CheckoutComponent implements OnInit {
  public reservations: ReservationModel[];
  public isReservationsEditable = false;
  public bookingTotals: BookingTotals;
  public showCityTaxEstimateEnabled = false;
  public showServiceChargeEnabled = true;
  public displayInclusiveExtrasAsTaxes = false;
  public cityTaxEstimate = 0;
  public serviceCharge = 0;
  public isSuppressed = false;

  constructor(
    public readonly bookingService: BookingService,
    public readonly imagesService: ImagesService,
    public readonly config: IbeConfigService,
    public readonly router: Router,
    public readonly currentRoute: ActivatedRoute,
    public readonly guestAuthService: GuestAuthService
  ) {
    this.reservations = this.bookingService.booking.reservations;
    this.isSuppressed = !!(this.reservations.find(reservation => reservation.suppressedRate));
  }

  public ngOnInit() {
    // slight leaky abstraction, this means they were redirected back here
    // after a mycheck iDEAL transaction. Can't set in deeper components
    // as they can't load, because localStorage might already be cleared.
    if (this.currentRoute.snapshot.queryParamMap.has('mycheck-transaction-id')) {
      return window.close();
    }

    if (this.reservations.length < 1) {
      return this.router.navigate(['booking/results']);
    }

    this._setIsReservationEditable();
    this.config.setCurrentProperty(this.reservations[0].property.id);

    this.config.getCurrentPropertySubscribable().subscribe(
      (property: Property) => {
        if (!property) {
          this.displayInclusiveExtrasAsTaxes = false;
        }

        if (property.config.displayInclusiveExtrasAsTaxes !== undefined) {
          this.displayInclusiveExtrasAsTaxes = property.config.displayInclusiveExtrasAsTaxes;
        }

        this.bookingTotals = this.bookingService.calculateBookingTotals(this.reservations, this.displayInclusiveExtrasAsTaxes);
        if (this.showServiceChargeEnabled) {
          this.serviceCharge = this.bookingTotals.serviceChargeTotal;
        }
      }
    );

    this.showCityTaxEstimateEnabled = this.config.settings.showCityTaxEstimateEnabled;
    if (this.showCityTaxEstimateEnabled) {
      this.cityTaxEstimate = calculateCityTaxEstimate(this.reservations);
    }
    this._removePmsGuestIdIfLoggedOut();
  }

  public addAnotherRoom() {
    this.router.navigate(['booking/results']);
  }

  public get bookingCurrency() {
    /* Because this gets consumed by something that
    only accepts string or undefined, we have to convert false
    to undefined */
    if (this.bookingService.bookingCurrency === false) {
      return undefined;
    } else {
      return this.bookingService.bookingCurrency;
    }
  }

  public onRemoveReservation() {
    this.reservations = this.bookingService.booking.reservations;
    this.bookingTotals = this.bookingService.calculateBookingTotals(this.reservations, this.displayInclusiveExtrasAsTaxes);
    this.cityTaxEstimate = calculateCityTaxEstimate(this.reservations);
  }

  public onExtrasChange() {
    this.bookingTotals = this.bookingService.calculateBookingTotals(this.reservations, this.displayInclusiveExtrasAsTaxes);
  }

  private _setIsReservationEditable() {
    if (this.currentRoute.snapshot.firstChild && this.currentRoute.snapshot.firstChild.data.isReservationsEditable) {
      this.isReservationsEditable = this.currentRoute.snapshot.firstChild.data.isReservationsEditable;
    }

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.currentRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data))
      .subscribe((event) => this.isReservationsEditable = event.isReservationsEditable);
  }

  private _removePmsGuestIdIfLoggedOut() {
    if (!this.guestAuthService.isLoggedIn()) {
      this.bookingService.removePmsGuestIdFromReservation();
    }
  }
}
