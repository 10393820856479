<div class="ibe-availability-restrictions">
  <svg class="ibe-date-restriction-warning-icon" width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.875 5.618l18.766 33.9A1 1 0 0 1 42.766 41H5.234a1 1 0 0 1-.875-1.484l18.766-33.9a1 1 0 0 1 1.75.002zM23.835 14c1.015 0 1.837.822 1.837 1.836v14.022a1.836 1.836 0 0 1-3.672 0V15.836c0-1.014.822-1.836 1.836-1.836zm1.836 21.835a1.836 1.836 0 1 0-3.671 0 1.836 1.836 0 0 0 3.671 0z" fill="#2b8ca1"/></svg>
  <h2>{{ 'availability_calendar.restrictions.date_has_restrictions' | translate }}</h2>
  <div class="ibe-restrictions-list">
    <div class="ibe-restriction" *ngIf="data.minLengthOfStay">
      <p [innerHTML]="'availability_calendar.restrictions.min_length_of_stay' | translate:minimumLength"></p>
    </div>
    <div class="ibe-restriction" *ngIf="data.maxLengthOfStay">
      <p [innerHTML]="'availability_calendar.restrictions.max_length_of_stay' | translate:maximumLength"></p>
    </div>
  </div>
  <button class="ibe-btn ibe-restrictions-ok-btn" (click)="onContinueClick($event)">{{ 'availability_calendar.restrictions.continue' | translate }}</button>
</div>
