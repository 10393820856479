import { Injectable } from '@angular/core';
import { Params, ActivatedRoute } from '@angular/router';
import { Observable, iif, throwError, of } from 'rxjs';
import { map, switchMap, shareReplay, retryWhen, delay, concatMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AvailabilityResultModel, AvailabilityResultByRegionModel } from 'up-ibe-types';
import { environment } from 'environments/environment';
import { arrayIfEmpty } from './store/common';
import { HttpStatus } from 'enums';
import { isEqual } from 'lodash';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityStoreService {

  constructor(
    private readonly http: HttpClient,
    private readonly route: ActivatedRoute,
    private eventsService: EventsService
  ) { }

  public availability$(validParams$: Observable<Params>) {
    const timeout = 2000;
    const maxRetries = 5;
    return validParams$.pipe(
      map((searchParams) => searchParams.toHttpParams()),
      switchMap((httpParams) => {
        return this.http.get<AvailabilityResultModel[]>(environment.serverUrl + '/api/ibe/availability', {
          params: httpParams
        })
      }),
      arrayIfEmpty(),
      shareReplay(),
      map((availability: AvailabilityResultModel[]) => {
        const newAvail = availability.reduce(
        (avail: AvailabilityResultModel[], offer: AvailabilityResultModel) => {

          const filterString: string = this.route?.snapshot?.queryParams?.filters;
          if (filterString) {
            const filterList = filterString.split(',');

            if (!filterList.length) {
              return avail;
            }
            if (offer.unitType && offer.unitType.attributes) {
              if (!filterList.every(filter => offer.unitType.attributes?.includes(filter))) {
                avail.splice(avail.findIndex(availOffer => isEqual(availOffer, offer)), 1);
              }
            }
          }
          return avail;
        }, availability);
        this.eventsService.getAvailabilityEvent().next(newAvail);
        return newAvail;
      }),
      retryWhen((errors) => {
        return errors.pipe(
          concatMap((error, index) => {
            return iif(
              () => (error.status === HttpStatus.Bad_Request) || (index >= maxRetries),
              throwError(error),
              of(error).pipe(delay(timeout))
            )
          })
        )
      })
    );
  }

  public availabilityForRegion$(validParams$: Observable<Params>) {
    return validParams$.pipe(
      map((searchParams) => searchParams.toHttpParams()),
      switchMap((httpParams) => {
        return this.http.get<AvailabilityResultByRegionModel[]>(environment.serverUrl + '/api/ibe/region-availability', {
          params: httpParams
        })
      }),
      arrayIfEmpty(),
      shareReplay()
    );
  }
}
