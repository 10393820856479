import { Component, OnInit } from '@angular/core';
import { BookingService } from '../../services/booking.service';
import { fadeInOnEnterAnimation, scaleDownOnLeaveAnimation } from '../../animations';

@Component({
  selector: 'ibe-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  animations: [
    fadeInOnEnterAnimation,
    scaleDownOnLeaveAnimation
  ]
})
export class CartComponent implements OnInit {
  public reservations = this.bookingService.booking.reservations;
  public isOpen = true;

  constructor(
    private readonly bookingService: BookingService
  ) { }

  public ngOnInit() {
    this.closeCartAfterPeriodOfTime();
  }

  public get reservationCount() {
    return this.bookingService.booking.reservations.length;
  }

  public get hasReservations() {
    return !!(this.bookingService.booking.reservations.length);
  }

  private closeCartAfterPeriodOfTime() {
    const openDuration = 2000;
    setTimeout(() => {
      this.isOpen = false;
    }, openDuration);
  }

  public toggleCart() {
    this.isOpen = !this.isOpen;
  }
}
