import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface DateRestrictions {
  closed: Boolean;
  closedOnArrival: Boolean;
  closedOnDeparture: Boolean;
  minLengthOfStay: Number;
  maxLengthOfStay: Number;
}

@Component({
  selector: 'ibe-availability-restrictions-dialog',
  templateUrl: './availability-restrictions-dialog.component.html',
  styleUrls: ['./availability-restrictions-dialog.component.scss']
})
export class AvailabilityRestrictionsDialogComponent implements OnInit {
  public minimumLength: { minLengthOfStay: Number };
  public maximumLength: { maxLengthOfStay: Number };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DateRestrictions,
    public dialogRef: MatDialogRef<AvailabilityRestrictionsDialogComponent>
  ) {
    this.dialogRef.disableClose = true;
  }

  public ngOnInit() {
    this.minimumLength = {
      minLengthOfStay: this.data.minLengthOfStay
    };
    this.maximumLength = {
      maxLengthOfStay: this.data.maxLengthOfStay
    };
  }

  public onContinueClick(event: Event) {
    event.preventDefault();
    this.dialogRef.close(true);
  }

  public onCancelClick(event: Event) {
    event.preventDefault();
    this.dialogRef.close(false);
  }

}
