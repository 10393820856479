/* tslint:disable:max-file-line-count */
import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IbeConfigService } from '../../../services/ibe-config.service';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { AdyenService } from '../../../services/adyen.service';
import { PaymentMethod } from '@adyen/adyen-web/dist/types/types';
import { MatDialog } from '@angular/material/dialog';
import { ErrorSource } from '../../../error-dialog/error-mapping';
import { AdyenFactory } from '../../../factories/adyen.factory';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { interval, Subscription } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { ErrorDialogService } from 'app/services/error-dialog.service';
import RedirectElement from '@adyen/adyen-web/dist/types/components/Redirect';
import { ErrorHandlerService, tryInitializeSentry } from '../../../services/error-handler.service';
import { captureException, Scope, Severity } from '@sentry/browser';

interface AdyenDropInState {
  data: { [key: string]: string },
  isValid: boolean
}

interface WebhookResponse {
  pspReference: string;
  amount: {
    currency: string;
    value: number;
  };
  merchantReference: string;
  merchantAccountCode: string;
  originalFolioId: string;
  propertyId: string;
  journal: {
    pspReference: string;
    amount: {
      currency: string;
      value: number;
    };
    eventCode: string;
    dateTime: string;
    isSuccess: boolean;
    reason: string;
  }[];
}

export interface AdyenDropinComponent {
  props: {
    type: string,
    [key: string]: unknown
  };

  handleAction(action: { [key: string]: string }): void;
}

interface AdyenPaymentPayload {
  propertyPaymentProviderSettings?: {
    merchantAccount: string;
    paymentCurrency: string;
  };
  PaRes?: string;
  MD?: string;
  redirectResult?: string;
  bookingReference?: string;
}

interface AdyenResponse {
  resultCode: string,
  reason: string,
  action?: { [key: string]: string },
  pspReference?: string
}

@Component({
  selector: 'ibe-adyen-dropin-payment',
  templateUrl: './adyen-dropin-payment.component.html',
  styleUrls: ['./adyen-dropin-payment.component.scss']
})
export class AdyenDropinPaymentComponent implements OnInit, OnDestroy {
  @ViewChild('dropin', { static: true }) public dropin: ElementRef;
  @Input('bookingRequestId') public bookingRequestId: string;
  @Input('paymentSetupData') public paymentSetupData: {
    payload: string,
    paymentMethods: PaymentMethod[],
    clientKey: string,
    merchantAccount: string,
    paymentCurrency: string,
    amount: number,
    countryCode: string,
    currency: string
    bookingOrReservationId?: string
  };
  @Input('isLoading') public isLoading: boolean;
  @Input('paymentRedirected') public paymentRedirected = false;
  @Input('paymentButtonLabel') public paymentButtonLabel = 'Pay';
  @Output('onComplete') public onComplete: EventEmitter<AdyenPaymentPayload> = new EventEmitter();
  @Output('toggleIsLoading') public toggleIsLoading: EventEmitter<boolean> = new EventEmitter();
  @Output('toggleInPaymentFlow') public toggleInPaymentFlow: EventEmitter<boolean> = new EventEmitter();
  @Output('togglePaymentRedirected') public togglePaymentRedirected: EventEmitter<boolean> = new EventEmitter();
  public showMessageSubscription: Subscription;
  public showMessage = false;
  public instance: RedirectElement;
  private hasSentry = false;

  constructor(
    private readonly http: HttpClient,
    private readonly translate: TranslateService,
    private readonly config: IbeConfigService,
    public readonly route: ActivatedRoute,
    public readonly adyenService: AdyenService,
    public readonly dialog: MatDialog,
    private readonly adyenFactory: AdyenFactory,
    public readonly errorDialogService: ErrorDialogService
  ) {
  }

  public ngOnInit(): void {
    this.hasSentry = tryInitializeSentry();
    if (this.paymentRedirected && this.hasPayload()) {
      if (this.route.snapshot.queryParams.redirectResult) {
        // This is called when we have a direct redirect result from a payment provider.
        /*
         * I have absolutely no idea why the following was done, because this is literally
         * just the verify payment step. I don't know why this is being done early, but it screws
         * with the flow at the moment so I'm commenting it out. If we need it in the future it's here
        */
        // this.adyenService.submitAdditionalDetails(this.bookingRequestId, {
        //   details: {
        //     redirectResult: this.route.snapshot.queryParams.redirectResult
        //   }
        // }).subscribe((response: AdyenResponse) => {
        //   this.handlePaymentResponse(response)
        // })
        // return;
      }

      this.togglePaymentRedirected.emit(true);
      this.toggleInPaymentFlow.emit(true);

      let redirectResult = this.route.snapshot.queryParams['redirectResult'];

      // Putting this here because a weird issue cropped up where it was being pulled out as an array
      // which is invalid and breaks the flow
      if (Array.isArray(redirectResult)) {
        redirectResult = redirectResult[0];
      }

      this.onComplete.emit({
        redirectResult,
        bookingReference: this.bookingRequestId
      });

      return;
    }

    this.setupAdyenWidget().then(
      () => {
        this.showMessageSubscription = this.adyenFactory.showMessage.subscribe(
          response => this.showMessage = response,
          error => this.handleError(error, 'http', 'ibe-adyen-dropin-payment.adyenFactory', {})
        );
      }
    ).catch(error => this.handleError(error, 'http', 'ibe-adyen-dropin-payment.setupAdyenWidget', {}));
  }

  public ngOnDestroy() {
    if (this.showMessageSubscription) {
      this.showMessageSubscription.unsubscribe();
    }
  }

  public async setupAdyenWidget(): Promise<void> {
    const locale = this.mapLanguageCode(this.config.language);
    const translations = {
      [locale]: {
        'payButton': this.paymentButtonLabel
      }
    };
    const configuration: CoreOptions = {
      paymentMethodsResponse: {
        paymentMethods: this.paymentSetupData.paymentMethods
      },
      clientKey: this.paymentSetupData.clientKey,
      locale,
      translations,
      environment: this.config.getPaymentProviderSettings().testModeEnabled ? 'test' : 'live',
      showPayButton: true,
      onReady: () => {
        this.toggleIsLoading.emit(false);
      },
      onSubmit: (state: AdyenDropInState, dropin: AdyenDropinComponent) => {
        this.toggleIsLoading.emit(true);
        this.toggleInPaymentFlow.emit(true);

        if (state.isValid) {
          this.adyenService.getIpAddress().then(response => {
            this.initiatePayment(state, dropin, response.ip);
          }).catch(() => {
            // ignore IP address request failure ( https://myibe.com/mip/ )
            // - the IP is not required for the payment
            // - this is a bad hack and should be removed
            // - the server must read the ip (client data can be spoofed)
            this.initiatePayment(state, dropin);
          });
        }
      },
      onAdditionalDetails: (state: AdyenDropInState, dropin: AdyenDropinComponent) => {
        this.toggleIsLoading.emit(true);
        this.submitAdditionalDetails(state, dropin);
      },
      onError: (error: unknown) => {
        this.handleError(error, 'http', 'ibe-adyen-dropin-payment.onError', {});
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
          enableStoreDetails: true,
          hideCVC: false,
          name: this.translate.instant('checkout_payment.method_card.name')
        },
        paypal: {
          environment: this.config.getPaymentProviderSettings().testModeEnabled ? 'test' : 'live',
          countryCode: this.paymentSetupData.countryCode,
          amount: {
            currency: this.paymentSetupData.currency,
            // tslint:disable-next-line:no-magic-numbers
            value: this.paymentSetupData.amount * 100
          },
          onCancel: (data, dropin) => {
            dropin.setStatus('ready');
            this.toggleIsLoading.emit(false);
          }
        },
        threeDS2: {
          challengeWindowSize: '05'
        }
      }
    };

    this.instance = await this.adyenFactory.create(
      configuration,
      'dropin',
      this.dropin.nativeElement
    );

    this.toggleIsLoading.emit(false);
  }

  private hasPayload(): boolean {
    return !!this.route.snapshot.queryParams.payload;
  }

  public submitAdditionalDetails(state: AdyenDropInState, dropin: AdyenDropinComponent) {
    this.adyenService.submitAdditionalDetails(this.bookingRequestId, state.data).subscribe(
      response => {
        this.handlePaymentResponse(response, dropin);
      },
      error => {
        this.errorDialogService.errorDialog(error.statusText ?? 'Unexpected Error', ErrorSource.ADYENDROPIN);
      }
    );
  }

  public getJournalForPspReference(response: WebhookResponse | null, pspReference?: string) {
    return response?.journal.find(
      j => j.eventCode === 'AUTHORISATION' && j.pspReference === response.pspReference
    );
  }

  public isSuccessfulWebhookResponse(response: WebhookResponse | null, pspReference?: string): boolean {
    return !!this.getJournalForPspReference(response, pspReference);
  }

  public pollBookingsPspReference(pspReference: string) {
    return this.http.get(`${environment.serverUrl}/api/webhooks/bookings/${this.bookingRequestId}/payment-transactions`, {
      params: {
        pspReference
      }
    });
  }

  public handlePaymentResponse(
    response: AdyenResponse,
    dropin?: AdyenDropinComponent
  ) {
    if (response.action && dropin) {
      this.toggleInPaymentFlow.emit(false);
      dropin.handleAction(response.action);
      this.toggleIsLoading.emit(false);
      return;
    }

    if (response.pspReference && (response.resultCode === 'Pending' || response.resultCode === 'Received')) {
      const INTERVAL = 5000;
      interval(INTERVAL).pipe(
        switchMap(() => this.pollBookingsPspReference(response.pspReference as string)),
        takeWhile((webhookResponse: WebhookResponse | null) => {
          return !this.isSuccessfulWebhookResponse(webhookResponse, response.pspReference);
        }, true)
      ).subscribe(
        (webhook: WebhookResponse) => {
          this.isLoading = false;
          this.toggleIsLoading.emit(false);

          if (response.pspReference && this.isSuccessfulWebhookResponse(webhook, response.pspReference)) {
            const journal = this.getJournalForPspReference(webhook, response.pspReference);

            if (journal && journal.isSuccess && journal.reason === 'Authorised') {
              return this.onAuthorisation();
            }
          } else if (response.pspReference && (response.resultCode === 'Pending' || response.resultCode === 'Received')) {
            return;
          }
          this.errorDialogService.errorDialog(response.reason, ErrorSource.ADYENDROPIN);
        },
        error => this.handleError(error, 'http', 'ibe-adyen-dropin-payment.setupAdyenWidget', {})
        // this.errorDialogService.errorDialog(error.statusText ?? 'Unexpected Error', ErrorSource.ADYENDROPIN);
      );

      return;
    }

    this.isLoading = false;
    this.toggleIsLoading.emit(false);

    if (response.resultCode === 'Authorised') {
      return this.onAuthorisation();
    }

    this.toggleInPaymentFlow.emit(false);

    this.errorDialogService.errorDialog(response.reason, ErrorSource.ADYENDROPIN);
  }

  public onAuthorisation() {
    if (!this.paymentSetupData) {
      return this.onComplete.emit();
    }

    return this.onComplete.emit({
      propertyPaymentProviderSettings: {
        merchantAccount: this.paymentSetupData.merchantAccount,
        paymentCurrency: this.paymentSetupData.paymentCurrency
      }
    });
  }

  public initiatePayment(state: AdyenDropInState, dropin: AdyenDropinComponent, ipAddress?: string) {
    this.isLoading = true;
    this.toggleIsLoading.emit(true);
    this.toggleInPaymentFlow.emit(true);

    const payload = {
      data: state.data,
      bookingRequestId: this.bookingRequestId,
      merchantAccount: this.paymentSetupData.merchantAccount,
      bookingOrReservationId: this.paymentSetupData.bookingOrReservationId,
      ipAddress
    };

    this.adyenService.initiatePayment(payload).subscribe(
      (response) => {
        this.handlePaymentResponse(response, dropin);
      },
      (error) => {
        this.handleError(error, 'http', 'ibe-adyen-dropin-payment.initiatePayment', {});
      }
    );
  }

  public mapLanguageCode(languageCode: string) {
    if (languageCode === 'en') {
      return 'en_GB';
    } else if (languageCode === 'de') {
      return 'de_DE';
    } else if (languageCode === 'fi') {
      return 'fi_FI';
    } else if (languageCode === 'se') {
      return 'se_SE';
    } else if (languageCode === 'ru') {
      return 'ru_RU';
    } else if (languageCode === 'fr') {
      return 'fr_FR';
    } else {
      return 'en_GB';
    }
  }

  // tslint:disable-next-line:no-any
  private handleError(error: any, type: string, category: string, details: { [key: string]: any; }): void {
    if (this.hasSentry) {
      const err = ErrorHandlerService.toError(error);
      details.error = error;
      captureException(err, (scope: Scope) =>
        scope.addBreadcrumb({
          type,
          level: Severity.Critical,
          message: `${err.message}`,
          category,
          data: details
        })
      );
    } else {
      console.error('handleError', error);
    }

    this.errorDialogService.errorDialog('Payment Process Failed', ErrorSource.ADYENDROPIN);
  }
}
