import { enableProdMode, LOCALE_ID, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { IbeConfigService } from './app/services/ibe-config.service';
import { EventsService } from './app/services/events.service';

// for @ngu-carousel
// tslint:disable-next-line: no-import-side-effect
import 'hammerjs';
import { Observable } from 'rxjs';

if (environment.production) {
  enableProdMode();
}

const oldTag = document.querySelector('ibe-apaleo');

if (oldTag) {
  oldTag.outerHTML = oldTag.outerHTML.replace(/ibe-apaleo/g, 'ibe-up');
}

let service: EventsService;

function maybeBootstrap(): Promise<NgModuleRef<AppModule>>|undefined {
  if (document.querySelector('ibe-up')) {
    return platformBrowserDynamic().bootstrapModule(AppModule, {
      providers: [{
        provide: LOCALE_ID,
        deps: [IbeConfigService],
        useFactory: (ibeConfigService: IbeConfigService) => ibeConfigService.language
      }]

    }).then(ref => {
      service = ref.injector.get(EventsService);

      return ref;
    });
  } else {
    console.warn('It looks like ibe.min.js is included on a page where the IBE is not used. For performance reasons you should avoid doing this.');
    return undefined;
  }

}

interface IbeEvents {
  [key: string]: Observable<Object>
}

declare global {
  interface Window {
    up:
    {
      bootstrapEngine(): void;
      destroyEngine(): void;
      ibeEvents(): IbeEvents;
    },
    myIbe: {
      bootstrapEngine(): void;
      destroyEngine(): void;
      ibeEvents(): IbeEvents;
    }
  }
}

let _maybePlatformRef = maybeBootstrap();

const bootstraps = {
  bootstrapEngine() {
    _maybePlatformRef = maybeBootstrap();
  },
  destroyEngine() {
    if (_maybePlatformRef) { _maybePlatformRef.then(ref => ref.destroy()) }
  },
  ibeEvents(): IbeEvents {
    return {
      availability: service.getAvailabilityEvent(),
      step: service.getStepEvent()
    }
  }
};

window.myIbe = window.myIbe || {};
Object.assign(window.myIbe, bootstraps);

// tslint:disable-next-line:no-console
console.log(`IBE Version: ${environment.version}`);
