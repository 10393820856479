/* tslint:disable:max-file-line-count */
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import {
  BookingModel,
  ReservationModel,
  ExtraModel,
  EmbeddedItemModel,
  AvailabilityResultModel,
  AvailabilityResultRateModel,
  UnitTypeModel,
  Property,
  AvailabilityResultByRegionModel
} from 'up-ibe-types';
import { flatten, isEmpty, get } from 'lodash';
import { IbeConfigService } from './ibe-config.service';
import { calculateBookingTotals } from '../helpers/booking.helper';
import * as moment from 'moment';

interface GoogleAnalyticsProductObject {
  name: string | undefined;
  id: string;
  // extras do not have variants
  variant?: string;
  price: number;
  quantity: number;
  brand: string;
}

@Injectable()
export class AnalyticsService {
  constructor(
    public readonly config: IbeConfigService
  ) {}
  public createNoResultsEvent(queryParams: HttpParams, property: Property|undefined) {
    if (this.defineOrGetDatalayer()) {
      dataLayer.push({
        'event': 'noResults',
        'pageUrl': '/booking/impressions',
        'arrivalDate': queryParams.get('arrival'),
        'departureDate': queryParams.get('departure'),
        'numberOfGuests': queryParams.get('adults'),
        'numberOfRooms': 0,
        'hotel': (property) ? property.name : 'not set'
      });
    }
  }

  public createNoRegionResultsEvent(queryParams: HttpParams, region: string) {
    if (this.defineOrGetDatalayer()) {
      dataLayer.push({
        'event': 'noResults',
        'pageUrl': '/booking/impressions',
        'arrivalDate': queryParams.get('arrival'),
        'departureDate': queryParams.get('departure'),
        'numberOfGuests': queryParams.get('adults'),
        'numberOfRooms': 0,
        'region': (region) ? region : 'not set'
      });
    }
  }

  public createRoomImpressionsEvent(property: EmbeddedItemModel, availabilityResults: AvailabilityResultModel[], queryParams: HttpParams) {
    const impressions = availabilityResults.map((availabilityResult) => {
      return availabilityResult.rates.map((rate) => {
        return {
          'brand': availabilityResult.unitType.name,
          'category': property.name,
          'id': availabilityResult.unitType.id,
          'list': 'Search Room Results',
          'name': rate.name,
          'price': rate.totalGrossAmount.amount,
          'quantity': rate.availableUnits,
          'variant': rate.id
        };
      });
    });

    let numOfChildren = queryParams.getAll('childrenAges') || 0
    const numOfAdults = queryParams.get('adults') || 0;
    if (Array.isArray(numOfChildren)) {
      numOfChildren = numOfChildren.length;
    }

    const numberOfGuests = (+numOfAdults) + (+numOfChildren);

    const cheapestRoom = availabilityResults.reduce((accumulator, result) => {
      if (result.fromPrice < accumulator.fromPrice) {
        return result;
      }
      return accumulator;
    })

    const numOfNights = moment(availabilityResults[0].departure).diff(moment(availabilityResults[0].arrival), 'days')

    if (this.defineOrGetDatalayer()) {
      dataLayer.push({
        'event': 'showRooms',
        'pageUrl': '/booking/impressions',
        'hotel': property.name,
        'ecommerce': {
          'impressions': flatten(impressions)
        },
        'up': {
          'lowestPrice': cheapestRoom.fromPrice,
          'children': numOfChildren,
          'numberOfGuests': numberOfGuests,
          'numberOfRooms': availabilityResults.length,
          'roomNights': numOfNights,
          'arrivalDate': availabilityResults[0].arrival,
          'departureDate': availabilityResults[0].departure,
          'dateMade': moment(new Date()).format('YYYY-MM-DD'),
          'specialCode': queryParams.get('promoCode'),
          'hotel': property.name
        }
      });
    }
  }

  public createExtrasImpressionsEvent(property: EmbeddedItemModel, extras: ExtraModel[]) {
    // rest of the function errors if extras is empty, so do not move
    if (isEmpty(extras)) { return; }

    const currency = get(extras[0], 'totalGrossAmount.currency') || 'unset';

    const impressions = extras.map((extra) => {
      return {
        'name': extra.name,
        'id': extra.id,
        'price': getExtraPrice(extra),
        'brand': property.name,
        'category': 'extra',
        'list': 'Search Room Results'
      };
    });

    if (this.defineOrGetDatalayer()) {
      dataLayer.push({
        'event': 'showExtras',
        'ecommerce': {
          'currencyCode': currency,
          'impressions': impressions
        }
      });
    }
  }

  public createRegionSearchImpressionEvent(region: string, results: AvailabilityResultByRegionModel[]) {
    // rest of the function errors if extras is empty, so do not move
    if (isEmpty(results)) { return; }

    const currency = get(results[0], 'fromPrice.currency') || 'unset';

    const impressions = results.map((result) => {
      return {
        'name': result.property.name,
        'id': result.property.id,
        'price': result.fromPrice.amount || 0,
        'brand': region,
        'category': 'city',
        'list': 'Search Room Results'
      };
    });

    if (this.defineOrGetDatalayer()) {
      dataLayer.push({
        'event': 'showRegionResults',
        'ecommerce': {
          'currencyCode': currency,
          'impressions': impressions
        }
      });
    }
  }

  public createRoomViewEvent(propertyName: string, room: AvailabilityResultRateModel | UnitTypeModel) {
    if (this.defineOrGetDatalayer()) {
      dataLayer.push({
        'event': 'productDetail',
        'hotel': (propertyName) ? propertyName : 'not set',
        'ecommerce': {
          'detail': {
            'actionField': {'list': 'Search Room Results'},
            'products': [{
               'name': room.name,
               'id': room.id,
               'brand': propertyName,
               'category': 'room'
             }]
           }
         }
      });
    }
  }

  public createRoomClickEvent(propertyName: string, room: AvailabilityResultRateModel | UnitTypeModel) {
    if (this.defineOrGetDatalayer()) {
      dataLayer.push({
        'event': 'productClick',
        'hotel': (propertyName) ? propertyName : 'not set',
        'ecommerce': {
          'click': {
            'actionField': {'list': 'Search Room Results'},
            'products': [{
               'name': room.name,
               'id': room.id,
               'brand': propertyName,
               'category': 'room'
             }]
           }
         }
      });
    }
  }

  public createRoomAddToCartEvent(roomQty: number, reservation: ReservationModel) {
    if (this.defineOrGetDatalayer()) {
      dataLayer.push({
        'event': 'addToCart',
        'hotel': (reservation.property.name) ? reservation.property.name : 'not set',
        'ecommerce': {
          'currencyCode': reservation.totalGrossAmount.currency,
          'add': {
            'actionField': {'list': 'Search Room Results'},
            'products': [{
               'name': reservation.unitType.name,
               'id': reservation.unitType.id,
               'price': reservation.totalGrossAmount.amount,
               'brand': reservation.property.name,
               'category': 'room',
               'variant': reservation.ratePlan.id,
               'quantity': roomQty
             }]
           }
         },
         'up': {
           'numberOfGuests': reservation.adults + reservation.childrenAges.length,
           'numberOfRooms': 1
         }
      });
    }
  }

  public createExtrasAddToCartEvent(reservation: ReservationModel) {
    if (isEmpty(reservation.extras)) { return };

    const products = reservation.extras.map((extra: ExtraModel) => {
      let qty = 1;
      if (extra.pricingUnit === 'Person') {
        qty = reservation.adults;
      }

      return {
        'name': extra.name,
        'id': extra.id,
        'price': getExtraPrice(extra),
        'brand': reservation.property.name,
        'category': 'extra',
        'quantity': qty
      };
    });

    if (this.defineOrGetDatalayer()) {
      dataLayer.push({
        'event': 'addToCart',
        'hotel': (reservation.property.name) ? reservation.property.name : 'not set',
        'ecommerce': {
          'currencyCode': reservation.totalGrossAmount.currency,
          'add': {
            'actionField': {'list': 'Search Room Results'},
            'products': products
           }
         }
      });
    }
  }

  public createRoomRemoveFromCartEvent(reservation: ReservationModel) {
    if (this.defineOrGetDatalayer()) {
      dataLayer.push({
        'event': 'removeFromCart',
        'hotel': (reservation.property.name) ? reservation.property.name : 'not set',
        'ecommerce': {
          'remove': {
            'actionField': {'list': 'Search Room Results'},
            'products': [{
               'name': reservation.unitType.name,
               'id': reservation.unitType.id,
               'price': reservation.totalGrossAmount.amount,
               'brand': reservation.property.name,
               'category': 'room',
               'variant': reservation.ratePlan.id,
               'quantity': 1
             }]
           }
         }
      });
    }
  }

  public createRoomExtrasRemoveFromCartEvent(reservation: ReservationModel) {
    if (isEmpty(reservation.extras)) { return };

    const products = reservation.extras.map((extra: ExtraModel) => {
      return {
        'name': extra.name,
        'id': extra.id,
        'price': getExtraPrice(extra),
        'brand': reservation.property.name,
        'category': 'extra',
        'quantity': reservation.adults
      };
    });

    if (this.defineOrGetDatalayer()) {
      dataLayer.push({
        'event': 'removeFromCart',
        'ecommerce': {
          'remove': {
            'actionField': {'list': 'Search Room Results'},
            'products': products
           }
         }
      });
    }
  }

  public createExtraRemoveFromCartEvent(property: EmbeddedItemModel, extra: ExtraModel, personsQty: number) {
    if (this.defineOrGetDatalayer()) {
      dataLayer.push({
        'event': 'removeFromCart',
        'hotel': (property.name) ? property.name : 'not set',
        'ecommerce': {
          'remove': {
            'actionField': {'list': 'Search Room Results'},
            'products': [{
              'name': extra.name,
              'id': extra.id,
              'price': getExtraPrice(extra),
              'brand': property.name,
              'category': 'extra',
              'quantity': personsQty
            }]
           }
         }
      });
    }
  }

  public createCheckoutEvent(reservations: ReservationModel[] | undefined, step: number) {
    const products = reservations?.map((reservation, _) => {
      return {
         'name': reservation.unitType.name,
         'id': reservation.unitType.id,
         'price': reservation.totalGrossAmount.amount,
         'brand': reservation.property.name,
         'category': 'checkout',
         'variant': reservation.ratePlan.id,
         'quantity': 1
       };
    });

    if (this.defineOrGetDatalayer()) {
      dataLayer.push({
        'event': 'checkout',
        'hotel': (products?.[0]?.brand) ? products[0].brand : 'not set',
        'ecommerce': {
          'checkout': {
            'actionField': {'step': step, 'list': 'Search Room Results'},
            'products': products
         }
       }
      });
    }
  }

  public createAnalyticsTransactionEvent(bookingRequestId: string, booking: BookingModel, pmsBookingIds: string[]) {
    const bookingTotals = calculateBookingTotals(booking.reservations);
    let products: GoogleAnalyticsProductObject[] = [];

    booking.reservations.forEach((reservation: ReservationModel) => {
      const offerProduct = {
        'upArrival': reservation.arrival,
        'upDeparture': reservation.departure,
        'name': `${reservation.unitType.name} - (${reservation.ratePlan.name})`,
        'id': reservation.unitType.id,
        'variant': reservation.ratePlan.id,
        'price': reservation.totalGrossAmount.amount,
        'brand': reservation.property.name,
        'category': 'room',
        'quantity': 1
      };

      const extraProducts = reservation.extras.map((extra: ExtraModel) => {
        let qty = 1;
        if (extra.pricingUnit === 'Person') {
          qty = reservation.adults;
        }

        return {
          'name': extra.name,
          'id': extra.id,
          'price': getExtraPrice(extra),
          'brand': reservation.property.name,
          'category': 'extra',
          'quantity': qty
        };
      });

      products.push(offerProduct);
      products = products.concat(extraProducts);
    });

    if (this.defineOrGetDatalayer()) {
      dataLayer.push({
        'event': 'bookingCompletion',
        'hotel': (products?.[0]?.brand) ? products[0].brand : 'not set',
        'ecommerce': {
          'purchase': {
            'actionField': {
              'id': bookingRequestId,
              'upBookingIds': pmsBookingIds,
              'affiliation': 'UP Booking Engine',
              'revenue': bookingTotals.grossTotal,
              'upNet': bookingTotals.netTotal,
              'tax': bookingTotals.taxTotal,
              'upCurrency': booking.reservations[0].totalGrossAmount.currency,
              'upFirstName': booking.booker.firstName,
              'upLastName': booking.booker.lastName,
              'upEmail': booking.booker.email,
              'list': 'Search Room Results'
            },
            'products': products
          }
        }
      });
    }
  }

  // Data is sent for each individual reservation in a booking for clients who need specific data from each booking
  public createCompletedReservationsEvent(bookingRequestId: string, reservations: ReservationModel[]) {
    reservations.forEach((reservation: ReservationModel) => {
      const reservationTotal = calculateBookingTotals([reservation]);

      const numOfNights = moment(reservation.departure).diff(moment(reservation.arrival), 'days');

      if (this.defineOrGetDatalayer()) {
        dataLayer.push({
          'event': 'completedReservation',
          'hotel': reservation.property.name,
          'upBookingReference': bookingRequestId,
          'reservationId': reservation.id || '',
          'name': `${reservation.unitType.name} - (${reservation.ratePlan.name})`,
          'propertyName': reservation.property.name,
          'arrivalDate': reservation.arrival,
          'departureDate': reservation.departure,
          'numOfAdults': reservation.adults,
          'numOfChildren': reservation.childrenAges.length,
          'numberOfGuests': reservation.adults + reservation.childrenAges.length,
          'numberOfRooms': reservations.length,
          'roomTypeId': reservation.unitType.id,
          'roomTypeName': reservation.unitType.name,
          'ratePlanId': reservation.ratePlan.id,
          'ratePlanName': reservation.ratePlan.name,
          'roomNights': numOfNights,
          'revenue': reservationTotal.grossTotal,
          'net': reservationTotal.netTotal,
          'currency': reservation.totalGrossAmount.currency,
          'confirmationNumber': reservation.bookingReference || ''
        });
      }
    });
  }

  public defineOrGetDatalayer() {
    return window.dataLayer = window.dataLayer || [];
  }
}

function getExtraPrice(extra: ExtraModel) {
 if (extra.totalGrossAmount) {
   return extra.totalGrossAmount.amount;
 } else {
   return 0;
 }
}
