import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { BookingService } from '../../services/booking.service';
import { EventsService } from 'app/services/events.service';

@Component({
  selector: 'ibe-guest-management-options',
  templateUrl: './guest-management-options.component.html',
  styleUrls: ['./guest-management-options.component.scss']
})
export class GuestManagementOptionsComponent {

  public reservationInProgress = false;

  constructor(
    private router: Router,
    private bookingService: BookingService,
    private eventsService: EventsService
  ) {
    if (this.bookingService.booking.reservations.length) {
      this.reservationInProgress = true;
    }
    this.eventsService.getStepEvent().next('guest-manage');
  }

  public navigateToGuestUrl(path: string) {
    this.router.navigateByUrl(path);
  }
}
