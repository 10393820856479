<div class="ibe-calendar">
  <div class="ibe-calendar-navigation">
    <button (click)="selectPreviousMonth()">
      <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><defs><style>.cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:3px;}</style></defs><title>arrow-left</title><polyline class="cls-1" points="28.69 33.9 18.78 24 28.69 14.1"/></svg>
    </button>
    <span>{{ currentMonthDisplay }}</span>
    <button (click)="selectNextMonth()">
      <svg id="Icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><defs><style>.cls-1{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:3px;}</style></defs><title>arrow-right</title><polyline class="cls-1" points="19.31 14.1 29.22 24 19.31 33.9"/></svg>
    </button>
  </div>
  <div class="ibe-calendar-header">
    <span>{{ 'availability_calendar.m' | translate }}</span>
    <span>{{ 'availability_calendar.tu' | translate }}</span>
    <span>{{ 'availability_calendar.w' | translate }}</span>
    <span>{{ 'availability_calendar.th' | translate }}</span>
    <span>{{ 'availability_calendar.f' | translate }}</span>
    <span>{{ 'availability_calendar.sa' | translate }}</span>
    <span>{{ 'availability_calendar.su' | translate }}</span>
  </div>
  <div class="ibe-calendar-rows" *ngIf="!isLoading">
    <div class="ibe-calendar-row" *ngFor="let row of calendarDates">
      <div class="ibe-calendar-day-wrapper" *ngFor="let day of row">
        <div class="ibe-calendar-day" *ngIf="day" [ngClass]="calendarDayClass(day)" (click)="onDaySelect($event, day)" (mouseenter)="onDayHover($event, day)">
          <div class="ibe-calendar-in-out-label">
            <div class="ibe-closed-arrival-departure-warning" *ngIf="day.data.isAvailable && day.data && day.data.restrictions && (day.data.restrictions.closedOnArrival || day.data.restrictions.closedOnDeparture)">
              <div class="ibe-closed-arrival" *ngIf="day.data.isAvailable && day.data && day.data.restrictions && day.data.restrictions.closedOnArrival">
                <svg class="ibe-closed-arrival-icon" xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M2.5 19h19v2h-19v-2zm16.84-3.15c.8.21 1.62-.26 1.84-1.06.21-.8-.26-1.62-1.06-1.84l-5.31-1.42-2.76-9.02L10.12 2v8.28L5.15 8.95l-.93-2.32-1.45-.39v5.17l16.57 4.44z"/></svg>            </div>
              <div class="ibe-closed-departure" *ngIf="day.data.isAvailable && day.data && day.data.restrictions && day.data.restrictions.closedOnDeparture">
                <svg class="ibe-closed-departure-icon" xmlns="http://www.w3.org/2000/svg" height="14px" viewBox="0 0 24 24" width="14px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M2.5 19h19v2h-19v-2zm19.57-9.36c-.21-.8-1.04-1.28-1.84-1.06L14.92 10l-6.9-6.43-1.93.51 4.14 7.17-4.97 1.33-1.97-1.54-1.45.39 2.59 4.49L21 11.49c.81-.23 1.28-1.05 1.07-1.85z"/></svg>            </div>
            </div>
            <div class="ibe-date-restriction-warning" *ngIf="day.data && day.hasRestrictions && (restrictionSet.length > 1 || (numOfDaysWithRestrictions !== numOfDaysLeftInMonth))">
              <svg class="ibe-date-restriction-warning-icon" width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.875 5.618l18.766 33.9A1 1 0 0 1 42.766 41H5.234a1 1 0 0 1-.875-1.484l18.766-33.9a1 1 0 0 1 1.75.002zM23.835 14c1.015 0 1.837.822 1.837 1.836v14.022a1.836 1.836 0 0 1-3.672 0V15.836c0-1.014.822-1.836 1.836-1.836zm1.836 21.835a1.836 1.836 0 1 0-3.671 0 1.836 1.836 0 0 0 3.671 0z"/></svg>
              <span class="ibe-date-restriction-warning-text">This date has some restrictions</span>
            </div>
            <span *ngIf="isArrivalDay(day)">{{ 'availability_calendar.in' | translate }}</span>
            <span *ngIf="isDepartureDay(day)">{{ 'availability_calendar.out' | translate }}</span>
          </div>
          <span class="ibe-calendar-day-number-label">{{ day.date | date:'d' }}</span>
          <span class="ibe-calendar-day-price" *ngIf="day.data">{{ day.data.price | currency:day.data.currency:'symbol':'1.2-2' }}</span>
        </div>
    </div>
  </div>
</div>
<div class="ibe-restriction-warning ibe-min-los-restriction" *ngIf="(numOfDaysWithRestrictions === numOfDaysLeftInMonth) && restrictionSet.length == 1 && restrictionSet[0].minLengthOfStay">
  <svg class="ibe-date-restriction-warning-icon" width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.875 5.618l18.766 33.9A1 1 0 0 1 42.766 41H5.234a1 1 0 0 1-.875-1.484l18.766-33.9a1 1 0 0 1 1.75.002zM23.835 14c1.015 0 1.837.822 1.837 1.836v14.022a1.836 1.836 0 0 1-3.672 0V15.836c0-1.014.822-1.836 1.836-1.836zm1.836 21.835a1.836 1.836 0 1 0-3.671 0 1.836 1.836 0 0 0 3.671 0z"/></svg>
  <span class="ibe-restriction-warning-text" [innerHTML]="getRestrictionText('minLengthOfStay')"></span>
</div>
<div class="ibe-restriction-warning ibe-max-los-restriction" *ngIf="(numOfDaysWithRestrictions === numOfDaysLeftInMonth) && restrictionSet.length == 1 && restrictionSet[0].maxLengthOfStay">
  <svg class="ibe-date-restriction-warning-icon" width="18" height="18" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path fill-rule="evenodd" clip-rule="evenodd" d="M24.875 5.618l18.766 33.9A1 1 0 0 1 42.766 41H5.234a1 1 0 0 1-.875-1.484l18.766-33.9a1 1 0 0 1 1.75.002zM23.835 14c1.015 0 1.837.822 1.837 1.836v14.022a1.836 1.836 0 0 1-3.672 0V15.836c0-1.014.822-1.836 1.836-1.836zm1.836 21.835a1.836 1.836 0 1 0-3.671 0 1.836 1.836 0 0 0 3.671 0z"/></svg>
  <span class="ibe-restriction-warning-text" [innerHTML]="getRestrictionText('maxLengthOfStay')"></span>
</div>
<div class="ibe-icon-legend" *ngIf="config.accountFeatureWhitelist.maintenanceDays && calendarDates.length">
  <div class="ibe-closed-arrival" *ngIf="someDayHasRestriction('closedOnArrival')">
    <svg class="ibe-closed-arrival-icon" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M2.5 19h19v2h-19v-2zm16.84-3.15c.8.21 1.62-.26 1.84-1.06.21-.8-.26-1.62-1.06-1.84l-5.31-1.42-2.76-9.02L10.12 2v8.28L5.15 8.95l-.93-2.32-1.45-.39v5.17l16.57 4.44z"/></svg>
    <span class="ibe-icon-description">{{ 'availability_calendar.closed_arrival' | translate }}</span>
  </div>
  <div class="ibe-closed-departure" *ngIf="someDayHasRestriction('closedOnDeparture')">
    <svg class="ibe-closed-departure-icon" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M2.5 19h19v2h-19v-2zm19.57-9.36c-.21-.8-1.04-1.28-1.84-1.06L14.92 10l-6.9-6.43-1.93.51 4.14 7.17-4.97 1.33-1.97-1.54-1.45.39 2.59 4.49L21 11.49c.81-.23 1.28-1.05 1.07-1.85z"/></svg>
    <span class="ibe-icon-description">{{ 'availability_calendar.closed_departure' | translate }}</span>
  </div>
</div>
<ibe-loading-bar [isLoading]="isLoading"></ibe-loading-bar>
