import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Params } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { SearchParamsUpgradeService } from './search-params-upgrade.service';

export const INVALID_PARAMS = 'Invalid Params';

export class AvailabilitySearchParams {
  constructor(readonly queryParams: Params) {}

  // filters out empty values
  public toHttpParams(): HttpParams {
    return this._toHttpParams(this._withCsvChildAges(this.queryParams));
  }

  public valid() {
    return this._valid(this.queryParams);
  }

  private _valid(queryParams: Params): Boolean {
    return !this._anythingMissing(queryParams) && !this._anythingIncorrectType(queryParams);
  }

  private _anythingMissing(queryParams: Params): boolean {
    return !queryParams.propertyId ||
    !queryParams.arrival ||
    !queryParams.departure ||
    !queryParams.adults ||
    !moment(queryParams.arrival, 'YYYY-MM-DD', true).isValid() ||
    !moment(queryParams.departure, 'YYYY-MM-DD', true).isValid() ||
    moment(queryParams.arrival).isBefore(moment(), 'day') ||
    moment(queryParams.departure).isBefore(moment(), 'day') ||
    moment(queryParams.departure).isSameOrBefore(moment(queryParams.arrival), 'day')
  }

  private _anythingIncorrectType(queryParams: Params): boolean {
    return !(typeof queryParams.propertyId === 'string') ||
    !(typeof queryParams.arrival === 'string') ||
    !(typeof queryParams.departure === 'string') ||
    !(typeof Number(queryParams.adults) === 'number' && !isNaN(Number(queryParams.adults)))
  }

  private _toHttpParams(params: Params): HttpParams {

    // must create special case for childrenAges as apparently this needs to be comma separated string?
    const allWantedItems = _.pick<Params>(params,
      ['propertyId', 'arrival', 'departure', 'adults', 'childrenAges', 'promoCode', 'region', 'filters' ]);
    const wantedItemsNoEmpties = _.pickBy<Params>(allWantedItems)

    const httpParams = new HttpParams({ fromObject: wantedItemsNoEmpties })
    return httpParams;
  }

  private _withCsvChildAges(queryParams: Params) {
    const childrenAges = this._paramValueToChildrenAges(queryParams.childrenAges);
    return {...queryParams, childrenAges}
  }

  // tslint:disable-next-line:no-any
  private _paramValueToChildrenAges(childrenAges: any) {
    // childrenAges could be an array of strings, or just a string

    if (Array.isArray(childrenAges)) {
      return childrenAges.join(',') ;
    } else if (childrenAges) {
      return childrenAges
    } else {
      return undefined;
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class SearchParamsService {
  constructor(private readonly searchParamsUpgrade: SearchParamsUpgradeService) { }
  /*
    Gives an observable that resolves when you get some valid params
    Will never resolve if invalid params are provided

    Danger: It might change queryParams triggering a re-run
    if it finds old params
  */
  public validParams(queryParams: Observable<Params>) {
    return queryParams.pipe(
      map((params) => new AvailabilitySearchParams(params)),
      map((searchParams) => this.searchParamsUpgrade.maybeUpgradeAndStop(searchParams)),
      mergeMap(searchParams =>
        searchParams.valid() ? of(searchParams) : throwError(INVALID_PARAMS)
      )
    );
  }
}
