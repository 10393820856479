import { Component, Input } from '@angular/core';

@Component({
  selector: 'ibe-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent {
  @Input('card') public card: {
    paymentMethod: string;
    cardNumber: string;
    cardName?: string;
    expiryMonth?: string;
    expiryYear?: string;
  }

  constructor() {}

  public getCardLastFourDigits(cardNumber: string) {
    const last4Numbers = -4;
    return cardNumber.slice(last4Numbers);
  }
}
