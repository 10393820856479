import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ToasterService } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { BookingModel, BookingConfirmationResponseModel, ReservationModel } from 'up-ibe-types';
import { environment } from '../../../environments/environment';
import { ImagesService } from '../../services/images.service';
import { BookingService } from '../../services/booking.service';
import { GuestAuthService } from '../../services/guest-auth.service';
import { IbeConfigService } from '../../services/ibe-config.service';
import { AnalyticsService } from '../../services/analytics.service';
import { LocalStorageService } from 'app/services/local-storage.service';

@Component({
  selector: 'ibe-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss']
})
export class BookingConfirmationComponent implements OnInit, OnDestroy {
  public isLoading = true;
  public bookings: BookingModel[];
  public bookingCurrency: string;
  public propertyId: string;
  public isLoggedIn: boolean;
  public areRatesSuppressed: boolean;

  constructor(
    public readonly config: IbeConfigService,
    public readonly guestAuthService: GuestAuthService,
    public readonly bookingService: BookingService,
    public readonly localStorageService: LocalStorageService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly toasterService: ToasterService,
    private readonly router: Router,
    private readonly translate: TranslateService,
    private readonly imagesService: ImagesService,
    public readonly http: HttpClient,
    public readonly analyticsService: AnalyticsService
  ) { }

  public ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      const httpParams = new HttpParams()
        .set('bookingRequestId', params.bookingRequestId);

      return this.http.get(environment.serverUrl + '/api/ibe/booking-confirmation',  {
        params: httpParams
      })
      .subscribe((response: BookingConfirmationResponseModel) => {
        if (response.bookings) {
          this.isLoading = false;
          this.bookings = response.bookings;
          this.areRatesSuppressed = !!(this.bookings.find(booking => {
            return booking.reservations.find(reservation => reservation.suppressedRate);
          }))
          this.propertyId = response.propertyId;
          this._setBookingCurrency();
          this._fireCompletedReservationAnalyticsEvent(params.bookingRequestId);
        }
      }, (error) => {
        this.isLoading = false;
        this.toasterService.pop('error',
        this.translate.instant('booking_confirmation.booking_not_found'),
        this.translate.instant('booking_confirmation.booking_not_found_for_booking_id'));
        this.router.navigate(['/']);
      });
    });
    this.isLoggedIn = this.guestAuthService.isLoggedIn();
  }

  public ngOnDestroy() {
    this.bookingService.clearBookingDataFromLocalStorage();
    this.bookingService.clearSearchCriteriaFromLocalStorage();
  }

  private _setBookingCurrency() {
    // we determine the currency code for the currency pipe from the booking reservation data
    if (this.bookings[0].reservations && this.bookings[0].reservations.length) {
      this.bookingCurrency = this.bookings[0].reservations[0].totalGrossAmount.currency;
    }
  }

  private _fireCompletedReservationAnalyticsEvent(bookingRequestId: string) {
    const firedBookingRequestId = this.localStorageService.getBookingRequestIdEventHasBeenFiredFor();

    if (!firedBookingRequestId || firedBookingRequestId !== bookingRequestId) {
      this.bookings.forEach((booking: BookingModel) => {
        this.analyticsService.createCompletedReservationsEvent(bookingRequestId, booking.reservations);
      });
      this.localStorageService.setBookingRequestIdEventHasBeenFiredFor(bookingRequestId);
    }
  }

  public getUnitTypeImageUrl(unitTypeId: string) {
    return this.imagesService.getUnitTypeImageUrl(this.propertyId, unitTypeId);
  }

  public calculateBookingTotals(reservations: ReservationModel[]) {
    return this.bookingService.calculateBookingTotals(reservations);
  }
}
