import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BookingSearchComponent } from './booking/booking-search/booking-search.component';
import { ResultsContainerComponent } from './booking/results-container/results-container.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CheckoutDetailsComponent } from './checkout/checkout-details/checkout-details.component';
import { CheckoutAddressComponent } from './checkout/checkout-address/checkout-address.component';
import { CheckoutPaymentComponent } from './checkout/checkout-payment/checkout-payment.component';
import { CheckoutPaymentErrorComponent } from './checkout/checkout-payment-error/checkout-payment-error.component';
import { BookingConfirmationComponent } from './booking/booking-confirmation/booking-confirmation.component';
import { BookingManagementComponent } from './booking-management/booking-management.component';
import { GuestManagementComponent } from './guest-management/guest-management.component';
import { GuestCreateAccountComponent } from './guest-management/guest-create-account/guest-create-account.component';
import {
  GuestManagementOptionsComponent
} from './guest-management/guest-management-options/guest-management-options.component';
import { EditAccountComponent } from './guest-management/edit-account/edit-account.component';
import {
  ReservationDetailComponent
} from './guest-management/reservations-history/reservation-detail/reservation-detail.component';
import { ReservationHistoryComponent } from './guest-management/reservations-history/reservations-history.component';
import { ModifyReservationComponent } from './guest-management/modify-reservation/modify-reservation.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { RoomResultsGuardService } from './services/room-results-guard.service';
import { GuestManagementGuardService } from './services/guest-management-guard.service';
import { LogGuestMovementGuard } from './guards/log-guest-movement-guard';
import { GuestPasswordResetComponent } from './guest-management/guest-password-reset/guest-password-reset.component';
import { AnchorGuard } from './guards/anchor-guard';
import {
  GuestAccountAuthComponent
} from './guest-management/guest-create-account/guest-account-auth/guest-account-auth.component';
import { IBE_STEPS } from './steps-bar/step.type';

const appRoutes: Routes = [
  {
    path: '',
    canActivateChild: [ AnchorGuard ],
    children: [
      {
        path: '',
        redirectTo: '/booking/search',
        pathMatch: 'full'
      },
      {
        path: 'booking',
        redirectTo: '/booking/search',
        pathMatch: 'full'
      },
      {
        path: 'booking',
        children: [
          {
            path: 'search',
            component: BookingSearchComponent,
            data: {
              step: IBE_STEPS.STEP_SEARCH_OFFER
            }
          },
          {
            path: 'results',
            component: ResultsContainerComponent,
            data: {
              step: IBE_STEPS.STEP_RESULTS_OFFER
            },
            canActivate: [ RoomResultsGuardService ]
          }
        ]
      },
      {
        path: 'checkout',
        redirectTo: '/checkout/details',
        pathMatch: 'full'
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
        data: {
          step: IBE_STEPS.STEP_CHECKOUT_DETAILS
        },
        children: [
          {
            path: 'details',
            component: CheckoutDetailsComponent,
            data: {
              isReservationsEditable: true
            }
          },
          {
            path: 'address',
            component: CheckoutAddressComponent,
            data: {
              isReservationsEditable: true
            }
          },
          {
            path: 'payment',
            component: CheckoutPaymentComponent,
            data: {
              isReservationsEditable: false
            },
            canDeactivate: [LogGuestMovementGuard]
          },
          {
            path: 'payment/error',
            component: CheckoutPaymentErrorComponent
          }
        ]
      },
      {
        path: 'booking/confirmation',
        component: BookingConfirmationComponent,
        data: {
          step: IBE_STEPS.STEP_CHECKOUT_DETAILS
        }
      },
      {
        path: 'guest',
        redirectTo: '/guest/manage',
        pathMatch: 'full'
      },
      {
        path: 'account/create',
        component: GuestCreateAccountComponent
      },
      {
        path: 'guest/account/reset-password',
        component: GuestPasswordResetComponent
      },
      {
        path: 'guest/account/complete-reset-password/:resetToken',
        component: GuestPasswordResetComponent
      },
      {
        path: 'guest/account/authenticate',
        component: GuestAccountAuthComponent
      },
      {
        path: 'guest',
        component: GuestManagementComponent,
        canActivate: [ GuestManagementGuardService ],
        children: [
          {
            path: 'manage', component: GuestManagementOptionsComponent
          },
          {
            path: 'account/edit',
            component: EditAccountComponent
          },
          {
            path: 'reservations',
            redirectTo: '/guest/reservations/history',
            pathMatch: 'full'
          },
          {
            path: 'reservations',
            children: [
              {
                path: 'history',
                component: ReservationHistoryComponent
              },
              {
                path: 'details',
                component: ReservationDetailComponent
              },
              {
                path: 'modify',
                component: ModifyReservationComponent
              }
            ]
          }
        ]
      },
      {
        path: ':bookingOrReservation/manage',
        component: BookingManagementComponent
      },
      {
        path: '404',
        component: ErrorPageComponent
      },
      {
        path: '**',
        component: ErrorPageComponent
      }
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(appRoutes, {anchorScrolling: 'enabled'}) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
