import { Component, OnInit, OnDestroy, ViewChild, Output, EventEmitter, ElementRef, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToasterService } from 'angular2-toaster';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { GalleryDialogComponent } from './gallery-dialog/gallery-dialog.component';
import { AlertDialogComponent } from 'app/alert-dialog/alert-dialog.component';
import { BookingService, Cart } from 'app/services/booking.service';
import { ImagesService } from 'app/services/images.service';
import { IbeConfigService } from 'app/services/ibe-config.service';
import { AnalyticsService } from 'app/services/analytics.service';
import { scrollToElement } from 'app/helpers/scroll.helper';
import {
  AvailabilityResultModel,
  UnitTypeModel,
  EmbeddedItemModel,
  Property
} from 'up-ibe-types';
import { OnAddToCartEvent } from './rate-selector/rate-selector.component';
import { SearchParamsService, INVALID_PARAMS } from 'app/services/room-results/search-params.service';
import { ExtrasDialogService } from 'app/services/extras-dialog.service';
import { map, startWith, catchError, mapTo, tap } from 'rxjs/operators';
import { of, combineLatest, Observable, merge, Subscription } from 'rxjs';
import { AvailabilityStoreService } from 'app/services/availability-store.service';
import { ErrorDialogComponent } from 'app/error-dialog/error-dialog.component';
import { WindowLocationService } from 'app/services/window-location.service';
import { GuestAuthService } from 'app/services/guest-auth.service';
import { GuestLoginComponent } from 'app/guest-management/guest-login/guest-login.component';

@Component({
    selector: 'ibe-room-results',
    templateUrl: './room-results.component.html',
    styleUrls: ['./room-results.component.scss'],
})
export class RoomResultsComponent implements OnInit, OnDestroy {
  @ViewChild('roomResultsContainer') set roomResultsContainer(elementRef: ElementRef) {
    if (elementRef && this.config.settings.autoscrollEnabled) {
      scrollToElement(elementRef);
    }
  }
  @Output('onLoadingEmit') public onLoadingEmit: EventEmitter<Observable<boolean>> = new EventEmitter();
  @HostBinding('class.no-availability') public noAvailabilityClass = false;

    public propertyId: string;
    public property: EmbeddedItemModel;
    public selectedUnitQty: number;
    public promoCode: string;
    public region: string;
    public descriptionId: string;
    public availability$: Observable<AvailabilityResultModel[]>;
    public loading$: Observable<boolean>;
    public noAvailability$: Observable<boolean>;
    public noAvailabilityShowCalendar$: Observable<boolean>;
    public subscription: Subscription;
    public showAvailabilityCalendar = false;
    public displayInclusiveExtrasAsTaxes = false;
    public isCustomSearch = false; // what does CustomSearch mean?
    public descriptionPanelIsExpanded: boolean[] = [];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly analyticsService: AnalyticsService,
    private readonly bookingService: BookingService,
    private readonly toasterService: ToasterService,
    private readonly translate: TranslateService,
    private readonly imagesService: ImagesService,
    private readonly searchParamsService: SearchParamsService,
    private readonly extrasDialogService: ExtrasDialogService,
    public readonly windowLocationService: WindowLocationService,
    public readonly dialog: MatDialog,
    public readonly availabilityStoreService: AvailabilityStoreService,
    public readonly config: IbeConfigService,
    public readonly guestAuthService: GuestAuthService
  ) {}

  public ngOnInit() {
    this.isCustomSearch = this.config.accountFeatureWhitelist.specialMemberRatesView;
    const validParams$ = this.searchParamsService.validParams(this.route.queryParams);

    validParams$.subscribe(
      (params) => {
        if (params.queryParams.propertyId !== undefined) {
          this.config.setCurrentProperty(params.queryParams.propertyId);
        }
      }
    );

    this.config.getCurrentPropertySubscribable().subscribe(
      (property: Property | undefined) => {
        if (!property) {
          this.displayInclusiveExtrasAsTaxes = false;
        }

        if (property?.config.displayInclusiveExtrasAsTaxes !== undefined) {
          this.displayInclusiveExtrasAsTaxes = property.config.displayInclusiveExtrasAsTaxes;
        }
      }
    );

    this.availability$ = this.availabilityStoreService.availability$(validParams$);
    this.loading$ = merge<boolean, boolean>(
      validParams$.pipe(mapTo(true)),
      this.availability$.pipe(mapTo(false))
    ).pipe(
      startWith(true),
      catchError(_ => of(false))
    );

    this.onLoadingEmit.emit(this.loading$);

    this.noAvailability$ = this.availability$.pipe(
      map((availability) => availability.length < 1),
      tap((noAvailability: boolean) => this.noAvailabilityClass = noAvailability)
    )

    this.noAvailabilityShowCalendar$ = this.noAvailability$.pipe(
      map((noAvailability) => noAvailability && this.config.settings.availabilityCalendarEnabled),
      startWith(false)
    )

    this.subscription = combineLatest(validParams$, this.availability$)
      .subscribe(([searchParams, availability]) => {
        this.propertyId = searchParams.queryParams.propertyId;
        this.promoCode = searchParams.queryParams.promoCode;
        this.bookingService.saveAvailabilityResult(availability);

        if (availability.length) {
          this.property = availability[0].property;

          this.analyticsService.createRoomImpressionsEvent(this.property, availability, searchParams.toHttpParams());
        } else {
          const property = this.config.findPropertyById(searchParams.queryParams.propertyId);
          this.analyticsService.createNoResultsEvent(searchParams.toHttpParams(), property);
        }
      },
        (err) => {
          if (err !== INVALID_PARAMS) {
            this.dialog.open(ErrorDialogComponent, {
              data: {
                title: this.translate.instant('room_results.availability_error_title'),
                message: this.translate.instant('room_results.availability_error_message'),
                allowRetry: true
              }
            }).afterClosed().subscribe((retry: boolean) => {
              if (retry) {
                this.windowLocationService.reload();
              }
            });
            return err;
          }
          this.dialog.open(AlertDialogComponent, {
            data: {
              title: this.translate.instant('room_results.invalid_search_params_error_title'),
              message: this.translate.instant('room_results.invalid_search_params_error_message')
            }
          }).afterClosed().subscribe(() => {
            this.bookingService.clearSearchCriteriaFromLocalStorage();
            return this.router.navigate(['booking/search']);
          })
        });

    // this.filterSubscirption = this.route.queryParams.subscribe((params) => {

    // })
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public openGalleryDialog(unitType: UnitTypeModel) {
    this.analyticsService.createRoomClickEvent(this.property.name, unitType);
    this.analyticsService.createRoomViewEvent(this.property.name, unitType);
    this.dialog.open(GalleryDialogComponent, {
      data: {
        propertyId: this.propertyId,
        unitType
      }
    });
  }

  public getUnitTypeImageUrl(unitTypeId: string) {
    return this.imagesService.getUnitTypeImageUrl(this.propertyId, unitTypeId);
  }

  public openDescription(id: string, element: Element) {
    // setTimeout is included to drag this to the back of the event loop so the height of the element is set properly.
    setTimeout(() => {
      element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }, 0);

    if (id === this.descriptionId) {
      this.descriptionId = '';
      return
    }
    this.descriptionId = id;
  }

  public toggleAvailabilityCalendar() {
    this.showAvailabilityCalendar = !this.showAvailabilityCalendar;
  }

  public onAddToCart($event: OnAddToCartEvent) {
    if ($event.selectedUnitQty > this.config.settings.roomQtySelectorLimit
      || (this.bookingService.booking.reservations.length + $event.selectedUnitQty) > this.config.settings.roomQtySelectorLimit
    ) {
      this.toasterService.pop('error',
        this.translate.instant('room_results.addtocart_failed'),
        this.translate.instant('room_results.max_rooms_exceeded')
      )
      return;
    }

    if ($event.availabilityResultRate.isMemberRate && !this.guestAuthService.isLoggedIn()) {
      this.dialog.open(GuestLoginComponent, {
        height: '550px',
        width: '500px'
      }).afterClosed().subscribe((loggedIn: boolean) => {
        if (this.windowLocationService.href.includes('create')) {
          this.dialog.closeAll();
          return;
        } else if (!loggedIn) {
          this.windowLocationService.reload();
        } else {
          this._setupSaveCart($event);
        }
      });
    } else {
      this._setupSaveCart($event);
    }
  }

  private _setupSaveCart($event: OnAddToCartEvent) {
    this.selectedUnitQty = $event.selectedUnitQty;

    this.analyticsService.createRoomClickEvent(
      this.property.name, $event.availabilityResultRate);
    this.analyticsService.createRoomViewEvent(
      this.property.name, $event.availabilityResultRate);

    this.extrasDialogService.open(
              this.route.snapshot.queryParams,
              $event.availabilityResultRate.id,
              this.property.id
      ).subscribe(([hasSomeExtras, selectedExtras]) => {
        this.analyticsService.createExtrasImpressionsEvent(this.property, selectedExtras)

      selectedExtras = $event.featuredExtra ? [$event.featuredExtra] : selectedExtras

      this.analyticsService.createExtrasImpressionsEvent(this.property, selectedExtras)

      const cart = Cart.FromParams(
        this.route.snapshot.queryParams,
        $event.availabilityResult,
        $event.availabilityResultRate
      ).withSelectedExtras(selectedExtras)
        .withExtrasAvailable(hasSomeExtras);

        this._saveCart(cart);
    })
  }

  private _saveCart(
    cart: Cart
  ) {
    const addedOk = this.bookingService.addReservationToBooking(
      cart,
      this.selectedUnitQty
    );

    if (addedOk) {
      this.analyticsService.createRoomAddToCartEvent(this.selectedUnitQty, cart.getReservation());
      this.analyticsService.createExtrasAddToCartEvent(cart.getReservation());
      this.toasterService.pop('success',
        this.translate.instant('room_results.room_added'),
        this.translate.instant('room_results.room_added_to_cart')
      );
      this.router.navigate(['/checkout/details']);
    }
  }
}
