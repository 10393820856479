import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Moment } from 'moment';
import { CalendarStayDateSelectionEvent } from '../../booking/availability-calendar/calendar/calendar';
import { IbeConfigService } from 'app/services/ibe-config.service';

@Component({
  selector: 'ibe-native-datepicker',
  templateUrl: './native-datepicker.component.html',
  styleUrls: ['./native-datepicker.component.scss']
})
export class NativeDatepickerComponent implements OnInit {
  @Input('arrival') public arrivalDate: Date | undefined;
  @Input('departure') public departureDate: Date | undefined;
  @Input('startDate') public startDate: Date;
  @Output('onStayDateSelection') public onStayDateSelection: EventEmitter<CalendarStayDateSelectionEvent> = new EventEmitter();
  public arrivalMinDate: Moment;
  public departureMinDate: Moment;
  public maximumDate: string;

  constructor(
    public readonly currentRoute: ActivatedRoute,
    public readonly router: Router,
    public readonly config: IbeConfigService
  ) { }

  public ngOnInit() {
    const MAX_YEARS = 5;
    this.arrivalMinDate = moment(this.startDate);
    if (this.arrivalMinDate.isBefore(moment(), 'day')) {
      this.arrivalMinDate = moment();
    }
    this.departureMinDate = moment(this.arrivalMinDate).add(1, 'day');
    this.maximumDate = moment().add(MAX_YEARS, 'years').format('YYYY-MM-DD');
    this._initData();
  }

  private _initData() {
    const queryParams = this.currentRoute.snapshot.queryParams;
    this.arrivalDate = moment(queryParams.arrival).toDate();
    this.departureDate = moment(queryParams.departure).toDate();
    if (!queryParams.arrival || moment(queryParams.arrival).isBefore(this.arrivalMinDate, 'day')) {
      this.arrivalDate = this.arrivalMinDate.toDate();
      this.departureDate = this.departureMinDate.toDate();
    }
  }

  public onArrivalDateChange(arrivalDate: string) {
    if (!arrivalDate) {
      return;
    }
    if (moment(arrivalDate).isSameOrBefore(this.arrivalMinDate)) {
      return;
    }

    this.departureMinDate = moment(arrivalDate).add(1, 'day');
    const departureDate = moment(this.departureDate);

    if (moment(arrivalDate).isSameOrAfter(departureDate)) {
      // tslint:disable-next-line: no-magic-numbers
      this.departureDate = moment(arrivalDate).add(1, 'day').toDate();
    }
    // tslint:disable-next-line: no-magic-numbers
    this.maximumDate = moment(arrivalDate).add(5, 'years').format('YYYY-MM-DD');
    this.arrivalDate = moment(arrivalDate).toDate();
    this._stayDateSelected();
  }

  public onDepartureDateChange(departureDate: string) {
    if (!departureDate) {
      return;
    }

    if (moment(departureDate).isSameOrBefore(this.departureMinDate)) {
      return;
    }

    const arrivalDate = moment(this.arrivalDate);
    if (moment(departureDate).isSameOrBefore(arrivalDate)) {
      this.departureDate = arrivalDate.add(1, 'day').toDate();
    }
    this.departureDate = moment(departureDate).toDate();
    this._stayDateSelected();
  }

  private _stayDateSelected() {
    if (this.arrivalDate && this.departureDate) {
      this.onStayDateSelection.emit({
        arrivalDate: this.arrivalDate,
        departureDate: this.departureDate
      });
    }
  }

  public formatDateForHtmlInput(date: Moment | Date | undefined) {
    if (date) {
      return moment(date).format('YYYY-MM-DD');
    }
    return;
  }
}
