import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AvailabilityResultModel } from 'up-ibe-types';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  private availabilityEvent: Subject<AvailabilityResultModel[]> = new Subject();
  private stepEvent: Subject<string> = new Subject();

  constructor() {}

  public getAvailabilityEvent() {
    return this.availabilityEvent;
  }

  public getStepEvent() {
    return this.stepEvent;
  }
}
