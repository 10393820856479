<div class="ibe-steps-bar" *ngIf="currentStep">
  <div class="ibe-steps">
    <div class="ibe-step" *ngFor="let step of steps" [ngClass]="{'ibe-active-step': isStepActive(step.handle)}" (click)="goToStep(step)">{{step.number}}</div>
  </div>
  <div class="ibe-step-title">{{currentStep.title}}</div>
  <div class="ibe-guest-manage-page-link" *ngIf="config.settings.guestManagementEnabled">
    <span *ngIf="!guestAuthService.isLoggedIn() && (currentStep.handle !== 'checkout')">
      <a routerLink="/account/create">{{ 'guest_management_auth.register' | translate }}</a> {{ 'global.or' | translate }}
      <a class="ibe-login-link" (click)="goToGuestManagement($event)" href=""> {{ 'guest_management_auth.login' | translate }}</a>
    </span>
    <span *ngIf="!guestAuthService.isLoggedIn() && (currentStep.handle === 'checkout')">
      <a class="ibe-login-link" (click)="goToGuestManagement($event)" href="">{{ 'guest_management_auth.login' | translate }}</a> {{ 'guest_management.load_details_automatically' | translate }}
    </span>
    <a routerLink="/guest/manage" *ngIf="guestAuthService.isLoggedIn()">{{ 'guest_management.manage_reservations' | translate }}</a>
  </div>

  <!-- only way to silence a18y errors. about this
    fragment not pointing to an id.
    no other choice as we have to use fragment routing. -->
  <div id="/account/create"></div>

</div>
