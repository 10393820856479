import { ReservationModel, ExtraModel, TaxModel } from 'up-ibe-types';
import { TaxTypeEnum } from '../../enums';
import * as _ from 'lodash';

export interface BookingTotals {
  netTotal: number;
  cityTaxTotal: number;
  touristTaxTotal: number;
  salesTaxTotal: number;
  taxTotal: number;
  grossTotal: number;
  serviceChargeTotal: number;
  breakdown: TaxModel[];
  additionalIncludedExtrasBreakdown: ExtraModel[];
}

const calculateBookingTotals = (reservations: ReservationModel[], displayInclusiveExtrasAsTaxes: boolean = false): BookingTotals => {
  let grossTotal = 0;
  let taxTotal = 0;
  let cityTaxTotal = 0;
  let touristTaxTotal = 0;
  let salesTaxTotal = 0;
  let serviceChargeTotal = 0;
  let additionalIncludedExtras: ExtraModel[] = [];
  let additionalIncludedExtrasTotal = 0;

  reservations.forEach((reservation) => {
    if (reservation.includedExtras !== undefined && reservation.includedExtras.length) {
      const reservationAdditionalExtras = reservation.includedExtras.filter((extra: ExtraModel) => {
        return extra.pricingMode === 'Additional';
      });

      if (reservationAdditionalExtras.length) {
        additionalIncludedExtras = additionalIncludedExtras.concat(reservationAdditionalExtras);
      }
    }

    grossTotal = grossTotal + (reservation.totalGrossAmount.amount || 0);

    if (reservation.extras) {
      grossTotal = grossTotal + calculateExtrasTotals(reservation.extras).grossTotal;
    }
  });

  additionalIncludedExtrasTotal = additionalIncludedExtras.reduce((runningTotal, currentExtra) => {
    return currentExtra.totalGrossAmount !== undefined ?
      runningTotal + currentExtra.totalGrossAmount?.amount :
      runningTotal;
  }, 0);

  const addUp = (reservation: ReservationModel|ExtraModel) => {
    if (reservation.taxes) {
      reservation.taxes.forEach((tax) => {
        if (tax.type === TaxTypeEnum.VAT) {
          taxTotal = taxTotal + tax.amount;
        }
        if (tax.type === TaxTypeEnum.CityTax) {
          cityTaxTotal = cityTaxTotal + tax.amount;
        }
        if (tax.type === TaxTypeEnum.ServiceCharge) {
          serviceChargeTotal = serviceChargeTotal + tax.amount;
        }
        if (tax.type === TaxTypeEnum.TouristTax) {
          touristTaxTotal = touristTaxTotal + tax.amount;
        }
        if (tax.type === TaxTypeEnum.SalesTax) {
          salesTaxTotal = salesTaxTotal + tax.amount;
        }
      });
    }
  }

  reservations.forEach(addUp);

  // We add taxes from extras to the total. This is necessary
  // for US hotels on Apaleo.
  reservations.forEach((reservation) => reservation.extras.forEach(addUp));

  // for now, just include breakdowns for service charges
  // not used in calculations, only for display
  const taxAggregator = (existingTaxes: TaxModel[], reservation: ReservationModel|ExtraModel) => {
    const taxes = reservation.taxes ?? [];
    return existingTaxes.concat(taxes);
  };
  const rateTaxes = reservations.reduce(taxAggregator, []);
  const extraTaxes = reservations.reduce<TaxModel[]>((existingTaxes, reservation) => {
    return existingTaxes.concat(reservation.extras.reduce<TaxModel[]>(taxAggregator, []))
  }, []);
  const allTaxes = rateTaxes.concat(extraTaxes);

  const allServiceCharges = allTaxes.filter((tax) => tax.type === TaxTypeEnum.ServiceCharge);

  const updateArrayAt = (array: TaxModel[], index: number, value: TaxModel) => {
    return Object.assign([], array, {[index]: value});
  }

  const breakdown = allServiceCharges.reduce<TaxModel[]>((output, tax) => {
    const existingEntry = output.find((otherTax) => tax.id === otherTax.id);
    if (existingEntry) {
      const existingEntryIndex = output.indexOf(existingEntry);
      const amount = existingEntry.amount + tax.amount;
      const updatedTaxEntry = {...existingEntry, amount};
      // don't add a new entry, just update the existing one
      return updateArrayAt(output, existingEntryIndex, updatedTaxEntry);
    } else {
      // do add a new entry
      return output.concat(tax);
    }
  }, []);

  const additionalIncludedExtrasBreakdown = additionalIncludedExtras.reduce<ExtraModel[]>((output, extra) => {
    const existingEntry = output.find((otherExtra) => extra.id === otherExtra.id);
    if (existingEntry && existingEntry.totalGrossAmount !== undefined && extra.totalGrossAmount !== undefined) {
      const existingEntryIndex = output.indexOf(existingEntry);
      const amount = existingEntry.totalGrossAmount.amount + extra.totalGrossAmount.amount;
      const updatedExtraEntry = {
        ...existingEntry,
        totalGrossAmount: {
          amount,
          currency: extra.totalGrossAmount.currency
        }
      };

      return Object.assign([], output, {[existingEntryIndex]: updatedExtraEntry});
    } else {
      return output.concat(extra);
    }
  }, []);

  return {
    netTotal: displayInclusiveExtrasAsTaxes ? grossTotal - taxTotal - additionalIncludedExtrasTotal : grossTotal - taxTotal,
    taxTotal,
    grossTotal: grossTotal + cityTaxTotal + touristTaxTotal + salesTaxTotal + serviceChargeTotal,
    cityTaxTotal,
    touristTaxTotal,
    salesTaxTotal,
    serviceChargeTotal,
    breakdown,
    additionalIncludedExtrasBreakdown
  }
}

const calculateExtrasTotals = (extras: ExtraModel[]) => {
  let grossTotal = 0;
  let taxTotal = 0;

  extras.forEach((extra) => {
    if (extra.isInclusiveInRate === true || extra.pricingMode === 'Inclusive') {
      return;
    }

    if (extra.totalGrossAmount) {
      grossTotal = grossTotal + extra.totalGrossAmount.amount;
    }

    if (extra.taxes) {
      extra.taxes.forEach(tax => {
        // nobody else is using ServiceCharge except the US right now
        // so we have this exclusion to stop US sasles taxes getting counted up
        // as VAT
        if (tax.type !== TaxTypeEnum.ServiceCharge) {
          taxTotal = taxTotal + tax.amount;
        }
      });
    }
  });

  return {
    grossTotal,
    taxTotal
  }
}

const calculateCityTaxEstimate = (reservations: ReservationModel[]) => {
  return reservations.map(reservation => {
    if (reservation.estimatedCityTax) {
      return reservation.estimatedCityTax.amount;
    }
    return 0;
  }).reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);
}

// tslint:disable-next-line
const serializeQueryParams = (params: any) => {
  return Object.keys(params)
    .filter((param) => params[param])
    .map((param) => { return param + '=' + params[param]; })
    .join('&');
};

export {
  calculateBookingTotals,
  calculateExtrasTotals,
  serializeQueryParams,
  calculateCityTaxEstimate
}
