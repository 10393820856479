// tslint:disable:no-magic-numbers
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as tinycolor from 'tinycolor2';
import { IbeConfigService } from './ibe-config.service';
@Injectable()
export class ThemeService {

  constructor(
    public config: IbeConfigService,
    private sanitizer: DomSanitizer
  ) { }

  private get primaryColour() {
    const primaryColour = this.sanitizer.sanitize(SecurityContext.STYLE, this.config.settings.primaryColour);

    if (primaryColour) {
      return primaryColour;
    } else {
      return '';
    }
  }

  public generateThemeCss() {
    return this.sanitizer.bypassSecurityTrustHtml(`
      <style>
        .ibe-loader-bar,
        .ibe-primary-btn,
        .ibe-rate-options .ibe-rate-option.selected .ibe-radio-inner,
        .ibe-add-another-room-btn:hover,
        .ibe-extra-toggle-status,
        .ibe-rate-options .ibe-rate-option .ibe-rate-option-left .ibe-rate-info-btn,
        .mat-calendar-body-selected,
        .ibe-cart.open .ibe-checkout-btn,
        .ibe-checkout-btn:hover,
        .chckt-button--submit,
        .cal-day-selected,
        .ibe-calendar-day-arrival-date,
        .ibe-calendar-day-departure-date,
        .ibe-calendar-day-departure-date,
        .ibe-calendar-day:hover,
        .ibe-carousel-next,
        .ibe-carousel-prev,
        .ibe-add-extras-btn,
        .ibe-cancellation-policy-btn:hover,
        .ibe-credit-card #cardfront,
        .ibe-pay-btn,
        .ibe-persons-qty-selector-button:hover,
        .ibe-promo-rate-title,
        .ibe-checkbox-frame input[type="checkbox"]:checked ~ .ibe-checkmark,
        .ibe-date-restriction-warning-text,
        .ibe-restrictions-ok-btn,
        .ibe-double-bounce-1,
        .ibe-double-bounce-2,
        .ibe-reservation-pay-now-tooltip,
        .ibe-guest-create-email,
        .ibe-download-invoice-btn,
        .ibe-room-content-button,
        .ibe-special-rate-title,
        .ibe-guest-availability-dialog-footer button,
        .ibe-retry-btn,
        .ibe-rate-info-btn,
        .ibe-included-extra-info .ibe-included-extra-text,
        .ibe-checkout-email-tooltip,
        .mat-checkbox-ripple .mat-ripple-element,
        .mat-checkbox-checked.mat-accent .mat-checkbox-background,
        .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
          background-color: ${this.primaryColour}!important;
        }

        .ibe-unit-qty-button {
          background-color: ${tinycolor(this.primaryColour).setAlpha(.8).toRgbString()}!important;
        }

        .ibe-date-restriction-warning-text::before {
          border-color: ${this.primaryColour} transparent transparent transparent!important;
        }

        .ibe-steps-bar .ibe-steps .ibe-step.ibe-active-step,
        .ibe-steps-bar .ibe-steps .ibe-step:hover,
        .ibe-rate-options .ibe-rate-option.selected .ibe-rate-option-left,
        .ibe-rate-options .ibe-rate-option.selected .ibe-featured-extra,
        .ibe-rate-options .ibe-rate-option:hover,
        .ibe-add-another-room-btn,
        .ibe-booking-id-box,
        .ibe-calendar-day-stay-date,
        .ibe-no-reservations,
        .ibe-guest-management-modify-reservation-head {
          background-color: ${tinycolor(this.primaryColour).setAlpha(.2).toRgbString()}!important;
        }

        .ibe-rate-options .ibe-rate-option .ibe-rate-option-right .ibe-unit-qty-select, .ibe-unit-qty-display {
          background-color: ${tinycolor(this.primaryColour).setAlpha(.5).toRgbString()}!important;
          color: #fff!important;
        }

        .ibe-rate-options .ibe-rate-option {
          border-bottom-color: ${tinycolor(this.primaryColour).setAlpha(.2).toRgbString()}!important;
        }

        .up-ibe h1,
        .ibe-form-control,
        .ibe-steps-bar .ibe-step-title,
        .ibe-checkout-btn,
        .ibe-cart-top .ibe-icon,
        .ibe-reservation .ibe-detail .ibe-title,
        .ibe-reservation .ibe-detail .ibe-stay-details,
        .ibe-reservation .ibe-price,
        .ibe-cancellation-policy-btn,
        .ibe-room-title,
        .ibe-room-results-title,
        .ibe-rate-options .ibe-rate-option .ibe-rate-option-left .ibe-rate-title,
        .ibe-from-price,
        .ibe-more-info-btn,
        .ibe-checkout-step-title,
        .ibe-form-group label,
        .ibe-add-another-room-btn,
        .ibe-total-cost,
        .ibe-vat-breakdown-btn,
        .ibe-total-summary,
        .ibe-checkout-step-checklist .ibe-icon-edit,
        .ibe-confirmation-heading,
        .ibe-booking-id-box,
        .ibe-total,
        .ibe-total .ibe-total-price,
        .modal-title,
        .ibe-skip-extras-btn,
        .ibe-extra-name,
        .ibe-extra-price,
        .ibe-reservation-extra-price,
        .ibe-reservation-extra-name .ibe-icon,
        .ibe-reservation-title,
        .ibe-reservation-price,
        .ibe-reservation-stay-details,
        .ibe-room-overview-title,
        .ibe-no-room-results,
        .ibe-steps-bar .ibe-steps .ibe-step,
        .ibe-custom-select,
        .mat-dialog-title,
        .ibe-terms-link,
        .ibe-availability-calendar-button,
        .ibe-calendar-day,
        .ibe-vat-breakdown-mobile-btn,
        .ibe-grey-panel dl dt,
        .ibe-no-reservations,
        .ibe-property-name,
        .mat-autocomplete-panel .mat-optgroup .mat-optgroup-label,
        .ibe-availability-restrictions h2,
        .ibe-flexible-guarantee-message,
        .ibe-pre-payment-message,
        .ibe-modal-subtitle,
        .ibe-guest-management,
        .ibe-extra-image,
        .ibe-login-title,
        .ibe-no-account-link a,
        .ibe-guest-management,
        .ibe-guest-management-option,
        .ibe-extra-image,
        .ibe-create-guest-account,
        .ibe-no-account-link a,
        .ibe-guest-create-login-link a,
        .ibe-guest-management-bookings-row td a,
        .ibe-reservation-add-extra-btn,
        .ibe-cancel-rerservation-btn,
        .ibe-reservation-detail-cancellation-btn,
        .ibe-reservation-detail-rate-plan svg,
        .ibe-reservation-rate-plan svg,
        .ibe-active-link,
        .ibe-room-custom-content-closed-description,
        .ibe-room-custom-content-open-description,
        .ibe-guest-booker-details dt,
        .ibe-guest-booker-details dt,
        .ibe-guest-manage-page-link a,
        .ibe-guest-management-sidebar-option h2 a,
        .ibe-guest-create-account-header nav a,
        .ibe-forgotten-password-link a,
        .ibe-manage-booking-link a,
        .ibe-login-link a,
        .ibe-guest-availability-dialog-footer,
        .ibe-guest-register-title,
        .ibe-guest-register-login span,
        .ibe-guest-management-bookings-table .ibe-guest-reservations .ibe-guest-reservation-status,
        .ibe-reservation-footer-links.ibe-reservation-action-btn.ibe-reservation-actions-open,
        .ibe-retry-btn:hover,
        .ibe-gallery-close,
        .ibe-region-results-title,
        .ibe-region-from-price-text,
        .ibe-icon.ibe-comment-clear,
        .ibe-member-rate-option .ibe-rate-title,
        .ibe-member-rate-option .ibe-rate-price {
          color: ${this.primaryColour} !important;
        }

        .ibe-custom-rate-select .ibe-unit-qty-button {
          background-color: transparent !important;
          color: ${tinycolor(this.primaryColour).setAlpha(.8).toRgbString()}!important;
        }

        .ibe-custom-rate-select .ibe-unit-qty-display {
          color: ${this.primaryColour}!important;
          background-color: transparent !important;
        }

        .ibe-custom-rate-select .ibe-unit-qty-button:disabled {
          color: ${tinycolor(this.primaryColour).setAlpha(.2).toRgbString()}!important;
        }

        .ibe-form-control:focus,
        .ibe-custom-select:focus,
        .ibe-cart.open .ibe-checkout-btn,
        .ibe-rate-options .ibe-rate-option .ibe-rate-option-left .ibe-radio-outer,
        .ibe-cancellation-policy-btn,
        .ibe-search-box .ibe-form-control:hover,
        .ibe-persons-qty-popup-open,
        .ibe-persons-qty-selector-button,
        .ibe-promo-rates,
        .ibe-date-input.ibe-date-input-active,
        .ibe-stay-date-selection ibe-date-range-picker,
        .ibe-checkbox-frame .ibe-checkmark,
        .ibe-special-rates,
        .ibe-reservation-status-select,
        .ibe-cancel-rerservation-btn,
        .ibe-retry-btn {
          border-color: ${this.primaryColour}!important;
        }

        .ibe-persons-qty-popup {
          border-left-color: ${this.primaryColour}!important;
          border-right-color: ${this.primaryColour}!important;
          border-bottom-color: ${this.primaryColour}!important;
        }

        .ibe-cart.open .ibe-cart-reservations {
          border-bottom-color: ${this.primaryColour}!important;
        }

        .ibe-total-cost.open {
          border-top-color: ${this.primaryColour}!important;
        }

        .ibe-extra-result:hover,
        .ibe-extra-result.ibe-extra-selected {
          border-left-color: ${this.primaryColour}!important;
        }

        .ibe-cart.open .ibe-checkout-btn,
        .ibe-cart .ibe-checkout-btn:hover,
        .ibe-cart.open .ibe-checkout-btn:hover,
        .ibe-add-another-room-btn:hover,
        .ibe-add-extras-btn:hover,
        .ibe-cancellation-policy-btn:hover,
        .ibe-calendar-day-active:hover .ibe-date-restriction-warning-icon path {
          color: #fff!important;
        }

        .ibe-rate-title,
        .ibe-rate-price,
        .ibe-rate-number-rooms {
          color: ${tinycolor(this.primaryColour).setAlpha(.5).toRgbString()}!important;
        }

        .ibe-room-image .ibe-room-image-inner:before {
          background-color: ${tinycolor(this.primaryColour).setAlpha(.5).toRgbString()}!important;
        }

        .ibe-reservation-detail-rate-plan:hover,
        .ibe-reservation-rate-plan:hover,
        .ibe-guest-create-login-link:hover {
          color: ${tinycolor(this.primaryColour).setAlpha(.8).toRgbString()}!important;
        }

        .ibe-add-extras-btn:hover,
        .ibe-guest-availability-dialog-footer button:hover {
          background-color: ${tinycolor(this.primaryColour).setAlpha(.8).toRgbString()}!important;
        }

        .ibe-guest-management-bookings-table .ibe-guest-reservations .ibe-guest-reservation-status,
        .ibe-reservation-footer-links.ibe-reservation-action-btn.ibe-reservation-actions-open {
          background-color: ${tinycolor(this.primaryColour).setAlpha(.2).toRgbString()}!important;
        }

        .ibe-booking-id {
          color: ${tinycolor(this.primaryColour).darken(15).toHexString()}!important;
        }

        .ibe-carousel-next:hover,
        .ibe-carousel-prev:hover {
          background-color: ${tinycolor(this.primaryColour).darken(10).toHexString()}!important;
        }

        .ibe-calendar-day-active:hover .ibe-date-restriction-warning-icon path,
        .ibe-calendar-day-arrival-date .ibe-date-restriction-warning-icon path,
        .ibe-calendar-day-departure-date .ibe-date-restriction-warning-icon path {
          fill: #fff !important;
        }

        .ibe-date-restriction-warning-icon path,
        .ibe-checkout-email-tooltip-icon circle {
          fill: ${this.primaryColour} !important;
        }

        .chckt-button--disabled {
          background-color: #d2dae1!important;
        }

        .ibe-included-extra-info .ibe-included-extra-text::before {
          border-color: transparent ${this.primaryColour} transparent transparent !important;
        }
        .ibe-included-extra-info .ibe-included-extra-text::after {
          border-color: transparent transparent transparent ${this.primaryColour} !important;
        }
      </style>`
    );
  }
// tslint:disable-next-line: max-file-line-count
}
