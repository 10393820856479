import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/browser';

import { environment } from 'environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { JourneyService } from './journey.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  private readonly reportToSentry = true as boolean;

// tslint:disable-next-line:no-any
  public static toError(error: any): Error {
    if (error instanceof Error) {
      return error;
    } else if (error instanceof Response || error instanceof HttpErrorResponse) {
      return ErrorHandlerService.formatHttpResponseError(error);
    } else {
      return ErrorHandlerService.formatError(error);
    }
  }

  private static formatHttpResponseError(responseError: Response | HttpErrorResponse) {
    const e = new Error(`HTTP Error | ${responseError.status} | ${responseError.statusText}`);
    e.stack = tryGetJson(responseError);
    return e;
  }

  // tslint:disable-next-line:no-any
  private static formatError(error: any) {
    const e = new Error(ErrorHandlerService.getNameFromError(error));
    e.stack = tryGetJson(error);

    return e;
  }

  // tslint:disable-next-line:no-any
  private static getNameFromError(error: any) {
    const maybeMsg = error.name || error.message;

    if (typeof (maybeMsg) === 'string') {
      // definitely OK to use this as an error name
      return maybeMsg;
    } else {
      // probably not safe, just call it an "Error"
      return 'Error';
    }
  }

  constructor(
    private injector: Injector,
    private journey: JourneyService
  ) {
    this.reportToSentry = tryInitializeSentry();
  }

  // tslint:disable-next-line:no-any
  public handleError(error: any): void {
    // We're seeing a lot of errors in sentry like:
    // Non-Error exception captured with keys: message, name, stack

    // Edward is trying to diangose this by ripping out the guts of this and implementing
    // something really simple, we can then bring pieces back in from there

    if (!error) {
      return;
    }

    // Having our own ErrorHandler silences errors, so we have to
    // show them again here
    console.error(error);

    this.sendToSentry(error);

    if (error instanceof HttpErrorResponse) {
      const serverInternalErrorCode = 500;
      if (error.status === serverInternalErrorCode) {
        const router = this.injector.get(Router);
        router.navigate(['/error'], { queryParams: { errorCode: error.status } });
      }
    }
  }

  // tslint:disable-next-line:no-any
  private sendToSentry(error: any) {
    if (this.reportToSentry) {
      const realError = ErrorHandlerService.toError(error);
      Sentry.withScope(scope => {
        scope.setExtra('journeyToken', this.journey.getJourneyToken());

        Sentry.captureException(realError);
      });
    }
  }
}

function tryGetJson(error: Object) {
  const numberOfSpaces = 2;
  try {
    return JSON.stringify(error, undefined, numberOfSpaces);
  } catch (e) {
    return `Could not stringify error object. Best I can do is typeof: ${typeof (error)}`;
  }
}

let sentryInitialized = false;

export function tryInitializeSentry(): boolean {
  // tslint:disable-next-line:no-console
  if (environment.sentry.enabled && !sentryInitialized) {
    const sentryOptions = {
      dsn: environment.sentry.endpointUrl,
      release: environment.sentry.release,

      // following suggestions on
      // https://docs.sentry.io/platforms/javascript/#decluttering-sentry
      // to cut down on the noise. Feel free to add your own
      // rules e.g. hide errors from jQuery or other common js
      // plugins
      ignoreErrors: [
        // Random plugins/extensions
        'top.GLOBALS',
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        'Can\'t find variable: ZiteReader',
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        // Facebook borked
        'fb_xd_fragment',
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
        // reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        'conduitPage'
      ],
      allowUrls: [
        'ibe.uphotel.agency/ibe.min.js',
        'ibe.staging.uphotel.agency/runtime.js',
        'ibe.staging.uphotel.agency/polyfills-es5.js',
        'ibe.staging.uphotel.agency/polyfills.js',
        'ibe.staging.uphotel.agency/scripts.js',
        'ibe.staging.uphotel.agency/vendor.js',
        'ibe.staging.uphotel.agency/main.js'
      ]
    };

    Sentry.init(sentryOptions);
    sentryInitialized = true;
  }

  return sentryInitialized;
}
